import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";
import { LoginService } from "../../../core/services/login.service";
import { NotifyParentService } from "../../../core/services/notify-parent.service";
import { Router } from "@angular/router";
import { AccountControllerService } from "../../../core/api/controllers/account-controller.service";
import { AuthControllerService } from "../../../core/api/controllers/auth-controller.service";

export interface IProcessingPaymentDialogData {
  personalDetailsForm: FormGroup;
  registrationPayload: any;
}

@Component({
  selector: "app-processing-payment-dialog",
  templateUrl: "./processing-payment-dialog.component.html",
  styleUrls: ["./processing-payment-dialog.component.scss"],
})
export class ProcessingPaymentDialogComponent implements OnInit {
  registerSuccess: { value: boolean } = { value: null };
  registerError: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProcessingPaymentDialogData,
    private loginService: LoginService,
    private authController: AuthControllerService,
    private router: Router,
    private notifyParent: NotifyParentService,
    public dialogRef: MatDialogRef<ProcessingPaymentDialogComponent>,
    private accountController: AccountControllerService,
  ) {}

  ngOnInit(): void {
    this.processPayment();
  }

  processPayment(): void {
    this.accountController.register(this.data.registrationPayload).subscribe(
      () => {
        this.registerSuccess = { value: true };
        sessionStorage.clear();

        setTimeout(() => {
          this.redirectToDashboard();
        }, 5000);
      },
      (error) => {
        this.registerSuccess = { value: false };
        if (error.status === 400) {
          const message = error.error.message;
          message.indexOf("Failed") >= 0
            ? (this.registerError = message.substring(
                message.lastIndexOf("Failed"),
                message.indexOf('","path'),
              ))
            : message.indexOf("already exists") >= 0
              ? (this.registerError =
                  "An account with this name already exists!")
              : (this.registerError =
                  "The server is currently busy. Please try again later.");
        } else {
          this.registerError = `An error occurred: ${error.status}: Server Error`;
        }
      },
    );
  }

  redirectToDashboard(): void {
    this.authController
      .login(
        this.data.personalDetailsForm.controls["username"].value,
        this.data.personalDetailsForm.controls["password"].value,
      )
      .then((response) => {
        this.loginService.setTokens(
          true,
          response["accessToken"],
          response["refreshToken"],
        );
        localStorage.setItem("selectedLocationId", response.selectedLocationId);
        this.dialogRef.close();
        this.router.navigate(["/"]);
        this.notifyParent.sendData("fullUpdate");
      })
      .catch(() => {});
  }
}
