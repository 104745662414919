export interface ILevel {
  id: string;
  name: string;
}

export class Level {
  id: string;
  name: string;

  constructor(level: ILevel) {
    this.id = level.id;
    this.name = level.name;
  }
}
