<div class="flex flex-col gap-5 p-5">
  <div>
    <h2>Change password</h2>
    <sup><b>*Note:</b> You will be asked to login again.</sup>
  </div>
  <form
    [formGroup]="changePasswordForm"
    (ngSubmit)="updatePassword()"
    class="flex flex-col gap-5"
    *ngIf="!isLoading"
  >
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Old Password </mat-label>
      <input
        type="password"
        matInput
        [formControl]="oldPassword"
        autocomplete="current-password"
      />
      <mat-error *ngIf="ValidationHelperService.isInvalid(oldPassword)">
        {{ ValidationHelperService.getErrorMessage(oldPassword) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>New Password </mat-label>
      <input
        type="password"
        matInput
        [formControl]="newPassword"
        autocomplete="new-password"
      />
      <mat-error *ngIf="ValidationHelperService.isInvalid(newPassword)">
        {{ ValidationHelperService.getErrorMessage(newPassword) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>New Password Confirm </mat-label>
      <input
        type="password"
        matInput
        [formControl]="newPasswordConfirm"
        autocomplete="new-password"
      />
      <mat-error *ngIf="ValidationHelperService.isInvalid(newPasswordConfirm)">
        {{ ValidationHelperService.getErrorMessage(newPasswordConfirm) }}
      </mat-error>
    </mat-form-field>

    <div class="flex justify-end w-full gap-5">
      <button
        mat-flat-button
        color="warn"
        [disabled]="!changePasswordForm.valid"
      >
        Change password
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="dialogRef.close(false)"
      >
        Close
      </button>
    </div>
  </form>
  <div
    class="w-full h-[350px] flex items-center justify-center"
    *ngIf="isLoading"
  >
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
