import {Injectable} from "@angular/core";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {IReactForm} from "../../shared/interfaces/forms/exercises/IReactForm";

@Injectable({
  providedIn: "root",
})
export class ExerciseValidationService {
  static startValueValidation() {
    return (group: FormGroup) => {
      if (
        group.controls["startType"].value === 1 &&
        !group.controls["startValue"].value
      ) {
        group.controls["startValue"].setErrors({ startValueRequired: true });
      }
    };
  }

  static neurocognitiveValidation(): ValidatorFn {
    return ((group: FormGroup) => {
      const neurocognitiveType = group.controls["neurocognitiveType"];
      if (!ExerciseValidationService.isNeuroCognitiveTypeValid(group)) {
        neurocognitiveType.setErrors(
          ExerciseValidationService.getNeuroCognitiveTypeError(group),
        );
      }
    }) as ValidatorFn;
  }

  static getNeuroCognitiveTypeError(group: FormGroup) {
    const neurocognitiveType = group.controls["neurocognitiveType"];
    return neurocognitiveType.value !== 2
      ? {
          invalidNeurocognitiveArray: true,
        }
      : {
          invalidNeurocognitiveGo: true,
        };
  }

  static isNeuroCognitiveTypeValid(group: FormGroup<IReactForm>) {
    const reactSensorsCount = group.controls.reactTo.value.filter(
      (value) => value,
    ).length;
    const dontReactSensorsCount = group.controls.dontReactTo.value.filter(
      (value) => value,
    ).length;
    const activeSensorsCount = group.controls.activeSensors.value.filter(
      (value) => value,
    ).length;

    const neurocognitiveType = group.controls.neurocognitiveType;

    return (
      (neurocognitiveType.value === 0 && reactSensorsCount === 1) || // Neurocognitive type 0 requires only one sensor to react
      (neurocognitiveType.value === 1 && // Neurocognitive type 1 requires one senor to react and the rest to not react
        reactSensorsCount === 1 &&
        dontReactSensorsCount + reactSensorsCount === activeSensorsCount) ||
      (neurocognitiveType.value === 2 && // Neurocognitive type 2 requires at least one sensor to react and one to not react
        reactSensorsCount >= 1 &&
        dontReactSensorsCount >= 1)
    );
  }

  static activeSensorValidation(): ValidatorFn {
    return ((group: FormGroup<IReactForm>) => {
      if (group.controls.neurocognitiveType.value !== 1) return;

      const activeSensorsCount = group.controls.activeSensors.value.filter(
        (value) => value,
      ).length;

      if (activeSensorsCount < 1) {
        group.controls.neurocognitiveType.setErrors({ invalidSensors: true });
        return;
      }

      const reactSensorsCount = group.controls.reactTo.value.filter(
        (value) => value,
      ).length;

      if (reactSensorsCount > activeSensorsCount) {
        group.controls.neurocognitiveType.setErrors({
          invalidReactSensors: true,
        });
      }

      const dontReactSensorsCount = group.controls.dontReactTo.value.filter(
        (value) => value,
      ).length;

      if (dontReactSensorsCount > activeSensorsCount) {
        group.controls.neurocognitiveType.setErrors({
          invalidDontReactSensors: true,
        });
      }
    }) as ValidatorFn;
  }

  static sequenceValidation(): ValidatorFn {
    return ((group: FormGroup) => {
      const length = group.controls["sequenceSensors"].value.length;
      if (length < 1 || length > 200) {
        group.controls["sequenceSensors"].setErrors({ invalidSequence: true });
      }
    }) as ValidatorFn;
  }

  static arrowValidation(): ValidatorFn {
    return ((group: FormGroup) => {
      const agilitySensors = group.controls["agilitySensors"].value;
      const length = agilitySensors.length;

      if (group.controls["agilityCustomType"].value === 0) {
        if (length < 1 || length > 200) {
          group.controls["agilitySensors"].setErrors({
            invalidArrows: true,
          });
        }
      } else if (length === 0) {
        group.controls["agilitySensors"].setErrors({
          invalidActiveArrows: true,
        });
      }
    }) as ValidatorFn;
  }

  constructor() {}
}
