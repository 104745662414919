<div *ngIf="!isLoading" class="p-5">
  <h2>
    <span class="bold-text"
      >{{ location.location.name }} - Network Configurations</span
    >
  </h2>
  <div class="d-flex flex-column gap-5 my-5">
    <div
      *ngFor="
        let networkConfig of networkConfigsFormArray.controls;
        let i = index
      "
      class="d-flex justify-content-between align-items-center w-100 border-bottom py-3"
    >
      <form
        class="d-flex justify-content-between align-items-center w-full flex-grow-1"
        [formGroup]="getNetworkConfigFormGroup(networkConfig)"
      >
        <div class="flex justify-center items-center gap-3">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Ip</mat-label>
              <input
                type="text"
                [formControl]="getNetworkConfigIp(networkConfig)"
                matInput
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    getNetworkConfigIp(networkConfig)
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    getNetworkConfigIp(networkConfig)
                  )
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <span class="text-3xl pb-3"> / </span>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Subnet Mask</mat-label>
              <input
                matInput
                type="text"
                [formControl]="getNetworkConfigMask(networkConfig)"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    getNetworkConfigMask(networkConfig)
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    getNetworkConfigMask(networkConfig)
                  )
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center gap-5">
          <button
            mat-flat-button
            color="primary"
            type="button"
            *ngIf="getNetworkConfigIp(networkConfig).disabled"
            (click)="
              getNetworkConfigIp(networkConfig).enable();
              getNetworkConfigMask(networkConfig).enable();
              formGroupCached[networkConfig.value.id] = networkConfig.value
            "
            matTooltip="Edit network configuration"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-flat-button
            color="accent"
            type="button"
            *ngIf="getNetworkConfigIp(networkConfig).enabled"
            (click)="
              submitEditNetworkConfigForm(networkConfig);
              getNetworkConfigIp(networkConfig).disable();
              getNetworkConfigMask(networkConfig).disable()
            "
            [disabled]="
              ValidationHelperService.isInvalid(
                getNetworkConfigFormGroup(networkConfig)
              )
            "
            matTooltip="Save network configuration"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button
            mat-flat-button
            color="warn"
            type="button"
            *ngIf="getNetworkConfigIp(networkConfig).enabled"
            (click)="
              networkConfig.patchValue(
                formGroupCached[networkConfig.value.id],
                {}
              );
              getNetworkConfigIp(networkConfig).disable();
              getNetworkConfigMask(networkConfig).disable()
            "
            matTooltip="Cancel editing"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            mat-flat-button
            color="warn"
            type="button"
            *ngIf="getNetworkConfigIp(networkConfig).disabled"
            (click)="openConfirmationModal(networkConfig)"
            matTooltip="Remove network configuration"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </form>
    </div>
    <form
      [formGroup]="addNetworkConfigFormGroup"
      class="d-flex justify-content-between align-items-center w-full flex-grow-1"
    >
      <div class="flex gap-3 justify-center items-center">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Ip</mat-label>
            <input type="text" [formControl]="ip" matInput />
            <mat-error *ngIf="ValidationHelperService.isInvalid(ip)">
              {{ ValidationHelperService.getErrorMessage(ip) }}
            </mat-error>
          </mat-form-field>
        </div>
        <span class="text-3xl pb-3"> / </span>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Subnet Mask</mat-label>
            <input matInput [formControl]="mask" />
            <mat-error *ngIf="ValidationHelperService.isInvalid(mask)">
              {{ ValidationHelperService.getErrorMessage(mask) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <button
          mat-flat-button
          color="primary"
          (click)="submitAddNetworkConfigForm()"
          [disabled]="
            ValidationHelperService.isInvalid(addNetworkConfigFormGroup)
          "
        >
          Add network config
        </button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
</div>
