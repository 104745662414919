import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {FormControl, FormGroup} from "@angular/forms";
import {NotificationService} from "../../../core/services/notification.service";
import {EAction} from "../../../shared/enums/EAction";
import {IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {RegularExerciseHelper} from "../../../core/forms/helpers/RegularExerciseHelper";
import {ExerciseHelper} from "../../../core/helpers/exercise.helper";
import {EBodyPart} from "../../../shared/enums/EBodyPart";

export interface IRegularExercise {
  id: string
  videoUrl: string
  thumbnailUrl: string
  imageUrl: any
  defaultConfigId: string
  configs: IExerciseConfig[]
  name: string
  categoryId: string
  boardType: number
  bodyPart: EBodyPart
  type: number
  isFavorite: boolean
}

export interface IExerciseConfig {
  id: string
  countdownStart: number
  countdownEndType: number
  countdownEnd: number
  sumType: any
  neurocognitiveType: any
  reactTo: any
  dontReactTo: any
  arrows: number[]
  arrowsType: number
  timerDelay: number
  timerPrompt: any
  activeSensors: any
  sensorsSequence: number[]
  jumpType: any
  numberOfLegs: any
  jumpCount: any
  difficulty: number
  isolatedReact: any
}


@Component({
  selector: "app-exercise-form",
  templateUrl: "./exercise-form.component.html",
  styleUrls: ["./exercise-form.component.scss"],
})
export class ExerciseFormComponent implements OnInit {
  protected readonly ValidationHelperService = ValidationHelperService;

  @Input() _formGroup: FormGroup<IRegularExerciseForm>;
  @Input() action: EAction;
  @Input() exercise: IRegularExercise;
  @Output() _submit = new EventEmitter();
  configFormGroup = new FormGroup({
    selectedConfig: new FormControl(),
  });


  isLoading = false;

  constructor(private notificationService: NotificationService, private exerciseHelper: ExerciseHelper) {}

  ngOnInit(): void {
    this.onTypeChange();
    if(this.action === EAction.Edit) {
      this.configFormGroup.controls.selectedConfig.setValue(this.exercise.configs.find(config => config.id === this.exercise.defaultConfigId));
      this.configFormGroup.controls.selectedConfig.valueChanges.subscribe((data) => {
        this.exercise.defaultConfigId = data.id;
        RegularExerciseHelper.patchFormConfig(this._formGroup, this.exercise);
      })
    }
  }

  getConfigTitle(config: IExerciseConfig): string {
    return this.exerciseHelper.getConfigTitle(this._formGroup.controls.type.value, config);
  }

  onTypeChange(): void {
    this.type.valueChanges.subscribe((data) => {
      if (data !== 4) return;

      this.startType.setValue(12);
      this.startValue.setValue(1);
    });
  }

  currentForm() {
    switch (this._formGroup.controls.type.value) {
      case 0:
        return this.countForm;
      case 1:
        return this.reactForm;
      case 2:
        return this.sequenceForm;
      case 3:
        return this.agilityForm;
      case 4:
        return this.verticalForm;
      default:
        this.notificationService.error("Invalid exercise type.");
        this._formGroup.controls.type.setValue(0);
        return this.countForm;
    }
  }

  submitForm() {
    if (!RegularExerciseHelper.isFormValid(this._formGroup, this.type)) {
      console.log(this._formGroup)
      this._formGroup.markAllAsTouched();
      this.currentForm().markAllAsTouched();
      this.notificationService.error("The form is invalid. Please check.");
      return;
    }

    this._submit.emit(RegularExerciseHelper.createPayload(this._formGroup));
  }

  get category(): FormControl {
    return this._formGroup.get("category") as FormControl;
  }

  get type(): FormControl {
    return this._formGroup.get("type") as FormControl;
  }

  get difficulty(): FormControl {
    return this.exerciseConfig.get("difficulty") as FormControl;
  }

  get numberOfLegs(): FormControl {
    return this.verticalForm.get("numberOfLegs") as FormControl;
  }

  get reactTo(): boolean[] {
    return this.reactForm.get("reactTo").value;
  }

  get react(): number[] {
    return this.reactTo
      .map((value, index) => (value ? index : null))
      .filter((value) => value !== null);
  }

  get dontReactTo(): boolean[] {
    return this.reactForm.get("dontReactTo").value;
  }

  get verticalForm(): FormGroup {
    return this.exerciseConfig.get("verticalForm") as FormGroup;
  }

  get countdownsForm(): FormGroup {
    return this.exerciseConfig.get("countdownForm") as FormGroup;
  }

  get startType(): FormControl {
    return this.countdownsForm.get("startType") as FormControl;
  }

  get startValue(): FormControl {
    return this.countdownsForm.get("startValue") as FormControl;
  }

  get endType(): FormControl {
    return this.countdownsForm.get("endType") as FormControl;
  }

  get endValue(): FormControl {
    return this.countdownsForm.get("endValue") as FormControl;
  }

  get reactForm(): FormGroup {
    return this.exerciseConfig.get("reactForm") as FormGroup;
  }

  get neurocognitiveType(): FormControl {
    return this.reactForm.get("neurocognitiveType") as FormControl;
  }

  get countForm(): FormGroup {
    return this.exerciseConfig.get("countForm") as FormGroup;
  }

  get countType(): FormControl {
    return this.countForm.get("countType") as FormControl;
  }

  get sequenceForm(): FormGroup {
    return this.exerciseConfig.get("sequenceForm") as FormGroup;
  }

  get agilityForm(): FormGroup {
    return this.exerciseConfig.get("agilityForm") as FormGroup;
  }

  get name(): FormControl {
    return this._formGroup.get("name") as FormControl;
  }

  get videoUrl(): FormControl {
    return this._formGroup.get("videoUrl") as FormControl;
  }

  get imgThumb(): FormControl {
    return this._formGroup.get("imgThumb") as FormControl;
  }

  get activeSensors(): boolean[] {
    return this.reactForm.get("activeSensors").value;
  }

  get timerPrompt(): FormControl {
    return this.reactForm.get("timerPrompt") as FormControl;
  }

  get sequenceSensors(): number[] {
    return this.sequenceForm.get("sequenceSensors").value;
  }

  get isolatedReact(): FormControl {
    return this.reactForm.get("isolatedReact") as FormControl;
  }

  get exerciseConfig() {
    return this._formGroup.get("config") as FormGroup;
  }

  get bodyPart(): FormControl
  {
    return this._formGroup.get("bodyPart") as FormControl
  }

  protected readonly EAction = EAction;
}
