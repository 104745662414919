<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100 p-3">
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="qb-blue-background primary ui button inline-display right-floated"
        (click)="openGenerateModal()"
      >
        Create Report
      </button>

      <div class="ui selection dropdown inline-display" >
        <div class="medium-text text">Modify selected</div>

        <i class="dropdown icon"></i>

        <div class="menu no-box-shadow" >
          <div class="item">Disable</div>
          <div class="item">Delete</div>
        </div>
      </div>
    </div>

    <div
      class="ui grid column single-report box-shadow-bottom"
      *ngFor="let report of reports; let i = index"
    >
      <div
        class="one wide computer two wide tablet three wide mobile column flex flex-center"
      >
        <i
          class="clipboard material-icons clickable"
          (click)="openReportModal(report)"
          >assignment</i
        >
        <!--<div class="ui fitted checkbox zoomed">-->
        <!--<input type="checkbox">-->
        <!--<label></label>-->
        <!--</div>-->
      </div>

      <div
        class="one wide computer two wide tablet three wide mobile column flex flex-center"
      >
        <a href="{{ report.reportUrl }}" target="_blank">Link</a>
      </div>

      <div
        class="five wide computer ten wide tablet seven wide mobile column athlete-data data"
      >
        <div id="text-wrapper" class="inline-display">
          <p class="grayish no-text-align no-bottom-margin report-name">
            {{ report.athleteName }}
          </p>
          <!--<p class="mobile-only tablet-only next-report">Next report: Jan 01, 2018</p>-->
        </div>
      </div>

      <div
        class="two wide column flex flex-center data no-border computer only"
      >
        <p>{{ report.startPeriod | date }}</p>
      </div>

      <div
        class="two wide column flex flex-center data no-border computer only"
      >
        <p>{{ report.endPeriod | date }}</p>
      </div>

      <div
        class="two wide column flex flex-center data no-border computer only"
      >
        <p>{{ report.creationDate | date }}</p>
      </div>

      <div
        class="one wide right floated column large-links flex flex-center computer only"
      >
        <!--<p>Disable</p>-->
      </div>

      <div
        class="one wide computer two wide tablet three wide mobile column menu flex flex-center"
      >
        <div class="clickable" (click)="deleteReport(report.id, i)">
          <i class="trash icon red"></i>
        </div>
      </div>
    </div>
  </div>
</div>
