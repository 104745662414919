<div class="card w-100 p-3 mt-2 mt-sm-0 mr-sm-5 mb-2">
  <div *ngIf="!isLoading">
    <div class="d-flex">
      <div class="w-100 text-center">
        <h5 class="bold-text">Invoice Name</h5>
      </div>

      <div class="w-100 text-center">
        <h5 class="bold-text">Date</h5>
      </div>
    </div>

    <div *ngFor="let invoice of invoices" class="d-flex align-items-center">
      <div class="w-100 text-center">
        <button class="btn m-0 p-1">
          <i
            class="clipboard icon clickable"
            (click)="openInvoice(invoice.url)"
          ></i>
        </button>
        <span>{{ invoice.name }}</span>
      </div>

      <div class="w-100 text-center">
        <span>{{ invoice.date | date }}</span>
      </div>
    </div>

    <div class="text-center p-3" *ngIf="invoices.length === 0">
      <span class="text-center h5 alt-color-text">No invoices.</span>
    </div>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
