import {Component, Input} from "@angular/core";
import {EAgilityArrow} from "../../../../../shared/enums/EAgilityArrow";

@Component({
  selector: "app-agility-arrow-icon",
  templateUrl: "./agility-arrow-icon.component.html",
  styleUrls: ["./agility-arrow-icon.component.scss"],
})
export class AgilityArrowIconComponent {
  @Input() arrow: EAgilityArrow;
  protected readonly EAgilityArrow = EAgilityArrow;
}
