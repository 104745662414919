<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="w-100" *ngIf="type == 1">
    <div class="w-100 card mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <div class="p-3">
        <button
          type="button"
          class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4 mr-3"
          routerLink="/save-exercise"
        >
          <i _ngcontent-c4="" class="material-icons button-icon"> add </i>
        </button>
      </div>
    </div>

    <div class="w-100 p-3 card mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <form class="form-inline">
        <div class="w-100" style="padding: 0 10px" [formGroup]="injuryForm">
          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="0">Male</mat-option>
                  <mat-option value="1">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Age</mat-label>
                <input
                  matInput
                  placeholder="Insert Age"
                  formControlName="age"
                />
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Activity Level</mat-label>
                <mat-select formControlName="activityLevel">
                  <mat-option *ngFor="let a of activityLevels" [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Injury Type</mat-label>
                <mat-select formControlName="injuryType">
                  <mat-option value="0">Contact</mat-option>
                  <mat-option value="1">Noncontact</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Injury</mat-label>
                <mat-select formControlName="injury">
                  <mat-option
                    *ngFor="let injury of injuriesNew"
                    [value]="injury"
                  >
                    {{ injury }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Injury Grade</mat-label>
                <mat-select formControlName="injuryGrade">
                  <mat-option
                    *ngFor="let igrade of injuryGrades"
                    [value]="igrade"
                  >
                    {{ igrade }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>End date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  formControlName="endDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="w-100 p-3">
          <button class="btn btn-outline-primary" (click)="doFilter()">
            Filter
          </button>
          <button
            class="btn btn-outline-primary ml-3"
            [disabled]="!injuryForm.dirty"
            (click)="injuryForm.reset()"
          >
            Clear Selections
          </button>
        </div>
      </form>
    </div>

    <div class="card w-100 p-3">
      <div class="ui grid">
        <div class="full-height" *ngIf="!overviewLoade">
          <div class="ui massive active text loader"></div>
        </div>

        <div *ngIf="overviewLoade" style="flex: 1 1 auto">
          <div *ngFor="let location of locationAdmins" style="flex: 1 1 auto">
            <div
              class="single-exercise box-shadow-bottom ui grid white-background full-width right-column"
            >
              <div
                class="eleven wide computer twelve wide tablet ten wide mobile column grayish white-background"
              >
                <p class="exercise-name no-text-align">
                  {{ location.locationName }}
                </p>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row">
                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Patients
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.patients }}
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Total results
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.totalResults }}
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Avg improvement
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.avgImprovement }} %
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Rank
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.rank }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100" *ngIf="type == 0">
    <!-- <div class="w-100 card mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <div class="p-3">
        <button type="button" class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4 mr-3">
          <i _ngcontent-c4="" class="material-icons button-icon"> add </i>
        </button>
      </div>
    </div> -->

    <div class="w-100 p-3 card mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <form class="form-inline">
        <div
          class="w-100"
          style="padding: 0 10px"
          [formGroup]="performanceForm"
        >
          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                  <mat-option value="0">Male</mat-option>
                  <mat-option value="1">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>Age</mat-label>
                <input
                  matInput
                  placeholder="Insert Age"
                  formControlName="age"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Sport</mat-label>
                <mat-select
                  formControlName="sport"
                  (selectionChange)="selectSport()"
                >
                  <mat-option *ngFor="let a of sportOptions" [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Position</mat-label>
                <mat-select formControlName="position">
                  <mat-option *ngFor="let a of positionOptions" [value]="a">
                    {{ a }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field class="w-100 mb-5">
                <mat-label>Level</mat-label>
                <mat-select formControlName="level">
                  <mat-option
                    *ngFor="let level of levelOptions"
                    [value]="level"
                  >
                    {{ level }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column flex-md-row">
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="d-flex mr-5 mb-3">
              <mat-form-field>
                <mat-label>End date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  formControlName="endDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="w-100 p-3">
          <button class="btn btn-outline-primary" (click)="doFilter()">
            Filter
          </button>
          <button
            class="btn btn-outline-primary ml-3"
            [disabled]="!performanceForm.dirty"
            (click)="performanceForm.reset()"
          >
            Clear Selections
          </button>
        </div>
      </form>
    </div>

    <div class="card w-100 p-3">
      <div class="ui grid">
        <div class="full-height" *ngIf="!overviewLoade">
          <div class="ui massive active text loader"></div>
        </div>

        <div *ngIf="overviewLoade" style="flex: 1 1 auto">
          <div *ngFor="let location of locationAdmins" style="flex: 1 1 auto">
            <div
              class="single-exercise box-shadow-bottom ui grid white-background full-width right-column"
            >
              <div
                class="eleven wide computer twelve wide tablet ten wide mobile column grayish white-background"
              >
                <p class="exercise-name no-text-align">
                  {{ location.locationName }}
                </p>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row">
                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Patients
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.patients }}
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Total results
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.totalResults }}
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Avg improvement
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.avgImprovement }} %
                    </div>
                  </div>
                </div>

                <div class="col-6 col-lg-3 mb-3">
                  <div
                    class="bg-light card ui statistic dashbordTableCellContent"
                  >
                    <div
                      class="label dashboardCellDrillText dashboardCellDrillTextTop"
                    >
                      Rank
                    </div>
                    <div class="blue dashboardCellDrillNumbers ng-binding">
                      {{ location.rank }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
