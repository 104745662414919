import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddActivityDialogComponent } from "../../shared/components/add-activity-dialog/add-activity-dialog.component";

@Component({
  selector: "app-activity-data",
  templateUrl: "./activity-data.component.html",
  styleUrls: [
    "./activity-data.component.scss",
    "../athletes/athletes.component.scss",
  ],
})
export class ActivityDataComponent implements OnInit {
  startDate: Date;
  endDate: Date;

  filtering = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  createActivityData() {
    this.dialog.open(AddActivityDialogComponent, {
      data: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }
}
