<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100">
    <div class="w-100 border-bottom">
      <div class="p-3">
        <div class="w-100 flex gap-2 justify-start items-center">
          <a
            mat-flat-button
            color="primary"
            type="button"
            routerLink="/save-{{ global?.data?.athleteName }}"
          >
            <mat-icon matButtonIcon> add </mat-icon>
          </a>
          <button
            mat-button
            mat-flat-button
            type="button"
            class="text-uppercase font-weight-bold"
            (click)="accordion.openAll(); filtering = !filtering"
            *ngIf="!filtering"
          >
            Show filters
          </button>
          <button
            mat-button
            mat-flat-button
            type="button"
            class="text-uppercase font-weight-bold"
            (click)="accordion.closeAll(); filtering = !filtering"
            *ngIf="filtering"
          >
            Hide filters
          </button>
            <button mat-flat-button color="warn" (click)="resetFilters()" *ngIf="hasFilters">
                Remove selected filters
            </button>
        </div>
      </div>
    </div>
    <mat-accordion *ngIf="filtersForm" multi>
      <mat-expansion-panel class="p-0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="font-weight-bold"
              >Category
              <span *ngIf="possibleFilters['categoryFilters']">
                ({{ possibleFilters["categoryFilters"].length }})
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="d-flex flex-wrap field-wrapper p-3" content>
            <div
              *ngFor="let category of filtersForm.controls.categories.controls"
              class="field"
            >
              <mat-checkbox
                [formControl]="category.controls.isSelected"
                (change)="updateAthleteList(true)"
              >
                {{ category.value.name }}</mat-checkbox
              >
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="font-weight-bold"
              >Sport
              <span *ngIf="filtersForm.controls.sports.controls.length > 0">
                ({{ filtersForm.controls.sports.controls.length || 0 }})
              </span>
            </span>
          </mat-expansion-panel-header>
          <div class="d-flex flex-wrap field-wrapper p-3" content>
            <div
              class="field"
              *ngFor="let sport of filtersForm.controls.sports.controls"
            >
              <mat-checkbox
                [formControl]="sport.controls.isSelected"
                (change)="updateAthleteList(true)"
              >
                {{ sport.value.name }}
              </mat-checkbox>

              <div
                class="filter box-shadow-bottom"
                *ngIf="
                  sport?.controls.positions?.length !== 0 &&
                  sport.controls.isSelected.value
                "
              >
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <span
                      >Position
                      <ng-container>
                        ({{ sport.controls.positions?.length }})
                      </ng-container>
                    </span>
                    <i class="dropdown icon"></i>
                  </mat-expansion-panel-header>
                  <div class="overflowing-filter overflowing" content>
                    <div class="field" *ngFor="let position of sport.controls.positions.controls">
                      <mat-checkbox
                        [formControl]="position.controls.isSelected"
                        (change)="
                          updateAthleteList(true)
                        "
                      >
                        {{ position.value.name }}
                      </mat-checkbox>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="font-weight-bold"
              >Level
              <ng-container *ngIf="filtersForm.controls.levels?.length">
                ({{ possibleFilters["levelFilters"]?.length || 0 }})
              </ng-container>
            </span>
          </mat-expansion-panel-header>
          <div class="d-flex flex-wrap field-wrapper p-3" content>
            <div
              class="field"
              *ngFor="let level of filtersForm.controls.levels.controls"
            >
              <mat-checkbox
                [formControl]="level.controls.isSelected"
                (change)="
                  updateAthleteList(true)
                "
              >
                {{ level.value.name }}
              </mat-checkbox>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="font-weight-bold"
              >Team
              <ng-container *ngIf="filtersForm.controls.teams?.length > 0">
                ({{ filtersForm.controls.teams?.length || 0 }})
              </ng-container>
            </span>
          </mat-expansion-panel-header>
          <div class="d-flex flex-wrap field-wrapper border-bottom p-3" content>
            <div
              class="field"
              *ngFor="let team of filtersForm.controls.teams.controls"
            >
              <mat-checkbox
                class="medium-label"
                [formControl]="team.controls.isSelected"
                (change)="updateAthleteList(true)"
              >
                {{ team.value.name }}
              </mat-checkbox>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="card w-100 mt-2 mt-sm-3 mt-md-4 mt-lg-5">
    <div class="w-full">
        <div class="w-full h-[400px] flex justify-center items-center" *ngIf="!isLoaded">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
      <div class="flex content-wrapper" *ngIf="isLoaded">
        <div class="w-100 p-3" *ngIf="athleteList?.length > 0">
          <form class="d-flex flex-column gap-5">
              <div class="w-full flex gap-3 items-center">
                  <button mat-flat-button color="warn" (click)="removeSelectedAthletes()" *ngIf="this.selectedAthletes.length > 0">
                      Remove selected athletes
                  </button>
              </div>
            <div
              *ngFor="let athlete of athletesForm.controls.athletes.controls"
              class="flex-grow-1 d-flex align-items-center gap-3 border-bottom py-3"
              (click)="athlete.controls.isSelected.setValue(!athlete.value.isSelected)"
              matRipple
            >
              <div
                class="d-flex justify-content-start align-items-center gap-3 w-[30%]"
              >
                  <div (click)="$event.stopPropagation()">
                      <mat-checkbox color="primary" [formControl]="athlete.controls.isSelected"></mat-checkbox>
                  </div>
                  <div class="athlete-image">
                  <div class="flex justify-center">
                    <div
                      *ngIf="
                        athlete.value.athleteObject.profilePictureUrl !== null &&
                        athlete.value.athleteObject.profilePictureUrl !== ''
                      "
                      [style.background-image]="
                        'url(' + athlete.value.athleteObject?.profilePictureUrl + ')'
                      "
                      class="profile-picture"
                    ></div>

                    <div
                      *ngIf="
                        athlete.value.athleteObject.profilePictureUrl === null ||
                        athlete.value.athleteObject.profilePictureUrl === ''
                      "
                      [style.background-image]="
                        'url(assets/images/no_avatar.png)'
                      "
                      class="profile-picture"
                    ></div>
                  </div>

                  <div id="text-wrapper" class="inline-display"></div>
                </div>
                  <div>
                  <span class="font-weight-bold"
                    >{{ athlete.value.athleteObject.firstName }} {{ athlete.value.athleteObject.lastName }}</span
                  >
                </div>
              </div>

              <div class="w-[50%]">
                <span
                  >{{ athlete.value.athleteObject.sportName || "No Sport" }},
                  {{ athlete.value.athleteObject.positionName || "No Position" }}</span
                >
              </div>

              <div class="w-[20%]" (click)="$event.stopPropagation()">
                <div class="d-flex align-items-center justify-content-center">
                  <button
                          matRipple
                    class="flex justify-center items-center !rounded-full p-2 !bg-[#F5DC11]"
                    [matMenuTriggerFor]="menu"
                  >
                      <div class="flex justify-center items-center">
                          <mat-icon class="scale-75">more_horizontal</mat-icon>
                      </div>
                  </button>
                  <mat-menu class="menu pushed-menu" #menu="matMenu">
                    <a
                      mat-menu-item
                      class="item"
                      routerLink="/athletes/{{ athlete.value.athleteObject.id }}"
                      >Agility Ratings</a
                    >
                    <a
                      mat-menu-item
                      class="item"
                      routerLink="/save-{{ global?.data?.athleteName }}/{{
                       athlete.value.athleteObject.id
                      }}"
                      >Edit</a
                    >
                  </mat-menu>
                </div>
              </div>
            </div>

            <div *ngIf="canLoadAthletes">
              <p
                (click)="updateAthleteList()"
                class="text-center pointer p-3 font-weight-bold"
              >
                Load more
              </p>
            </div>
          </form>
        </div>
        <div
          class="w-full p-3 alt-color-text"
          *ngIf="athleteList?.length === 0 && filters.length > 0"
        >
          <div
            class="flex flex-col gap-3 items-center justify-center w-full max-w-[500px] mx-auto py-10 text-center"
          >
            <div class="flex gap-3 items-center justify-center">
              <mat-icon> group </mat-icon>
              <span class="h4 alt-color-text !m-0"
                >No {{ global?.data?.uppercaseAthleteName }}s</span
              >
            </div>
            <p class="alt-color-text">
              There are no {{ global?.data?.athleteName }}s that match the
              selected filters. Select other filters or press the button on the
              right to create a new one.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
