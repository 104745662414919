import {Component, Input} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {NotificationService} from "../../../../core/services/notification.service";
import {ISequenceForm} from "../../../../shared/interfaces/forms/exercises/ISequenceForm";

@Component({
  selector: "app-sequence-exercise-form",
  templateUrl: "./sequence-exercise-form.component.html",
  styleUrls: ["./sequence-exercise-form.component.scss"],
})
export class SequenceExerciseFormComponent {
  @Input() _formGroup: FormGroup<ISequenceForm>;

  constructor(private notificationService: NotificationService) {}

  removeSequenceSensor(index: number) {
    (this._formGroup.get("sequenceSensors") as FormArray).removeAt(index);
  }

  resetSequence() {
    this.sequenceSensors.clear();
    this.delay.updateValueAndValidity();
  }

  addSequence(sensor: number) {
    const length = this.sequenceSensorsValue.length;
    if (length < 200) {
      this.sequenceSensors.push(new FormControl(sensor));

      this.delay.updateValueAndValidity();
    } else
      this.notificationService.error(
        "The sequence length cannot exceed 26 steps.",
      );
  }

  get delay(): FormControl {
    return this._formGroup.get("delay") as FormControl;
  }

  get timerPrompt(): FormControl {
    return this._formGroup.get("timerPrompt") as FormControl;
  }

  get sequenceSensors(): FormArray {
    return this._formGroup.get("sequenceSensors") as FormArray;
  }

  get sequenceSensorsValue(): number[] {
    return this._formGroup.get("sequenceSensors").value;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
