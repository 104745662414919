<div class="w-100 p-3 border-top border-bottom">
  <div class="mb-5">
    <span class="h4">Exercise Type</span>
  </div>

  <div class="d-flex flex-wrap justify-content-center" id="exercises-wrapper">
    <div
      class="exercise-type mb-4 text-center relative"
      [class.cursor-pointer]="action === EAction.Add"
      (click)="setExerciseType(0);"
      [class.pointer-events-none]="action === EAction.Edit"
      [matRippleDisabled]="action === EAction.Edit"
      matRipple
    >
        <div class="absolute top-0 left-0 w-full h-full z-10 bg-opacity-20 bg-black rounded-2xl" *ngIf="action === EAction.Edit && _control.value !== 0"></div>
      <div class="image-container relative">
        <mat-icon
          *ngIf="_control.value == 0"
          class="text-white scale-[2] font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
          >done</mat-icon
        >
        <div class="overlay" [hidden]="_control.value != 0"></div>
        <img
          ngSrc="../../../../../assets/svg/count-exercise.svg"
          alt="Count exercise image"
          height="16"
          width="16"
        />
      </div>

      <div
        class="ui label gray medium-text uppercase"
        [class.bold-text]="_control.value == 0"
      >
        Count
      </div>
    </div>
    <div
      class="exercise-type mb-4 clickable text-center cursor-pointer"
      [class.cursor-pointer]="action === EAction.Add"
      (click)="setExerciseType(1);"
      [class.pointer-events-none]="action === EAction.Edit"
      [matRippleDisabled]="action === EAction.Edit"
      matRipple
    >
        <div class="absolute top-0 left-0 w-full h-full z-10 bg-opacity-20 bg-black rounded-2xl" *ngIf="action === EAction.Edit && _control.value !== 1"></div>

        <div class="image-container relative">
        <mat-icon
          *ngIf="_control.value === 1"
          class="text-white scale-[2] font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
          >done</mat-icon
        >
        <div class="overlay" [hidden]="_control.value !== 1"></div>
        <img
          ngSrc="../../../../../assets/svg/react-exercise.svg"
          alt="React exercise image"
          height="16"
          width="16"
        />
      </div>

      <div
        class="ui label gray medium-text uppercase"
        [class.bold-text]="_control.value == 1"
      >
        React
      </div>
    </div>
    <div
      class="exercise-type mb-4 clickable text-center cursor-pointer"
      [class.cursor-pointer]="action === EAction.Add"
      (click)="setExerciseType(2);"
      [class.pointer-events-none]="action === EAction.Edit"
      [matRippleDisabled]="action === EAction.Edit"
      matRipple
    >
        <div class="absolute top-0 left-0 w-full h-full z-10 bg-opacity-20 bg-black rounded-2xl" *ngIf="action === EAction.Edit && _control.value !== 2"></div>

        <div class="image-container relative">
        <mat-icon
          *ngIf="_control.value === 2"
          class="text-white scale-[2] font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
          >done</mat-icon
        >
        <div class="overlay" [hidden]="_control.value !== 2"></div>
        <img
          ngSrc="../../../../../assets/svg/sequence-exercise.svg"
          alt="Sequence exercise image"
          height="16"
          width="16"
        />
      </div>
      <div
        class="ui label gray medium-text uppercase"
        [class.bold-text]="_control.value == 2"
      >
        Sequence
      </div>
    </div>
    <div
      class="exercise-type mb-4 clickable text-center cursor-pointer"
      [class.cursor-pointer]="action === EAction.Add"
      (click)="setExerciseType(3);"
      [class.pointer-events-none]="action === EAction.Edit"
      [matRippleDisabled]="action === EAction.Edit"
      matRipple
    >
        <div class="absolute top-0 left-0 w-full h-full z-10 bg-opacity-20 bg-black rounded-2xl" *ngIf="action === EAction.Edit && _control.value !== 3"></div>

        <div class="image-container relative">
        <mat-icon
          *ngIf="_control.value === 3"
          class="text-white scale-[2] font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
          >done</mat-icon
        >
        <div class="overlay" [hidden]="_control.value !== 3"></div>
        <img
          src="../../../../../assets/svg/agility-exercise.svg"
          alt="Agility exercise image"
        />
      </div>
      <div
        class="ui label gray medium-text uppercase"
        [class.bold-text]="_control.value == 3"
      >
        Agility
      </div>
    </div>
    <div
      class="exercise-type mb-4 text-center cursor-pointer"
      [class.cursor-pointer]="action === EAction.Add"
      (click)="setExerciseType(4);"
      [class.pointer-events-none]="action === EAction.Edit"
      [matRippleDisabled]="action === EAction.Edit"
      matRipple
    >
        <div class="absolute top-0 left-0 w-full h-full z-10 bg-opacity-20 bg-black rounded-2xl" *ngIf="action === EAction.Edit && _control.value !== 4"></div>

        <div class="image-container relative">
        <mat-icon
          *ngIf="_control.value === 4"
          class="text-white scale-[2] font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
          >done</mat-icon
        >
        <div class="overlay" [hidden]="_control.value !== 4"></div>
        <img
          src="../../../../../assets/svg/vertical-exercise.svg"
          alt="Vertical exercise image"
        />
      </div>
      <div
        class="ui label gray medium-text uppercase"
        [class.bold-text]="_control.value === 4"
      >
        Vertical
      </div>
    </div>
  </div>
</div>
