import {Component} from "@angular/core";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  reloadPage() {
    window.location.reload();
  }
}
