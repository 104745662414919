import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {FileUploader} from "ng2-file-upload";
import {Subscription} from "rxjs";
import {GlobalPreferences} from "./core/helpers/global-data";
import {AuthService} from "./core/services/auth.service";
import {GeographyControllerService} from "./core/api/controllers/geography-controller.service";
import {MessageService} from "./core/services/message-service";
import {MixpanelService} from "./core/services/mixpanel.service";
import {NotificationService} from "./core/services/notification.service";
import {NotifyParentService} from "./core/services/notify-parent.service";
import {PaymentControllerService} from "./core/api/controllers/payment-controller.service";
import {LocationsService} from "./core/services/locations.service";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "./features/settings/generic-account-card/change-password/change-password.component";
import {AccountInfoDialogComponent} from "./features/settings/generic-account-card/account-info/account-info-dialog.component";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {ShopControllerService} from "./core/api/controllers/shop-controller.service";
import {AccountControllerService} from "./core/api/controllers/account-controller.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [
    "./app.component.scss",
    "./features/register/register.component.scss",
  ],
  providers: [
    PaymentControllerService,
    GeographyControllerService,
    NotificationService,
    MatDialog,
    ShopControllerService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["YYYY-MM-DD"],
        },
        display: {
          dateInput: "YYYY-MM-DD",
          monthYearLabel: "YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "YYYY",
        },
      },
    },
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  userRoles = [];

  imageUploader: FileUploader;
  imageNeedsUpdate = false;
  image = null;
  imagePreviewUrl = null;
  hidden = true;
  profilePicture;
  paymentData;
  hasMultipleLocations = false;
  name;
  currentLocation;
  childSub: Subscription;
  blankPage = false;
  subscription: Subscription;

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private childNotifier: NotifyParentService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private dialog: MatDialog,
    private router: Router,
    private mixpanelService: MixpanelService,
    private sanitizer: DomSanitizer,
    private accountController: AccountControllerService,
    private parentNotifier: NotifyParentService,
    private locationsService: LocationsService,
  ) {
    this.childSub = this.childNotifier.getData().subscribe((data) => {
      if (data === "fullUpdate") {
        this.updateUserSettings();
      }
      if (data === "update") {
        this.updateUserSettings();
      }
    });

    this.subscription = this.messageService.accessMessage().subscribe((msg) => {
      if (msg) {
        this.hasMultipleLocations = true;
      }
    });
  }

  ngOnInit() {
    if (this.fullyLoggedIn()) {
      this.updateUserSettings();
    }
    this.imageUploader = new FileUploader({
      queueLimit: 1,
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      this.imageNeedsUpdate = true;
      this.image =
        file.file.type.indexOf("image") >= 0 && file.file.size <= 2000000
          ? file.file
          : null;
      this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file._file),
      );

      this.updateProfilePicture();
    };
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  updateProfilePicture() {
    const data = new FormData();
    data.append("file", this.image.rawFile);
    this.accountController.updateProfilePicture(data).subscribe(
      () => {
        this.notificationService.success(
          "Profile picture updated successfully.",
        );
        this.profilePicture = this.imagePreviewUrl;
        this.imagePreviewUrl = null;
        this.parentNotifier.sendData("update");
      },
      (error) => this.notificationService.error(error),
    );
  }

  getDefaultLocation(locations) {
    const selectedLocationId = localStorage.getItem("selectedLocationId");
    return locations.find(({ id }) => id === selectedLocationId);
  }

  updateUserSettings() {
    this.accountController.getUserSettings().subscribe(
      (response) => {
        const userInfoString =
          localStorage.getItem("decodedToken") ||
          sessionStorage.getItem("decodedToken");
        const userInfo = JSON.parse(userInfoString);

        this.userRoles = userInfo.roles;
        this.name = response.name;
        this.locationsService.locations = response.locations;
        const defaultLocation = this.getDefaultLocation(response.locations);
        if (defaultLocation) {
          this.currentLocation = defaultLocation.name;
        }

        this.hasMultipleLocations = response.locations.length > 1;

        this.mixpanelService.identify(response.username);
        this.profilePicture = response.profilePictureUrl;

        if (response.subscription) {
          localStorage.setItem(
            "userSubscription",
            JSON.stringify(response.subscription),
          );
        }
      },
      (error) => {
        this.notificationService.error(error);
        this.logout();
      },
    );
  }

  ngAfterViewInit() {
    const faux = document.getElementById("faux");
    this.blankPage =
      this.router.url === "/privacy-policy" ||
      this.router.url === "/terms-and-conditions";
    if (faux != null) {
      faux.style.width =
        Math.round(document.getElementById("nav_menu").offsetWidth) + "px";
    }
  }

  fullyLoggedIn() {
    return (
      this.authService.checkTokenAccess("User") ||
      this.authService.checkTokenAccess("OrganizationAdmin")
    );
  }

  isPaymentAdmin() {
    return this.authService.checkTokenAccess("OrganizationAdmin");
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.childSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  changeInfo() {
    this.dialog.open(AccountInfoDialogComponent, {
      width: "400px",
    });
  }

  openPasswordModal() {
    this.dialog.open(ChangePasswordComponent, {
      width: "600px",
      disableClose: true,
    });
  }
}
