import { Category, ICategory } from "./ICategory";
import { EGender } from "../enums/EGender";
import { Height, IHeight } from "./IHeight";
import { ILevel, Level } from "./ILevel";
import { ITeam, Team } from "./ITeam";
import { IWeight, Weight } from "./IWeight";
import { ISportPosition, SportPosition } from "./ISportPosition";
import { ISport, Sport } from "./ISport";

export interface IAthlete {
  dateOfBirth: string;
  dominantLeg: number;
  email: string;
  firstName: string;
  gender: EGender;
  height: IHeight;
  lastName: string;
  level: ILevel;
  profilePictureUrl: string;
  team: ITeam;
  weight: IWeight;
  category: ICategory;
  sportPosition: ISportPosition;
  sport: ISport;
}

export interface IAthleteUpdate {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  profilePictureUrl: string;
  categoryId: string;
  sportPositionId: string;
  levelId: string;
  teamId: string;
  dominantLeg: number;
  gender: number;
  height: IHeight;
  weight: IWeight;
  id: string;
}

export class Athlete {
  dateOfBirth: string;
  dominantLeg: number;
  email: string;
  firstName: string;
  gender: EGender;
  height: Height;
  lastName: string;
  level: Level;
  profilePictureUrl: string;
  team: Team;
  weight: Weight;
  category: Category;
  sportPosition: SportPosition;
  sport: Sport;

  constructor(athlete?: Partial<IAthlete>) {
    if (!athlete) return;
    if (athlete.dateOfBirth) this.dateOfBirth = athlete.dateOfBirth;
    if (athlete.dominantLeg || athlete.dominantLeg === 0)
      this.dominantLeg = athlete.dominantLeg;
    if (athlete.email) this.email = athlete.email;
    if (athlete.firstName) this.firstName = athlete.firstName;
    if (athlete.gender || athlete.gender === 0) this.gender = athlete.gender;
    if (athlete.height) this.height = new Height(athlete.height);
    if (athlete.lastName) this.lastName = athlete.lastName;
    if (athlete.level) this.level = new Level(athlete.level);
    if (athlete.profilePictureUrl)
      this.profilePictureUrl = athlete.profilePictureUrl;
    if (athlete.team) this.team = new Team(athlete.team);
    if (athlete.weight) this.weight = new Weight(athlete.weight);
    if (athlete.category) this.category = new Category(athlete.category);
    if (athlete.sportPosition)
      this.sportPosition = new SportPosition(athlete.sportPosition);
    if (athlete.sport) this.sport = new Sport(athlete.sport);
  }

  setHeight(height: IHeight) {
    this.height = new Height(height);
  }

  setWeight(weight: IWeight) {
    this.weight = new Weight(weight);
  }
}
