import {Component, OnInit} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {
    ExercisesCategoryFormFactory,
    ExercisesCategoryFormHelper,
    IExercisesCategoryForm,
    IFullPairedExerciseForm,
    IFullRegularExerciseForm,
    IPairedExerciseConfigForm
} from "../../../workouts/workout-form/WorkoutFormFactory";
import {ExercisesControllerService} from "../../../../core/api/controllers/exercises-controller.service";
import {NotificationService} from "../../../../core/services/notification.service";
import {
    ConfirmationDialogComponent,
    IConfirmationDialogData
} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RegularExerciseHelper} from "../../../../core/forms/helpers/RegularExerciseHelper";
import {ExerciseHelper} from "../../../../core/helpers/exercise.helper";
import {EExerciseType} from "../../../../shared/enums/EExerciseType";
import {IRegularExerciseConfigForm} from "../../../../core/forms/factory/RegularExerciseFormFactory";
import {IGetExercisesLeaderboardReq} from "../../../../shared/interfaces/DTOs/Request/IGetExercisesLeaderboardReq";
import {GlobalPreferences} from "../../../../core/helpers/global-data";

@Component({
  selector: 'app-app-exercises-category-select-dialog',
  templateUrl: './app-exercises-category-select-dialog.component.html',
  styleUrls: ['./app-exercises-category-select-dialog.component.scss']
})
export class AppExercisesCategorySelectDialogComponent  implements OnInit{
    isLoading = false;
    exerciseCategoriesFormArray: FormArray<FormGroup<IExercisesCategoryForm>> = new FormArray<FormGroup<IExercisesCategoryForm>>([]);
    exerciseCategoriesFormArraySearched: FormArray<FormGroup<IExercisesCategoryForm>> = new FormArray<FormGroup<IExercisesCategoryForm>>([]);

    constructor(
      private exercisesController: ExercisesControllerService,
      private notificationService: NotificationService,
      private dialog: MatDialog,
      private dialogRef : MatDialogRef<AppExercisesCategorySelectDialogComponent>,
      private exerciseHelper: ExerciseHelper,
      private global: GlobalPreferences
    ) {}


    ngOnInit(): void {
        this.getExerciseCategories();
    }

    searchExercises(evt: {target: {value: string}}): void {
        const value = evt.target.value;
        this.exerciseCategoriesFormArraySearched = new FormArray<FormGroup<IExercisesCategoryForm>>([]);
        this.exerciseCategoriesFormArray.controls.forEach((categoryForm: FormGroup<IExercisesCategoryForm>) => {
            const includesCategoryName = categoryForm.controls.name.value.toLowerCase().includes(value.toLowerCase());
            if(includesCategoryName) this.exerciseCategoriesFormArraySearched.push(categoryForm);
            else {
                const newCategory = ExercisesCategoryFormFactory.create();
                newCategory.controls.name.setValue(categoryForm.controls.name.value);
                newCategory.controls.id.setValue(categoryForm.controls.id.value);
                categoryForm.controls.exercises.controls.forEach(exercise => {
                    if(exercise.controls.regularExercise.controls.name.value.toLowerCase().includes(value.toLowerCase()) || exercise.controls.pairedExercise.controls.name.value.toLowerCase().includes(value.toLowerCase()))
                        newCategory.controls.exercises.push(exercise);
                });
                if(newCategory.controls.exercises.controls.length > 0) this.exerciseCategoriesFormArraySearched.push(newCategory);
            }
        })
    }


    getExerciseCategories(): void {
        this.isLoading = true;
        this.exercisesController.getCategories().subscribe({
            next: (res) => {
                res.forEach(category => {
                    const newCategoryForm = ExercisesCategoryFormFactory.create();
                    ExercisesCategoryFormHelper.patchFormValue(newCategoryForm, category);
                    this.exerciseCategoriesFormArray.push(newCategoryForm);
                    this.exerciseCategoriesFormArraySearched.push(newCategoryForm);
                })
                this.isLoading = false;
            },
            error: (error) => {
                this.notificationService.error(error);
                this.isLoading = false;
            }
        });
    }


    openCloseDialog(): void {
        const data: IConfirmationDialogData = {
            title: "Are you sure?",
            message: "You will lose all your progress."
        }
        this.dialog.open(ConfirmationDialogComponent, {
            data
        }).afterClosed().subscribe({
            next: (res) => {
                if(res) this.dialog.closeAll();
            }
        })
    }


    onSave(): void {
        this.dialogRef.close(null);
    }


    selectPairedExerciseConfiguration(exerciseForm:FormGroup<IFullPairedExerciseForm>, configForm: FormGroup<IPairedExerciseConfigForm>): void {
        this.dialogRef.close({
            payload: this.createPairedPayload(configForm)
        });
    }

    createPairedPayload(configForm: FormGroup<IPairedExerciseConfigForm>): IGetExercisesLeaderboardReq {
        return {
            configId: configForm.controls.id.value,
            start: null,
            end: null,
            gmtOffset: this.global.getTimezoneOffset()
        }
    }

    selectRegularExerciseConfiguration(exerciseForm:FormGroup<IFullRegularExerciseForm>, configForm: FormGroup<IRegularExerciseConfigForm>): void {
        this.dialogRef.close({
            payload: this.createRegularPayload(configForm)
        });
    }

    createRegularPayload(configForm: FormGroup<IRegularExerciseConfigForm>): IGetExercisesLeaderboardReq {
        return {
            configId: configForm.controls.id.value,
            start: null,
            end: null,
            gmtOffset: this.global.getTimezoneOffset()
        }
    }

    getRegularExerciseConfigTitle(type: EExerciseType, config: FormGroup<IRegularExerciseConfigForm>): string {
        const exerciseConfig = RegularExerciseHelper.getConfigRaw(type, config);
        return this.exerciseHelper.getConfigTitle(type, exerciseConfig);
    }


    getFirstConfigTitle(exerciseForm: FormGroup<IFullPairedExerciseForm>, configForm: FormGroup<IPairedExerciseConfigForm>): string {
        const firstExerciseConfigForm = exerciseForm.controls.firstExercise.controls.configs.controls.find(config => config.controls.id.value === configForm.controls.firstConfigId.value);
        if(!firstExerciseConfigForm) return '-';
        const firstConfig = RegularExerciseHelper.getConfigRaw(exerciseForm?.controls.type.value, firstExerciseConfigForm);

        return this.exerciseHelper.getConfigTitle(exerciseForm.controls.type.value, firstConfig);
    }

    getSecondConfigTitle(exerciseForm: FormGroup<IFullPairedExerciseForm>, configForm: FormGroup<IPairedExerciseConfigForm>): string {
        const secondExerciseConfigForm = exerciseForm.controls.secondExercise.controls.configs.controls.find(config => config.controls.id.value === configForm.controls.secondConfigId.value);
        if(!secondExerciseConfigForm) return "-";

        const secondConfig = RegularExerciseHelper.getConfigRaw(exerciseForm?.controls.type.value, secondExerciseConfigForm);
        return this.exerciseHelper.getConfigTitle(exerciseForm.controls.type.value, secondConfig);
    }


}
