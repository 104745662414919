<div class="flex flex-column p-5 items-center">
  <div *ngIf="!isLoading">
    <div>
      <h2>{{ action }} user</h2>
    </div>
    <form
      [formGroup]="userForm"
      class="flex flex-col gap-3 py-5"
      (ngSubmit)="update()"
    >
      <div class="flex gap-5">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="firstName"
            placeholder="First name"
          />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(userForm.controls['firstName'])
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                userForm.controls["firstName"]
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="lastName"
            placeholder="Last name"
          />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(userForm.controls['lastName'])
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                userForm.controls["lastName"]
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="Email"
        />
        <mat-error
          *ngIf="ValidationHelperService.isInvalid(userForm.controls['email'])"
        >
          {{
            ValidationHelperService.getErrorMessage(userForm.controls["email"])
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="action === 'Add'">
        <mat-label>Username</mat-label>
        <input
          matInput
          type="text"
          formControlName="username"
          placeholder="Username"
          (blur)="checkUsername()"
        />
        <mat-error
          *ngIf="
            ValidationHelperService.isInvalid(userForm.controls['username'])
          "
        >
          {{
            ValidationHelperService.getErrorMessage(
              userForm.controls["username"]
            )
          }}
        </mat-error>
        <mat-error *ngIf="alreadyExists == -1">
          This username has already been taken.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Locations</mat-label>
        <mat-select
          multiple
          (click)="location.markAsUntouched()"
          placeholder="Category"
          [formControl]="location"
        >
          <mat-option *ngFor="let option of locations" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ValidationHelperService.isInvalid(location)">{{
          ValidationHelperService.getErrorMessage(location)
        }}</mat-error>
        <mat-error *ngIf="errorLocations">
          You need to select at least one location
        </mat-error>
      </mat-form-field>
      <div class="w-full flex justify-end">
        <button mat-flat-button color="primary" [disabled]="isLoading">
          <div class="flex gap-3 justify-center items-center">
            <span *ngIf="action === 'Edit'">Update User</span>
            <span *ngIf="action !== 'Edit'">Add User</span>
            <mat-spinner
              *ngIf="isLoading"
              diameter="20"
              color="accent"
            ></mat-spinner>
          </div>
        </button>
      </div>
    </form>
  </div>
  <div
    *ngIf="isLoading"
    class="w-[400px] h-[400px] flex justify-center items-center"
  >
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
