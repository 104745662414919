import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators,} from "@angular/forms";
import {AtheleteUnitsAdapter} from "./AtheleteUnitsAdapter";

export class CustomValidators {

  public static noWhitespaceValidator(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (value) {
      if (value.toString().match(/\s/)) {
        return { noWhitespace: true };
      }
    }
    return null;
  }

  public static passwordMatchValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    if (
      control?.get("password")?.value !== control?.get("confirmPassword")?.value
    ) {
      control?.get("confirmPassword")?.setErrors({ passwordMatch: true });
      return { passwordMatch: true };
    }
  }

  public static requiredCategoryValidator(control: AbstractControl): {
    [key: string]: any;
  } {
    if (!control?.value?.id || !control?.value?.name) {
      return { required: true };
    }
  }

  public static noDigitsValidator(
    control: FormControl,
  ): ValidationErrors | null {
    const value = control.value;
    if (value) {
      if (value.toString().match(/[aA-zZ'-]$/)) {
        return null;
      }
      return { noDigits: true };
    }
    return null;
  }

  public static variableCvv(group: FormGroup): void {
    const cardNo =
      (group.controls["cardNumber"].value &&
        group.controls["cardNumber"].value.toString()) ||
      "";
    const cvv =
      (group.controls["CVV"].value && group.controls["CVV"].value.toString()) ||
      "";
    if (cardNo.startsWith("37") || cardNo.startsWith("34")) {
      if (cvv.length !== 3 && cvv.length !== 4) {
        group.controls["CVV"].setErrors({
          invalidAmexCVV: true,
          invalidCVV: false,
        });
      }
    } else {
      if (cvv.length !== 3) {
        group.controls["CVV"].setErrors({
          invalidCVV: true,
          invalidAmexCVV: false,
        });
      }
    }
  }

  public static heightBoundariesValidator(group: FormGroup): void {
    const height = group.controls["height"].value;
    let value;
    if (typeof height === "string" && height.includes("'")) {
      value = AtheleteUnitsAdapter.valueToCm(height);
    } else value = height;
    if (value !== "" && (value < 50 || value > 300)) {
      group.controls["height"].setErrors({ invalidHeight: true });
    }
  }

  public static nameValidator(): ValidatorFn {
    return Validators.pattern("^[A-Za-z \\u00C0-\\u00FF\\u0100-\\u0280'-]*$");
  }

  public static floatValidator(): ValidatorFn {
    return Validators.pattern("^\\d+(\\.\\d{1,2})?$");
  }

  public static emailValidator(): ValidatorFn {
    return Validators.pattern(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
        "@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
    );
  }

  public static phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^[0-9-+\s()]*$/.test(control.value);
      return isValid ? null : { invalidPhone: true };
    };
  }

  public static numberValidator(): ValidatorFn {
    return Validators.pattern("^[0-9]*$");
  }

  public static CVVValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^[0-9]*$/.test(control.value);
      return isValid ? null : { invalidCVV: true };
    };
  }

  public static usernameValidator(): ValidatorFn {
    return Validators.pattern("^[a-zA-Z\\-0-9]*$");
  }

  public static passwordValidator(): ValidatorFn {
    return ((formControl: FormControl) => {
      const value = formControl.value;
      // regex for one uppercase, one lowercase, one number and one special character

      if (
        !value?.match(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        )
      ) {
        return {
          invalidPassword: true,
        };
      }
    }) as ValidatorFn;
  }

  public static codeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^[0-9 ]+$/.test(control.value);
      return isValid ? null : { invalidCVV: true };
    };
  }

  public static zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^[0-9 ]+$/.test(control.value);
      return isValid ? null : { invalidZipCode: true };
    };
  }

  public static cardValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid =
        /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(
          control.value,
        );
      return isValid ? null : { invalidCardNumber: true };
    };
  }

  public static onlyDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^\d+$/.test(control.value);
      return isValid ? null : { invalidOnlyDigits: true };
    };
  }

  public static feetValidator(): ValidatorFn {
    return Validators.pattern(/^\d+'(\d+")?$/gm);
  }
}
