<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100">
    <div class="top-row-wrapper p-3">
      <p class="inline-display top-header large-text bold-text">My account</p>
      <div
        class="card w-100 p-3 border-danger"
        *ngIf="!settingsData?.paymentInfo && !isLoading"
      >
        <div class="d-flex justify-content-between align-items-center">
          Please update your payment information
          <button
            class="btn btn-secondary disabled-custom"
            (click)="openSubscriptionPaymentModal()"
          >
            Update Payment Info
          </button>
        </div>
      </div>
    </div>
    <div>
      <ul class="nav nav-tabs" id="links-wrapper">
        <li class="nav-item">
          <a
            class="nav-link pointer text-uppercase font-weight-bold p-3"
            [routerLinkActive]="['active']"
            routerLink="/settings/team"
            >Team</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer text-uppercase font-weight-bold p-3"
            [routerLinkActive]="['active']"
            routerLink="/settings/locations"
            >Locations</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer text-uppercase font-weight-bold p-3"
            [routerLinkActive]="['active']"
            routerLink="/settings/invoices"
            >Invoices</a
          >
        </li>
        <li class="nav-item" *ngIf="isSuperAdmin">
          <a
            class="nav-link pointer text-uppercase font-weight-bold p-3"
            [routerLinkActive]="['active']"
            routerLink="/settings/history"
            >Payment history</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer text-uppercase font-weight-bold p-3"
            [routerLinkActive]="['active']"
            routerLink="/settings/whitelist"
            >Whitelist
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="ui clearing divider"></div>

  <div class="full-height" *ngIf="isLoading">
    <div class="ui massive active text loader"></div>
  </div>

  <div
    class="d-flex flex-sm-column flex-md-row flex-sm-row justify-content-between w-100"
    *ngIf="!isLoading"
  >
    <div class="flex-1">
      <router-outlet></router-outlet>
    </div>
    <div class="w-[400px]">
      <app-generic-account-card
        [settingsData]="settingsData"
        [isLoading]="isLoading"
        [paymentData]="paymentData"
      ></app-generic-account-card>
    </div>
  </div>
</div>
