export const monthVector = [
  "01 - Jan",
  "02 - Feb",
  "03 - Mar",
  "04 - Apr",
  "05 - May",
  "06 - Jun",
  "07 - Jul",
  "08 - Aug",
  "09 - Sep",
  "10 - Oct",
  "11 - Nov",
  "12 - Dec",
];

export function yearVector() {
  let i = new Date().getFullYear();
  return Array(15)
    .fill(0)
    .reduce((arr) => {
      arr.push(i);
      ++i;
      return arr;
    }, []);
}
