import { Component, Inject, OnInit } from "@angular/core";
import { CustomValidators } from "../../../core/helpers/custom-validators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NotificationService } from "../../../core/services/notification.service";
import { monthVector, yearVector } from "../../../core/helpers/calendar-data";
import { MatDialogRef } from "@angular/material/dialog";
import { PaymentControllerService } from "../../../core/api/controllers/payment-controller.service";

@Component({
  selector: "app-subscription-payment-dialog",
  templateUrl: "./subscription-payment-dialog.component.html",
  styleUrls: ["./subscription-payment-dialog.component.scss"],
})
export class SubscriptionPaymentDialogComponent implements OnInit {
  paymentData;
  subscriptionPaymentForm: FormGroup;
  useMonthVector = false;
  selectedMonth: string;
  currentMonthVector: string[];
  selectedYear: string;
  yearVector: string[];
  attemptingPurchase = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private paymentController: PaymentControllerService,
    private dialogRef: MatDialogRef<SubscriptionPaymentDialogComponent>,
    @Inject("response") private response: any = {},
  ) {}

  ngOnInit(): void {
    this.initDialog();
  }

  initDialog(): void {
    this.router.navigate(["/"]);
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.subscriptionPaymentForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, CustomValidators.nameValidator]],
        lastName: ["", [Validators.required, CustomValidators.nameValidator]],
        cardNumber: ["", [Validators.required, CustomValidators.cardValidator]],
        CVV: ["", [Validators.required]],
      },
      { validator: CustomValidators.variableCvv },
    );

    const { amount, annuallySubscription, annuallyDiscount } = this.response;

    this.paymentData = {
      amount: annuallySubscription ? amount * 12 - annuallyDiscount : amount,
      subscriptionName: this.response.subscriptionName,
      triesToReBill: this.response.triesToReBill,
    };
  }

  validateDate() {
    if (this.selectedYear !== this.yearVector[0]) {
      this.useMonthVector = true;
    } else {
      this.useMonthVector = false;
      this.selectedMonth = this.currentMonthVector[0];
    }
  }

  attemptPayment() {
    this.attemptingPurchase = true;
    this.paymentController
      .attemptPayment({
        cardNo: this.subscriptionPaymentForm.controls["cardNumber"].value,
        firstName:
          this.subscriptionPaymentForm.controls["firstName"].value.trim(),
        lastName:
          this.subscriptionPaymentForm.controls["lastName"].value.trim(),
        expiration:
          this.selectedMonth.toString().substring(0, 2) +
          this.selectedYear.toString().substring(2),
        cvv: this.subscriptionPaymentForm.controls["CVV"].value,
      })
      .subscribe(
        () => {
          this.attemptingPurchase = false;
          this.dialogRef.close(false);
        },
        (error) => {
          this.attemptingPurchase = false;
          this.notificationService.error(error);
        },
      );
  }

  get monthVector() {
    return monthVector;
  }
}
