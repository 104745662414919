import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ApiService} from "../api.service";
import {ISport} from "../../../shared/interfaces/ISport";
import {ISportPosition} from "../../../shared/interfaces/ISportPosition";

@Injectable({
  providedIn: "root",
})
export class SportsControllerService {
  private readonly _resourceUrl: string = "/sports";

  constructor(private apiService: ApiService) {}

  getSportById(id: string): Observable<ISport> {
    return this.apiService.get<ISport>(`${this._resourceUrl}/${id}`, {} , AuthService.defaultTokenHeader().headers);
  }

  getPositionBySportName(sportName: string): Observable<any> {
    return this.apiService.get(
      `/position-sport`,
      {
        sportName,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getSports(): Observable<ISport[]> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getSportsPositions(id: string): Observable<ISportPosition[]> {
    return this.apiService.get<ISportPosition[]>(
      `${this._resourceUrl}/${id}/positions`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  editSport(sport: ISport): Observable<ISport> {
    return this.apiService.put(`${this._resourceUrl}`, sport, {} ,AuthService.defaultTokenHeader().headers);
  }

  createSport(sport: ISport): Observable<ISport> {
    return this.apiService.post(`${this._resourceUrl}`, sport, {} ,AuthService.defaultTokenHeader().headers);
  }

  editPosition(position: ISportPosition): Observable<ISportPosition> {
    return this.apiService.put(`${this._resourceUrl}/positions`, position, {}, AuthService.defaultTokenHeader().headers);
  }
  
  createPosition(payload: {sportId: string; name: string;}): Observable<{id: string}>
  {
    return this.apiService.post(`${this._resourceUrl}/positions`, payload, {}, AuthService.defaultTokenHeader().headers);
  }
}
