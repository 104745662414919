import {Component, OnInit} from "@angular/core";
import {ISport} from "../../shared/interfaces/ISport";
import {SportsControllerService} from "../../core/api/controllers/sports-controller.service";
import {NotificationService} from "../../core/services/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateSportDialogComponent} from "./create-sport-dialog/create-sport-dialog.component";

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit {
  fetchedSports: ISport[];
  sports: ISport[];
  isLoading = false;
  searchField = "";

  constructor(
      public sportsController: SportsControllerService,
      public notificationService: NotificationService,
      private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.getSports();
  }

  getSports(): void {
    this.isLoading = true;
    this.sportsController.getSports().subscribe({
      next: (res) => {
        this.fetchedSports = res;
        this.search();
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      }
    })
  }

  search(): void {
      if(this.searchField === "") {
        this.sports = this.fetchedSports;
      } else {
        this.sports = this.fetchedSports.filter(sport => sport.name.toLowerCase().includes(this.searchField.toLowerCase()))
      }
  }

  openCreateSportDialog(): void {
    this.dialog.open(CreateSportDialogComponent, {
     maxWidth: "800px",
     maxHeight: "400px",
      width: "90vw"
    }).afterClosed().subscribe(res => {
      if(res) this.getSports();
    })
  }


  toggleIsActive(sport: ISport): void {
    this.isLoading = true;
    sport.isActive = !sport.isActive;
    this.sportsController.editSport(sport).subscribe({
      next: (res) => {
        sport = res;
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      }
    })
  }

  protected readonly open = open;
}
