import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../../core/services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { WhitelistDialogComponent } from "./whitelist-dialog/whitelist-dialog.component";
import { LocationsControllerService } from "../../../core/api/controllers/locations-controller.service";

@Component({
  selector: "app-whitelist",
  templateUrl: "./whitelist.component.html",
  styleUrls: ["./whitelist.component.scss"],
})
export class WhitelistComponent implements OnInit {
  locations = [];
  isLoading = false;

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private locationsController: LocationsControllerService,
  ) {}

  ngOnInit(): void {
    this.getLocations();
  }

  getLocations() {
    this.isLoading = true;
    this.locationsController.getAllLocations().subscribe(
      (response) => {
        this.locations = response.locations;
        this.locations = this.locations.map((location) => {
          return {
            ...location,
            ips: ["192.168.1.1", "172.192.15.13", "152.145.161.2"],
            collapsed: false,
          };
        });
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  openWhitelistDialog(location) {
    this.dialog.open(WhitelistDialogComponent, {
      data: {
        location,
      },
      width: "800px",
      height: "600px",
    });
  }
}
