import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IWorkout} from "../../../shared/interfaces/IWorkout";
import {MatDialog} from "@angular/material/dialog";
import {
    ConfirmationDialogComponent,
    IConfirmationDialogData
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {WorkoutsControllerService} from "../../../core/api/controllers/workouts/workouts-controller.service";

@Component({
  selector: 'app-workout-card',
  templateUrl: './workout-card.component.html',
  styleUrls: ['./workout-card.component.scss']
})
export class WorkoutCardComponent {
  @Input() workout: IWorkout;
  @Output() _delete = new EventEmitter<string>();
  isLoading = false;

  constructor(
      private dialog: MatDialog,
      private workoutsController: WorkoutsControllerService
  ) {}

  getExercisesCount(): number {
    return this.workout.playlists.map(playlist => {
      return playlist.exercises.length
    }).reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }

  openDeleteDialog(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "Are you sure you want to delete this workout?"
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (value) => {
        if(value) {
          this.deleteWorkout();
        }
      }
    })
  }

  deleteWorkout(): void {
    this.isLoading = true;
    this.workoutsController.toggleWorkoutStatus(this.workout.id, false).subscribe({
      next: () => {
        this._delete.emit(this.workout.id);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }
}
