import {AddressType} from "../constants/subscription.const";

export class FormGeographyData {
  personalAddress: AddressItems;
  billingAddress: AddressItems;
  shippingAddress: AddressItems;

  constructor() {
    this.personalAddress = new AddressItems();
    this.billingAddress = new AddressItems();
    this.shippingAddress = new AddressItems();
  }

  initStatesLists(states: IGeographicalEntity[]): void {
    this.personalAddress.states = [...states];
    this.billingAddress.states = [...states];
    this.shippingAddress.states = [...states];
  }

  initCitiesLists(cities: IGeographicalEntity[]): void {
    this.personalAddress.cities = [...cities];
    this.billingAddress.cities = [...cities];
    this.shippingAddress.cities = [...cities];
  }

  setStates(states: IGeographicalEntity[], addressType: AddressType): void {
    switch (addressType) {
      case AddressType.Personal:
        this.personalAddress.states = [...states];
        break;
      case AddressType.Billing:
        this.billingAddress.states = [...states];
        break;
      case AddressType.Shipping:
        this.shippingAddress.states = [...states];
        break;
    }
  }

  setCities(cities: IGeographicalEntity[], addressType: AddressType): void {
    switch (addressType) {
      case AddressType.Personal:
        this.personalAddress.cities = [...cities];
        break;
      case AddressType.Billing:
        this.billingAddress.cities = [...cities];
        break;
      case AddressType.Shipping:
        this.shippingAddress.cities = [...cities];
        break;
    }
  }

  getStates(addressType: AddressType): IGeographicalEntity[] {
    switch (addressType) {
      case AddressType.Billing:
        return this.billingAddress.states;
      case AddressType.Shipping:
        return this.shippingAddress.states;
      case AddressType.Personal:
      default:
        return this.personalAddress.states;
    }
  }

  addCityInAllLists(city: IGeographicalEntity): void {
    this.personalAddress.cities.unshift(city);
    this.billingAddress.cities.unshift(city);
    this.shippingAddress.cities.unshift(city);
  }
}

export class AddressItems {
  states: IGeographicalEntity[];
  cities: IGeographicalEntity[];

  constructor() {
    this.states = [];
    this.cities = [];
  }
}

export interface IGeographicalEntity {
  id: string;
  name: string;
}

export interface Country extends IGeographicalEntity {
  regionId: string;
}
