<div class="d-flex flex-column gap-5 p-5" *ngIf="!isLoading">
  <h2>Generate usage report</h2>
  <div
    class="d-flex flex-column justify-content-around align-items-center flex-grow-1"
  >
    <div
      class="d-flex flex-column justify-content-center gap-3 align-items-center"
    >
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" #dateRangeStart />
          <input
            matEndDate
            placeholder="End date"
            #dateRangeEnd
            (dateChange)="changeDate(dateRangeStart, dateRangeEnd)"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <button
        class="qb-blue-background primary ui button inline-display float-right"
        [disabled]="!isValidDate(newStartDate, newEndDate)"
        (click)="getResultData()"
      >
        Select
      </button>
    </div>
    <div></div>
  </div>
</div>
<div
  *ngIf="isLoading"
  class="w-full h-[300px] flex justify-center items-center"
>
  <mat-spinner diameter="50"></mat-spinner>
</div>
