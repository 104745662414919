import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class PlaylistControllerService {
  private readonly _resourceUrl: string = "/playlist";

  constructor(private apiService: ApiService) {}

  getPlaylistData(
    page: number,
    size: number,
    search: string = "",
    athleteId: string = "",
  ): Observable<any> {
    const details =
      search === ""
        ? `page=${page}&size=${size}`
        : `page=${page}&size=${size}&search=${search}&id=${athleteId}`;
    return this.apiService.get(
      `${this._resourceUrl}?${details}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  savePlaylist(data): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  clonePlaylist(data): Observable<any> {
    return this.apiService.post(
      `/playlist-clone`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
