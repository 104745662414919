


<div class="flex flex-col gap-3 p-5 w-full min-w-[800px] h-full">
    <h2>Select an exercise configuration</h2>
    <form class="flex flex-col gap-3 h-full">
        <div class="h-[calc(100%-80px)]">
            <div class="h-full flex flex-col gap-3">
                <div class="w-full h-[56px] bg-white z-50">
                    <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Search category or exercise</mat-label>
                        <input type="text" matInput (input)="searchExercises($event)">
                    </mat-form-field>
                </div>
                <div class="h-[calc(100%-56px)] overflow-y-auto" *ngIf="!isLoading">
                    <div *ngFor="let exerciseCategoryForm of exerciseCategoriesFormArraySearched.controls">
                        <div class="flex flex-col gap-3 h-full">
                            <div class="flex flex-col gap-5" *ngIf="exerciseCategoryForm.controls.exercises.controls.length > 0">
                                <div class="w-full p-3 font-medium text-xl color-primary bg-gray-400 sticky top-0 z-50 mt-3">
                                    {{exerciseCategoryForm.controls.name.value}}
                                </div>
                                <div class="flex flex-col gap-5" *ngIf="exerciseCategoryForm.controls.exercises.controls.length > 0">
                                    <div *ngFor="let exercise of exerciseCategoryForm.controls.exercises.controls">
                                        <div *ngIf="exercise?.controls.regularExercise.controls.configs.controls.length > 0">
                                            <div class="flex flex-col gap-3">
                                                <div class="w-full p-3 font-medium text-md text-white bg-gray-400">
                                                    {{exercise.controls.regularExercise.controls.name.value}}
                                                </div>
                                                <div class="flex flex-col gap-3">
                                                    <div class="h-[56px] flex gap-5 items-center justify-between" *ngFor="let config of exercise.controls.regularExercise.controls.configs.controls">
                                                        <div class="flex gap-5 items-center">
                                                            <div class="flex items-center gap-2 hover:opacity-80 cursor-pointer" (click)="selectRegularExerciseConfiguration(exercise.controls.regularExercise, config)">
                                                                <div class="flex items-center">
                                                                    <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                    <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                </div>
                                                                <span  class="font-medium text-md">{{getRegularExerciseConfigTitle(exercise.controls.regularExercise.controls.type.value, config)}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="exercise?.controls.pairedExercise.controls.pairedExerciseConfigs.controls.length > 0">
                                            <div class="flex flex-col gap-3">
                                                <div class="w-full p-3 font-medium text-md text-white bg-gray-400">
                                                    {{exercise.controls.pairedExercise.controls.name.value}}
                                                </div>
                                                <div class="flex flex-col gap-3">
                                                    <div class="flex gap-5 items-center justify-between" *ngFor="let configForm of exercise.controls.pairedExercise.controls.pairedExerciseConfigs.controls">
                                                        <div class="flex gap-5 items-center">
                                                            <mat-checkbox  #checkbox color="primary" [formControl]="configForm.controls.isSelected"></mat-checkbox>
                                                            <div class="flex flex-col hover:opacity-80 cursor-pointer" (click)="selectPairedExerciseConfiguration(exercise.controls.pairedExercise, configForm)">
                                                                <div class="flex items-center gap-2">
                                                                    <div class="flex items-center">
                                                                        <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                        <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                    </div>
                                                                    <span  class="font-medium text-md">{{getFirstConfigTitle(exercise.controls.pairedExercise, configForm)}}</span>
                                                                </div>
                                                                <div class="flex items-center gap-2">
                                                                    <div class="flex items-center">
                                                                        <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                        <img src="assets/images/qb_logo_black_small.svg" alt="Quickboard logo" class="h-[20px] w-[20px]" />
                                                                    </div>
                                                                    <span  class="font-medium text-md">{{getSecondConfigTitle(exercise.controls.pairedExercise, configForm)}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="isLoading" class="w-full h-[400px] flex justify-center items-center">
                    <mat-spinner diameter="30"></mat-spinner>
                </div>
            </div>

        </div>

    </form>
    <div class="flex justify-end flex-grow">
        <div class="flex justify-center items-center gap-3">
            <button
                    type="button"
                    mat-flat-button
                    (click)="openCloseDialog()"
            >
                Cancel
            </button>
            <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    (click)="onSave()"
            >
                Save
            </button>
        </div>
    </div>
</div>
