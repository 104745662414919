import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GeographyControllerService } from "../../../../core/api/controllers/geography-controller.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { CustomValidators } from "../../../../core/helpers/custom-validators";
import { MatDialogRef } from "@angular/material/dialog";
import { AccountControllerService } from "../../../../core/api/controllers/account-controller.service";

@Component({
  selector: "app-account-info",
  templateUrl: "./account-info-dialog.component.html",
  styleUrls: ["./account-info-dialog.component.scss"],
})
export class AccountInfoDialogComponent implements OnInit {
  subscriptionPaymentForm: FormGroup;
  accountInformationForm: FormGroup;
  addressSelected;
  accountInfo;
  addressObject = {
    countries: [],
    states: [],
    cities: [],
    typeahead: [],
    countryName: "country",
    stateName: "state",
    cityName: "city",
  };
  shippingObject = {
    countries: [],
    states: [],
    typeahead: [],
    countryName: "shippingCountry",
    stateName: "shippingState",
    cityName: "shippingCity",
  };
  billingObject = {
    countries: [],
    states: [],
    typeahead: [],
    countryName: "billingCountry",
    stateName: "billingState",
    cityName: "billingCity",
  };

  public newShippingCity: boolean;
  public newBillingCity: boolean;

  shippingAddressStateName: "";
  billingAddressStateName: "";

  constructor(
    private accountController: AccountControllerService,
    private formBuilder: FormBuilder,
    private countryService: GeographyControllerService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AccountInfoDialogComponent>,
  ) {}

  ngOnInit() {
    this.addressSelected = 0;
    this.accountInformationForm = this.formBuilder.group({
      email: ["", []],
      organizationName: ["", [Validators.required]],
      firstName: ["", [Validators.required, CustomValidators.nameValidator]],
      lastName: ["", [Validators.required, CustomValidators.nameValidator]],
      country: ["", []],
      address: ["", []],
      address2: ["", []],
      city: ["", []],
      state: ["", [Validators.required]],
      zipCode: ["", []],
      shippingCountry: ["", []],
      shippingAddress: ["", []],
      shippingAddress2: ["", []],
      shippingCity: ["", []],
      shippingState: ["", []],
      shippingZipCode: ["", []],
      billingCountry: ["", []],
      billingAddress: ["", []],
      billingAddress2: ["", []],
      billingCity: ["", []],
      billingState: ["", []],
      billingZipCode: ["", []],
      phone: ["", [CustomValidators.phoneValidator]],
    });
    this.subscriptionPaymentForm = this.formBuilder.group({
      firstName: [""],
      lastName: [""],
      cardNumber: [""],
      CVV: [""],
    });
    this.accountController.getAccountInformation().subscribe(
      (response) => {
        this.accountInfo = response;
        this.accountInformationForm.controls["email"].setValue(response.email);
        this.accountInformationForm.controls["organizationName"].setValue(
          response.organizationName,
        );
        this.accountInformationForm.controls["firstName"].setValue(
          response.firstName,
        );
        this.accountInformationForm.controls["lastName"].setValue(
          response.lastName,
        );

        if (response.organizationAddress) {
          this.accountInformationForm.controls["country"].setValue(
            response.organizationAddress.country.id,
          );
          this.accountInformationForm.controls["state"].setValue(
            response.organizationAddress.state.id,
          );
          this.accountInformationForm.controls["city"].setValue(
            response.organizationAddress.city.id,
          );
          this.accountInformationForm.controls["zipCode"].setValue(
            response.organizationAddress.zipCode,
          );

          this.accountInformationForm.controls["address"].setValue(
            response.organizationAddress.addressLine1,
          );
          this.accountInformationForm.controls["address2"].setValue(
            response.organizationAddress.addressLine2,
          );
        }

        this.accountInformationForm.controls["phone"].setValue(
          response.phoneNumber,
        );

        if (response.shippingAddress) {
          this.accountInformationForm.controls["shippingCountry"].setValue(
            response.shippingAddress.country.id,
          );
          this.accountInformationForm.controls["shippingState"].setValue(
            response.shippingAddress.state.id,
          );
          this.accountInformationForm.controls["shippingCity"].setValue(
            response.shippingAddress.city.id,
          );
          this.accountInformationForm.controls["shippingZipCode"].setValue(
            response.shippingAddress.zipCode,
          );

          this.accountInformationForm.controls["shippingAddress"].setValue(
            response.shippingAddress.addressLine1,
          );
          this.accountInformationForm.controls["shippingAddress2"].setValue(
            response.shippingAddress.addressLine2,
          );

          this.getStates(
            this.shippingObject,
            response.shippingAddress.country.id,
          );
          this.getCities(
            this.shippingObject,
            response.shippingAddress.state.id,
          );
        }

        if (response.billingAddress) {
          this.accountInformationForm.controls["billingCountry"].setValue(
            response.billingAddress.country.id,
          );
          this.accountInformationForm.controls["billingState"].setValue(
            response.billingAddress.state.id,
          );
          this.accountInformationForm.controls["billingCity"].setValue(
            response.billingAddress.city.id,
          );
          this.accountInformationForm.controls["billingZipCode"].setValue(
            response.billingAddress.zipCode,
          );

          this.accountInformationForm.controls["billingAddress"].setValue(
            response.billingAddress.addressLine1,
          );
          this.accountInformationForm.controls["billingAddress2"].setValue(
            response.billingAddress.addressLine2,
          );

          this.getStates(
            this.billingObject,
            response.billingAddress.country.id,
          );
          this.getCities(this.billingObject, response.billingAddress.state.id);
        }

        this.getCountries();
      },
      (error) => this.notificationService.error(error),
    );
  }

  getCountries() {
    this.countryService.getAllCountries().subscribe(
      (response) => {
        this.addressObject.countries = response;
        this.shippingObject.countries = response;
        this.billingObject.countries = response;
      },
      (error) => {
        this.notificationService.error("Error!");
      },
    );
  }

  getStates(object, countryId) {
    this.countryService.getStatesByCountry(countryId).subscribe((response) => {
      object.states = response;
    });
  }

  getCities(object, stateId) {
    this.countryService.getCitiesByState(stateId).subscribe((response) => {
      object.typeahead = response;
    });
  }

  changeAccountInformation() {
    const payload = {
      user: {
        email: this.accountInformationForm.controls["email"].value,
        firstName: this.accountInformationForm.controls["firstName"].value,
        lastName: this.accountInformationForm.controls["lastName"].value,
        phoneNumber: this.accountInformationForm.controls["phone"].value,
      },

      organization: {
        organizationName:
          this.accountInformationForm.controls["organizationName"].value,
        organizationAddress: {
          addressLine1: this.accountInformationForm.controls["address"].value
            ? this.accountInformationForm.controls["address"].value.trim()
            : "",
          addressLine2: this.accountInformationForm.controls["address2"].value
            ? this.accountInformationForm.controls["address2"].value.trim()
            : "",
          zipCode: this.accountInformationForm.controls["zipCode"].value.trim(),
          cityId:
            this.accountInformationForm.controls["city"].value.id ||
            this.accountInformationForm.controls["shippingCity"].value,
        },

        shippingAddress: {
          addressLine1: this.accountInformationForm.controls[
            "shippingAddress"
          ].value.trim()
            ? this.accountInformationForm.controls[
                "shippingAddress"
              ].value.trim()
            : "",
          addressLine2:
            this.accountInformationForm.controls["shippingAddress2"].value &&
            this.accountInformationForm.controls[
              "shippingAddress2"
            ].value.trim()
              ? this.accountInformationForm.controls[
                  "shippingAddress2"
                ].value.trim()
              : "",
          zipCode:
            this.accountInformationForm.controls["shippingZipCode"].value &&
            this.accountInformationForm.controls[
              "shippingZipCode"
            ].value.trim(),
          cityId: this.accountInformationForm.controls["shippingCity"].value,
          firstName:
            this.accountInformationForm.controls["firstName"].value.trim(),
          lastName:
            this.accountInformationForm.controls["lastName"].value.trim(),
        },

        billingAddress: {
          addressLine1: this.accountInformationForm.controls[
            "billingAddress"
          ].value.trim()
            ? this.accountInformationForm.controls[
                "billingAddress"
              ].value.trim()
            : "",
          addressLine2:
            this.accountInformationForm.controls["billingAddress2"].value &&
            this.accountInformationForm.controls["billingAddress2"].value.trim()
              ? this.accountInformationForm.controls[
                  "billingAddress2"
                ].value.trim()
              : "",
          zipCode:
            this.accountInformationForm.controls["billingZipCode"].value &&
            this.accountInformationForm.controls["billingZipCode"].value.trim(),
          cityId: this.accountInformationForm.controls["billingCity"].value,
          firstName:
            this.accountInformationForm.controls["firstName"].value.trim(),
          lastName:
            this.accountInformationForm.controls["lastName"].value.trim(),
        },
      },
    };

    if (this.newShippingCity) {
      payload.organization.shippingAddress["newCity"] = {
        name: this.accountInformationForm.controls["shippingCity"].value,
        stateId: this.accountInformationForm.controls["shippingState"].value,
      };
    } else {
      payload.organization.shippingAddress["cityId"] =
        this.accountInformationForm.controls["shippingCity"].value;
    }

    if (this.newBillingCity) {
      payload.organization.billingAddress["newCity"] = {
        name: this.accountInformationForm.controls["billingCity"].value,
        stateId: this.accountInformationForm.controls["billingState"].value,
      };
    } else {
      payload.organization.billingAddress["cityId"] =
        this.accountInformationForm.controls["billingCity"].value;
    }

    this.accountController.changeAccountInformation(payload).subscribe(
      () => {
        this.notificationService.success(
          "Account information successfully changed.",
        );
      },
      (error) => this.notificationService.error(error),
    );
  }

  stateHasChanged(e, isBilling) {
    const stateId = e.target.value;
    const { name } = (
      isBilling ? this.billingObject : this.shippingObject
    ).states.find(({ id }) => id === stateId);
    if (isBilling) {
      this.billingAddressStateName = name;
    } else {
      this.shippingAddressStateName = name;
    }
  }
}
