<ng-template
  let-modal="modal"
  #exerciseModalTemplate
  style="margin-bottom: 4rem"
>
  <!-- continue -->

  <div class="w-100 p-3 border-bottom">
    <span class="h6 font-weight-bold">Add exercises</span>
  </div>

  <div *ngFor="let category of data.exerciseCategories" class="w-100">
    <table class="table table-bordered table-striped">
      <thead class="p-3 w-100">
        <tr>
          <th colspan="4">
            <span class="h-6 ml-2 font-weight-bold">{{ category.name }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="w-100"
          *ngFor="let exercise of data.exercises[category.id]; let i = index"
        >
          <td class="radio" id="left-column">
            <label class="check-container">
              <input
                type="checkbox"
                (change)="updateSelected(exercise, data.addExercisesSelected)"
                [checked]="isAlreadyAdded(exercise)"
              />
              <span class="checkmark"></span>
            </label>
          </td>
          <td class="">
            <div class="d-flex align-items-center">
              <p>{{ exercise.name }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="actions no-border gray-background center-text">
    <button
      class="btn btn-primary text-uppercase"
      [disabled]="data.addExercisesSelected.length === 0"
      (click)="addExercises(); modal.approve()"
    >
      Add exercise
    </button>
  </div>
</ng-template>
