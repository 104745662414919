<div class="locations-container">
  <h2 class="required-message" *ngIf="isRequired">
    You are currently not allowed to access the platform.
    <br />
    Please select a location to continue.
  </h2>
  <div class="form-wrapper">
    <form [formGroup]="form">
      <input
        type="text"
        formControlName="location"
        placeholder="Search a location"
        (keydown)="updateSearchedLocations()"
      />
    </form>
    <div class="close-button" (click)="goHome()" *ngIf="!isRequired">
      <i class="material-icons">clear</i>
    </div>
  </div>
  <div class="overflow-auto px-5">
    <div class="locations" *ngIf="searchedLocations?.length > 0">
      <h1>Searched Locations</h1>
      <div class="locations-wrapper">
        <div *ngFor="let location of searchedLocations">
          <div
            class="location"
            (click)="selectLocation(location.id)"
            [class.disabled-location]="isSelected(location.id)"
          >
            <div class="location-name">
              {{ location.name }}
              <span *ngIf="isSelected(location.id)">(Selected)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="locations">
      <h1>All Locations</h1>
      <div class="spinner" *ngIf="isLoading">
          <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div class="locations-wrapper" *ngIf="!isLoading">
        <div *ngFor="let location of locations">
          <div
            class="location"
            (click)="selectLocation(location.id)"
            [class.disabled-location]="isSelected(location.id)"
          >
            <div class="location-name">
              {{ location.name }}
              <span *ngIf="isSelected(location.id)">(Selected)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
