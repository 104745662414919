import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {EAction} from "../../../../shared/enums/EAction";

@Component({
  selector: "app-exercise-type",
  templateUrl: "./exercise-type.component.html",
  styleUrls: ["./exercise-type.component.scss"],
})
export class ExerciseTypeComponent {
  @Input() _control: FormControl;
  @Input() action = EAction.Add;

  setExerciseType(type: number): void {
    if(this.action === EAction.Edit) {
     return;
    }
    this._control.setValue(type);
  }

  protected readonly EAction = EAction;
}
