import { Component, OnInit } from "@angular/core";
import { WorkoutControllerService } from "../../../core/api/controllers/workout-controller.service";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { NotificationService } from "../../../core/services/notification.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-sync-workout",
  templateUrl: "./sync-workout.component.html",
  styleUrls: ["./sync-workout.component.scss"],
  providers: [WorkoutControllerService],
})
export class SyncWorkoutComponent implements OnInit {
  workoutId: string;
  multipleWorkoutIds: string[] = [];
  adminWorkout: any;
  addAllWorkout = false;
  isWorkoutOnAllLocations = false;
  checkFinished = false;
  showData = false;
  collapseFlag = false;
  searchText: string = "";
  addWorkoutArray: any[];
  addWorkoutArrayCopy: any[];
  organizations: any = [];
  syncExerciseArray: any = [];
  syncExerciseArrayCopy: any = [];
  syncFullArray: any = [];
  syncFullArrayCopy: any = [];
  canLoadOrganizations = false;
  organizationsLoaded = false;
  private organizationName: Subject<string> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private addWorkoutService: WorkoutControllerService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ id }) => {
      if (id instanceof Array) {
        this.workoutId = id[0];
        this.multipleWorkoutIds = id;
      } else {
        this.workoutId = id;
      }

      this.addWorkoutService
        .getWorkoutById(this.workoutId)
        .subscribe((data) => {
          this.adminWorkout = data;
        });

      this.getOrganizations();
      this.checkLocationsWorkout();
    });

    this.organizationName
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(
        (data) => {
          const page = 0;
          const size = 20;

          this.addWorkoutService
            .getOrganizations(page, size, this.searchText, this.workoutId)
            .subscribe((data) => {
              this.organizations = data;

              const canLoadLength = data.length;
              this.canLoadOrganizations =
                canLoadLength % 20 === 0 && canLoadLength !== 0;
              this.showData = true;

              this.addWorkoutArray = [];
              let rows = this.getArraySize(this.organizations)[0];
              let cols = this.getArraySize(this.organizations)[1];

              this.addWorkoutArray = Array.from({ length: rows }, () =>
                Array.from({ length: cols }, () => false),
              );

              this.syncExerciseArray = [];

              this.syncExerciseArray = Array.from({ length: rows }, () =>
                Array.from({ length: cols }, () => false),
              );

              this.syncFullArray = [];

              this.syncFullArray = Array.from({ length: rows }, () =>
                Array.from({ length: cols }, () => false),
              );
            });
        },
        (error) => this.notificationService.error(error),
      );
  }

  getOrganizations() {
    const orgsLength = this.organizations.length;

    const page = orgsLength > 0 ? Math.floor(orgsLength / 20) : 0;
    const size = 20;

    this.addWorkoutService
      .getOrganizations(page, size, "", this.workoutId)
      .subscribe(
        (data) => {
          for (const org of data) {
            this.organizations.push(org);
          }

          const canLoadLength = data.length;
          this.canLoadOrganizations =
            canLoadLength % 20 === 0 && canLoadLength !== 0;
          this.showData = true;
          this.organizationsLoaded = true;

          if (this.addWorkoutArray != null) {
            for (let i = 0; i < this.addWorkoutArray.length; i++) {
              for (let j = 0; j < this.addWorkoutArray[i].length; j++) {
                this.addWorkoutArrayCopy[i][j] = this.addWorkoutArray[i][j];
              }
            }
          }

          if (this.syncExerciseArray != null) {
            for (let i = 0; i < this.syncExerciseArray.length; i++) {
              for (let j = 0; j < this.syncExerciseArray[i].length; j++)
                this.syncExerciseArrayCopy[i][j] = this.syncExerciseArray[i][j];
            }
          }

          if (this.syncFullArray != null) {
            for (let i = 0; i < this.syncFullArray.length; i++) {
              for (let j = 0; j < this.syncFullArray[i].length; j++) {
                this.syncFullArrayCopy[i][j] = this.syncFullArray[i][j];
              }
            }
          }

          let rows = this.getArraySize(this.organizations)[0];
          let cols = this.getArraySize(this.organizations)[1];

          this.addWorkoutArray = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );

          if (this.addWorkoutArrayCopy != null) {
            for (let i = 0; i < this.addWorkoutArrayCopy.length; i++) {
              for (let j = 0; j < this.addWorkoutArrayCopy[i].length; j++) {
                this.addWorkoutArray[i][j] = this.addWorkoutArrayCopy[i][j];
              }
            }
          }

          this.addWorkoutArrayCopy = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );

          this.syncExerciseArray = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );

          if (this.syncExerciseArrayCopy != null) {
            for (let i = 0; i < this.syncExerciseArrayCopy.length; i++) {
              for (let j = 0; j < this.syncExerciseArrayCopy[i].length; j++) {
                this.syncExerciseArray[i][j] = this.syncExerciseArrayCopy[i][j];
              }
            }
          }

          this.syncExerciseArrayCopy = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );

          this.syncFullArray = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );

          if (this.syncFullArrayCopy != null) {
            for (let i = 0; i < this.syncFullArrayCopy.length; i++) {
              for (let j = 0; j < this.syncFullArrayCopy[i].length; j++) {
                this.syncFullArray[i][j] = this.syncFullArrayCopy[i][j];
              }
            }
          }

          this.syncFullArrayCopy = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => false),
          );
        },
        (error) => this.notificationService.error(error),
      );
  }

  onSelectCollapse() {
    this.collapseFlag = !this.collapseFlag;
  }

  onSearch(search: string) {
    this.organizationName.next(search);
  }

  getArraySize(arr) {
    let rowCount = arr.length;
    let rowSizes = [];
    for (let i = 0; i < rowCount; i++) {
      rowSizes.push(arr[i].locationWorkoutList.length);
    }
    return [rowCount, rowSizes.reduce((a, b) => Math.max(a, b))];
  }

  onAddWorkoutIndividually(location, i, j) {
    let body = {
      workoutId: this.workoutId,
      locationId: location.id,
    };

    this.addWorkoutArray[i][j] = true;

    if (this.multipleWorkoutIds.length) {
      this.addWorkoutService.addMultipleWorkouts(
        location.id,
        this.multipleWorkoutIds,
      );
      return;
    }

    this.addWorkoutService.addWorkoutIndividually(body).subscribe(
      (data) => {},
      (error) => this.notificationService.error(error),
    );
  }

  syncFull(location, i, j) {
    this.addWorkoutService.syncForFull(this.workoutId, location.id).subscribe(
      (data) => {},
      (error) => this.notificationService.error(error),
    );
    this.syncFullArray[i][j] = true;
  }

  syncExercise(location, i, j) {
    this.addWorkoutService
      .syncForExercises(this.workoutId, location.id)
      .subscribe(
        (data) => {},
        (error) => this.notificationService.error(error),
      );
    this.syncExerciseArray[i][j] = true;
  }

  checkLocationsWorkout() {
    this.addWorkoutService.checkLocationsWorkout(this.workoutId).subscribe(
      (data) => {
        if (data) {
          this.isWorkoutOnAllLocations = true;
          this.checkFinished = true;
        } else {
          this.isWorkoutOnAllLocations = false;
          this.checkFinished = true;
        }
      },
      (error) => this.notificationService.error(error),
    );
  }

  addToAll() {
    this.addWorkoutService.addWorkoutToAll(this.workoutId).subscribe(
      (data) => {
        this.addAllWorkout = true;
        this.showData = false;
        this.notificationService.success(data);
      },
      (error) => {
        this.notificationService.error(error);
      },
    );
  }

  syncForPlaylists() {
    this.addWorkoutService.syncForPlaylists(this.workoutId).subscribe(
      (data) => {
        this.addAllWorkout = true;
        this.showData = false;

        this.notificationService.success(data);
      },
      (error) => {
        this.notificationService.error(error);
      },
    );
  }
}
