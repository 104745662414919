import {Component, OnInit} from "@angular/core";
import {NotificationService} from "../../../core/services/notification.service";
import {InvoicesControllerService} from "../../../core/api/controllers/invoices-controller.service";
import {IInvoice} from "../../../shared/interfaces/IInvoice";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
  invoices: IInvoice[] = [];
  isLoading = false;

  constructor(
    private invoicesController: InvoicesControllerService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.getInvoices();
  }

  getInvoices() {
    this.isLoading = true;
    this.invoicesController.getInvoices().subscribe(
      (response) => {
        this.invoices = response;
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  openInvoice(invoiceUrl: string) {
    window.open(invoiceUrl, "_blank");
  }
}
