import { Component } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "../../../../core/helpers/custom-validators";
import { AuthService } from "../../../../core/services/auth.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { MatDialogRef } from "@angular/material/dialog";
import { ValidationHelperService } from "../../../../core/helpers/validation-helper.service";
import { AccountControllerService } from "../../../../core/api/controllers/account-controller.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  changePasswordForm = this.formBuilder.group(
    {
      oldPassword: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          CustomValidators.passwordValidator(),
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          CustomValidators.passwordValidator(),
        ],
      ],
    },
    {
      validators: [CustomValidators.passwordMatchValidator],
    },
  );

  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private accountController: AccountControllerService,
    private notificationService: NotificationService,
    private router: Router,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
  ) {}

  updatePassword() {
    this.isLoading = true;
    this.accountController
      .updatePassword({
        oldPassword: this.oldPassword.value,
        newPassword: this.newPassword.value,
        confirmNewPassword: this.newPasswordConfirm.value,
      })
      .subscribe({
        next: () => {
          this.notificationService.success("Password successfully changed.");
          AuthService.clearStorageData();
          this.dialogRef.close(true);
          this.router.navigate(["/login"]);
          this.isLoading = false;
        },
        error: (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
        },
      });
  }

  get oldPassword(): FormControl {
    return this.changePasswordForm.get("oldPassword") as FormControl;
  }

  get newPassword(): FormControl {
    return this.changePasswordForm.get("password") as FormControl;
  }

  get newPasswordConfirm(): FormControl {
    return this.changePasswordForm.get("confirmPassword") as FormControl;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
