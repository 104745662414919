<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100">
    <div class="d-flex p-3 w-100">
      <div class="mr-3">
        <button
          type="button"
          class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4"
          [disabled]="playlistData?.length === 0"
          *ngIf="editing"
          (click)="createMode()"
        >
          <i class="material-icons button-icon"> add </i>
        </button>
        <button
          type="button"
          class="text-uppercase btn btn-primary"
          *ngIf="!editing"
          (click)="editMode()"
        >
          Back
        </button>
      </div>
    </div>
  </div>

  <div class="card w-100 mt-2 mt-sm-3 mt-md-4 mt-lg-5">
    <div
      class="d-flex justify-content-between align-items-center w-100 border-bottom"
    >
      <div class="ml-3">
        <ul id="links-wrapper" class="nav nav-tabs">
          <li class="nav-item">
            <a
              #athletesTab
              class="nav-link pointer p-3 text-uppercase font-weight-bold"
              (click)="toggle = true"
              [class]="{ inactive: !toggle, active: toggle }"
              >{{ global?.data?.uppercaseAthleteName }}s</a
            >
          </li>
          <li>
            <div
              class="d-flex align-items-center"
              style="height: 50px; font-size: 20px"
            >
              >
            </div>
          </li>
          <li class="nav-item">
            <a
              #exercisesTab
              class="nav-link pointer p-3 text-uppercase font-weight-bold"
              (click)="toggle = false"
              [class]="{ inactive: toggle, active: !toggle }"
              >Exercises</a
            >
          </li>
          <!-- <button (click)="initialClick()">InitialClick</button> -->
        </ul>
      </div>
    </div>

    <!-- normal view -->
    <ng-container *ngIf="editing && playlistData?.length != 0">
      <!-- athletes (normal view) -->
      <div [class]="{ toggled: !toggle }" class="display-block w-100 p-3">
        <table class="table table-bordered table-striped w-100">
          <tbody>
            <tr *ngFor="let athlete of playlistData; let i = index">
              <th colspan="5" *ngIf="playlistData?.length == 0">
                <div>
                  <p>No {{ global?.data?.athleteName }}s found.</p>
                </div>
              </th>

              <td class="radio">
                <mat-radio-button
                  [checked]="i == selectedIndex"
                  (click)="
                    checkModified(
                      athlete.playlist,
                      i,
                      $event,
                      athlete.firstName,
                      athlete.lastName
                    )
                  "
                  class="example-radio-button"
                ></mat-radio-button>
              </td>

              <td style="width: 100px">
                <div
                  class="d-flex align-items-center justify-content-center mt-2"
                >
                  <div
                    *ngIf="
                      athlete.profilePicturePath != null &&
                      athlete.profilePicturePath != ''
                    "
                    [style.background-image]="
                      'url(' + athlete?.profilePicturePath + ')'
                    "
                    class="profile-picture"
                  ></div>
                  <div
                    *ngIf="
                      athlete.profilePicturePath == null ||
                      athlete.profilePicturePath == ''
                    "
                    [style.background-image]="
                      'url(assets/images/no_avatar.png)'
                    "
                    class="profile-picture"
                  ></div>
                </div>
              </td>

              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <p>{{ athlete.firstName }} {{ athlete.lastName }}</p>
                  </div>
                </div>
              </td>

              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <p>
                      {{ athlete.sport || "No Sport" }},
                      {{ athlete.position || "No Position" }}
                    </p>
                  </div>
                </div>
              </td>

              <td class="radio">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="ui dropdown center-text button-dropdown">
                    <i class="default text ellipsis horizontal icon zoomed"></i>
                    <div class="menu pushed-menu">
                      <div
                        class="item"
                        routerLink="/save-{{ global?.data?.athleteName }}/{{
                          athlete.id
                        }}"
                      >
                        Edit
                      </div>
                      <div class="item" (click)="openDeleteModal(athlete)">
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr class="pointer" *ngIf="canLoadPlaylistData">
              <td colspan="5">
                <p
                  (click)="getAthleteData()"
                  class="text-center font-weight-bold p-3"
                >
                  Load more
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- exercises (normal view) -->
      <div [class]="{ toggled: toggle }" class="display-block w-100 p-3">
        <div class="mb-3 d-flex justify-content-between">
          <button
            type="button"
            class="text-uppercase btn btn-primary"
            (click)="toggleTab()"
          >
            Back
          </button>
          <p class="text-center font-weight-bold p-3">
            {{ playlistData[selectedIndex].firstName }}
            {{ playlistData[selectedIndex].lastName }}
          </p>
          <button
            type="button"
            [disabled]="exercisesSelected.length == 0"
            class="text-uppercase btn btn-secondary"
            (click)="removeSelectedExercises()"
          >
            Remove
          </button>

          <button
            type="button"
            class="text-uppercase btn btn-secondary"
            [disabled]="selectedPlaylist?.exerciseList.length == 0"
            (click)="removeSelectedPlaylist()"
          >
            Remove Playlist
          </button>
        </div>

        <div class="card shadow-none">
          <div
            class="p-3 w-100 text-center"
            *ngIf="selectedPlaylist?.exerciseList?.length == 0"
          >
            <i
              class="material-icons no-data-icon alt-color-text d-block mx-auto"
            >
              playlist_play
            </i>
            <span class="h4 alt-color-text">No Playlists</span>
            <p class="alt-color-text">
              There are no playlists to show. Press the button on the left to
              create a new one, or select another
              {{ global?.data?.athleteName }}.
            </p>
          </div>

          <div
            class="bg-white w-100 shadow-none"
            *ngIf="selectedPlaylist?.exerciseList?.length > 0"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-column flex-md-row"
            >
              <div class="d-flex m-3">
                <button
                  type="button"
                  class="btn btn-outline-primary mr-2"
                  (click)="openExerciseModal()"
                >
                  Exercises
                </button>
              </div>

              <div class="d-flex m-3">
                <div class="select-field parameter-select ml-1">
                  <div class="inline fields">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Automated</span
                    >
                  </div>
                  <div class="inline fields">
                    <mat-slide-toggle
                      (change)="onChangeAutomated()"
                      [(ngModel)]="automated"
                    >
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="select-field parameter-select ml-1">
                  <div class="inline fields">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Circuit</span
                    >
                  </div>
                  <div class="inline fields">
                    <mat-slide-toggle
                      (change)="onChangeCircuit()"
                      [disabled]="!automated"
                      [(ngModel)]="circuit"
                    ></mat-slide-toggle>
                  </div>
                </div>

                <div class="select-field parameter-select ml-1">
                  <div class="inline fields">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Circuit Sets</span
                    >
                  </div>
                  <div class="inline fields">
                    <select
                      class="selectpicker custom-select"
                      [disabled]="!circuit"
                      (change)="modified = true"
                      [(ngModel)]="playlistCircuitSets"
                    >
                      <option *ngFor="let set of possibleSets" [ngValue]="set">
                        {{ set }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="select-field parameter-select ml-1">
                  <div class="inline fields">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Rest Time</span
                    >
                  </div>
                  <div class="inline fields">
                    <select
                      class="selectpicker custom-select"
                      (change)="modified = true"
                      [disabled]="!automated"
                      [(ngModel)]="playlistRestTime"
                    >
                      <option
                        *ngFor="let time of possibleRestTime"
                        [ngValue]="time"
                      >
                        {{ time | secondsToTime }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="playlist-wrapper"
            *ngIf="selectedPlaylist?.exerciseList?.length > 0"
          >
            <table class="table table-bordered table-striped w-100 mb-0">
              <tr
                *ngFor="
                  let exercise of selectedPlaylist?.exerciseList;
                  let i = index
                "
              >
                <td class="radio">
                  <label class="check-container">
                    <input
                      type="checkbox"
                      (click)="updateSelected(exercise, exercisesSelected)"
                      [checked]="checkIfSelected(exercise?.id)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>

                <td *ngIf="automated && !circuit">
                  <div class="d-flex align-items-center justify-content-center">
                    <mat-form-field appearance="none">
                      <mat-select
                        *ngIf="automated && !circuit"
                        class="selectpicker"
                        (selectionChange)="checkModifiedSets($event)"
                        required
                        [(value)]="exercise.sets"
                      >
                        <mat-option
                          *ngFor="let set of possibleSets"
                          [value]="set"
                        >
                          {{ set }} sets
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-items-center justify-content-center">
                    <p>
                      {{ exercise.name }}
                      <span class="small-text" *ngIf="exercise.paired === 2">
                        (Composed of: {{ exercise.firstExerciseName }} and
                        {{ exercise.secondExerciseName }})
                      </span>
                    </p>
                  </div>
                </td>

                <!-- <td class="radio">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div class="ui dropdown center-text button-dropdown" suiDropdown>
                                            <i class="default text ellipsis horizontal icon zoomed"></i>
                                            <div class="menu pushed-menu" suiDropdownMenu>
                                                <div class="item">
                                                    (click)="$event.stopPropagation(); openEditModal(exercise.exerciseId)"  todo add when implemented on iPad
                                                    Edit
                                                </div>
                                                <div class="item"
                                                    (click)="$event.stopPropagation(); removeExercise(exercise)">
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td> -->
              </tr>
            </table>

            <div
              class="center-text"
              *ngIf="selectedPlaylist?.exerciseList?.length != 0"
            >
              <div class="w-100 p-3">
                <div>
                  <button
                    type="button"
                    class="btn btn-secondary d-block mx-auto text-uppercase"
                    [disabled]="!modified || loading"
                    (click)="savePlaylist()"
                  >
                    <ng-container *ngIf="loading">Saving...</ng-container>
                    <ng-container *ngIf="!loading">Save Playlist</ng-container>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- create view -->
    <ng-container *ngIf="!editing && playlistData?.length != 0">
      <!-- athletes (create view) -->
      <div
        [class]="{ toggled: !toggle }"
        class="w-100 p-3 display-block-create"
      >
        <table class="table table-bordered table-striped w-100">
          <tbody class="">
            <tr>
              <th colspan="5">
                <div class="">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-3"
                    (click)="selectAllAthletes()"
                  >
                    Select All
                  </button>

                  <button
                    type="button"
                    class="btn btn-outline-primary mr-3"
                    (click)="athletesSelected = []"
                  >
                    Deselect All
                  </button>
                </div>
              </th>
            </tr>

            <tr *ngFor="let athlete of playlistData">
              <td colspan="4" *ngIf="playlistData?.length == 0">
                <p>No {{ global?.data?.athleteName }}s found.</p>
              </td>

              <td class="radio">
                <label class="check-container">
                  <input
                    type="checkbox"
                    (click)="
                      updateAthletesSelected(athlete.id, athletesSelected)
                    "
                    [checked]="athletesSelected.indexOf(athlete.id) != -1"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>

              <td style="width: 100px">
                <div
                  class="d-flex align-items-center justify-content-center mt-2"
                >
                  <div
                    *ngIf="
                      athlete.profilePicturePath != null &&
                      athlete.profilePicturePath != ''
                    "
                    [style.background-image]="
                      'url(' + athlete?.profilePicturePath + ')'
                    "
                    class="profile-picture"
                  ></div>

                  <div
                    *ngIf="
                      athlete.profilePicturePath == null ||
                      athlete.profilePicturePath == ''
                    "
                    [style.background-image]="
                      'url(assets/images/no_avatar.png)'
                    "
                    class="profile-picture"
                  ></div>
                </div>
              </td>

              <td>
                <div class="d-flex">
                  <p>{{ athlete.firstName }} {{ athlete.lastName }}</p>
                </div>
              </td>

              <td>
                <div class="d-flex">
                  <p>
                    {{ athlete.sport || "No Sport" }},
                    {{ athlete.position || "No Position" }}
                  </p>
                </div>
              </td>

              <td class="radio">
                <div class="d-flex align-items-center justify-content-center">
                  <div
                    class="ui dropdown center-text button-dropdown"
                    suiDropdown
                  >
                    <i class="default text ellipsis horizontal icon zoomed"></i>
                    <div class="menu pushed-menu" suiDropdownMenu>
                      <div
                        class="item"
                        routerLink="/save-{{ global?.data?.athleteName }}/{{
                          athlete.id
                        }}"
                      >
                        Edit
                      </div>
                      <div class="item" (click)="deleteAthlete(athlete.id)">
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- exercises (create view) -->
      <div [class]="{ toggled: toggle }" class="w-100 p-3 display-block-create">
        <div class="mb-3">
          <button
            type="button"
            class="text-uppercase btn btn-secondary"
            (click)="removeSelectedExercises()"
          >
            Remove
          </button>
        </div>

        <div class="card shadow-none bg-white w-100">
          <div
            class="d-flex justify-content-between align-items-center flex-column flex-md-row"
          >
            <div class="d-flex m-3">
              <button
                type="button"
                class="btn btn-outline-primary align-items-center"
                (click)="openExerciseModal()"
              >
                Exercises
              </button>
            </div>

            <div class="d-flex m-3">
              <div class="select-field parameter-select ml-1">
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Automated</span
                  >
                </div>
                <div class="inline fields">
                  <mat-slide-toggle
                    (change)="onChangeAutomatedNew()"
                    [(ngModel)]="automated"
                  >
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="select-field parameter-select ml-1">
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3">Circuit</span>
                </div>
                <div class="inline fields">
                  <mat-slide-toggle
                    (change)="onChangeCircuitNew()"
                    [disabled]="!automated"
                    [(ngModel)]="circuit"
                  ></mat-slide-toggle>
                </div>
              </div>

              <div class="select-field parameter-select ml-1">
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Circuit Sets</span
                  >
                </div>
                <div class="inline fields">
                  <select
                    class="selectpicker custom-select"
                    [disabled]="!circuit"
                    (change)="updateModified()"
                    [(ngModel)]="playlistCircuitSets"
                  >
                    <option *ngFor="let set of possibleSets" [ngValue]="set">
                      {{ set }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="select-field parameter-select ml-1">
                <div class="infline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Rest Time</span
                  >
                </div>
                <div class="inline fields">
                  <select
                    class="selectpicker custom-select"
                    [disabled]="!automated"
                    (change)="updateModified()"
                    [(ngModel)]="newPlaylist.restTime"
                  >
                    <option
                      *ngFor="let time of possibleRestTime"
                      [ngValue]="time"
                    >
                      {{ time | secondsToTime }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="playlist-wrapper">
          <table class="table table-bordered table-striped w-100 mb-0">
            <tr
              class="text-center"
              *ngIf="newPlaylist?.exerciseList?.length == 0"
            >
              <th colspan="4">
                <p class="text-center p-3">No exercises added.</p>
              </th>
            </tr>

            <tr
              *ngFor="let exercise of newPlaylist.exerciseList; let i = index"
            >
              <td class="radio">
                <label class="check-container">
                  <input
                    type="checkbox"
                    (click)="updateSelected(exercise, exercisesSelected)"
                    [checked]="exercisesSelected.indexOf(exercise) !== -1"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>

              <td *ngIf="automated && !circuit">
                <select
                  *ngIf="newPlaylist.type != 1"
                  class="selectpicker custom-select"
                  [(ngModel)]="exercise.sets"
                >
                  <option *ngFor="let set of possibleSets" [ngValue]="set">
                    {{ set }} sets
                  </option>
                </select>
              </td>

              <td>
                <p class="grayish">
                  {{ exercise.name }}
                  <span class="small-text" *ngIf="exercise.paired === 2">
                    (Composed of: {{ exercise.firstExerciseName }} and
                    {{ exercise.secondExerciseName }})
                  </span>
                </p>
              </td>

              <td class="radio">
                <div class="d-flex align-items-center justify-content-center">
                  <div
                    class="ui dropdown center-text button-dropdown"
                    suiDropdown
                  >
                    <i class="default text ellipsis horizontal icon zoomed"></i>
                    <div class="menu pushed-menu" suiDropdownMenu>
                      <div
                        class="item"
                        (click)="
                          $event.stopPropagation(); openEditModal(exercise.id)
                        "
                      >
                        Edit
                      </div>
                      <div
                        class="item"
                        (click)="
                          $event.stopPropagation(); removeExercise(exercise)
                        "
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="center-text">
            <div class="card shadow-none w-100 p-3">
              <div
                matTooltip="Add at least one Athlete"
                [matTooltipDisabled]="hasAthletes()"
              >
                <button
                  type="button"
                  class="btn btn-secondary d-block mx-auto text-uppercase"
                  [disabled]="
                    newPlaylist.exerciseList.length === 0 ||
                    athletesSelected.length === 0 ||
                    loading
                  "
                  (click)="createNewPlaylist()"
                >
                  <ng-container *ngIf="!loading">Create Playlist</ng-container>
                  <ng-container *ngIf="loading">Creating...</ng-container>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="full-height" *ngIf="!playlistsLoaded">
        <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div
      class="text-center p-3"
      *ngIf="playlistData?.length == 0 && playlistsLoaded"
    >
      <i class="material-icons no-data-icon d-block mx-auto alt-color-text">
        group
      </i>
      <span class="h4 alt-color-text">No Athletes</span>
      <p class="alt-color-text">
        There are no {{ global?.data?.athleteName }}s to show. Go to the
        {{ global?.data?.athleteName }}s page to create a new one.
      </p>
    </div>
  </div>

  <!--

  <ng-template let-modal="modal" #exerciseModalTemplate>
    <div class="w-100 p-3 border-bottom">
      <span class="h6 font-weight-bold">Add exercises</span>
    </div>
    <div *ngFor="let category of exerciseCategoriesNew" class="w-100">
      <table class="table table-bordered table-striped">
        <thead class="p-3 w-100">
          <tr>
            <th colspan="4">
              <span class="h-6 ml-2 font-weight-bold">{{ category.name }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr class="w-100" *ngFor="let exercise of exercisesNew[category.id]; let i = index">
            <td class="radio" id="left-column">
              <label class="check-container">
                <input type="checkbox" (change)="updateSelected(exercise, addExercisesSelected)" [checked]="isAlreadyAdded(exercise)" />
                <span class="checkmark"></span>
              </label>
            </td>
            <td class="">
              <div class="d-flex align-items-center">
                <p>{{ exercise.name }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="actions no-border gray-background center-text">
      <button type="button" class="btn btn-primary text-uppercase mr-3" (click)="modal.deny()">Close</button>
      <button
        type="button"
        class="btn btn-secondary text-uppercase"
        [disabled]="addExercisesSelected.length === 0"
        (click)="addExercises(); modal.approve()">
        Add exercise
      </button>
    </div>
  </ng-template>
--></div>
