import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-is-enabled-indicator',
  templateUrl: './is-enabled-indicator.component.html',
  styleUrls: ['./is-enabled-indicator.component.scss']
})
export class IsEnabledIndicatorComponent {
  @Input() value: boolean;
}
