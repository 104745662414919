<div class="card mb-2 w-[20%] !min-w-[400px]">
  <div *ngIf="!isLoading">
    <div>
      <div class="p-3">
        <div class="text-center">
          <p (click)="fileInput.click()" class="btn bold-text">
            <i class="camera icon"></i>Upload Photo
          </p>
          <span class="d-none">
            <input
              type="file"
              #fileInput
              name="file"
              ng2FileSelect
              [uploader]="imageUploader"
              (click)="
                image = null; imagePreviewUrl = null; imageUploader.clearQueue()
              "
              accept="image/*"
            />
          </span>
        </div>

        <div class="d-flex align-items-center justify-content-around">
          <img
            class="ui small circular image"
            *ngIf="imagePreviewUrl == null"
            [ngSrc]="profilePicture || 'assets/images/no_avatar.png'"
            width="150"
            height="150"
          />
          <img
            class="ui small circular image athlete-image"
            *ngIf="imagePreviewUrl != null"
            [ngSrc]="imagePreviewUrl"
            width="150"
            height="150"
          />
        </div>

        <button
          class="ui primary button"
          *ngIf="imageNeedsUpdate"
          [disabled]="imagePreviewUrl == null"
          (click)="updateProfilePicture(); imageNeedsUpdate = false"
        >
          Save
        </button>

        <div
          class="d-flex flex-column align-items-center justify-content-around"
        >
          <div class="text-center">
            <p class="large-text qb-black">{{ settingsData?.name }}</p>
            <p class="medium-text qb-gray">
              {{ settingsData?.organization.name }}
            </p>
          </div>

          <div class="d-flex flex-column">
            <button
              mat-flat-button
              color="primary"
              class="btn btn-secondary m-3"
              (click)="openAccountInformationModal()"
            >
              Change Account Information
            </button>
            <button
              mat-flat-button
              color="primary"
              class="btn btn-secondary ml-3 mr-3 mb-3"
              (click)="openPasswordModal()"
            >
              Change Password
            </button>
            <button
              mat-flat-button
              color="primary"
              class="btn btn-secondary ml-3 mr-3 mb-3"
              (click)="openLogsModal()"
            >
              Generate logs report
            </button>
            <button
              mat-flat-button
              color="primary"
              class="btn btn-secondary ml-3 mr-3 mb-3"
              (click)="openUsageModal()"
            >
              Generate usage reports
            </button>
            <div class="flex w-full">
              <button
                mat-flat-button
                [color]="this.isSyncDisabled ? 'accent' : 'warn'"
                class="w-full ml-3 mr-3 mb-3"
                (click)="openToggleModal()"
              >
                <span *ngIf="isLoadingSync">Loading...</span>
                <span *ngIf="!isLoadingSync">{{
                  isSyncDisabled ? "Enable Data sync" : "Disable Data sync"
                }}</span>
              </button>
            </div>
            <button
              mat-flat-button
              color="primary"
              class="btn btn-secondary ml-3 mr-3 mb-3"
              *ngIf="showQuickbooks"
              (click)="connectQuickbooks()"
            >
              Connect Quickbooks
            </button>
          </div>

          <div class="d-flex">
            <a class="d-inline" routerLink="/terms-and-conditions"
              >Terms and Conditions</a
            >
            <a href="/assets/pdf/Quick%20Board%20Terms.pdf" class="ml-1 mt-1"
              ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
              ></a
            >
          </div>
          <div class="d-flex">
            <a class="d-inline" routerLink="/privacy-policy">Privacy Policy</a>
            <a
              href="/assets/pdf/Quick%20Board%20Privacy%20Policy.pdf"
              class="ml-1 mt-1"
              ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
              ></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 border-top border-bottom">
      <div
        class="d-flex align-items-center justify-content-around flex-column p-3 mt-2 mb-2"
      >
        <p class="m-2">Subscription type</p>
        <p class="text-primary h1 font-weight-bold m-2">
          {{ settingsData?.subscription.name }}
        </p>
        <!-- <p class="m-2">Updates on {{ settingsData?.subscriptionEndDate | date }}</p> -->
        <!--<a class="change-link">Change or purchase more</a>-->
        <button
          class="btn btn-secondary m-3 disabled-custom"
          (click)="openSubscriptionPaymentModal()"
        >
          Update Payment Info
        </button>
        <mat-slide-toggle
          (change)="updateMandatoryEmail()"
          [(ngModel)]="mandatoryEmail"
        >
          Enforce Mandatory Email
        </mat-slide-toggle>
      </div>
    </div>

    <div class="w-100 d-flex">
      <div class="p-3 w-50 border-right text-center">
        <p class="h4">{{ settingsData?.athletes }}</p>
        <p class="h4">{{ global?.data?.athleteName }}s</p>
      </div>

      <div class="p-3 w-50 text-center">
        <p class="h4">{{ settingsData?.exercises }}</p>
        <p class="h4">exercises</p>
      </div>
    </div>
  </div>
  <div
    class="flex justify-center items-center w-full h-[400px]"
    *ngIf="isLoading"
  >
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
