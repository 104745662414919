import { Component, OnDestroy, OnInit } from "@angular/core";
import { WorkoutsControllerService } from "../../core/api/controllers/workouts-controller.service";
import {
  possibleRestTime,
  possibleSets,
} from "../../core/helpers/exercise-data";
import { NotificationService } from "../../core/services/notification.service";
import { GlobalPreferences } from "../../core/helpers/global-data";
import { Router } from "@angular/router";
import { WorkoutControllerService } from "../../core/api/controllers/workout-controller.service";
import { AuthService } from "../../core/services/auth.service";

@Component({
  selector: "app-workouts",
  templateUrl: "./workouts.component.html",
  styleUrls: ["./workouts.component.scss"],
  providers: [WorkoutsControllerService, WorkoutControllerService],
})
export class WorkoutsComponent implements OnInit, OnDestroy {
  workoutData = [];
  workoutList = [];
  selectedIndex = null;
  possibleRestTime = possibleRestTime;
  possibleSets = possibleSets;
  toggle = true;
  canLoadPagedWorkouts = false;
  workoutsLoaded;
  globalSub;
  locations: Array<string> = [];
  selectedWorkouts = [];

  constructor(
    private workoutsService: WorkoutsControllerService,
    private workoutController: WorkoutControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf(data.workoutName + "s") == -1) {
          this.router.navigate(["/" + data.workoutName + "s"]);
        }
      },
      (error) => this.notificationService.error(error),
    );
    this.getWorkouts();
  }

  getWorkouts() {
    this.workoutsLoaded = false;
    const noOfWorkouts = this.workoutList.length;
    const page = noOfWorkouts > 0 ? Math.floor(noOfWorkouts / 20) : 0;
    const size = 20;
    this.workoutsService.getWorkouts(page, size).subscribe(
      (response) => {
        for (const workout of response) {
          this.workoutList.push(workout);
        }
        const canLoadLength = response.length;
        this.canLoadPagedWorkouts =
          canLoadLength % 20 === 0 && canLoadLength !== 0;
        this.workoutsLoaded = true;
      },
      (error) => this.notificationService.error(error),
    );
  }

  deleteWorkout(workoutId: string) {
    this.workoutController.deleteWorkout(workoutId).subscribe(
      () => {
        for (let i = 0; i < this.workoutList.length; i++) {
          if (this.workoutList[i].id === workoutId) {
            this.workoutList.splice(i, 1);
            break;
          }
        }
        for (const data of this.workoutData) {
          for (let i = 0; i < data.workouts.length; i++) {
            if (data.workouts[i].id === workoutId) {
              data.workouts.splice(i, 1);
              break;
            }
          }
        }
        this.notificationService.success("Workout deleted successfully.");
      },
      (error) => this.notificationService.error(error),
    );
  }

  ngOnDestroy() {
    this.globalSub.unsubscribe();
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  syncWorkout(workoutId) {
    this.router.navigate([`workouts/sync-workout`], {
      queryParams: {
        id: workoutId ? workoutId : this.selectedWorkouts.map((id) => id),
      },
    });
  }

  handleWorkoutSelection(id) {
    if (this.selectedWorkouts.includes(id)) {
      this.selectedWorkouts.splice(this.selectedWorkouts.indexOf(id), 1);
    } else {
      this.selectedWorkouts.push(id);
    }
  }
}
