import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {
  AppExercisesCategorySelectDialogComponent
} from "./app-exercises-category-select-dialog/app-exercises-category-select-dialog.component";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {IGetExercisesLeaderboardReq} from "../../../shared/interfaces/DTOs/Request/IGetExercisesLeaderboardReq";
import {ILeaderboardRow} from "../../../shared/interfaces/DTOs/Response/IGetExercisesLeaderboardRes";
import {ETimeFrame} from "../../../shared/enums/ETimeFrame";
import {FormControl} from "@angular/forms";


@Component({
  selector: "app-most-active-exercise-categories",
  templateUrl: "./most-active-exercise-categories.component.html",
  host: {
    class: "w-full",
  },
  styleUrls: ["./most-active-exercise-categories.component.scss"],
})
export class MostActiveExerciseCategoriesComponent {
  isLoading = false;
  leaderboardRows: ILeaderboardRow[] = [];
  timeFrame = new FormControl<ETimeFrame>(ETimeFrame.AllTime);
  payload: IGetExercisesLeaderboardReq;


  constructor(
      private dialog: MatDialog,
      private exercisesController: ExercisesControllerService,
  ) {}

  getLeaderboard(payload: IGetExercisesLeaderboardReq): void {
    this.isLoading = true;
    this.exercisesController.getLeaderboard(payload).subscribe({
      next: (res) => {
        this.leaderboardRows = res;
        this.isLoading = false;
      }
    })
  }

  openSelectExerciseConfigModal(): void {
    this.dialog.open(AppExercisesCategorySelectDialogComponent, {
      maxHeight: '90vh',
      height: '90vh',
    }).afterClosed().subscribe({
      next:(res: {payload: IGetExercisesLeaderboardReq}) => {
        this.payload = {
          ...res.payload,
          ...this.getTimeRange()
        }
        this.getLeaderboard(this.payload);
      }
    });
  }

  setTimeRange(): void {
    this.payload = {
      ...this.payload,
      ...this.getTimeRange()
    }
    this.getLeaderboard(this.payload);
  }

  getTimeRange(): Partial<IGetExercisesLeaderboardReq> {
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    let start = new Date();
    switch (this.timeFrame.value) {
      case ETimeFrame.Today: {
        start = new Date();
        start.setHours(0, 0 , 0 , 0);
        break;
      }
      case ETimeFrame.AllTime: {
        start = new Date(end.getFullYear() - 15);
        break;
      }
      case ETimeFrame.LastMonth: {
        start = new Date(end.getFullYear(), end.getMonth(), 0);
        break;
      }
      case ETimeFrame.LastWeek: {
        start = new Date(end.getFullYear(), end.getMonth(), end.getDay() - 7);
        break;
      }
    }

    return {
      start: start.toISOString(),
      end: end.toISOString()
    }
  }

  protected readonly ETimeFilter = ETimeFrame;
}
