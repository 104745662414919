import { animate, style, transition, trigger } from "@angular/animations";

const inOutAnimation = trigger("inOutAnimation", [
  transition("void => active", [
    // using status here for transition
    style({ opacity: 0 }),
    animate(1000, style({ opacity: 1 })),
  ]),
  transition("* => void", [animate(1000, style({ opacity: 0 }))]),
]);

export default inOutAnimation;
