<div class="w-full h-screen bg-white">
  <div
    class="mx-auto max-w-[1000px] p-5 flex flex-col gap-5 [&>h2]:pt-5 [&>h2]:text-5xl [&>h2]:text-center [&>h3]:text-3xl [&>*>h3]:text-3xl"
  >
    <h2>Terms of Sale</h2>
    <div>
      <p>
        These terms of sale (“Terms”) apply to all orders accepted by The Quick
        Board, LLC. (“Quick Board”) for the sale of its and sensor board
        products (“Products”), except in the case that you and Quick Board have
        executed a written agreement that supersedes these Terms. To the extent
        the Products contain or consist of software in any form (“Software”),
        such Software is licensed to you, not sold, and only in accordance with
        the section entitled “Software License”, below. Terms such as “sell” and
        “purchase”, as used in these Terms, apply only to the extent the
        Products consist of items other than Software.
      </p>
    </div>
    <h3>ORDERING FROM THE QUICK BOARD WEBSITE</h3>

    <div>
      <h4>ORDERS</h4>
      <p>
        You can browse and place orders for our Products through,
        www.thequickboard.com/pricing (the “Quick Board Website”). We will ask
        you for your name, phone number, e-mail address, shipping and billing
        address, and other information so we can fulfill your order. When you
        place an order, Quick Board’s third-party payment service provider will
        collect your credit card details and charge your credit card account in
        connection with the order, and you agree to make all applicable payments
        in connection with any order placed by you. Quick Board does not view or
        store your credit card information. We store your name, phone number,
        email address and shipping address so we can process your order.
      </p>
      <p>
        By placing an order through the Quick Board Website, you agree that: (i)
        any credit card information supplied by you is true and complete; (ii)
        you will pay the applicable price listed, as well as any shipping and
        handling charges and applicable taxes; and (iii) Software License usage
        fees.
      </p>
    </div>
    <div>
      <h4>PRICING</h4>
      <p>
        Quick Board can withdraw Products from the Quick Board Website at any
        time and for any reason. Prices listed through the Quick Board Website
        are stated in U.S. dollars, and do not include any shipping and handling
        charges or applicable taxes, which charges and taxes will be
        communicated after the order is processed. You agree to indemnify and
        hold Quick Board harmless from and against any liabilities, interest,
        penalties or fees assessed against Quick Board arising from your failure
        to pay any such taxes. All Product prices are subject to change at any
        time.
      </p>
    </div>
    <div>
      <h4>ACCEPTANCE AND FULFILLMENT</h4>
      <p>
        All orders are subject to acceptance by Quick Board. After you place an
        order, you will receive an email from Quick Board confirming that Quick
        Board has received it. If an order is on back order, we’ll send you an
        email indicating that this is the case, followed by another email when
        the items in question are in stock containing a shipping confirmation,
        tracking number and carrier information. Quick Board reserves the right
        not to accept your order for any reason or no reason
      </p>
    </div>
    <div>
      <h4>RESALE</h4>
      <p>
        Purchases made through the Quick Board Website are intended for end
        users only and are not authorized for resale.
      </p>
    </div>
    <div>
      <h4>SHIPPING AND DELIVERY</h4>
      <p>
        Quick Board will pack the Products in accordance with its standard
        practices. All orders will ship ground unless you specify otherwise.
        Title to the Products (except to the extent that the Products consist of
        Software) and risk of loss will pass to you upon Quick Board’s delivery
        of the Products to the carrier. You acknowledge that all scheduled
        shipment dates are estimates only. Quick Board will make reasonable
        efforts to meet the scheduled shipment dates, but in no event will Quick
        Board be liable for any loss, damage, or penalty resulting from any
        delay in shipment or delivery.
      </p>
    </div>
    <div>
      <h3>RETURN POLICY</h3>
      <p>
        If within thirty (30) days from the date of sale Purchaser wishes to
        return non-defective Products, Quick Board, in its sole and absolute
        discretion with respect to the determination of whether such returned
        Products are non-defective, shall refund the full purchase price of
        Products to Purchaser within sixty (60) days. Purchaser must provide to
        Quick Board the original invoice or sales receipt (indicating the date
        of purchase) upon return of such Products.
      </p>
    </div>
    <div>
      <h3>LIMITED PRODUCT WARRANTY</h3>
      <p>
        Subject to the terms and conditions set forth herein, Quick Board
        expressly and exclusively warrants solely to original, end-user
        purchasers (each a “Purchaser”) that sensor boards and any other
        products manufactured by the Quick Board (collectively, “Products”)
        shall be free from defects in materials and workmanship when properly
        used and maintained for a period of one (1) year from the date of sale
        (the “Warranty Period”). NO WARRANTY, INCLUDING THIS LIMITED STATEMENT
        OF WARRANTY, SHALL BE IN EFFECT UNTIL PURCHASER HAS TENDERED AN INITIAL
        PAYMENT FOR PRODUCTS PURCHASED. IF PURCHASER PURCHASES PRODUCTS ON AN
        INSTALLMENT BASIS PAYMENT PLAN AND DEFAULTS THEREUNDER, THIS LIMITED
        STATEMENT OF WARRANTY SHALL TERMINATE WITH RESPECT TO PURCHASER AND
        SHALL RELIEVE THE QUICK BOARD OF FURTHER OBLIGATION TO PURCHASER
        HEREUNDER.
      </p>
      <p>
        The exclusive warranty for any Products that Quick Board, in its sole
        and absolute discretion, determines to be defective within the Warranty
        Period is repair or replacement of such defective Products. The
        existence of a defect shall be determined by Quick Board in accordance
        with procedures established by Quick Board. Repaired parts or
        replacement Products shall be provided by Quick Board on an exchange
        basis and shall either be new or refurbished to be functionally
        equivalent to new. Repaired parts or replacement Products shall be Board
        of defective Products within the Warranty Period shall terminate this
        Limited Statement of Warranty with respect to Purchaser and shall
        relieve Quick Board of any further obligation to such Purchaser
        hereunder. Quick Board shall not be responsible for any transportation
        costs and/or any risks associated with transportation of Products.
        provided to Purchaser only upon submission to Quick Board of the
        original invoice or sales receipt (indicating the date of purchase)
        within the Warranty Period. Purchaser’s failure to notify Quick
      </p>
      <p>
        THIS LIMITED STATEMENT OF WARRANTY IS IN LIEU OF ALL OTHER WARRANTIES
        EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF THE CONDITION OF THE
        EQUIPMENT, OR ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE. QUICK BOARD DOES NOT REPRESENT OR WARRANT THAT
        PRODUCTS WILL OPERATE ERROR FREE, UNINTERRUPTEDLY OR IN COMBINATION WITH
        ANY OTHER HARDWARE, INFRASTRUCTURE OR SYSTEMS NOT SUPPLIED BY QUICK
        BOARD OR THAT PRODUCTS WILL, IN ALL CASES, SERVE THE PURPOSE FOR WHICH
        SUCH PRODUCTS ARE PURCHASED.
      </p>
      <p>
        No representative of Quick Board is authorized to extend any additional
        warranty unless approved in writing by an authorized representative of
        Quick Board. This Limited Statement of Warranty is provided solely to
        Purchaser and is not transferable. Purchaser is responsible for
        determining whether Products are suitable for Purchaser’s intended use.
      </p>
      <p>
        This Limited Statement of Warranty given by Quick Board does not cover,
        and Quick Board shall not be liable for, any conditions attributable to
        (1) acts of God, misuse, physical damage, vandalism, misapplication,
        normal wear and tear, extreme environmental conditions, lack of
        compliance with applicable instructions, inadequate or improper
        maintenance, negligence, accident (including, but not limited to, water
        damage and/or dropping Products), tampering, alteration or substitution
        of inferior quality component parts, (2) the incompatibility, improper
        design, malfunction, installation, operation or maintenance of
        equipment, accessories, and/or materials used in connection with
        Products which are not manufactured by Quick Board or (3) attempts to
        operate Products outside of the range or parameters of their intended
        capacity, whether intentional, accidental or otherwise.
      </p>
      <p>
        PURCHASER’S SOLE AND EXCLUSIVE REMEDY FOR LIABILITY OF ANY KIND WITH
        RESPECT TO PRODUCTS SHALL BE LIMITED TO THE REMEDY OF REPAIR OR
        REPLACEMENT OF PRODUCTS IN ACCORDANCE WITH THIS WARRANTY. QUICK BOARD
        SHALL, UNDER NO CIRCUMSTANCES, BE LIABLE TO PURCHASER OR ANY OTHER
        PERSON FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
        DAMAGES OR LOSS OF USE, REVENUE OR PROFIT, WHETHER ARISING OUT OF BREACH
        OF WARRANTY, BREACH OF CONTRACT, STRICT LIABILITY, NEGLIGENCE OR
        OTHERWISE, EVEN IF QUICK BOARD HAS BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES OR LOSSES. IN THE EVENT THIS LIMITED STATEMENT OF WARRANTY
        IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE, QUICK BOARD’S
        LIABILITY TO PURCHASER SHALL NOT EXCEED THE PRICE OF PRODUCTS PURCHASED.
      </p>
      <p>
        Purchaser agrees that Quick Board is not an insurer and no insurance
        coverage is offered herein. Quick Board is not assuming liability, and,
        therefore shall not be liable to Purchaser for any loss, personal injury
        or property damage sustained by Purchaser or any other party as a result
        of any failure of Products, or any other cause whatsoever, unless such
        loss or damage was proximately caused by the Quick Board’s gross
        negligence or caused by Quick Board’s intentional conduct.
      </p>
      <p>
        This Limited Statement of Warranty may be modified at any time by Quick
        Board without notice to Purchaser and the modified version shall be in
        effect for all sales of Products made after the modification
        irrespective of any warranty published or posted in any catalog, sales
        literature or online location. The most current version of this Limited
        Statement of Warranty may be found online at www.thequickboard.com.
      </p>
    </div>
    <div>
      <h3>COMPATIBILITY</h3>
      <p>
        You acknowledge that you have verified the compatibility of the Products
        you are purchasing with other required equipment (e.g., ensuring that
        your mobile device and/or operating system is compatible with the
        Product). You are solely responsible for determining the compatibility
        of the Products with other equipment, and you accept that lack of
        compatibility is not a valid claim under the warranty provided with your
        Products and does not otherwise constitute a basis for receiving a
        refund after the 30 day refund policy identified above.
      </p>
    </div>
    <div>
      <h3>LIMITATIONS ON LIABILITY</h3>
      <p>
        IN NO EVENT WILL QUICK BOARD BE LIABLE FOR ANY PUNITIVE, EXEMPLARY,
        SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES (INCLUDING, WITHOUT
        LIMITATION, LOSS OF REVENUE, USE, PROFITS, DATA, OR GOODWILL) OR COSTS
        OF PROCURING SUBSTITUTE PRODUCTS, ARISING OUT OF, RELATING TO, OR IN
        CONNECTION WITH THESE TERMS OR THE PURCHASE, SALE, USE, OPERATION OR
        PERFORMANCE OF THE PRODUCTS, WHETHER SUCH LIABILITY ARISES FROM ANY
        CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE),
        PRODUCT LIABILITY OR OTHERWISE, WHETHER OR NOT QUICK BOARD HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. QUICK BOARD AND YOU
        HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY
        LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS
        ESSENTIAL PURPOSE. IN NO EVENT WILL QUICK BOARD’S LIABILITY TO YOU
        ARISING OUT OF, RELATING TO, OR IN CONNECTION WITH THESE TERMS, FROM ALL
        CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY, EXCEED THE ACTUAL
        AMOUNT PAID TO QUICK BOARD BY YOU FOR THE PRODUCT THAT GIVES RISE TO THE
        CLAIM. Some jurisdictions do not allow the limitation or exclusion of
        liability for incidental or consequential damages, so the above
        limitation or exclusion may not apply to you.
      </p>
    </div>
    <div>
      <h3>SOFTWARE LICENSE</h3>
      <p>
        Quick Board grants to you a nonexclusive, nontransferable license to use
        the Software, in executable form, solely as embedded in the Products.
        You may not copy or modify the Software. You acknowledge that the
        Software contains trade secrets of Quick Board, and, in order to protect
        such trade secrets, you agree not to disassemble, decompile or reverse
        engineer the Software nor permit any third party to do so, except to the
        extent such restrictions are prohibited by law. Quick Board reserves all
        rights and licenses in and to the Software not expressly granted to you
        under this Agreement.
      </p>
    </div>
    <div>
      <h3>DISPUTE RESOLUTION</h3>
      <p>
        You agree that any dispute between you and Quick Board arising out of or
        relating to these Terms, or any other Quick Board products or services
        (collectively, “Disputes”) will be governed by the arbitration procedure
        outlined below.
      </p>
    </div>
    <div>
      <h4>GOVERNING LAW</h4>
      <p>
        The Terms and the resolution of any Disputes shall be governed by and
        construed in accordance with the laws of the State of Tennessee without
        regard to its conflict of laws principles.
      </p>
    </div>
    <div>
      <h4>INFORMAL DISPUTE RESOLUTION</h4>
      <p>
        We want to address your concerns without needing a formal legal case.
        Before filing a claim against Quick Board, you agree to try to resolve
        the Dispute informally by contacting Quick Board. We'll try to resolve
        the Dispute informally by contacting you through email. If a dispute is
        not resolved within 90 days after submission, you or Quick Board may
        bring a formal proceeding.
      </p>
    </div>
    <div>
      <h4>LIMITATION ON CLAIMS</h4>
      <p>
        Regardless of any statute or law to the contrary, any claim or cause of
        action arising out of or related to your use of the Quick Board Products
        or services must be filed within one (1) year after such claim or cause
        of action arose, or else that claim or cause of action will be barred
        forever.
      </p>
    </div>
    <div>
      <h4>TERM</h4>
      <p>
        Upon payment for the Quick Board Software license, you accept the
        one-year license contract required to use Quick Board Products
      </p>
    </div>
    <div>
      <h4>AUTOMATIC RENEWAL AND CANCELLATION.</h4>
      <p>
        Software License fees will be charged to your credit card and will
        automatically renew at the applied tier pricing then in effect for the
        duration of your subscription until you cancel. The software license
        fees will be charged based on the selected monthly or annual plan and
        payment cannot be suspended or postponed for any reason. The one-year
        license contract will automatically renew unless notice of cancellation
        is received in writing 30 days prior to the renewal date. If your credit
        card is invalid for any reason, charged back, or if Quick Board does not
        receive payment, we will cancel your subscription immediately.
      </p>
    </div>
    <div>
      <h4>REPRESENTATIONS, WARRANTIES AND COVENANTS.</h4>
      <p>
        You represent, warrant and covenant that (a) the subscription and credit
        card information that you supply is true, correct and complete, (b) you
        will pay any charges that you incur in connection with the Premium
        Service, including any applicable taxes, (c) you will update your
        subscription and credit card information as required, (d) you will not
        allow anyone else to use your subscription, (e) you will not transfer
        your subscription or password to anyone else and (f) you will report to
        us any unauthorized or prohibited use of your subscription.
      </p>
    </div>
    <div>
      <h3>GENERAL PROVISIONS</h3>
      <p>
        The parties disclaim application of the United Nations Convention on
        Contracts for the International Sale of Goods.
      </p>
      <p>
        You may not assign or transfer these Terms, or any order accepted by
        Quick Board hereunder, in whole or in part, by operation of law or
        otherwise, without Quick Board’s express prior written consent. Any
        attempt to do so, without Quick Board’s consent, will be null and of no
        effect. Quick Board may freely assign these Terms.
      </p>
      <p>
        Quick Board will not be responsible for any failure or delay in its
        performance under these Terms due to causes beyond its reasonable
        control, including, but not limited to, labor disputes, strikes,
        lockouts, shortages of or inability to obtain energy, raw materials or
        supplies, war, terrorism, riot, or acts of God.
      </p>
      <p>
        The failure by Quick Board to enforce any provision of these Terms will
        not constitute a waiver of future enforcement of that or any other
        provision. If for any reason a court of competent jurisdiction finds any
        provision of these Terms invalid or unenforceable, that provision will
        be enforced to the maximum extent permissible and the other provisions
        of these Terms will remain in full force and effect
      </p>
      <p>
        These Terms constitute the complete and exclusive agreement between
        Quick Board and you regarding its subject matter and supersedes all
        prior or contemporaneous quotations, agreements, communications or
        understandings, whether written or oral, relating to its subject matter.
        Any waiver, modification or amendment of any provision of these Terms
        will be effective only if in writing and signed by duly authorized
        representatives of each party.
      </p>
      <p>
        You will not export or re-export, directly or indirectly, the Products,
        or any technical information related thereto, or any direct products
        thereof, to any destination or person prohibited or restricted by the
        export control laws and regulations of the United States, without the
        prior authorization from the appropriate governmental authorities.
      </p>
      <p>
        All notices required or permitted to be given under these Terms will be
        in writing and will be deemed given: (i) upon actual delivery, if made
        by personal service; (ii) three (3) days after mailing, if made by U.S.
        certified or registered mail; and (iii) one (1) business day after
        delivery to the courier or overnight delivery service, if made by
        courier or overnight delivery service. All notices will be addressed to
        such address as the party who is to receive the notice so designates by
        written notice to the other.
      </p>
    </div>
    <h2>Terms of Service</h2>
    <div>
      <p>
        Quick Board’s technology evaluates and enhances agility performance.
        These Terms of Service (“Terms”) govern your use of Quick Board
        products, our website, www.thequickboard.com, the software embedded in
        Quick Board sensor boards, the Quick Board software, the Quick Board
        mobile application, and Quick Board subscriptions.
      </p>
      <p>
        You must accept these Terms to create a Quick Board account and to use
        the Quick Board Service.
      </p>
    </div>
    <div>
      <h3>These Terms May Change</h3>
      <p>
        These Terms will change over time. If we make minor changes to the Terms
        without materially changing your rights, we will post the modified Terms
        on www.thequickboard.com. We will notify you by email, through the Quick
        Board Service, or by presenting you with a new Terms of Service to
        accept if we make a modification that materially changes your rights.
        When you use the Quick Board Service after a modification is posted, you
        are telling us that you accept the modified terms.
      </p>
    </div>
    <div>
      <h3>Read Our Privacy Policy</h3>
      <p>
        Any information that Quick Board collects through your use of the Quick
        Board Service is subject to the Quick Board Privacy Policy, which is
        part of these Terms.
      </p>
    </div>
    <div>
      <h3>Who Can Use Quick Board?</h3>
      <p>
        You may use the Quick Board Service if you are over 13 years of age and
        are not barred from receiving services under applicable law. You may
        connect to the Quick Board Service using a sensor board that is
        manufactured, distributed, or sold by or on behalf of Quick Board; the
        Quick Board mobile application; the Quick Board software; or
        www.thequickboard.com. You may not connect to the Quick Board Service
        with any device that is not manufactured, distributed, or sold by or on
        behalf of Quick Board (such as a knock off or counterfeit version of a
        Quick Board Product); otherwise intends to resemble or purports to be a
        Quick Board Product; or any unauthorized application or-third party
        connection. Any violation or attempted violation of this provision may
        result in the immediate termination of your ability to access the Quick
        Board Service.
      </p>
    </div>
    <div>
      <h3>Creating an Account</h3>
      <p>
        Full use of the Quick Board Service requires that you purchase a sensor
        board and create an account by providing us with a username, password,
        email address, physical address, and valid credit card. You are
        responsible for all activity that occurs in association with your
        account. Quick Board is not liable for any loss or damages caused by
        your failure to maintain the confidentiality of your account
        credentials.
      </p>
      <p>
        We may need to contact you about your use of the Quick Board Service.
        These communications are part of the Quick Board Service and you may not
        opt-out from receiving them. You can manage and opt-out from receiving
        other communications and keep your email address up-to-date from your
        account settings.
      </p>
    </div>
    <div>
      <h3>Necessary Equipment</h3>
      <p>
        Full use of the Quick Board Service is dependent upon your use of a
        computer with adequate software or a supported mobile device and
        Internet access. The maintenance and security of this equipment may
        influence the performance of the Quick Board Service and it is your
        responsibility to ensure the equipment’s functionality.
      </p>
    </div>
    <div>
      <h3>What You Can Do On The Quick Board Service</h3>
      <p>
        Quick Board grants you a limited, non-exclusive, non-transferable,
        non-sublicensable license to (1) access and view the Quick Board
        Content, (2) access and use the software and mobile applications
        provided by the Quick Board Service, and (3) use the software that is
        embedded into Quick Board products as authorized in these Terms.
      </p>
      <p>
        You will not use, copy, adapt, modify, prepare derivative works based
        upon, distribute, license, sell, transfer, publicly display, publicly
        perform, transmit, broadcast or otherwise exploit the Quick Board
        Content, Quick Board Service or any portion thereof, except as expressly
        permitted in these Terms. No licenses or rights are granted to you by
        implication or otherwise under any intellectual property rights owned or
        controlled by Quick Board or its licensors, except for the licenses and
        rights expressly granted in these Terms.
      </p>
    </div>
    <div>
      <h3>Things You Cannot Do On The Quick Board Service</h3>
      <p>
        Except to the extent permitted by law, you may not perform, attempt to
        perform, or encourage or assist others in performing any of the
        following while accessing or using the Quick Board Service: (1) use,
        display, mirror or frame the Quick Board Service or any individual
        element within the Quick Board Service, Quick Board’s name, any Quick
        Board trademark, logo or other proprietary information, or the layout
        and design of any page or form contained on a page, without Quick
        Board’s express written consent; (2) access or tamper with non-public
        areas of the Quick Board Service, Quick Board’s computer systems, or the
        technical delivery systems of Quick Board’s providers; (3) test the
        vulnerability of any Quick Board system or breach any security or
        authentication measures; (4) circumvent any technological measure
        implemented by Quick Board or any of Quick Board’s providers or any
        other third party (including another user) to protect the Quick Board
        Service or Quick Board Content; (5) access the Quick Board Service or
        Quick Board Content through the use of any mechanism other than through
        the use of an Authorized Connection, Quick Board Service; or (6) modify,
        decompile, disassemble, reverse engineer, tamper with or otherwise
        attempt to derive the source code of any software that Quick Board
        provides to you or any other part of the Quick Board Service.
      </p>
    </div>
    <div>
      <h3>Our Enforcement Rights</h3>
      <p>
        We are not obligated to monitor access or use of the Quick Board
        Service, Quick Board Content, or to review or edit any Quick Board
        Content, but we have the right to do so for the purpose of operating the
        Quick Board Service, to ensure compliance with these Terms, and to
        comply with applicable law or other legal requirements. We may consult
        with and disclose unlawful conduct to law enforcement authorities; and
        pursuant to valid legal process, we may cooperate with law enforcement
        authorities to prosecute users who violate the law. We reserve the right
        (but are not required) to remove or disable access to the Quick Board
        Service and any Quick Board Content at any time and without notice, and
        at our sole discretion, if we determine that the Quick Board Content or
        your use of the Quick Board Service is objectionable or in violation of
        these Terms. We have the right to investigate violations of these Terms
        and any conduct that affects the Quick Board Service.
      </p>
    </div>
    <div>
      <h3>Use The Quick Board Service At Your Own Risk</h3>
      <p>
        Our goal is to provide helpful and accurate information on the Quick
        Board Service, but we make no endorsement, representation or warranty of
        any kind about any Quick Board Content, information, services or
        recommendations.
      </p>
    </div>
    <div>
      <h3>Consult Your Doctor Before Using The Quick Board Service</h3>
      <p>
        The Quick Board Service is not intended to diagnose, treat, cure, or
        prevent any disease. If you have a medical or heart condition, consult
        your doctor before using the Quick Board and Quick Board Service. If you
        experience a medical emergency, stop using the Quick Board and consult
        with a medical professional. We are not responsible for any health
        problems that may result from training programs through the Quick Board
        Service. If you engage in any training program you receive or learn
        about through the Quick Board Service you agree that you do so at your
        own risk and are voluntarily participating in these activities.
      </p>
    </div>
    <div>
      <h3>Copyright Policy</h3>
      <p>
        Quick Board respects copyright law and expects its users to do the same.
        It is Quick Board’s policy to terminate in appropriate circumstances
        account holders who repeatedly infringe the rights of copyright holders.
      </p>
    </div>
    <div>
      <h3>Changes To The Quick Board Service</h3>
      <p>
        Quick Board may change or discontinue, temporarily or permanently, any
        feature or component of the Quick Board Service at any time without
        notice. Quick Board is not liable to you or to any third party for any
        modification, suspension or discontinuance of any feature or component
        of the Quick Board Service. We reserve the right to determine the timing
        and content of software updates, which may be automatically downloaded
        and installed by Quick Board products without prior notice to you.
      </p>
    </div>
    <div>
      <h3>Termination</h3>
      <p>
        If you violate these Terms, we reserve the right to deactivate your
        account or terminate these Terms, at our sole discretion, at any time
        and without notice or liability to you. Upon any such termination, we
        may delete Your Content and other information related to your account.
        Upon any termination, discontinuation or cancellation of the Quick Board
        Service or your account, the following provisions of these Terms will
        survive: Quick Board’s Rights; Our Enforcement Rights; Consult Your
        Doctor Before Using The Quick Board Service; Termination; Disclaimers;
        Indemnity; Limitation of Liability; Dispute Resolution; and General
        Terms.
      </p>
    </div>
    <div>
      <h3>Disclaimers</h3>
      <p>
        THE QUICK BOARD SERVICE AND QUICK BOARD CONTENT ARE PROVIDED “AS IS,”
        WITHOUT WARRANTY OF ANY KIND. We make no warranty that the Quick Board
        Service or Quick Board Content will meet your requirements or be
        available on an uninterrupted, secure, or error-free basis. You
        acknowledge and agree that if you rely on any Quick Board Content or the
        Quick Board Service, you do so solely at your own risk.
      </p>
    </div>
    <div>
      <h3>Indemnity</h3>
      <p>
        You will indemnify and hold harmless Quick Board and its officers,
        directors, employees and agents, from and against any claims, disputes,
        demands, liabilities, damages, losses, and costs and expenses,
        including, without limitation, reasonable attorneys ’fees arising out of
        or in any way connected with (i) your access to or use of the Quick
        Board Service or (ii) your breach of any warranties made by you
        hereunder or your violation of any other provision of these Terms. We
        reserve the right to assume control of the defense of any third-party
        claim that is subject to indemnification by you, in which event you will
        cooperate with us in asserting any available defenses
      </p>
    </div>
    <div>
      <h3>Limitation Of Liability</h3>
      <p>
        NEITHER QUICK BOARD, ITS SUPPLIERS OR LICENSORS, NOR ANY OTHER PARTY
        INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE QUICK BOARD SERVICE
        WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
        DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE
        INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
        SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR
        FROM THE USE OF OR INABILITY TO USE THE QUICK BOARD SERVICE, WHETHER
        BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
        LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT QUICK BOARD HAS
        BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
        REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
        PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
        LIMITATION MAY NOT APPLY TO YOU
      </p>
      <p>
        IN NO EVENT WILL QUICK BOARD’S TOTAL LIABILITY ARISING OUT OF OR IN
        CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE
        QUICK BOARD SERVICE EXCEED THE AMOUNTS YOU HAVE PAID TO QUICK BOARD FOR
        USE OF THE QUICK BOARD SERVICE.
      </p>
      <p>
        THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
        FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN QUICK BOARD AND
        YOU.
      </p>
    </div>
    <div>
      <h3>Dispute Resolution</h3>
      <p>
        You agree that any dispute between you and Quick Board arising out of or
        relating to these Terms of Service, the Quick Board Service, or any
        other Quick Board products or services (collectively, “Disputes”) will
        be governed by the arbitration procedure outlined below.
      </p>
      <p>
        Governing Law: The Terms of Service and the resolution of any Disputes
        shall be governed by and construed in accordance with the laws of the
        State of Tennessee without regard to its conflict of laws principles.
      </p>
      <p>
        Informal Dispute Resolution: We want to address your concerns without
        needing a formal legal case. Before filing a claim against Quick Board,
        you agree to try to resolve the Dispute informally by contacting Quick
        Board. If a dispute is not resolved within 90 days after submission, you
        or Quick Board may bring a formal proceeding.
      </p>
      <p>
        Limitation on Claims: Regardless of any statute or law to the contrary,
        any claim or cause of action arising out of or related to your use of
        the Quick Board products or Quick Board Service must be filed within one
        (1) year after such claim or cause of action arose, or else that claim
        or cause of action will be barred forever.
      </p>
    </div>
    <div>
      <h3>General Terms</h3>
      <p>
        Except as otherwise stated herein, these Terms constitute the entire and
        exclusive understanding and agreement between Quick Board and you
        regarding the Quick Board Service, and these Terms supersede and replace
        any and all prior oral or written understandings or agreements between
        Quick Board and you regarding the Quick Board Service and Quick Board
        Content. If for any reason a court of competent jurisdiction finds any
        provision of these Terms invalid or unenforceable, that provision will
        be enforced to the maximum extent permissible and the other provisions
        of these Terms will remain in full force and effect.
      </p>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without Quick Board’s prior written consent. Any attempt by
        you to assign or transfer these Terms, without such consent, will be
        null. Quick Board may freely assign or transfer these Terms without
        restriction. Subject to the foregoing, these Terms will bind and inure
        to the benefit of the parties, their successors and permitted assigns.
      </p>
      <p>
        Any notices or other communications provided by Quick Board under these
        Terms, including those regarding modifications to these Terms, will be
        given: (i) via email; or (ii) by posting to the Quick Board Service. For
        notices made by e-mail, the date of receipt on the message will be
        deemed the date on which such notice is transmitted.
      </p>
      <p>
        Quick Board’s failure to enforce any right or provision of these Terms
        will not be considered a waiver of such right or provision. The waiver
        of any such right or provision will be effective only if in writing and
        signed by a duly authorized representative of Quick Board. Except as
        expressly set forth in these Terms, the exercise by either party of any
        of its remedies under these Terms will be without prejudice to its other
        remedies under these Terms or otherwise.
      </p>
    </div>
    <div>
      <h3>Additional Terms May Apply</h3>
      <p>
        Additional terms may apply to certain products or services. In the event
        that there is a conflict between these Terms and any additional terms,
        the additional terms will control.
      </p>
    </div>
    <h2>Commercial Terms and Conditions</h2>
    <div>
      <p>
        These terms of sale (“Terms”) apply to all orders accepted by The Quick
        Board, LLC (“Quick Board”) for the sale of its sensor board products
        (“Products”) and the software embedded in Quick Board sensor boards, the
        Quick Board web application, and the Quick Board mobile application
        (“Service”). To the extent the Products contain or consist of software
        in any form, such Service is licensed to Customer (“Customer”), not
        sold. Terms such as “sell” and “purchase”, as used in these Terms, apply
        only to the extent the Products consist of items other than the Service.
      </p>
    </div>
    <div>
      <h3>SUBSCRIPTION AND PAYMENT</h3>
      <ul class="list-disc list-inside">
        <li>
          The Customer agrees to the Products and Service pricing specified on
          the website or quoted by a Quick Board representative.
        </li>
        <li>
          Fees are due and payable in advance, unless payment terms have been
          established, and any unpaid fees may result in suspension or
          termination of the Service.
        </li>
        <li>
            If payment terms are granted, send purchase orders to the following email address:
            <a href="mailto:kevinmartin@thequickboard.com" class="text-blue-400">kevinmartin@thequickboard.com</a>
        </li>
      </ul>
    </div>
      <div>
          <h3>
              ACCESS AND USE
          </h3>
          <ul class="list-disc list-inside">
              <li>The Quick Board, LLC grants the Customer a non-exclusive, non-transferable right to
                  access and use the Service during the subscription term.</li>
              <li>The Customer shall not reverse engineer, decompile, or otherwise attempt to derive the
                  source code of the Service.</li>
              <li>The Customer is responsible for maintaining the confidentiality of its login credentials
                  and is liable for any unauthorized use of the Service under its account.</li>
              <li>The Customer must initially register an administrator account and is responsible for
                  creating location accounts within the administrator account.</li>
              <li>The Customer is responsible for creating location-based user profiles for employees to
                  access the Service within their organization.
              </li>
              <li>Customer will use its sole discretion on appropriate use with clients or patients.</li>
          </ul>
      </div>
      <div>
          <h3>DATA AND PRIVACY
          </h3>
          <ul class="list-disc list-inside">
              <li>The Quick Board, LLC will maintain appropriate administrative, physical, and technical
                  safeguards to protect the Customer's data</li>
              <li>The Quick Board, LLC complies with applicable data protection laws and regulations.</li>
          </ul>
      </div>
      <div>
          <h3>
              INTELLECTUAL PROPERTY
          </h3>
          <ul class="list-disc list-inside">
              <li>
                  The Quick Board, LLC retains all intellectual property rights of the Products and in the
                  Service and any related documentation or materials.
              </li>
              <li>The Customer shall not remove, alter, or obscure any proprietary notices accompanying
                  the Service.</li>
          </ul>
      </div>
      <div>
          <h3>OUR ENFORCEMENT RIGHTS</h3>
          <p>
              We are not obligated to monitor access or use of the Quick Board Service, Quick Board
              Content, or to review or edit any Quick Board Content, but we have the right to do so for the
              purpose of operating the Quick Board Service, to ensure compliance with these Terms, and to
              comply with applicable law or other legal requirements. We may consult with and disclose
              unlawful conduct to law enforcement authorities; and pursuant to valid legal process, we may
              cooperate with law enforcement authorities to prosecute users who violate the law. We reserve
              the right (but are not required) to remove or disable access to the Quick Board Service and any
              Quick Board Content at any time and without notice, and at our sole discretion, if we determine
              that the Quick Board Content or Customer use of the Quick Board Service is objectionable or in
              violation of these Terms. We have the right to investigate violations of these Terms and any
              conduct that affects the Quick Board Service.

          </p>
      </div>
      <div>
          <h3>TERMINATION</h3>
          <p>If Customer violates these Terms, we reserve the right to deactivate the Customer account or
              terminate these Terms, at our sole discretion, at any time and without notice or liability to
              Customer. Upon any such termination, we may delete Customer Content and other information
              related to the Customer account. Upon any termination, discontinuation or cancellation of the
              Quick Board Service or Customer account, the following provisions of these Terms will survive:
              Quick Board’s Rights; Our Enforcement Rights; Termination; Disclaimers; Indemnity; Limitations
              on Liability; Dispute Resolution; and General Provisions.
          </p>
      </div>
      <div>
          <h3>COMPATIBILITY</h3>
          <p>
              Customer acknowledges the compatibility of the Products customer is purchasing with other
              required equipment (e.g., ensuring that Customer mobile device and/or operating system is
              compatible with the Product). Customer is solely responsible for determining the compatibility of
              the Products with other equipment, and Customer accepts that lack of compatibility is not a valid
              claim under the warranty provided with Products and does not otherwise constitute a basis for
              receiving a refund after the 30-day refund policy identified above.
          </p>
      </div>
      <div>
          <h3>CHANGES TO THE QUICK BOARD SERVICE
          </h3>
          <p>
              Quick Board may change or discontinue, temporarily or permanently, any feature or component
              of the Quick Board Service at any time without notice. Quick Board is not liable to Customer or
              to any third party for any modification, suspension or discontinuance of any feature or
              component of the Quick Board Service. We reserve the right to determine the timing and
              content of software updates, which may be automatically downloaded and installed by Quick
              Board products without prior notice to Customer.
          </p>
      </div>
      <div>
          <h3>LIMITED PRODUCT WARRANTY</h3>
          <p>
              Subject to the terms and conditions set forth herein, Quick Board expressly and exclusively
              warrants solely to original, end-user Customers (each a “Customer”) that sensor boards and any
              other products manufactured by the Quick Board (collectively, “Products”) shall be free from
              defects in materials and workmanship when properly used and maintained for a period of one
              (1) year from the date of sale (the “Warranty Period”). NO WARRANTY, INCLUDING THIS
              LIMITED STATEMENT OF WARRANTY, SHALL BE IN EFFECT UNTIL CUSTOMER HAS
              TENDERED AN INITIAL PAYMENT FOR PRODUCTS PURCHASED. IF CUSTOMER
              PURCHASES PRODUCTS ON AN INSTALLMENT BASIS PAYMENT PLAN AND DEFAULTS
              THEREUNDER, THIS LIMITED STATEMENT OF WARRANTY SHALL TERMINATE WITH
              RESPECT TO CUSTOMER AND SHALL RELIEVE THE QUICK BOARD OF FURTHER
              OBLIGATION TO CUSTOMER HEREUNDER
          </p>
          <p>
              The exclusive warranty for any Products that Quick Board, in its sole and absolute discretion,
              determines to be defective within the Warranty Period is repair or replacement of such defective
              Products. The existence of a defect shall be determined by Quick Board in accordance with
              procedures established by Quick Board. Repaired parts or replacement Products shall be
              provided by Quick Board on an exchange basis and shall either be new or refurbished to be
              functionally equivalent to new. Repaired parts or replacement Products shall be provided to
              Customer only upon submission to Quick Board of the original invoice or sales receipt
              (indicating the date of purchase) within the Warranty Period. Customer’s failure to notify Quick
              Board of defective Products within the Warranty Period shall terminate this Limited Statement of
              Warranty with respect to Customer and shall relieve Quick Board of any further obligation to
              such Customer hereunder. Quick Board shall not be responsible for any transportation costs
              and/or any risks associated with transportation of Products.
          </p>
          <p>
              THIS LIMITED STATEMENT OF WARRANTY IS IN LIEU OF ALL OTHER WARRANTIES
              EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF THE CONDITION OF THE
              EQUIPMENT, OR ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE. QUICK BOARD DOES NOT REPRESENT OR WARRANT THAT
              PRODUCTS WILL OPERATE ERROR FREE, UNINTERRUPTEDLY OR IN COMBINATION
              WITH ANY OTHER HARDWARE, INFRASTRUCTURE OR SYSTEMS NOT SUPPLIED BY
              QUICK BOARD OR THAT PRODUCTS WILL, IN ALL CASES, SERVE THE PURPOSE FOR
              WHICH SUCH PRODUCTS ARE PURCHASED.
          </p>
          <p>
              No representative of Quick Board is authorized to extend any additional warranty unless
              approved in writing by an authorized representative of Quick Board. This Limited Statement of
              Warranty is provided solely to Customer and is not transferable. Customer is responsible for
              determining whether Products are suitable for Customer’s intended use.
          </p>
          <p>
              This Limited Statement of Warranty given by Quick Board does not cover, and Quick Board
              shall not be liable for, any conditions attributable to (1) acts of God, misuse, physical damage,
              vandalism, misapplication, normal wear and tear, extreme environmental conditions, lack of
              compliance with applicable instructions, inadequate or improper maintenance, negligence,
              accident (including, but not limited to, water damage and/or dropping Products), tampering,
              alteration or substitution of inferior quality component parts, (2) the incompatibility, improper
              design, malfunction, installation, operation or maintenance of equipment, accessories, and/or
              materials used in connection with Products which are not manufactured by Quick Board or (3)
              attempts to operate Products outside of the range or parameters of their intended capacity,
              whether intentional, accidental or otherwise.
          </p>
          <p>
              CUSTOMER’S SOLE AND EXCLUSIVE REMEDY FOR LIABILITY OF ANY KIND WITH
              RESPECT TO PRODUCTS SHALL BE LIMITED TO THE REMEDY OF REPAIR OR
              REPLACEMENT OF PRODUCTS IN ACCORDANCE WITH THIS WARRANTY. QUICK
              BOARD SHALL, UNDER NO CIRCUMSTANCES, BE LIABLE TO CUSTOMER OR ANY
              OTHER PERSON FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR
              PUNITIVE DAMAGES OR LOSS OF USE, REVENUE OR PROFIT, WHETHER ARISING OUT
              OF BREACH OF WARRANTY, BREACH OF CONTRACT, STRICT LIABILITY, NEGLIGENCE
              OR OTHERWISE, EVEN IF QUICK BOARD HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES OR LOSSES. IN THE EVENT THIS LIMITED STATEMENT OF WARRANTY
              IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE, QUICK BOARD’S LIABILITY
              TO CUSTOMER SHALL NOT EXCEED THE PRICE OF PRODUCTS PURCHASED. Customer
              agrees that Quick Board is not an insurer and no insurance coverage is offered herein. Quick
              Board is not assuming liability, and, therefore shall not be liable to Customer for any loss,
              personal injury or property damage sustained by Customer or any other party as a result of any
              failure of Products, or any other cause whatsoever, unless such loss or damage was
              proximately caused by the Quick Board’s gross negligence or caused by Quick Board’s
              intentional conduct.
          </p>
          <p>
              This Limited Statement of Warranty may be modified at any time by Quick Board without notice
              to Customer and the modified version shall be in effect for all sales of Products made after the
              modification irrespective of any warranty published or posted in any catalog, sales literature or
              online location.
          </p>
      </div>
      <div>
          <h3>
              DISCLAIMERS
          </h3>
          <p>
              THE QUICK BOARD SERVICE AND QUICK BOARD CONTENT ARE PROVIDED “AS IS,”
              WITHOUT WARRANTY OF ANY KIND. We make no warranty that the Quick Board Service or
              Quick Board Content will meet Customer requirements or be available on an uninterrupted,
              secure, or error-free basis. Customer acknowledges and agrees that if Customer relies on any
              Quick Board Content or the Quick Board Service, Customer will do so solely at Customer’s own
              risk.
          </p>
      </div>
      <div>
          <h3>
              INDEMNITY
          </h3>
          <p>
              Customer will indemnify and hold harmless Quick Board and its officers, directors, employees
              and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and
              costs and expenses, including, without limitation, reasonable attorneys ’fees arising out of or in
              any way connected with (i) Customer access to or use of the Quick Board Service or (ii)
              Customer breach of any warranties made by Customer hereunder or Customer violation of any
              other provision of these Terms. We reserve the right to assume control of the defense of any
              third-party claim that is subject to indemnification by Customer, in which event Customer will
              cooperate with us in asserting any available defenses.
          </p>
      </div>
      <div>
          <h3>
              LIMITATIONS ON LIABILITY
          </h3>
          <p>
              IN NO EVENT WILL QUICK BOARD BE LIABLE FOR ANY PUNITIVE, EXEMPLARY,
              SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES (INCLUDING, WITHOUT
              LIMITATION, LOSS OF REVENUE, USE, PROFITS, DATA, OR GOODWILL) OR COSTS OF
              PROCURING SUBSTITUTE PRODUCTS, ARISING OUT OF, RELATING TO, OR IN
              CONNECTION WITH THESE TERMS OR THE PURCHASE, SALE, USE, OPERATION OR
              PERFORMANCE OF THE PRODUCTS, WHETHER SUCH LIABILITY ARISES FROM ANY
              CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE),
              PRODUCT LIABILITY OR OTHERWISE, WHETHER OR NOT QUICK BOARD HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. QUICK BOARD AND
              CUSTOMER HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN
              IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF
              ITS ESSENTIAL PURPOSE. IN NO EVENT WILL QUICK BOARD’S LIABILITY TO
              CUSTOMER ARISING OUT OF, RELATING TO, OR IN CONNECTION WITH THESE TERMS,
              FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY, EXCEED THE
              ACTUAL AMOUNT PAID TO QUICK BOARD BY CUSTOMER FOR THE PRODUCT THAT
              GIVES RISE TO THE CLAIM. Some jurisdictions do not allow the limitation or exclusion of
              liability for incidental or consequential damages, so the above limitation or exclusion may not
              apply to Customer.
          </p>
      </div>
      <div>
          <h3>
              DISPUTE RESOLUTION
          </h3>
          <p>
              Customer agrees that any dispute between Customer and Quick Board arising out of or relating
              to these Terms, or any other Quick Board Products or Service (collectively, “Disputes”) will be
              governed by the arbitration procedure outlined below.
          </p>
      </div>
      <div>
          <h3>GOVERNING LAW
          </h3>
          <p>
              The Terms and the resolution of any Disputes shall be governed by and construed in
              accordance with the laws of the State of Tennessee without regard to its conflict of laws
              principles.
          </p>
      </div>
      <div>
          <h3>
              INFORMAL DISPUTE RESOLUTION
          </h3>
          <p>
              We want to address Customer concerns without needing a formal legal case. Before filing a
              claim against Quick Board, Customer agrees to try to resolve the Dispute informally by
              contacting Quick Board. We'll try to resolve the Dispute informally by contacting Customer
              through email. If a dispute is not resolved within 90 days after submission, Customer or Quick
              Board may bring a formal proceeding
          </p>
      </div>
      <div>
          <h3>
              LIMITATION ON CLAIMS
          </h3>
          <p>
              Regardless of any statute or law to the contrary, any claim or cause of action arising out of or
              related to Customer use of the Quick Board Products or Service must be filed within one (1)
              year after such claim or cause of action arose, or else that claim or cause of action will be
              barred forever.
          </p>
      </div>
      <div>
          <h3>
              TERM
          </h3>
          <p>
              Upon payment for the Quick Board Service, Customer accepts the one-year license contract
              required to use Quick Board Products.
          </p>
      </div>
      <div>
          <h3>
              AUTOMATIC RENEWAL AND CANCELLATION
          </h3>
          <p>
              Service fees will be charged to the Customer credit card, unless payment terms are in place,
              and will automatically renew at the applied tier pricing then in effect for the duration of Customer
              subscription until Customer cancels. The Service fees will be charged based on the selected
              monthly or annual plan and payment cannot be suspended or postponed for any reason. The
              one-year license contract will automatically renew unless notice of cancellation is received in
              writing 30 days prior to the renewal date. If Customer credit card is invalid for any reason,
              charged back, or if Quick Board does not receive payment, we will cancel Customer
              subscription immediately.
          </p>
      </div>
      <div>
          <h3>
              REPRESENTATIONS, WARRANTIES AND COVENANTS
          </h3>
          <p>
              Customer represents, warrants and covenants that (a) the subscription and credit card or
              banking information that Customer supplies is true, correct and complete, (b) Customer will pay
              any charges that Customer incurs in connection with the Products and Service, including any
              applicable taxes, (c) Customer will update Customer subscription and credit card or banking
              information as required, (d) Customer will not allow anyone else to use Customer subscription,
              (e) Customer will not transfer Customer subscription or password to anyone else and (f)
              Customer will report to us any unauthorized or prohibited use of Customer subscription.
          </p>
      </div>
      <div>
          <h3>
              GENERAL PROVISIONS
          </h3>
          <p>
              The parties disclaim application of the United Nations Convention on Contracts for the
              International Sale of Goods.
          </p>
          <p>
              Customer may not assign or transfer these Terms, or any order accepted by Quick Board
              hereunder, in whole or in part, by operation of law or otherwise, without Quick Board’s express
              prior written consent. Any attempt to do so, without Quick Board’s consent, will be null and of no
              effect. Quick Board may freely assign these Terms.
          </p>
          <p>
              Quick Board will not be responsible for any failure or delay in its performance under these
              Terms due to causes beyond its reasonable control, including, but not limited to, labor disputes,
              strikes, lockouts, shortages of or inability to obtain energy, raw materials or supplies, war,
              terrorism, riot, or acts of God.
          </p>
          <p>
              The failure by Quick Board to enforce any provision of these Terms will not constitute a waiver
              of future enforcement of that or any other provision. If for any reason a court of competent
              jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be
              enforced to the maximum extent permissible, and the other provisions of these Terms will
              remain in full force and effect.
          </p>
          <p>
              These Terms constitute the complete and exclusive agreement between Quick Board and
              Customer regarding its subject matter and supersedes all prior or contemporaneous quotations,
              agreements, communications or understandings, whether written or oral, relating to its subject
              matter. Any waiver, modification or amendment of any provision of these Terms will be effective
              only if in writing and signed by duly authorized representatives of each party.
          </p>
          <p>
              Customer will not export or re-export, directly or indirectly, the Products, or any technical
              information related thereto, or any direct products thereof, to any destination or person
              prohibited or restricted by the export control laws and regulations of the United States, without
              the prior authorization from the appropriate governmental authorities.
          </p>
          <p>
              All notices required or permitted to be given under these Terms will be in writing and will be
              deemed given: (i) upon actual delivery, if made by personal service; (ii) three (3) days after
              mailing, if made by U.S. certified or registered mail; and (iii) one (1) business day after delivery
              to the courier or overnight delivery service, if made by courier or overnight delivery service. All
              notices will be addressed to such address as the party who is to receive the notice so
              designates by written notice to the other.
          </p>
      </div>
      <div>
          <h3>
              ADDITIONAL TERMS MAY APPLY
          </h3>
          <p>
              Additional terms may apply to certain products or services. In the event that there is a conflict
              between these Terms and any additional terms, the additional terms will control.
          </p>
      </div>
      <div>
          <h3>
              CONTACT US
          </h3>
          <p>
              PLEASE CONTACT US IF CUSTOMER HAVE ANY QUESTIONS ABOUT THESE TERMS.
              The Quick Board, LLC
          </p>
      </div>
      <div class="w-full flex flex-col">
          <p class="!m-0">
              PO Box 241759
          </p>
          <p class="!m-0">
              Memphis, TN 38124
          </p>
          <a href="mailto:info@thequickboard.com" class="text-blue-400">info@thequickboard.com
          </a>
      </div>
  </div>
</div>
