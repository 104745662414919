import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ResultsControllerService {
  constructor(private apiService: ApiService) {}

  getResults(
    page: number,
    size: number,
    overview: number,
    exerciseId: string,
    endType: number,
    endValue: number,
    inputType: number,
    delay: number,
    flash: number,
    promptType: number,
    exerciseName: string,
    filterString: string,
  ): Observable<any> {
    let parameterString = `page=${page}&size=${size}&overview=${overview}&id=${exerciseId}&endType=${endType}&endValue=${endValue}&inputType=${inputType}`;
    if (delay != null) {
      parameterString += `&delay=${delay}`;
    }
    if (flash != null) {
      parameterString += `&flash=${flash}`;
    }
    if (promptType != null) {
      parameterString += `&promptType=${promptType}`;
    }
    parameterString += `&exerciseName=${exerciseName}${filterString}`;
    return this.apiService.get(
      `/result?${parameterString}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getResultData(
    page: number,
    size: number,
    filterString: string,
    overview,
  ): Observable<any> {
    return this.apiService.get(
      `/results`,
      {
        page,
        size,
        filterString,
        overview,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getPossibleFilters(): Observable<any> {
    return this.apiService.get(
      `/result-filters`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  exportResultData(data): Observable<any> {
    return this.apiService.post(
      `/export`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  massExport(filterString: string, overview): Observable<any> {
    let param = "";
    if (filterString.length == 0) {
      param = "?";
    } else {
      param = "&";
    }
    return this.apiService.get(
      `/mass-export` + filterString + param + `overview=${overview}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteResult(id: string): Observable<any> {
    return this.apiService.delete(
      `/result`,
      { id },
      AuthService.defaultTokenHeader().headers,
    );
  }
}
