import {Injectable} from "@angular/core";
import {ISettingsDTO} from "../../shared/DTOs/ISettingsDTO";


@Injectable({
  providedIn: "root",
})
export class SettingsService {
  public settings: ISettingsDTO;

  constructor() {}
}
