<div class="p-5 flex flex-col gap-3">
    <h2 class="text-2xl">Create injury</h2>
    <form (ngSubmit)="onSubmit()" [formGroup]="injuryForm" class="flex flex-col gap-3">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Injury name</mat-label>
            <input type="text" matInput [formControl]="injuryForm.controls.name" />
            <mat-error *ngIf="ValidationHelperService.isInvalid(injuryForm.controls.name)">
                {{
                    ValidationHelperService.getErrorMessage(injuryForm.controls.name)
                }}
            </mat-error>
        </mat-form-field>

        <div class="flex justify-end">
            <button mat-flat-button color="primary">
                Save
            </button>
        </div>
    </form>
</div>
