import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ApiService} from "../api.service";
import {IUser} from "../../models/subscription.model";
import {ISubscriptionDropdownData} from "../../../shared/interfaces/DTOs/Response/ISubscriptionDropdownItem";

@Injectable({
  providedIn: "root",
})
export class AdminControllerService {
  private readonly _resourceUrl: string = "/admin";

  constructor(private apiService: ApiService) {}

  public getClinicDetails(
    startTimestamp,
    endTimestamp,
    gender,
    age,
    activityLevel,
    injuryType,
    injury,
    injuryGrade,
  ): Observable<any> {
    let query = "";
    if (startTimestamp) {
      if (query != "") query = query + "&startTimestamp=" + startTimestamp;
      else query = "startTimestamp=" + startTimestamp;
    }
    if (endTimestamp) {
      if (query != "") query = query + "&endTimestamp=" + endTimestamp;
      else query = "endTimestamp=" + endTimestamp;
    }
    if (gender) {
      if (query != "") query = query + "&gender=" + gender;
      else query = "gender=" + gender;
    }
    if (age) {
      if (query != "") query = query + "&age=" + age;
      else query = "age=" + age;
    }
    if (activityLevel) {
      if (query != "") query = query + "&activityLevel=" + activityLevel;
      else query = "activityLevel=" + activityLevel;
    }
    if (injuryType) {
      if (query != "") query = query + "&injuryType=" + injuryType;
      else query = "injuryType=" + injuryType;
    }
    if (injury) {
      if (query != "") query = query + "&injury=" + injury;
      else query = "injury=" + injury;
    }
    if (injuryGrade) {
      if (query != "") query = query + "&injuryGrade=" + injuryGrade;
      else query = "injuryGrade=" + injuryGrade;
    }
    return this.apiService.get(
      `${this._resourceUrl}?${query}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  public getPerformanceDetails(
    startTimestamp,
    endTimestamp,
    gender,
    age,
    sport,
    position,
    level,
  ): Observable<any> {
    let query = "";
    if (startTimestamp) {
      if (query != "") query = query + "&startTimestamp=" + startTimestamp;
      else query = "startTimestamp=" + startTimestamp;
    }
    if (endTimestamp) {
      if (query != "") query = query + "&endTimestamp=" + endTimestamp;
      else query = "endTimestamp=" + endTimestamp;
    }
    if (gender) {
      if (query != "") query = query + "&gender=" + gender;
      else query = "gender=" + gender;
    }
    if (age) {
      if (query != "") query = query + "&age=" + age;
      else query = "age=" + age;
    }
    if (sport) {
      if (query != "") query = query + "&sport=" + sport;
      else query = "sport=" + sport;
    }
    if (position) {
      if (query != "") query = query + "&position=" + position;
      else query = "position=" + position;
    }
    if (level) {
      if (query != "") query = query + "&level=" + level;
      else query = "level=" + level;
    }
    return this.apiService.get(
      `${this._resourceUrl}/performanceData?${query}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  public getOverviewData(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/overviewData`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  public getInjuriesSettings(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/injuries`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  public getSubscriptions(): Observable<ISubscriptionDropdownData> {
    console.log( AuthService.defaultTokenHeader().headers)
    return this.apiService.get<ISubscriptionDropdownData>(`${this._resourceUrl}/subscriptions`, {}, AuthService.defaultTokenHeader().headers);
  }

  getPaymentHistory(body) {
    return this.apiService.post(
      `${this._resourceUrl}/get-payment-history`,
      body,
      {
        page: body.pagination.page,
        itemsPerPage: body.pagination.itemsPerPage,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getUsers(page, itemsPerPage, search = ""): Observable<{
    data: IUser[],
    totalCount: number
  }> {
    return this.apiService.get<{
      data: IUser[],
      totalCount: number
    }>(
      `${this._resourceUrl}/dashboard/users`,
      { page, itemsPerPage, search },
      AuthService.defaultTokenHeader().headers,
    );
  }

  updateUsers(
    body,
    page = "0",
    itemsPerPage = "20",
    search = "",
  ): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/dashboard/users`,
      body,
      { page, itemsPerPage, search },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getOrganizationsActiveCount(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/dashboard/statistics`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
