<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="mb-2 mb-sm-3 mb-md-4 mb-lg-5 p-3 card w-full">
    <div>
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="openBackModal()"
      >
        Back
      </button>
    </div>
  </div>

  <div class="card w-100" *ngIf="action === EAction.Add || !!exercise">
    <app-exercise-form
      [_formGroup]="exerciseForm"
      [action]="action"
      (_submit)="saveExercise($event)"
      [exercise]="exercise"
    ></app-exercise-form>
  </div>
</div>
