import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ApiService} from "../api.service";
import {IInjury} from "../../../shared/interfaces/IInjury";
import {IInjuryGrade} from "../../../shared/interfaces/IInjuryGrade";

@Injectable({
  providedIn: "root",
})
export class InjuriesControllerService {
  private readonly _resourceUrl: string = "/injuries";

  constructor(private apiService: ApiService) {}

  getInjuries(): Observable<IInjury[]> {
    return this.apiService.get(
        `${this._resourceUrl}`,
        {},
        AuthService.defaultTokenHeader().headers,
    );
  }

  createInjury(injury: IInjury): Observable<IInjury> {
    return this.apiService.post(`${this._resourceUrl}`, injury, {} ,AuthService.defaultTokenHeader().headers);
  }

  editInjury(injury: IInjury): Observable<IInjury> {
    return this.apiService.put(`${this._resourceUrl}`, injury, {} ,AuthService.defaultTokenHeader().headers);
  }

  getInjuryById(id: string): Observable<IInjury> {
    return this.apiService.get<IInjury>(`${this._resourceUrl}/${id}`, {} , AuthService.defaultTokenHeader().headers);
  }

  createGrade(payload: {injuryId: string; name: string;}): Observable<{id: string}>
  {
    return this.apiService.post(`${this._resourceUrl}/grades`, payload, {}, AuthService.defaultTokenHeader().headers);
  }

  editGrade(grade: IInjuryGrade): Observable<IInjuryGrade> {
    return this.apiService.put(`${this._resourceUrl}/grades`, grade, {}, AuthService.defaultTokenHeader().headers);
  }

}
