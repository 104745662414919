import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  jwtHelper;

  constructor(private apiService: ApiService) {
    this.jwtHelper = new JwtHelperService();
  }

  setTokens(rememberMe: boolean, acc_token: string, ref_token: string) {
    if (rememberMe) {
      localStorage.setItem("token", acc_token);
      localStorage.setItem("refresh_token", ref_token);
      const token = this.jwtHelper.decodeToken(acc_token);
      if (token != null) {
        localStorage.setItem("decodedToken", JSON.stringify(token));
      }
      return;
    } else {
      sessionStorage.setItem("token", acc_token);
      sessionStorage.setItem("refresh_token", ref_token);
      const token = this.jwtHelper.decodeToken(acc_token);
      if (token != null) {
        sessionStorage.setItem("decodedToken", JSON.stringify(token));
      }
    }
  }

  requestOauthToken(
    username: string,
    password: string,
    code: string,
  ): Promise<any> {
    let data: HttpParams;
    data = new HttpParams()
      .set("scope", "ui")
      .set("username", username)
      .set("password", password)
      .set("grant_type", "password");

    if (code !== null) {
      data = data.append("code", code);
    }

    const header = new HttpHeaders().set("Authorization", "Basic YnJvd3Nlcjo=");

    return this.apiService
      .post(`/uaa/oauth/token`, data, {}, header)
      .toPromise();
  }

  login2FA(username: string, password: string, code: string): Promise<any> {
    return this.requestOauthToken(username, password, code);
  }
}
