<div class="flex flex-col gap-3 max-w-[1200px] h-full p-[3rem] mx-auto">
    <div class="flex justify-between items-center border-b py-3">
        <div class="flex items-center justify-center gap-3">
            <h2 class="text-4xl !m-0">Restore Deleted Workouts</h2>
        </div>
        <div class="flex gap-3 items-center justify-center">
            <mat-paginator [length]="totalCount"
                           [pageSize]="itemsPerPage"
                           [pageSizeOptions]="[5, 10, 25, 100]"
                           (page)="changePage($event)"
                           aria-label="Select page">
            </mat-paginator>
            <a routerLink="/workouts" mat-flat-button color="primary">Go to workouts</a>
        </div>
    </div>
    <div *ngIf="!isLoading" class="flex flex-col gap-5 py-8 w-full">
        <div class="w-full grid grid-cols-3 gap-3">
            <app-deleted-workout-card *ngFor="let workout of workouts" (_delete)="restoreWorkout(workout.id)" [workout]="workout"></app-deleted-workout-card>
        </div>
    </div>
    <div *ngIf="!isLoading && workouts?.length === 0" class="h-[400px] w-full flex items-center justify-center">
        <h2 class="text-xl text-gray-500">There are no deleted workouts.</h2>
    </div>
    <div *ngIf="isLoading" class="flex justify-center items-center w-full h-[600px]">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>
