import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ISingleAthleteDto} from "../../../shared/DTOs/ISingleAthleteDto";
import {ApiService} from "../api.service";
import {Athlete, IAthlete, IAthleteUpdate,} from "../../../shared/interfaces/IAthlete";
import {map} from "rxjs/internal/operators";
import {ITeam, Team} from "../../../shared/interfaces/ITeam";
import {Category, ICategory} from "../../../shared/interfaces/ICategory";

@Injectable({
  providedIn: "root",
})
export class AthletesControllerService {
  private readonly _resourceUrl: string = "/athletes";

  constructor(private apiService: ApiService) {}

  getAthlete(id: string): Observable<Athlete> {
    return this.apiService
      .get<Athlete>(
        `${this._resourceUrl}/${id}`,
        {},
        AuthService.defaultTokenHeader().headers,
      )
      .pipe(
        map((athlete: IAthlete) => {
          return new Athlete(athlete);
        }),
      );
  }

  editAthlete(data: IAthleteUpdate): Observable<IAthlete> {
    return this.apiService.put<IAthlete>(
      `${this._resourceUrl}`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  saveAthlete(data): Observable<{ id: string }> {
    return this.apiService.post<{ id: string }>(
      `${this._resourceUrl}`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getLevels(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/levels`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getTeams(locationId): Observable<Team[]> {
    return this.apiService
      .get<
        Team[]
      >(`${this._resourceUrl}/teams`, { locationId }, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => {
          return response.map((team) => new Team(team));
        }),
      );
  }

  deleteAthlete(athleteId: string): Observable<any> {
    return this.apiService.delete(
      `${this._resourceUrl}`,
      {
        id: athleteId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getAthletes(
    locationId: string,
    body = { categoryIds: [], sportPositionIds: [], levelIds: [], teamIds: [] },
  ): Observable<ISingleAthleteDto[]> {
    return this.apiService.post<ISingleAthleteDto[]>(
      `${this._resourceUrl}/get-filtered`,
      body,
      { locationId },
      AuthService.defaultTokenHeader().headers,
    );
  }

  saveTeam(data): Observable<Team[]> {
    return this.apiService
      .post<
        ITeam[]
      >(`${this._resourceUrl}/teams`, data, {}, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => {
          return response.map((team) => new Team(team));
        }),
      );
  }

  updateTeam(data): Observable<Team[]> {
    return this.apiService
      .put<
        ITeam[]
      >(`${this._resourceUrl}/teams`, data, {}, AuthService.defaultTokenHeader().headers)
      .pipe(map((response) => response.map((team) => new Team(team))));
  }

  deleteTeam(teamId: string): Observable<Team[]> {
    return this.apiService
      .delete<
        ITeam[]
      >(`${this._resourceUrl}/teams/${teamId}`, {}, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => {
          return response.map((team) => new Team(team));
        }),
      );
  }

  getCategories(locationId): Observable<Category[]> {
    return this.apiService
      .get<
        ICategory[]
      >(`${this._resourceUrl}/categories`, { locationId }, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => response.map((category) => new Category(category))),
      );
  }

  saveCategory(data): Observable<Category[]> {
    return this.apiService
      .post<
        ICategory[]
      >(`${this._resourceUrl}/categories`, data, {}, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => response.map((category) => new Category(category))),
      );
  }

  updateCategory(data): Observable<Category[]> {
    return this.apiService
      .put<
        ICategory[]
      >(`${this._resourceUrl}/categories`, data, {}, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => response.map((category) => new Category(category))),
      );
  }

  deleteCategory(id: string): Observable<Category[]> {
    return this.apiService
      .delete<
        ICategory[]
      >(`${this._resourceUrl}/categories/${id}`, {}, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => {
          return response.map((category) => new Category(category));
        }),
      );
  }

  setActive(athleteId: string, active: boolean): Observable<any> {
    return this.apiService.delete(
      "/athlete-active",
      {
        id: athleteId,
        active,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getPossibleFilters(locationId): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/filters`,
      { locationId },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getAthleteById(id: string): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/${id}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteMultipleAthletes(ids: string[]) {
    const searchParams = new URLSearchParams();
    ids.forEach((id) => searchParams.append("id", id));
    return this.apiService.delete(
      `${this._resourceUrl}?${searchParams.toString()}`,
    );
  }
}
