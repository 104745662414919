import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class WorkoutControllerService {
  private readonly _resourceUrl: string = "/workout";

  constructor(private apiService: ApiService) {}

  saveWorkout(data): Observable<any> {
    return this.apiService.post(
      `/workout`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getWorkout(id: string) {
    return this.apiService.get(
      `/save-workout`,
      {
        id: id,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getAthletes(page, size): Observable<any> {
    return this.apiService.get(
      `/workout-athletes`,
      {
        page,
        size,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteWorkout(workoutId: string): Observable<any> {
    return this.apiService.delete(
      `${this._resourceUrl}`,
      {
        id: workoutId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getWorkoutData(page, size): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {
        page,
        size,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  addWorkoutIndividually(body) {
    return this.apiService.post(
      "/add-workout",
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getOrganizations(
    page: number,
    size: number,
    org: string,
    workoutId: string,
  ): Observable<any> {
    return this.apiService.get(
      "/organizations",
      {
        page,
        size,
        org,
        workoutId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  syncForFull(adminWorkoutId: string, locationId: string) {
    return this.apiService.post(
      "/sync-for-full",
      {},
      {
        adminId: adminWorkoutId,
        locId: locationId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  syncForExercises(adminWorkoutId: string, locationId: string) {
    return this.apiService.post(
      "/sync-for-exercises",
      {},
      {
        adminId: adminWorkoutId,
        locId: locationId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getWorkoutById(adminWorkoutId: string) {
    return this.apiService.get(
      `${this._resourceUrl}/${adminWorkoutId}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  checkLocationsWorkout(adminWorkoutId: string) {
    return this.apiService.get(
      "/check-locations-workout",
      {
        workoutId: adminWorkoutId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  addWorkoutToAll(adminWorkoutId: string) {
    return this.apiService.get(
      "/add-workout-multiple",
      {
        workoutId: adminWorkoutId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  syncForPlaylists(adminWorkoutId: string) {
    return this.apiService.get(
      "/sync-playlists-multiple",
      {
        workoutId: adminWorkoutId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  addMultipleWorkouts(locationId: string, workoutIds: string[]) {
    return this.apiService.post(
      "/add-multiple-workouts",
      { locationId, workoutIds },
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
