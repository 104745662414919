import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {ISettingsDTO} from "../../../shared/DTOs/ISettingsDTO";
import {map} from "rxjs/operators";
import {SettingsService} from "../../services/settings.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AccountControllerService {
  private readonly _resourceUrl: string = "/account";

  constructor(private apiService: ApiService, private settingsService: SettingsService, private router: Router) {}

  getSyncState(): Observable<{ syncDisabled: boolean }> {
    return this.apiService.get<{ syncDisabled: boolean }>(
      `${this._resourceUrl}/sync-state`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  toggleSyncState(): Observable<{ syncDisabled: boolean }> {
    return this.apiService.put<{ syncDisabled: boolean }>(
      `${this._resourceUrl}/sync-state`,
      {},
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  register(data): Observable<any> {
    return this.apiService.post(`${this._resourceUrl}/register`, data);
  }

  checkOrganizationName(name: string): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}/check-organization`, {
      name,
    });
  }

  validUser(username: string): Promise<any> {
    return this.apiService
      .get(`${this._resourceUrl}/check-user`, { username })
      .toPromise();
  }

  requestNewPassword(username) {
    return this.apiService.post(
      `${this._resourceUrl}/forgot-password`,
      {},
      { username },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getUserSettings(): Observable<ISettingsDTO> {
    return this.apiService.get<ISettingsDTO>(
      `${this._resourceUrl}/settings`,
      {},
      AuthService.defaultTokenHeader().headers,
    ).pipe(map(response => {
      this.settingsService.settings = response;
      if(response?.locations?.length === 0) {
        this.router.navigate(["/no-location-allowed"], {
          skipLocationChange: true,
        });
      }
      return response;
    }));
  }

  resetPassword(password, token) {
    return this.apiService.put(`${this._resourceUrl}/reset-password`, {
      password: password,
      token: token,
    });
  }

  updateUserPreferences(mandatoryEmail: boolean) {
    let body = {
      emailMandatory: mandatoryEmail,
    };
    return this.apiService.post(
      `${this._resourceUrl}/settings/user-preferences`,
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  updatePassword(data): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/change-password`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  changeAccountInformation(data): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/account-info`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getAccountInformation(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/account-info`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  updateProfilePicture(profilePictureUrl): Observable<any> {
    return this.apiService.patch(
      `${this._resourceUrl}/profile-picture`,
      {},
      { profilePictureUrl },
      AuthService.defaultTokenHeader().headers,
    );
  }
}
