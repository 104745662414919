import {Component, Inject, OnInit} from "@angular/core";
import * as moment from "moment/moment";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GlobalPreferences} from "../../../../core/helpers/global-data";
import {LogsControllerService} from "../../../../core/api/controllers/logs-controller.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-logs-report-dialog",
  templateUrl: "./logs-report-dialog.component.html",
  styleUrls: ["./logs-report-dialog.component.scss"],
})
export class LogsReportDialogComponent implements OnInit {
  newStartDate = undefined;
  newEndDate = undefined;
  isLoading = false;

  constructor(
    public global: GlobalPreferences,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      organizationId: string;
    },
    private logsController: LogsControllerService,
    private dialogRef: MatDialogRef<LogsReportDialogComponent>,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  changeDate(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.newStartDate = moment(dateRangeStart.value).toDate();
    this.newEndDate = moment(dateRangeEnd.value).toDate();
  }

  isValidDate(newStartDate, newEndDate) {
    return (
      moment(newStartDate).isValid() &&
      moment(newEndDate).isValid() &&
      newStartDate !== undefined &&
      newEndDate !== undefined
    );
  }

  getResultData() {
    if (this.isValidDate(this.newStartDate, this.newEndDate)) this.fetchLogs();
  }

  fetchLogs() {
    this.isLoading = true;
    this.logsController
      .exportLogs({
        start: this.newStartDate.toLocaleDateString(),
        end: this.newEndDate.toLocaleDateString(),
        organizationId: this.data.organizationId,
      })
      .subscribe({
        next: (res) => {
          this.downloadFile(res);
          this.dialogRef.close();
          this.isLoading = false;
        },
        error: (err) => {
          this.matSnackBar.open(err.error.error ?? "Something went wrong", "Close", {
            duration: 3000,
          });
          if(err.error.validationErrors) {
            this.matSnackBar.open(err.error.validationErrors[0].message, "Close", {
              duration: 3000,
            });
          }
          this.isLoading = false;
        },
      });
  }

  downloadFile(res) {
    const blob = new Blob([res], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    // Download the file with the given filename
    const a = document.createElement("a");
    a.href = url;
    a.download = "Logs Report " + new Date().toLocaleDateString() + " .xlsx";
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
