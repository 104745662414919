import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ExerciseControllerService } from "../../../core/api/controllers/exercise-controller.service";

@Component({
  selector: "app-select-exercise-category",
  templateUrl: "./select-exercise-category.component.html",
  styleUrls: ["./select-exercise-category.component.scss"],
})
export class SelectExerciseCategoryComponent {
  @Input() _control: FormControl;

  constructor(private exerciseController: ExerciseControllerService) {}

  getCategories(): any {
    return this.exerciseController.getCategories.bind(this.exerciseController);
  }

  saveCategory(): any {
    return this.exerciseController.saveCategory.bind(this.exerciseController);
  }

  updateCategory(): any {
    return this.exerciseController.updateCategory.bind(this.exerciseController);
  }

  deleteCategory(): any {
    return this.exerciseController.deleteCategory.bind(this.exerciseController);
  }
}
