import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {
  IExerciseForm,
  IFullPairedExerciseForm,
  IFullRegularExerciseForm,
  IPairedExerciseConfigForm,
  IPlaylistForm
} from "../workout-form/WorkoutFormFactory";
import {MatDialog} from "@angular/material/dialog";
import {EditPlaylistDialogComponent} from "./edit-playlist-dialog/edit-playlist-dialog.component";
import {IPlaylist} from "../../../shared/interfaces/IPlaylist";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {IRegularExerciseConfigForm} from "../../../core/forms/factory/RegularExerciseFormFactory";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

export interface IDraggableRegularExerciseConfig {
  regularConfig: FormGroup<IRegularExerciseConfigForm>,
  regularExercise: FormGroup<IFullRegularExerciseForm>,
  pairedConfig: FormGroup<IPairedExerciseConfigForm>,
  pairedExercise: FormGroup<IFullPairedExerciseForm>
}

@Component({
  selector: 'app-playlist-row-form',
  templateUrl: './playlist-row-form.component.html',
  styleUrls: ['./playlist-row-form.component.scss'],
  host: {
    class: 'h-full'
  }
})
export class PlaylistRowFormComponent implements OnInit {
  @Input() _form: FormGroup<IPlaylistForm>;
  @Output() _delete = new EventEmitter<null>();
  selectedConfigs: IDraggableRegularExerciseConfig[] = [];
  cachedFormValue: IPlaylist;
  constructor(
      private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedConfigs = this.getSelectedConfigs();
  }

  getAllExercises(): FormGroup<IExerciseForm>[] {
    return this._form.controls.exerciseCategories.controls.reduce((acc: FormGroup<IExerciseForm>[] , curr) => {
      acc = [
        ...acc,
        ...curr.controls.exercises.controls
      ]
      return acc;
    }, []);
  }

  getSelectedConfigs(): IDraggableRegularExerciseConfig[] {
    return [
        ...this.getSelectedRegularConfigs(),
        ...this.getSelectedPairedConfigs()
    ]
  }


  getSelectedPairedConfigs(): IDraggableRegularExerciseConfig[] {
    const allExercises = this.getAllExercises();
    return allExercises.reduce((acc: IDraggableRegularExerciseConfig[], curr) => {
      const exerciseConfigs: IDraggableRegularExerciseConfig[] = [];
      curr.controls.pairedExercise.controls.pairedExerciseConfigs.controls.forEach(config => {
        if (config.controls.isSelected.value) exerciseConfigs.push({
          regularExercise: null,
          regularConfig: null,
          pairedConfig: config,
          pairedExercise: curr.controls.pairedExercise
        });
      })
      acc = [
        ...acc,
        ...exerciseConfigs
      ]
      return acc;
    }, []);
  }


  getSelectedRegularConfigs(): IDraggableRegularExerciseConfig[] {
    const allExercises = this.getAllExercises();
    return allExercises.reduce((acc: IDraggableRegularExerciseConfig[], curr) => {
      const exerciseConfigs: IDraggableRegularExerciseConfig[] = [];
      curr.controls.regularExercise.controls.configs.controls.forEach(config => {
        if (config.controls.isSelected.value) exerciseConfigs.push({
          regularExercise: curr.controls.regularExercise,
          regularConfig: config,
          pairedConfig: null,
          pairedExercise: null
        });
      })
      acc = [
        ...acc,
        ...exerciseConfigs
      ]
      return acc;
    }, []);
  }


  openEditDialog(): void {
    this.cachedFormValue = this._form.value as IPlaylist;
    this.dialog.open(EditPlaylistDialogComponent, {
      maxHeight: '90vh',
      height: '90vh',
      data: {
        _form: this._form
      },
      disableClose: true
    }).afterClosed().subscribe({
      next: (res) => {
        if(!res) {
          this._form.patchValue(this.cachedFormValue);
        } else {
          this._form.patchValue(res);
          this.selectedConfigs = this.getSelectedConfigs();
        }
      }
    })
  }

  openDeleteDialog(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "Are you sure you want to delete this playlist?"
    };
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (res) => {
        if(res) this._delete.emit();
      }
    })
  }

  drop(event: CdkDragDrop<IDraggableRegularExerciseConfig[]>) {
    moveItemInArray(this.selectedConfigs, event.previousIndex, event.currentIndex);
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
