import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface IReportDialogData {
  height: number;
  url: string;
}

@Component({
  selector: "app-report-dialog",
  templateUrl: "./report-dialog.component.html",
  styleUrls: ["./report-dialog.component.scss"],
})
export class ReportDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IReportDialogData) {}
}
