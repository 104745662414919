import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaControllerService} from "../../../core/api/controllers/media-controller.service";
import {NotificationService} from "../../../core/services/notification.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MixpanelService} from "../../../core/services/mixpanel.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {EAction} from "../../../shared/enums/EAction";
import {Subscription} from "rxjs";
import {RegularExerciseFormFactory} from "../../../core/forms/factory/RegularExerciseFormFactory";
import {IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {RegularExerciseHelper} from "../../../core/forms/helpers/RegularExerciseHelper";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {ExerciseControllerService} from "../../../core/api/controllers/exercise-controller.service";

@Component({
  selector: "app-save-exercise",
  templateUrl: "./save-exercise.component.html",
  styleUrls: ["./save-exercise.component.scss"],
  providers: [ExerciseControllerService, MediaControllerService],
})
export class SaveExerciseComponent implements OnInit, OnDestroy {
  protected readonly ValidationHelperService = ValidationHelperService;
  id: any;
  sub: Subscription;
  isLoading = false;
  isPairedExercise: boolean;
  action = EAction.Add;
  exerciseForm: FormGroup<IRegularExerciseForm> =
    RegularExerciseFormFactory.create();
  exercise: any;

  constructor(
    private route: ActivatedRoute,
    private exercisesController: ExercisesControllerService,
    private router: Router,
    private notificationService: NotificationService,
    private mixpanelService: MixpanelService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("exercise_save_screen", {});
    this.identifyExerciseType();
  }

  identifyExerciseType(): void {
    this.sub = this.route.queryParams.subscribe(
      (params) => {
        this.id = params["id"];
        this.isPairedExercise = params["isPairedExercise"];
        this.action = this.id ? EAction.Edit : EAction.Add;
        if (this.action === EAction.Edit) {
          this.retrieveExercise();
        }
      },
      (error) => this.notificationService.error(error),
    );
  }

  retrieveExercise() {
    this.exercisesController.getRegularExercise(this.id).subscribe(
      (response) => {
        this.exercise = response;
        RegularExerciseHelper.patchFormValue(this.exerciseForm, response);
      },
      (error) => this.notificationService.error(error),
    );
  }

  saveExercise(data: any) {
    if (this.action === EAction.Add) this.createRegularExercise(data);
    else if (this.action === EAction.Edit)
      this.editRegularExercise({
        ...data,
        id: this.id,
      });
  }

  editRegularExercise(data): void {
    this.exercisesController.editRegularExercise(data).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["/exercises"]);
        this.notificationService.success("Exercise updated successfully.");
      },
      ({ error }) => {
        if (error.validationErrors && error.validationErrors.length) {
          error.validationErrors.forEach((er) => {
            this.notificationService.error(er);
          });
        }
        this.isLoading = false;
      },
    );
  }

  createRegularExercise(data): void {
    this.exercisesController.createRegularExercise(data).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["/exercises"]);
        this.notificationService.success("Exercise saved successfully.");
      },
      ({ error }) => {
        if (error.validationErrors && error.validationErrors.length) {
          error.validationErrors.forEach((er) => {
            this.notificationService.error(er);
          });
        }
        this.isLoading = false;
      },
    );
  }

  openBackModal() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Confirmation",
          message:
            "Are you sure you want to go back? All changes will be discarded!",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["/exercises"]);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get sequenceSensors(): FormArray {
    return this.sequenceForm.get("sequenceSensors") as FormArray;
  }

  get type(): FormControl {
    return this.exerciseForm.get("type") as FormControl;
  }

  get difficulty(): FormControl {
    return this.config.get("difficulty") as FormControl;
  }

  get numberOfLegs(): FormControl {
    return this.verticalForm.get("numberOfLegs") as FormControl;
  }

  get verticalForm(): FormGroup {
    return this.config.get("verticalForm") as FormGroup;
  }

  get countdownsForm(): FormGroup {
    return this.config.get("countdownForm") as FormGroup;
  }

  get startType(): FormControl {
    return this.countdownsForm.get("startType") as FormControl;
  }

  get startValue(): FormControl {
    return this.countdownsForm.get("startValue") as FormControl;
  }

  get endType(): FormControl {
    return this.countdownsForm.get("endType") as FormControl;
  }

  get endValue(): FormControl {
    return this.countdownsForm.get("endValue") as FormControl;
  }

  get reactForm(): FormGroup {
    return this.config.get("reactForm") as FormGroup;
  }

  get neurocognitiveType(): FormControl {
    return this.reactForm.get("neurocognitiveType") as FormControl;
  }

  get countForm(): FormGroup {
    return this.config.get("countForm") as FormGroup;
  }

  get countType(): FormControl {
    return this.countForm.get("countType") as FormControl;
  }

  get sequenceForm(): FormGroup {
    return this.config.get("sequenceForm") as FormGroup;
  }

  get delay(): FormControl {
    return this.sequenceForm.get("delay") as FormControl;
  }

  get agilityForm(): FormGroup {
    return this.config.get("agilityForm") as FormGroup;
  }

  get videoUrl(): FormControl {
    return this.exerciseForm.get("videoUrl") as FormControl;
  }

  get imgThumb(): FormControl {
    return this.exerciseForm.get("imgThumb") as FormControl;
  }

  get config(): FormGroup {
    return this.exerciseForm.get("config") as FormGroup;
  }

  get agilitySensors(): FormArray {
    return this.agilityForm.get("agilitySensors") as FormArray;
  }

    protected readonly EAction = EAction;
}
