import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class SportsControllerService {
  private readonly _resourceUrl: string = "/sports";

  constructor(private apiService: ApiService) {}

  getPositionBySportName(sportName: string): Observable<any> {
    return this.apiService.get(
      `/position-sport`,
      {
        sportName,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getSports(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getSportsPositions(id): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/${id}/positions`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
