<div class="flex justify-center items-center p-5 h-[800px]">
  <div *ngIf="registerSuccess.value === null" class="processing content">
    <div class="ui active inverted dimmer">
      <div class="ui text big loader">
        <h4>Please wait.</h4>
        <h4>We are currently processing your payment.</h4>
      </div>
    </div>
    <p></p>
  </div>

  <div *ngIf="registerSuccess.value === true" class="content">
    <figure>
      <img class="medium ui circular image" src="assets/images/success.png" />
      <figcaption class="green">
        <h2>
          Welcome,
          {{ data.personalDetailsForm.controls["firstName"].value }}
          {{ data.personalDetailsForm.controls["lastName"].value }}!
        </h2>
        <h3>Processing complete. Thank you for your business!</h3>
        <h3>Your subscription is now active.</h3>
        <h5>You will soon be redirected to the dashboard page.</h5>
      </figcaption>
    </figure>
  </div>

  <div *ngIf="registerSuccess.value === true" class="actions">
    <button
      class="ui fluid positive button action-button"
      (click)="redirectToDashboard()"
    >
      Dashboard
    </button>
  </div>

  <div *ngIf="registerSuccess.value === false" class="content">
    <figure>
      <img class="medium ui circular image" src="assets/images/fail.png" />
      <figcaption class="red">
        <h3>Something went wrong.</h3>
      </figcaption>
    </figure>
  </div>

  <div *ngIf="registerSuccess.value === false" class="actions">
    <button
      class="ui fluid negative button action-button"
      (click)="dialogRef.close(true)"
    >
      Retry
    </button>
  </div>
</div>
