export const colorArray = [
  "#7cb5ec",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#2f7ed8",
  "#0d233a",
  "#910000",
  "#8bbc21",
  "#3D96AE",
  "#1aadce",
  "#492970",
  "#f28f43",
  "#77a1e5",
  "#c42525",
  "#a6c96a",
  "#4572A7",
  "#AA4643",
  "#89A54E",
  "#80699B",
  "#3D96AE",
  "#DB843D",
  "#92A8CD",
  "#A47D7C",
  "#B5CA92",
];
