import { Component, OnInit } from "@angular/core";
import {
  ILocationActionDialogData,
  LocationActionComponent,
} from "./location-action/location-action.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../../core/services/notification.service";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { EDeleteLocationUsersAction } from "../../../shared/enums/EDeleteLocationUsersAction";
import { LocationsControllerService } from "../../../core/api/controllers/locations-controller.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
  locations = [];
  isLoading = false;

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private locationsController: LocationsControllerService,
  ) {}

  ngOnInit(): void {
    this.getLocations();
  }

  addLocation() {
    const data: ILocationActionDialogData = {
      action: "Add",
      location: {},
    };
    this.dialog
      .open(LocationActionComponent, { data, width: "400px" })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getLocations();
        }
      });
  }

  getLocations() {
    this.isLoading = true;
    this.locationsController.getAllLocations().subscribe(
      (response) => {
        this.locations = response.locations;
        this.locations = this.locations.map((location) => {
          return {
            ...location,
            ips: ["192.168.1.1", "172.192.15.13", "152.145.161.2"],
            collapsed: false,
          };
        });
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  editLocation(currentLocation: any) {
    const data: ILocationActionDialogData = {
      action: "Edit",
      location: currentLocation,
    };

    this.dialog
      .open(LocationActionComponent, { data, width: "400px" })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getLocations();
        }
      });
  }

  handleDeleteLocationModal(locationId) {
    const data: IConfirmationDialogData = {
      title: "Delete location?",
      message: "Are you sure you want to delete this location?",
    };
    this.dialog
      .open(ConfirmationDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteLocation(locationId);
        }
      });
  }

  deleteLocation(
    locationId,
    action = EDeleteLocationUsersAction.NoAction,
    newLocationId?,
  ) {
    this.locationsController
      .deleteLocation(locationId, action, newLocationId)
      .then(() => {
        this.getLocations();

        this.notificationService.success(
          "Location has been deleted" +
            (action === EDeleteLocationUsersAction.MoveUsers
              ? " and users have been moved."
              : "."),
        );
      })
      .catch((error) => this.notificationService.error(error));
  }
}
