import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../../../core/services/auth.service";
import { ConfirmationDialogComponent } from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { NotificationService } from "../../../../core/services/notification.service";
import { ValidationHelperService } from "../../../../core/helpers/validation-helper.service";

export enum ENetworkConfigType {
  IP,
  MASK,
}

export interface INetworkConfig {
  ip: string;
  mask: string;
  id?: string;
}

@Component({
  selector: "app-whitelist-dialog",
  templateUrl: "./whitelist-dialog.component.html",
  styleUrls: ["./whitelist-dialog.component.scss"],
})
export class WhitelistDialogComponent implements OnInit {
  formGroupCached = {};
  formGroup = new FormGroup({
    networkConfigs: new FormArray([]),
  });
  addNetworkConfigFormGroup = new FormGroup({
    ip: new FormControl("", [
      Validators.required,
      Validators.pattern(
        "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$",
      ),
    ]),
    mask: new FormControl("", [
      Validators.pattern("^\\d+$|^([0-9]{1,3}\\.){3}[0-9]{1,3}$"),
    ]),
  });
  isLoading = false;
  isEditingArray: boolean[] = [];
  networkConfigIpArray = [];
  options = [
    {
      value: 0,
      label: "IP",
    },
    {
      value: 1,
      label: "MASK",
    },
  ];

  constructor(
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA)
    public location: { location: { id: string; name: string } },
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.fetchNetworkConfigs();
  }

  resetForm(): void {
    this.ip.setValue("");
    this.mask.setValue("");
  }

  fetchNetworkConfigs(): void {
    this.isLoading = true;
    this.httpClient
      .get<INetworkConfig[]>(`${environment.baseURL}/network-configs`, {
        headers: AuthService.defaultTokenHeader().headers,
        params: {
          locationId: this.location.location.id,
        },
      })
      .subscribe((networkConfigs) => {
        this.hidrateForm(networkConfigs);
        this.isLoading = false;
      });
  }

  hidrateForm(networkConfigs: INetworkConfig[]) {
    this.formGroup.setControl("networkConfigs", new FormArray([]));
    this.isEditingArray = new Array(networkConfigs.length).fill(false);
    this.networkConfigIpArray = [];
    if (networkConfigs.length > 0)
      networkConfigs.forEach((networkConfig) => {
        this.addNetworkConfig(networkConfig);
      });
    this.resetForm();
  }

  addNetworkConfig(networkConfig: INetworkConfig) {
    const networkConfigs = this.formGroup.get("networkConfigs") as FormArray;
    networkConfigs.push(
      new FormGroup({
        ip: new FormControl(
          {
            value: networkConfig.ip,
            disabled: true,
          },
          [
            Validators.required,
            Validators.pattern(
              "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$",
            ),
          ],
        ),
        mask: new FormControl(
          {
            value: networkConfig.mask,
            disabled: true,
          },
          [
            Validators.required,
            Validators.pattern("^\\d+$|^([0-9]{1,3}\\.){3}[0-9]{1,3}$"),
          ],
        ),
        id: new FormControl(networkConfig.id ? networkConfig.id : null),
        isEditing: new FormControl(false),
      }),
    );
    this.networkConfigIpArray.push(this.options[networkConfig.ip]);
    this.formGroupCached[networkConfig.id] = networkConfig;
  }

  submitAddNetworkConfigForm() {
    this.isLoading = true;
    this.httpClient
      .post<INetworkConfig[]>(
        `${environment.baseURL}/network-configs`,
        this.createNetworkConfigPayload(),
        {
          headers: AuthService.defaultTokenHeader().headers,
        },
      )
      .subscribe({
        next: (networkConfigs) => {
          this.hidrateForm(networkConfigs);
          this.addNetworkConfigFormGroup.reset();
          this.isLoading = false;
        },
        error: (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
        },
      });
  }

  submitEditNetworkConfigForm(networkConfig: FormGroup): void {
    this.isLoading = true;
    this.httpClient
      .put<INetworkConfig[]>(
        `${environment.baseURL}/network-configs`,
        this.createNetworkEditPayload(networkConfig),
        {
          headers: AuthService.defaultTokenHeader().headers,
        },
      )
      .subscribe({
        next: (networkConfigs) => {
          this.hidrateForm(networkConfigs);
          this.isLoading = false;
        },
        error: (error) => {
          this.notificationService.error(error);
          this.getNetworkConfigIp(networkConfig).enable();
          this.getNetworkConfigMask(networkConfig).enable();
          this.isLoading = false;
        },
      });
  }

  createNetworkEditPayload(networkConfig: FormGroup): INetworkConfig {
    return {
      ip: networkConfig.get("ip").value,
      mask: networkConfig.get("mask").value,
      id: networkConfig.get("id").value,
    } as INetworkConfig;
  }

  deleteNetworkConfig(networkConfig) {
    this.isLoading = true;
    this.httpClient
      .delete<
        INetworkConfig[]
      >(`${environment.baseURL}/network-configs/${networkConfig.get("id").value}`, AuthService.defaultTokenHeader())
      .subscribe({
        next: (networkConfigs) => {
          this.hidrateForm(networkConfigs);
          this.isLoading = false;
        },
        error: (error) => {
          this.notificationService.error(error.error.error);
          this.isLoading = false;
        },
      });
  }

  createNetworkConfigPayload(): INetworkConfig {
    return {
      ip: this.addNetworkConfigFormGroup.get("ip").value,
      mask: this.addNetworkConfigFormGroup.get("mask").value,
      locationId: this.location.location.id,
    } as INetworkConfig;
  }

  getNetworkConfigFormGroup(networkConfigControls: AbstractControl): FormGroup {
    return networkConfigControls as FormGroup;
  }

  getNetworkConfigMask(networkConfigControls: any): FormControl {
    return (networkConfigControls as FormGroup).get("mask") as FormControl;
  }

  openConfirmationModal(networkConfig) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Delete Network Config",
          message: "Are you sure you want to delete this network config?",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteNetworkConfig(networkConfig);
        }
      });
  }

  getNetworkConfigIp(networkConfigControls: any): FormControl {
    return (networkConfigControls as FormGroup).get("ip") as FormControl;
  }

  get networkConfigsFormArray(): FormArray<FormGroup> {
    return this.formGroup.get("networkConfigs") as FormArray<FormGroup>;
  }

  get ip(): FormControl {
    return this.addNetworkConfigFormGroup.get("ip") as FormControl;
  }

  get mask(): FormControl {
    return this.addNetworkConfigFormGroup.get("mask") as FormControl;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
  ENetworkConfigType = ENetworkConfigType;
}
