import { Component, Inject } from "@angular/core";
import * as moment from "moment/moment";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface IAddActivityDialogData {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: "app-add-activity-dialog",
  templateUrl: "./add-activity-dialog.component.html",
  styleUrls: ["./add-activity-dialog.component.scss"],
})
export class AddActivityDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IAddActivityDialogData) {}

  changeDate(event: any) {
    this.data.startDate = moment(event.value.begin).toDate();
    this.data.endDate = moment(event.value.end).toDate();
  }
}
