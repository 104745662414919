<div
        class="w-full h-full relative flex justify-center items-center p-5 cursor-pointer hover:bg-gray-100 transition-all"
        [class.bg-gray-200]="athlete.controls.isSelected.value"
>
    <div class="absolute top-[10px] left-[10px]" (click)="$event.stopPropagation()">
        <mat-checkbox color="primary" [formControl]="athlete.controls.isSelected"></mat-checkbox>
    </div>
    <div class="flex flex-col gap-3 justify-center items-center">
        <div class="flex justify-center h-full w-auto">
            <div
                    *ngIf="
                        athlete.value.athleteObject.profilePictureUrl !== null &&
                        athlete.value.athleteObject.profilePictureUrl !== ''
                      "
                    [style.background-image]="
                        'url(' + athlete.value.athleteObject?.profilePictureUrl + ')'
                      "
                    class="profile-picture"
            ></div>

            <div
                    *ngIf="
                        athlete.value.athleteObject.profilePictureUrl === null ||
                        athlete.value.athleteObject.profilePictureUrl === ''
                      "
                    [style.background-image]="
                        'url(assets/images/no_avatar.png)'
                      "
                    class="profile-picture"
            ></div>
        </div>
        <span class="font-medium text-md">{{ athlete.value.athleteObject.firstName }} {{ athlete.value.athleteObject.lastName }}</span>
        <span class="text-xs text-gray-400">{{ athlete.value.athleteObject.sportName || "No Sport" }} {{ athlete.value.athleteObject.positionName || "No Position" }}</span>
    </div>
    <div class="absolute top-[10px] right-[10px]" (click)="$event.preventDefault(); $event.stopPropagation()">
        <div class="d-flex align-items-center justify-content-center">
            <button
                    matRipple
                    class="flex justify-center items-center !rounded-full p-2 !bg-[#F5DC11]"
                    [matMenuTriggerFor]="menu"
            >
                <div class="flex justify-center items-center">
                    <mat-icon class="scale-75">more_horizontal</mat-icon>
                </div>
            </button>
            <mat-menu class="menu pushed-menu" #menu="matMenu">
                <a
                        mat-menu-item
                        class="item"
                        routerLink="/athletes/{{ athlete.value.athleteObject.id }}"
                >Agility Ratings</a
                >
                <a
                        mat-menu-item
                        class="item"
                        routerLink="/save-athlete/{{
                       athlete.value.athleteObject.id
                      }}"
                >Edit</a
                >
            </mat-menu>
        </div>
    </div>

</div>
