<div class="max-w-[1200px] mx-auto py-5">
    <div class="flex justify-between items-center border-b py-3">
        <div class="flex items-center justify-center gap-3">
            <h2 class="text-4xl !m-0 pr-3">Athletes <span *ngIf="athletesLimit">({{athletesForm.controls.athletes.length}} / {{athletesLimit}})</span>
            </h2>
        </div>
        <div class="flex gap-3 items-center justify-center">
            <div class="w-100 flex gap-2 justify-start items-center">
                            <div             [matTooltip]="athletesLimitReached ? 'You have reached the limit of athletes' : ''"
                            >
                                <a
                                        mat-flat-button
                                        color="primary"
                                        type="button"
                                        routerLink="/save-athlete"
                                        class="flex justify-center items-center gap-3"
                                        [disabled]="athletesLimitReached"
                                >
                                    <mat-icon> add </mat-icon>
                                    <span>
                  Create athlete
              </span>
                                </a>
                            </div>

                            <button
                                    mat-button
                                    mat-flat-button
                                    type="button"
                                    class="text-uppercase font-weight-bold"
                                    (click)="accordion.openAll(); filtering = !filtering"
                                    *ngIf="!filtering"
                            >
                                Show filters
                            </button>
                            <button
                                    mat-button
                                    mat-flat-button
                                    type="button"
                                    class="text-uppercase font-weight-bold"
                                    (click)="accordion.closeAll(); filtering = !filtering"
                                    *ngIf="filtering"
                            >
                                Hide filters
                            </button>
                            <button mat-flat-button color="warn" (click)="resetFilters()" *ngIf="hasFilters">
                                Remove selected filters
                            </button>
                            <div>
                                <button mat-flat-button color="primary" (click)="fileInput.click()">Bulk import</button>
                                <span class="image-upload">
                <input
                        type="file"
                        #fileInput
                        name="file"
                        ng2FileSelect
                        [uploader]="imageUploader"
                        (click)="imageUploader.clearQueue()"
                        accept="text/csv"
                />
              </span>
                            </div>

                            <a mat-flat-button color="accent" href="assets/csv/ATHLETE_BULK_IMPORT_TEMPLATE.csv" download="ATHLETE_BULK_IMPORT_TEMPLATE.csv" target="_blank" >Download bulk import template</a>
                        </div>
        </div>
    </div>

    <mat-accordion *ngIf="filtersForm" multi>
        <mat-expansion-panel class="p-0">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-weight-bold"
                    >Category
                        <span *ngIf="possibleFilters['categoryFilters']">
                ({{ possibleFilters["categoryFilters"].length }})
              </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="d-flex flex-wrap field-wrapper p-3" content>
                    <div
                            *ngFor="let category of filtersForm.controls.categories.controls"
                            class="field"
                    >
                        <mat-checkbox
                                [formControl]="category.controls.isSelected"
                                (change)="updateAthleteList(true)"
                        >
                            {{ category.value.name }}</mat-checkbox
                        >
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
            <span class="font-weight-bold"
            >Sport
              <span *ngIf="filtersForm.controls.sports.controls.length > 0">
                ({{ filtersForm.controls.sports.controls.length || 0 }})
              </span>
            </span>
                </mat-expansion-panel-header>
                <div class="d-flex flex-wrap field-wrapper p-3" content>
                    <div
                            class="field"
                            *ngFor="let sport of filtersForm.controls.sports.controls"
                    >
                        <mat-checkbox
                                [formControl]="sport.controls.isSelected"
                                (change)="updateAthleteList(true)"
                        >
                            {{ sport.value.name }}
                        </mat-checkbox>

                        <div
                                class="filter box-shadow-bottom"
                                *ngIf="
                  sport?.controls.positions?.length !== 0 &&
                  sport.controls.isSelected.value
                "
                        >
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                    <span
                    >Position
                      <ng-container>
                        ({{ sport.controls.positions?.length }})
                      </ng-container>
                    </span>
                                    <i class="dropdown icon"></i>
                                </mat-expansion-panel-header>
                                <div class="overflowing-filter overflowing" content>
                                    <div class="field" *ngFor="let position of sport.controls.positions.controls">
                                        <mat-checkbox
                                                [formControl]="position.controls.isSelected"
                                                (change)="
                          updateAthleteList(true)
                        "
                                        >
                                            {{ position.value.name }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
            <span class="font-weight-bold"
            >Level
              <ng-container *ngIf="filtersForm.controls.levels?.length">
                ({{ possibleFilters["levelFilters"]?.length || 0 }})
              </ng-container>
            </span>
                </mat-expansion-panel-header>
                <div class="d-flex flex-wrap field-wrapper p-3" content>
                    <div
                            class="field"
                            *ngFor="let level of filtersForm.controls.levels.controls"
                    >
                        <mat-checkbox
                                [formControl]="level.controls.isSelected"
                                (change)="
                  updateAthleteList(true)
                "
                        >
                            {{ level.value.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
            <span class="font-weight-bold"
            >Team
              <ng-container *ngIf="filtersForm.controls.teams?.length > 0">
                ({{ filtersForm.controls.teams?.length || 0 }})
              </ng-container>
            </span>
                </mat-expansion-panel-header>
                <div class="d-flex flex-wrap field-wrapper border-bottom p-3" content>
                    <div
                            class="field"
                            *ngFor="let team of filtersForm.controls.teams.controls"
                    >
                        <mat-checkbox
                                class="medium-label"
                                [formControl]="team.controls.isSelected"
                                (change)="updateAthleteList(true)"
                        >
                            {{ team.value.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-expansion-panel>
    </mat-accordion>

  <div class="w-100 mt-2 mt-sm-3 mt-md-4 mt-lg-5">
    <div class="w-full">
        <div class="w-full h-[400px] flex justify-center items-center" *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
      <div class="flex flex-col content-wrapper" *ngIf="!isLoading">
        <div class="w-100 p-3" *ngIf="athleteList?.length > 0">
          <form class="d-flex flex-column gap-5">
              <div class="w-full flex gap-3 items-center">
                  <button mat-flat-button color="warn" (click)="removeSelectedAthletes()" *ngIf="this.selectedAthletes.length > 0">
                      Remove selected athletes
                  </button>
              </div>
              <div class="flex flex-col gap-5">
                  <div *ngFor="let athleteCategory of athletesForm | athletesGroupBy ">
                      <app-athletes-category [athletesCategory]="athleteCategory"></app-athletes-category>
                  </div>
              </div>
          </form>
        </div>
        <div
          class="w-full p-3 alt-color-text"
          *ngIf="athleteList?.length === 0 && filters.length > 0"
        >
          <div
            class="flex flex-col gap-3 items-center justify-center w-full max-w-[500px] mx-auto py-10 text-center"
          >
            <div class="flex gap-3 items-center justify-center">
              <mat-icon> group </mat-icon>
              <span class="h4 alt-color-text !m-0"
                >No athletes</span
              >
            </div>
            <p class="alt-color-text">
              There are no athletes that match the
              selected filters. Select other filters or press the button on the
              right to create a new one.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
