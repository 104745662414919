<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100">
    <div class="w-100 border-bottom">
      <div class="p-3">
        <div class="w-100" role="group" *ngIf="athlete !== null">
          <span class="mat-h1"> Agility Ratings </span>
          <p class="mat-h2 mt-2">
            {{ athlete.firstName }} {{ athlete.lastName }}
          </p>
          <p class="mat-subheading-1">
            {{ athlete.sport || "No Sport" }},
            {{ athlete.position || "No Position" }}
          </p>
          <button
            type="button"
            class="qb-blue-background primary ui button inline-display right-floated"
            routerLink="/athletes"
          >
            Back
          </button>
        </div>
      </div>
    </div>

    <div class="card w-100">
      <div></div>
      <div>
        <div class="athlete-container right-side">
          <div>
            <div class="d-flex m-2 p-2 space-between">
                <mat-form-field appearance="fill">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" (dateInput)="changeDate('input', $event)" (dateChange)="changeDate('change', $event)">
                        <input matEndDate placeholder="End date" (dateInput)="changeDate('input', $event)" (dateChange)="changeDate('change', $event)">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              <button
                class="qb-blue-background primary ui button inline-display float-right"
                [disabled]="!isValidDate(newStartDate, newEndDate)"
                (click)="getResultData()"
              >
                Select
              </button>
            </div>
            <div class="full-height" *ngIf="loading">
                <mat-spinner diameter="30"></mat-spinner>
            </div>

            <div
              class="overflowing-table overflowing"
              *ngIf="agilityRatings.length > 0"
            >
              <table
                class="ui unstackable table results-table"
                *ngIf="agilityRatings.length > 0"
              >
                <thead class="results-header">
                  <tr>
                    <th colspan="3" class="date-cell mat-header-cell">Date</th>
                    <th
                      colspan="3"
                      class="date-cell mat-header-cell align-cell-right mr-2"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let ar of agilityRatings">
                    <tr>
                      <td colspan="3" class="date-cell" data-label="Date">
                        {{ ar.lastModified | date: "medium" }}
                      </td>
                      <td
                        colspan="3"
                        class="date-cell align-cell-right"
                        data-label="Name"
                      >
                        <button
                          class="qb-blue-background primary ui button inline-display right-floated"
                          (click)="viewAgilityRatingByName(ar)"
                        >
                          View
                        </button>
                        <button
                          class="qb-blue-background primary ui button inline-display right-floated"
                          (click)="downloadAgilityByName(ar)"
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card w-100" *ngIf="agilityRatings.length === 0">
              <span class="h4 p-3 alt-color-text"
                >No Agility Rating in the last 6 months. Choose a different
                period</span
              >
            </div>
          </div>

          <div class="w-100 p-3" *ngIf="agilityRatings.length > 0">
            <div class="d-flex justify-content-center align-items-center">
              <ul class="pagination mt-2">
                <li class="page-item">
                  <a
                    class="page-link cursor pointer"
                    *ngIf="currentPage != 0"
                    (click)="currentPage = currentPage - 1; getResultData()"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link cursor pointer">{{ currentPage + 1 }}</a>
                </li>

                <li class="page-item">
                  <a
                    class="page-link cursor pointer"
                    *ngIf="
                      agilityRatings?.length != 0 &&
                      agilityRatings?.length % 3 == 0
                    "
                    (click)="currentPage = currentPage + 1; getResultData()"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</div>
