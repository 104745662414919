export interface ISportPosition {
  id: string;
  name: string;
}

export class SportPosition {
  id: string;
  name: string;

  constructor(sportPosition: ISportPosition) {
    this.id = sportPosition.id;
    this.name = sportPosition.name;
  }
}
