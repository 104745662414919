import { Component, OnInit } from "@angular/core";
import { ExercisesControllerService } from "../../core/api/controllers/exercises-controller.service";
import { MediaControllerService } from "../../core/api/controllers/media-controller.service";
import { NotificationService } from "../../core/services/notification.service";
import { MixpanelService } from "../../core/services/mixpanel.service";
import { AuthService } from "../../core/services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MoveExercisesDialogComponent } from "../../shared/components/move-exercises-dialog/move-exercises-dialog.component";

@Component({
  selector: "app-exercises",
  templateUrl: "./exercises.component.html",
  styleUrls: ["./exercises.component.scss"],
  providers: [ExercisesControllerService, MediaControllerService],
})
export class ExercisesComponent implements OnInit {
  exerciseCategories: any;
  exercises: any;
  active = true;
  isLoading = true;
  toggle = true;
  categoriesLoaded = false;
  exercisesSelected = [];
  selectedCategories = [];
  destinationCategory;

  constructor(
    private exerciseService: ExercisesControllerService,
    private exercisesController: ExercisesControllerService,
    private notificationService: NotificationService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("exercises_screen", {});
    this.getCategories();
  }

  getCategories() {
    this.isLoading = true;
    const locationId = localStorage.getItem("selectedLocationId");
    this.exercisesController.getCategories(locationId).subscribe(
      (response) => {
        this.exerciseCategories = response;
        this.exercises = [];
        this.categoriesLoaded = true;
        this.isLoading = false;

        response.forEach((category) => {
          category.exercises = category.exercises.map((exercise) => ({
            ...exercise,
            categoryId: category.id,
          }));
          this.exercises[category.id] = category.exercises.sort((a, b) => {
            const aName =
              this.getNestedProperty(a, "regularExercise.name") ||
              this.getNestedProperty(a, "pairedExercise.firstExercise.name") ||
              this.getNestedProperty(a, "pairedExercise.secondExercise.name");
            const bName =
              this.getNestedProperty(b, "regularExercise.name") ||
              this.getNestedProperty(b, "pairedExercise.firstExercise.name") ||
              this.getNestedProperty(b, "pairedExercise.secondExercise.name");

            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          });
        });
      },
      (error) => {
        this.notificationService.error(error);
        this.categoriesLoaded = true;
      },
    );
  }

  getNestedProperty(obj, path) {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }

  setActiveAll() {
    let index: number;
    let categoryId;
    for (const exercise of this.exercisesSelected) {
      for (const category of this.exerciseCategories) {
        index = this.exercises[category.id].indexOf(exercise);
        if (index > -1) {
          categoryId = category.id;
          break;
        }
      }
      this.setActive(exercise, categoryId);
    }
    this.finishAction(
      `Exercises successfully ${this.active ? "disabled" : "enabled"}.`,
    );
  }

  favoriteAll() {
    for (const exercise of this.exercisesSelected) {
      if (!exercise.isFavorite) {
        this.setFavorited(exercise);
      }
    }
    this.finishAction("Exercises successfully favorited.");
  }

  unfavoriteAll() {
    for (const exercise of this.exercisesSelected) {
      if (exercise.isFavorite) {
        this.setFavorited(exercise);
      }
    }
    this.finishAction("Exercises successfully unfavorited.");
  }

  finishAction(message: string) {
    this.notificationService.success(message);
    this.exercisesSelected = [];
  }

  setActive(exercise, categoryId) {
    this.exerciseService
      .setField({ id: exercise.id, field: "active", value: !exercise.active })
      .subscribe(
        () => {
          if (this.exercises[categoryId].length === 1) {
            this.removeExerciseCategory(categoryId);
          } else {
            this.exercises[categoryId].splice(
              this.exercises[categoryId].indexOf(exercise),
              1,
            );
          }
          this.notificationService.success(
            `Exercise successfully ${this.active ? "disabled" : "enabled"}.`,
          );
        },
        (error) => this.notificationService.error(error),
      );
  }

  setFavorited(exercise) {
    this.exerciseService
      .setField({
        id: exercise.id,
        field: "favorited",
        value: !exercise.favorited,
      })
      .subscribe(
        () => {
          exercise.favorited = !exercise.favorited;
        },
        (error) => this.notificationService.error(error),
      );
  }

  openMoveModal() {
    this.destinationCategory = null;
    const data = {
      destinationCategory: this.destinationCategory,
      exercisesSelected: this.exercisesSelected,
      exerciseCategories: this.exerciseCategories,
      exercises: this.exercises,
    };
    this.dialog
      .open(MoveExercisesDialogComponent, {
        data,
        width: "800px",
        maxWidth: "90vw",
        maxHeight: "90vh",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getCategories();
        }
      });
  }

  removeExerciseCategory(categoryId) {
    for (let i = 0; i < this.exerciseCategories.length; i++) {
      if (this.exerciseCategories[i].id === categoryId) {
        this.exerciseCategories.splice(i, 1);
        break;
      }
    }
  }

  getExerciseProps(exercise) {
    return {
      ...(exercise.regularExercise || exercise.pairedExercise),
      categoryId: exercise.categoryId,
    };
  }

  isPairedExercise(exercise) {
    return (
      Boolean(exercise.pairedExercise) &&
      Boolean(exercise.pairedExercise.firstExercise) &&
      Boolean(exercise.pairedExercise.secondExercise)
    );
  }

  addToSingleLocation(ex: any, categoryId: string, updateVideos = null): void {
    const queryParams = {};
    const exercise = this.getExerciseProps(ex);
    if (exercise) {
      Object.assign(queryParams, {
        exerciseId: exercise.id,
        exercisePaired: this.isPairedExercise(exercise),
      });
    }

    if (categoryId) {
      const currentSelectedExercises = this.exercisesSelected.filter(
        (item) => item.categoryId === categoryId,
      );
      if (!currentSelectedExercises.length) {
        return;
      }

      Object.assign(queryParams, {
        exerciseId: currentSelectedExercises.map((item) => item.id),
      });
    }

    if (updateVideos) {
      Object.assign(queryParams, { updateVideos });
    }

    this.router.navigate(["/sync-exercise"], { queryParams });
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  addCategory(categoryId) {
    this.exercises[categoryId].forEach((item) => {
      if (!this.exercisesSelected.includes(item))
        this.exercisesSelected.push(item);
    });

    if (this.selectedCategories.includes(categoryId)) {
      this.selectedCategories.splice(
        this.selectedCategories.indexOf(categoryId),
        1,
      );
    } else {
      this.selectedCategories.push(categoryId);
    }
  }
}
