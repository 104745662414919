<form [formGroup]="_formGroup" class="flex flex-col gap-5">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Timer delay</mat-label>
    <input
      type="number"
      [step]="0.1"
      [formControl]="delay"
      matInput
      [value]="delay.value"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(delay)">
      {{ ValidationHelperService.getErrorMessage(delay) }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Timer prompt</mat-label>
    <input
      type="number"
      [step]="0.1"
      [formControl]="timerPrompt"
      matInput
      [value]="timerPrompt.value"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(timerPrompt)">
      {{ ValidationHelperService.getErrorMessage(timerPrompt) }}
    </mat-error>
  </mat-form-field>
  <div>
    <div class="flex gap-5 items-center pb-5">
      <h4 class="text-3xl">Sequence</h4>
      <button
        type="button"
        mat-flat-button
        color="warn"
        (click)="resetSequence()"
      >
        Reset sequence
      </button>
    </div>
    <div
      class="w-full flex justify-center [&>div]:w-[50%] gap-5 !select-none relative pb-5"
    >
      <div
        class="flex items-center flex-wrap gap-5 bg-[#3E3E3E] rounded-3xl p-5 content-start border-2"
        [class.border-rose-500]="
          ValidationHelperService.isInvalid(_formGroup.get('sequenceSensors'))
        "
      >
        <ng-container *ngIf="sequenceSensorsValue.length === 0">
          <div
            class="text-white w-full h-full flex justify-center items-center"
          >
            <span class="text-2xl">No sensors selected</span>
          </div>
        </ng-container>
        <ng-container *ngIf="sequenceSensorsValue.length > 0">
          <div
            class="[&:hover>mat-icon]:!block [&:hover>span]:!hidden relative flex items-center justify-center cursor-pointer bg-amber-300 font-bold text-white rounded-full p-2 w-[50px] h-[50px] hover:brightness-75"
            *ngFor="let sensor of sequenceSensorsValue; let i = index"
            (click)="removeSequenceSensor(i)"
          >
            <span>
              {{ sensor }}
            </span>
            <mat-icon
              class="!hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-red-400"
              >close</mat-icon
            >
          </div>
        </ng-container>
      </div>
      <div class="flex items-center justify-center">
        <div
          class="max-w-[600px] border grid grid-cols-3 grid-rows-3 items-center justify-center content-center w-full h-auto [&>*]:aspect-square bg-[#3E3E3E] rounded-3xl"
        >
          <div class="w-full h-full flex justify-center items-center">
            <div
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white content-center"
              matRipple
              (click)="addSequence(1)"
            >
              1
            </div>
          </div>
          <div></div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(2)"
            >
              2
            </div>
          </div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(6)"
            >
              6
            </div>
          </div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(3)"
            >
              3
            </div>
          </div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(7)"
            >
              7
            </div>
          </div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(4)"
            >
              4
            </div>
          </div>
          <div></div>
          <div class="w-full h-full flex justify-center items-center">
            <div
              matRipple
              class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
              (click)="addSequence(5)"
            >
              5
            </div>
          </div>
        </div>
      </div>
      <mat-error
        *ngIf="
          ValidationHelperService.isInvalid(_formGroup.get('sequenceSensors'))
        "
        class="absolute bottom-[1rem] left-0 w-full"
      >
        {{
          ValidationHelperService.getErrorMessage(
            _formGroup.get("sequenceSensors")
          )
        }}
      </mat-error>
    </div>
  </div>
</form>
