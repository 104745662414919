import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReportsControllerService} from "../../../core/api/controllers/reports-controller.service";
import {PlaylistsComponent} from "../../../features/playlists/playlists.component";
import {NotificationService} from "../../../core/services/notification.service";

export interface IAddReportDialogData {
  type: number;
  filteredAthleteList: string[];
  addAthletesSelected: string[];
  searchAddAthlete: string;
  canLoadAthleteList: boolean;
}

@Component({
  selector: "app-add-report-dialog",
  templateUrl: "./add-report-dialog.component.html",
  styleUrls: ["./add-report-dialog.component.scss"],
})
export class AddReportDialogComponent {
  filterTimeout;
  searching = false;
  athleteList = [];
  filteredAthleteList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAddReportDialogData,
    private reportService: ReportsControllerService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddReportDialogComponent>,
  ) {}

  filterAthleteList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        this.data.filteredAthleteList = Object.assign([], this.athleteList);
        const length = this.athleteList.length;
        this.data.canLoadAthleteList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 1) {
        return;
      }
      this.searching = true;
      this.reportService.getAthletes(0, 20, value).subscribe(
        (response: any) => {
          this.filteredAthleteList = response;
          const length = response.length;
          this.data.canLoadAthleteList = length % 20 === 0 && length !== 0;
          this.searching = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.searching = false;
        },
      );
    }, 350);
  }

  updateSelected(value, array) {
    return PlaylistsComponent.updateSelected(value, array);
  }

  getAthletes() {
    const length = this.athleteList.length;
    const page = length > 0 ? Math.floor(length / 20) : 0;
    const size = 20;
    this.reportService
      .getAthletes(page, size, this.data.searchAddAthlete)
      .subscribe(
        (response) => {
          if (this.data.searchAddAthlete == "") {
            for (const athlete of response) {
              this.athleteList.push(athlete);
            }
            this.filteredAthleteList = Object.assign([], this.athleteList);
          } else {
            for (const athlete of response) {
              this.filteredAthleteList.push(athlete);
            }
          }
          const athletes = response.length;
          this.data.canLoadAthleteList = athletes % 20 === 0 && athletes !== 0;
        },
        (error) => this.notificationService.error(error),
      );
  }
}
