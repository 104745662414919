import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IWorkout} from "../../../../shared/interfaces/IWorkout";
import {MatDialog} from "@angular/material/dialog";
import {WorkoutsControllerService} from "../../../../core/api/controllers/workouts/workouts-controller.service";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData
} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-deleted-workout-card',
  templateUrl: './deleted-workout-card.component.html',
  styleUrls: ['./deleted-workout-card.component.scss']
})
export class DeletedWorkoutCardComponent {
  @Input() workout: IWorkout;
  @Output() _delete = new EventEmitter<string>();
  isLoading = false;

  constructor(
      private dialog: MatDialog,
      private workoutsController: WorkoutsControllerService
  ) {}

  getExercisesCount(): number {
    return this.workout.playlists.map(playlist => {
      return playlist.exercises.length
    }).reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }

  openRestoreWorkoutDialog(): void {
    const data: IConfirmationDialogData = {
      title: "Are you sure?",
      message: "Are you sure you want to restore this workout?"
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data
    }).afterClosed().subscribe({
      next: (value) => {
        if(value) {
          this.restoreWorkout();
        }
      }
    })
  }

  restoreWorkout(): void {
    this.isLoading = true;
    this.workoutsController.toggleWorkoutStatus(this.workout.id, true).subscribe({
      next: () => {
        this._delete.emit(this.workout.id);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }
}
