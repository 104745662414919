import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class MediaControllerService {
  private readonly _resourceUrl: string = "/media";

  constructor(private apiService: ApiService) {}

  mediaUpload(body): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/upload`,
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
