<div class="flex justify-between items-center w-full" *ngIf="config.regularExercise">
    <div class="flex justify-between items-center gap-2">
        <mat-icon>drag_indicator</mat-icon>
        <app-regular-exercise-config [_form]="config.regularConfig" [exerciseForm]="config.regularExercise"></app-regular-exercise-config>
    </div>
    <div class="flex gap-3 items-center">
        <mat-form-field appearance="outline" class="w-[100px] mr-5" subscriptSizing="dynamic" *ngIf="!playlistForm.controls.isCircuit.value && playlistForm.controls.isAutomated.value">
            <mat-label>Sets</mat-label>
            <input type="number" matInput [formControl]="config.regularConfig.controls.sets">
            <mat-error *ngIf="ValidationHelperService.isInvalid(config.regularConfig.controls.sets)">
                {{
                    ValidationHelperService.getErrorMessage(config.regularConfig.controls.sets)
                }}
            </mat-error>
        </mat-form-field>
        <app-exercise-type-badge [type]="config.regularExercise.controls.type.value"></app-exercise-type-badge>
    </div>
</div>
<div class="flex justify-between items-center w-full" *ngIf="config.pairedExercise">
    <div class="flex justify-between items-center gap-2">
        <mat-icon>drag_indicator</mat-icon>
        <app-paired-exercise-config [_form]="config.pairedConfig" [exerciseForm]="config.pairedExercise"></app-paired-exercise-config>
    </div>
    <div class="flex gap-3 items-center">
        <mat-form-field appearance="outline" class="w-[100px] mr-5" subscriptSizing="dynamic" *ngIf="!playlistForm.controls.isCircuit.value && playlistForm.controls.isAutomated.value">
            <mat-label>Sets</mat-label>
            <input type="number" matInput [formControl]="config.pairedConfig.controls.sets">
            <mat-error *ngIf="ValidationHelperService.isInvalid(config.pairedConfig.controls.sets)">
                {{
                    ValidationHelperService.getErrorMessage(config.pairedConfig.controls.sets)
                }}
            </mat-error>
        </mat-form-field>
        <app-exercise-type-badge [type]="config.pairedExercise.controls.type.value"></app-exercise-type-badge>
    </div>
</div>
