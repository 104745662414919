import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {ApiService} from "../../api.service";
import {IGetWorkoutsPayload} from "./data/IGetWorkoutsPayload";
import {IGetWorkoutsResponse} from "./data/IGetWorkoutsResponse";

@Injectable({
  providedIn: "root",
})
export class WorkoutsControllerService {
  private readonly _resourceUrl: string = "/workouts";

  constructor(private apiService: ApiService) {}

  getWorkouts(payload: IGetWorkoutsPayload): Observable<IGetWorkoutsResponse> {
    return this.apiService.get<IGetWorkoutsResponse>(
      `${this._resourceUrl}`,
      payload,
      AuthService.defaultTokenHeader().headers,
    );
  }
}
