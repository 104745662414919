<div class="card position-relative w-100 p-3 mt-2 mt-sm-0 mr-sm-5 mb-2">
  <div *ngIf="!isLoading">
    <div class="container mb-5">
      <form [formGroup]="paymentHistoryForm">
        <div class="row row-cols-3 align-items-center my-3">
          <div class="col p-0 mx-2">
            <mat-form-field>
              <input
                matInput
                placeholder="Search by"
                formControlName="searchBy"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row row-cols-3 align-items-center my-3">
          <div class="col p-0 mx-2">
            <mat-form-field>
              <mat-select
                placeholder="Payment Status"
                formControlName="isSuccess"
              >
                <mat-option value="false">OK</mat-option>
                <mat-option value="true">Failed</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col p-0 mx-2">
            <mat-form-field>
              <mat-select
                placeholder="Transaction Type"
                formControlName="transactionType"
              >
                <mat-option [value]="paymentHistoryTransactionType.FullPurchase"
                  >Full Purchase</mat-option
                >
                <mat-option [value]="paymentHistoryTransactionType.Subscription"
                  >Subscription</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col p-0 mx-2">
            <mat-form-field>
              <mat-select
                placeholder="Invoice Status"
                formControlName="invoiceGenerated"
              >
                <mat-option value="true">OK</mat-option>
                <mat-option value="false">Failed</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div
          class="row row-cols-3 align-items-center my-3"
          formGroupName="dateTimeInterval"
        >
          <div class="col p-0 mx-2">
            <div class="row">
              <div class="col p-0 mx-1">
                <mat-form-field>
                  <mat-label>Start Date</mat-label>
                  <input
                    matInput
                    [max]="paymentHistoryForm?.value?.dateTimeInterval?.endDate"
                    [matDatepicker]="pickerStartDate"
                    formControlName="startDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerStartDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #pickerStartDate
                    disabled="false"
                  ></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col p-0 mx-1">
                <mat-form-field>
                  <mat-label>End date</mat-label>
                  <input
                    matInput
                    [min]="
                      paymentHistoryForm?.value?.dateTimeInterval?.startDate
                    "
                    [matDatepicker]="pickerEndDate"
                    formControlName="endDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerEndDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #pickerEndDate
                    disabled="false"
                  ></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cols-3 align-items-center my-3">
          <div class="row">
            <button
              class="btn btn-primary m-2"
              [disabled]="paymentHistoryForm.pristine"
              (click)="handlePaymentHistoryFilters()"
            >
              Filter
            </button>

            <button
              class="btn btn-primary m-2"
              [disabled]="paymentHistoryForm.pristine"
              (click)="handlePaymentHistoryResetForm()"
            >
              Clear Filters
            </button>
          </div>
        </div>
      </form>
    </div>

    <h2 *ngIf="!paymentHistoryTransactions.length" class="text-center">
      No transactions found.
    </h2>

    <div *ngIf="paymentHistoryTransactions.length">
      <table class="table">
        <thead>
          <tr class="text-center">
            <th>Username</th>
            <th>Organisation</th>
            <th>Payment Attempt Date</th>
            <th>Amount Paid</th>
            <th>Transaction Type</th>
            <th>Payment Status</th>
            <th>Invoice Status</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let transaction of paymentHistoryTransactions"
            class="text-center"
          >
            <td>{{ transaction.ownerUsername || "-" }}</td>
            <td>{{ transaction.organizationName || "-" }}</td>
            <td>{{ (transaction.paymentAttemptDate | date) || "-" }}</td>
            <td>{{ transaction.amountPaid || "-" }}</td>
            <td>
              {{
                paymentHistoryTransactionTypeValues[transaction.transactionType]
              }}
            </td>
            <td>
              <i
                *ngIf="!transaction.isSuccess"
                style="font-size: 20px; color: red"
                class="material-icons"
                >cancel</i
              >
              <i
                *ngIf="transaction.isSuccess"
                style="font-size: 20px; color: green"
                class="material-icons"
                >check_circle</i
              >
            </td>
            <td>
              <i
                *ngIf="transaction.invoiceGenerated"
                style="font-size: 20px; color: green"
                class="material-icons"
                >check_circle</i
              >
              <i
                *ngIf="!transaction.invoiceGenerated"
                style="font-size: 20px; color: red"
                class="material-icons"
                >cancel</i
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container" *ngIf="paymentHistoryTotalPages">
        <div class="row justify-content-center">
          <button
            class="btn btn-primary"
            (click)="
              handlePaymentHistoryPageChange(paymentHistoryCurrentPage - 1)
            "
            [disabled]="paymentHistoryCurrentPage == 0"
          >
            Prev
          </button>

          <div class="mx-3">
            <select
              class="custom-select"
              [(ngModel)]="paymentHistoryCurrentPage"
              (ngModelChange)="handlePaymentHistoryPageSelect($event)"
            >
              <option
                *ngFor="
                  let _ of [].constructor(paymentHistoryTotalPages);
                  let i = index
                "
                [selected]="i === paymentHistoryCurrentPage"
                [value]="i"
              >
                {{ i + 1 }}
              </option>
            </select>
          </div>

          <button
            class="btn btn-primary"
            (click)="
              handlePaymentHistoryPageChange(paymentHistoryCurrentPage + 1)
            "
            [disabled]="
              paymentHistoryCurrentPage == paymentHistoryTotalPages - 1
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
