import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ReCaptchaModule} from "angular2-recaptcha";
import {FileUploadModule} from "ng2-file-upload";
import {TokenInterceptor} from "./shared/interceptors/token.interceptor";
import {ActivityDataComponent} from "./features/activity-data/activity-data.component";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {AthletesComponent} from "./features/athletes/athletes.component";
import {SaveAthleteComponent} from "./features/athletes/save-athlete.component";
import {DashboardComponent} from "./features/dashboard/dashboard.component";
import {ExercisesComponent} from "./features/exercises/exercises.component";
import {SaveExerciseComponent} from "./features/exercises/save-exercise/save-exercise.component";
import {ForgotPasswordComponent} from "./features/forgot-password/forgot-password.component";
import {LoginComponent} from "./features/login/login.component";
import {OverviewComponent} from "./features/overview/overview.component";
import {PlaylistsComponent} from "./features/playlists/playlists.component";
import {PrivacyPolicyComponent} from "./features/privacy-policy/privacy-policy.component";
import {RegisterComponent} from "./features/register/register.component";
import {ReportsComponent} from "./features/reports/reports.component";
import {ResetPasswordComponent} from "./features/reset-password/reset-password.component";
import {ResultsComponent} from "./features/results/results.component";
import {SelectLocationsComponent} from "./features/select-locations/select-locations.component";
import {SettingsComponent} from "./features/settings/settings.component";
import {TermsAndConditionsComponent} from "./features/terms-and-conditions/terms-and-conditions.component";
import {SaveWorkoutComponent} from "./features/workouts/save-workout.component";
import {WorkoutsComponent} from "./features/workouts/workouts.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SharedModule} from "./shared/shared.module";
import {AgilityRatingComponent} from "./features/athletes/agility-rating/agility-rating.component";
import {SavePairedExerciseComponent} from "./features/exercises/save-paired-exercise/save-paired-exercise.component";
import {SyncExerciseComponent} from "./features/exercises/sync-exercise/sync-exercise.component";
import {SyncWorkoutComponent} from "./features/workouts/sync-workout/sync-workout.component";
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef,} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NoLocationWhitelistedComponent} from "./features/no-location-whitelisted/no-location-whitelisted.component";
import {MatButtonModule} from "@angular/material/button";
import {TermsOfSaleComponent} from "./features/terms-of-sale/terms-of-sale.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {SettingsModule} from "./features/settings/settings.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CategoryExercisesComponent} from "./features/exercises/category-exercises/category-exercises.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {ExerciseTypeComponent} from "./features/exercises/exercise-form/exercise-type/exercise-type.component";
import {VerticalExerciseFormComponent} from "./features/exercises/exercise-form/vertical-exercise-form/vertical-exercise-form.component";
import {CountdownsFormComponent} from "./features/exercises/exercise-form/countdowns-form/countdowns-form.component";
import {ReactExerciseFormComponent} from "./features/exercises/exercise-form/react-exercise-form/react-exercise-form.component";
import {CountExerciseFormComponent} from "./features/exercises/exercise-form/count-exercise-form/count-exercise-form.component";
import {SequenceExerciseFormComponent} from "./features/exercises/exercise-form/sequence-exercise-form/sequence-exercise-form.component";
import {AgilityExerciseFormComponent} from "./features/exercises/exercise-form/agility-exercise-form/agility-exercise-form.component";
import {ExerciseFormComponent} from "./features/exercises/exercise-form/exercise-form.component";
import {SelectExerciseCategoryComponent} from "./shared/components/select-exercise-category/select-exercise-category.component";
import {MatRippleModule} from "@angular/material/core";
import {
    AgilityArrowIconComponent
} from "./features/exercises/exercise-form/agility-exercise-form/agility-icon/agility-arrow-icon.component";
import {ExerciseConfigFormComponent} from "./features/exercises/exercise-form/exercise-config-form/exercise-config-form.component";
import {PairedExerciseFormComponent} from "./features/exercises/paired-exercise-form/paired-exercise-form.component";
import {MatTabsModule} from "@angular/material/tabs";
import {
    MostActiveExerciseCategoriesComponent
} from "./features/dashboard/most-active-exercise-categories/most-active-exercise-categories.component";
import {MatStepperModule} from "@angular/material/stepper";
import {CdkDrag} from "@angular/cdk/drag-drop";
import {MatListModule} from "@angular/material/list";
import {SelectBodyPartComponent} from "./features/exercises/exercise-form/select-body-part/select-body-part.component";
import {SportsComponent} from "./features/sports/sports.component";
import {InjuriesComponent} from "./features/injuries/injuries.component";

@NgModule({
  declarations: [
    AppComponent,
    AthletesComponent,
    RegisterComponent,
    LoginComponent,
    SettingsComponent,
    PlaylistsComponent,
    ExercisesComponent,
    WorkoutsComponent,
    SyncWorkoutComponent,
    SyncExerciseComponent,
    SaveAthleteComponent,
    SaveExerciseComponent,
    AgilityRatingComponent,
    SavePairedExerciseComponent,
    SaveWorkoutComponent,
    DashboardComponent,
    ResultsComponent,
    ReportsComponent,
    ActivityDataComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    SelectLocationsComponent,
    OverviewComponent,
    NoLocationWhitelistedComponent,
    TermsOfSaleComponent,
    CategoryExercisesComponent,
    ExerciseTypeComponent,
    VerticalExerciseFormComponent,
    CountdownsFormComponent,
    ReactExerciseFormComponent,
    CountExerciseFormComponent,
    SequenceExerciseFormComponent,
    AgilityExerciseFormComponent,
    ExerciseFormComponent,
    SelectExerciseCategoryComponent,
    AgilityArrowIconComponent,
    ExerciseConfigFormComponent,
    PairedExerciseFormComponent,
    MostActiveExerciseCategoriesComponent,
    SelectBodyPartComponent,
    SportsComponent,
      InjuriesComponent
  ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReCaptchaModule,
        FileUploadModule,
        SharedModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatAutocompleteModule,
        SettingsModule,
        MatMenuModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSnackBarModule,
        NgOptimizedImage,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatRippleModule,
        MatTabsModule,
        MatStepperModule,
        CdkDrag,
        MatListModule,
    ],
  providers: [
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        centerVertically: true,
      },
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
  exports: [SelectLocationsComponent],
})
export class AppModule {}
