import {Component, Input} from "@angular/core";
import {IAthletesCategoryForm} from "../../../shared/pipes/athletesGroupBy.pipe";
import {AthleteBlockComponent} from "../athlete-block/athlete-block.component";
import {NgForOf} from "@angular/common";

@Component({
  selector: "app-athletes-category",
  templateUrl: "./athletes-category.component.html",
  styleUrls: ["./athletes-category.component.scss"],
  imports: [
    AthleteBlockComponent,
    NgForOf
  ],
  standalone: true
})
export class AthletesCategoryComponent {
  @Input() athletesCategory: IAthletesCategoryForm;
}
