<div class="absolute top-0 left-0 w-full flex items-center justify-center bg-black" style="z-index: 99999">
    <span class="text-white font-bold">
        You are currently using the new version of QuickBoard. If you have not yet migrated to our new platform, <a
            href="https://app.thequickboard.com/register" class="color-primary">click here</a> to continue using the old version of the website.
    </span>
</div>
<div
  *ngIf="qbSubscriptions"
  class="lg:h-screen w-full flex justify-center items-center bg-white"
>
  <div class="w-full lg:h-screen">
    <div
      class="pb-[12rem] lg:pb-0 w-full h-full relative"
      *ngIf="!isLoading"
    >
      <mat-stepper
        [linear]="true"
        class="max-w-[1200px] mx-auto overflow-y-auto"
        [orientation]="this.innerWidth > 1024 ? 'horizontal' : 'vertical'"
      >
        <mat-step label="Choose your subscription">
          <h1 class="font-weight-bold text-center p-3">Order Details</h1>
          <div
            class="w-full flex justify-center flex-column items-center gap-3 mb-5"
          >
            <mat-button-toggle-group
              value="italic"
              class="flex items-center justify-center"
            >
              <mat-button-toggle
                value="italic"
                (click)="selectUserType(subscriptionUserTypes.Performance)"
              >
                <div
                  class="flex justify-center items-center w-[100px] lg:w-[200px] drop-shadow font-medium"
                >
                  Performance
                </div>
              </mat-button-toggle>
              <mat-button-toggle
                value="underline"
                (click)="selectUserType(subscriptionUserTypes.Clinic)"
              >
                <div
                  class="flex justify-center items-center w-[100px] lg:w-[200px] drop-shadow font-medium"
                >
                  Clinic
                </div></mat-button-toggle
              >
            </mat-button-toggle-group>
            <span>
              Available only on iPad (9th gen or later recommended).
            </span>
          </div>
          <div class="d-flex flex-wrap mb-5">
            <div
              *ngFor="
                let subscription of qbSubscriptions.selectedSubscriptionType
                  .options;
                let i = index
              "
              class="p-2 computer seven tablet sixteen mobile column flex-center subscription-item h-[500px]"
              (click)="selectSubscription(subscription)"
            >
              <div
                class="sub-container cursor-pointer"
                [class.selected]="subscription.selected"
              >
                <div class="flex items-center justify-center">
                  <img
                    class="w-full h-auto px-4"
                    [src]="subscription.imageUrl"
                  />
                </div>
                <div class="content text-center flex flex-center">
                  <a class="header"
                    ><b>{{ subscription.name }}</b></a
                  >
                  <div
                    class="meta"
                    *ngIf="
                      qbSubscriptions.selectedSubscriptionType.type ===
                      subscriptionUserTypes.Performance
                    "
                  >
                    <span
                      ><b>{{ subscription.athleteLimit }} </b></span
                    ><span>athletes</span>
                  </div>
                  <div
                    class="meta"
                    *ngIf="
                      qbSubscriptions.selectedSubscriptionType.type ===
                      subscriptionUserTypes.Clinic
                    "
                  >
                    <span
                      ><b>{{ subscription.athleteLimit }} </b></span
                    ><span>patients</span>
                  </div>
                  <div class="meta" *ngIf="subscription.price > -1">
                    <span class="date"
                      ><b>${{ subscription.price }}</b> / month</span
                    >
                  </div>
                  <div
                    class="description"
                    *ngIf="subscription.athleteLimit === -1"
                  >
                    {{ subscription.description }}
                    <p><b>100+ Athletes</b></p>
                    <p>
                      <b
                        ><a href="mailto:daniel@thequickboard.eu"
                          ><button class="ui primary button">
                            Contact us
                          </button></a
                        ></b
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center subscription-buttons"
                  *ngIf="subscription.selected"
                >
                  <div class="ui buttons">
                    <button
                      class="ui button"
                      matRipple
                      [class.selected-duration]="
                        qbSubscriptions.billingPeriod ===
                        subscriptionBillingPeriod.Monthly
                      "
                      (click)="
                        selectBillingPeriod(subscriptionBillingPeriod.Monthly)
                      "
                    >
                      Monthly
                    </button>
                    <div class="or" data-text="or"></div>
                    <button
                      class="ui button"
                      matRipple
                      [class.selected-duration]="
                        qbSubscriptions.billingPeriod ===
                        subscriptionBillingPeriod.Annually
                      "
                      (click)="
                        selectBillingPeriod(subscriptionBillingPeriod.Annually)
                      "
                    >
                      Annually
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-column flex-md-row">
            <ng-container *ngFor="let product of productsList; let i = index">
              <div
                class="p-2 five computer seven tablet sixteen mobile column"
                [class]="{ 'right floated': i % 3 != 0 }"
              >
                <div class="product-image-wrapper">
                  <img
                    src="assets/images/HD%20Pro-min.png"
                    class="ui small image mx-auto"
                    [alt]="product.name"
                    *ngIf="i === 0"
                  />
                  <img
                    src="assets/images/HD%20Pro-min.png"
                    class="ui small image mx-auto"
                    [alt]="product.name"
                    *ngIf="i === 1"
                  />
                  <img
                    [src]="product.imageUrl"
                    class="ui small image mx-auto"
                    [alt]="product.name"
                    *ngIf="i > 1"
                  />
                </div>

                <div
                  class="field image-product inline-display left-floated max-250 mx-auto"
                >
                  <label class="big-label text-center"
                    >{{ product.name }}:
                    <input
                      class="form-control form-control-lg"
                      type="number"
                      min="0"
                      max="10000"
                      [(ngModel)]="product.quantity"
                      #quantity
                      (ngModelChange)="
                        orderDetails.productsChanged(
                          productsList,
                          this.shippingInformationForm.controls.country.value
                        )
                      "
                      (keypress)="omit_special_char($event)"
                      (input)="
                        quantity.value =
                          quantity.value > 10000
                            ? 10000
                            : quantity.value < 0
                              ? 0
                              : quantity.value
                      "
                  /></label>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="five wide computer seven tablet sixteen mobile right floated column"
            *ngIf="productList?.length % 3 == 1 || productList?.length % 3 == 2"
          ></div>
          <div class="p-3 mx-auto" style="max-width: 35rem">
            <div class="mt-5 w-100">
              <div class="w-100" id="coupon-button">
                <input
                  id="form-coupon"
                  class="form-control form-control-lg d-block mx-auto"
                  type="text"
                  [(ngModel)]="couponCode"
                  (keydown.enter)="('')"
                />

                <button
                  *ngIf="orderDetails.activeCoupon"
                  class="btn btn-warning btn-lg mx-auto mt-5 w-100"
                  (click)="removeCoupon()"
                >
                  {{ "REMOVE " + orderDetails.activeCoupon.code }}
                </button>

                <button
                  *ngIf="!orderDetails.activeCoupon"
                  class="btn btn-secondary btn-lg mx-auto mt-5 w-100"
                  [disabled]="!couponCode"
                  (click)="checkCouponValidity()"
                >
                  ADD COUPON
                </button>
              </div>

              <ng-container *ngIf="couponMessageCode === 0">
                <span class="green"
                  ><i class="big check circle icon"></i> Coupon applied
                  successfully.</span
                >
              </ng-container>

              <ng-container *ngIf="couponMessageCode === 1">
                <span class="red"
                  ><i class="big times circle icon"></i> Invalid coupon!</span
                >
              </ng-container>

              <ng-container *ngIf="couponMessageCode === 2">
                <span class="orange"
                  ><i class="big minus circle icon"></i> The coupon is only
                  applicable to annual subscriptions.</span
                >
              </ng-container>

              <ng-container *ngIf="couponMessageCode === 3">
                <span class="orange"
                  ><i class="big minus circle icon"></i> You can not be apply
                  multiple coupons for the same product.</span
                >
              </ng-container>
            </div>

            <div class="center-buttons mt-5">
              <button
                mat-flat-button
                color="primary"
                matStepperNext
                class="w-100"
                [disabled]="!qbSubscriptions.selectedSubscriptionOption"
                (click)="currentStep = 2"
              >
                CONTINUE
              </button>
            </div>
      </div>
            <div class="flex justify-center items-center w-full py-5">
                <a routerLink="/privacy-policy" target="_blank" style="font-size: 20px"
                >Privacy Policy</a
                >
                <a
                        href="assets/pdf/Quick%20Board%20Privacy%20Policy.pdf"
                        style="margin-left: 10px"
                ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
                ></a
                >
            </div>
        </mat-step>
        <mat-step
          [stepControl]="personalDetailsForm"
          label="Fill out your personal details"
        >
          <h1 class="font-weight-bold text-center p-3">Personal Details</h1>
          <form
            autocomplete="off"
            class="p-3 mx-auto flex flex-col gap-5 max-w-[35rem]"
            [formGroup]="personalDetailsForm"
            (submit)="personalDetailsForm.markAllAsTouched()"
          >
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>First name</mat-label>
              <input
                matInput
                type="text"
                formControlName="firstName"
                name="firstName"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['firstName']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["firstName"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Last name</mat-label>
              <input
                matInput
                type="text"
                formControlName="lastName"
                name="lastName"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['lastName']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["lastName"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Username</mat-label>

              <input
                matInput
                type="text"
                autocomplete="do-not-autofill"
                formControlName="username"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['username']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["username"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="email"
                autocomplete="do-not-autofill"
                formControlName="email"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['email']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["email"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full pb-5" appearance="outline">
              <mat-label>Password</mat-label>

              <input
                matInput
                type="password"
                autocomplete="do-not-autofill"
                formControlName="password"
              />
              <mat-hint
                >(one number, one lowercase, one uppercase, one special
                character)</mat-hint
              >
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['password']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["password"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full pb-5" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                type="password"
                name="passwordConfirm"
                formControlName="confirmPassword"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['confirmPassword']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["confirmPassword"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Organization Name</mat-label>
              <input
                matInput
                type="text"
                name="organizationName"
                formControlName="organizationName"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    personalDetailsForm.controls['organizationName']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    personalDetailsForm.controls["organizationName"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <div class="center-buttons">
              <button
                mat-flat-button
                matStepperNext
                color="primary"
                class="w-full mt-3"
              >
                CONTINUE
              </button>
            </div>
          </form>
            <div class="flex justify-center items-center w-full py-5">
                <a routerLink="/privacy-policy" target="_blank" style="font-size: 20px"
                >Privacy Policy</a
                >
                <a
                        href="assets/pdf/Quick%20Board%20Privacy%20Policy.pdf"
                        style="margin-left: 10px"
                ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
                ></a
                >
            </div>

        </mat-step>
        <mat-step [stepControl]="addressForm" label="Fill out your address">
          <h1 class="font-weight-bold text-center p-3">Address</h1>
          <form
            class="p-3 mx-auto flex flex-col gap-5 max-w-[35rem]"
            [formGroup]="addressForm"
            (ngSubmit)="submitStep3()"
          >
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country">
                <mat-option
                  *ngFor="let country of countriesList"
                  [value]="country"
                >
                  {{ country.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    addressForm.controls['country']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["country"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>State/Province</mat-label>
              <mat-select formControlName="state">
                <mat-option
                  *ngFor="let state of formGeographyData.personalAddress.states"
                  [value]="state.id"
                >
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!addressForm.controls['state'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["state"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="!p-0 !m-0 w-full relative">
              <mat-label>City</mat-label>
              <input
                type="text"
                matInput
                [formControl]="city"
                (change)="updateCity($event, city)"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option
                  *ngFor="
                    let option of formGeographyData?.personalAddress?.cities
                  "
                  [value]="option"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="!addressForm.controls['city'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["city"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Address</mat-label>
              <input type="text" matInput formControlName="address" />
              <mat-error *ngIf="!addressForm.controls['address'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["address"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Address 2</mat-label>
              <input matInput type="text" formControlName="address2" />
              <mat-error *ngIf="!addressForm.controls['address2'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["address2"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Postal code / Zipcode</mat-label>
              <input
                class="form-control form-control-lg"
                matInput
                type="text"
                formControlName="zipCode"
              />
              <mat-error *ngIf="!addressForm.controls['zipCode'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["zipCode"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Phone number</mat-label>
              <input type="tel" matInput formControlName="phone" />
              <mat-error *ngIf="!addressForm.controls['phone'].valid">
                {{
                  ValidationHelperService.getErrorMessage(
                    addressForm.controls["phone"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <div class="center-buttons">
              <button
                mat-flat-button
                matStepperNext
                color="primary"
                class="w-full mt-3"
              >
                CONTINUE
              </button>
            </div>
          </form>
            <div class="flex justify-center items-center w-full py-5">
                <a routerLink="/privacy-policy" target="_blank" style="font-size: 20px"
                >Privacy Policy</a
                >
                <a
                        href="assets/pdf/Quick%20Board%20Privacy%20Policy.pdf"
                        style="margin-left: 10px"
                ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
                ></a
                >
            </div>

        </mat-step>
        <mat-step
          [stepControl]="shippingInformationForm"
          label="Fill out your shipping information"
        >
          <h1 class="font-weight-bold text-center p-3">Shipping Information</h1>
          <form
            class="p-3 mx-auto flex flex-col gap-5 max-w-[35rem]"
            [formGroup]="shippingInformationForm"
          >
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput type="text" formControlName="firstName" />
              <mat-error
                *ngIf="!shippingInformationForm.controls['firstName'].valid"
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["firstName"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Last Name</mat-label>

              <input type="text" matInput formControlName="lastName" />
              <mat-error
                *ngIf="!shippingInformationForm.controls['lastName'].valid"
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["lastName"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Shipping Address</mat-label>
              <input type="text" matInput formControlName="address" />
              <mat-error
                *ngIf="!shippingInformationForm.controls['address'].valid"
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["address"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Shipping Address 2</mat-label>
              <input matInput type="text" formControlName="address2" />
              <mat-error
                *ngIf="!shippingInformationForm.controls['address2'].valid"
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["address2"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Shipping Country</mat-label>
              <mat-select formControlName="country">
                <mat-option
                  *ngFor="let country of countriesList"
                  [value]="country"
                >
                  {{ country?.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    shippingInformationForm.controls['country']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["country"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Shipping City</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="shippingCity"
                    (change)="updateCity($event, shippingCity)"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option
                            *ngFor="
                    let option of formGeographyData?.shippingAddress?.cities
                    " [value]="option"
                    >
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="ValidationHelperService.isInvalid(city)">
                    {{ ValidationHelperService.getErrorMessage(city) }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Shipping State</mat-label>
              <mat-select formControlName="state">
                <mat-option
                  *ngFor="let state of formGeographyData.shippingAddress.states"
                  [value]="state?.id"
                >
                  {{ state?.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    shippingInformationForm.controls['state']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["state"]
                  )
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Shipping Zip Code</mat-label>
              <input
                matInput
                type="text"
                formControlName="zipCode"
              />
              <mat-error
                *ngIf="
                  ValidationHelperService.isInvalid(
                    shippingInformationForm.controls['zipCode']
                  )
                "
              >
                {{
                  ValidationHelperService.getErrorMessage(
                    shippingInformationForm.controls["zipCode"]
                  )
                }}
              </mat-error>
            </mat-form-field>
          </form>

            <div>
            <h3 class="font-weight-bold text-center p-3">
              Billing Information
            </h3>
            <form
              class="p-3 mx-auto flex flex-col gap-5 max-w-[35rem]"
              [formGroup]="billingInformationForm"
            >
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>First Name on Account</mat-label>
                <input matInput type="text" formControlName="firstName" />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingInformationForm.controls['firstName']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingInformationForm.controls["firstName"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Last Name on Account</mat-label>
                <input matInput type="text" formControlName="lastName" />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingInformationForm.controls['lastName']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingInformationForm.controls["lastName"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Card Number</mat-label>
                <input
                  matInput
                  (keypress)="omit_special_char($event)"
                  formControlName="cardNumber"
                />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingInformationForm.controls['cardNumber']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingInformationForm.controls["cardNumber"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Expiration Month</mat-label>
                <mat-select
                  [(ngModel)]="selectedMonth"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option *ngFor="let month of monthVector" [value]="month">
                    {{ month }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>CVV</mat-label>
                <input type="text" matInput formControlName="CVV" />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingInformationForm.controls['CVV']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingInformationForm.controls["CVV"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Expiration Year</mat-label>
                <mat-select
                  [(ngModel)]="selectedYear"
                  (change)="validateDate()"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option *ngFor="let year of yearVector" [value]="year">
                    {{ year }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
          <div>
            <h3 class="font-weight-bold text-center p-3">Billing Address</h3>

            <form
              class="p-3 mx-auto flex flex-col gap-5 max-w-[35rem]"
              [formGroup]="billingAddressForm"
            >
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing Address</mat-label>
                <input matInput type="text" formControlName="address" />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingAddressForm.controls['address']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingAddressForm.controls["address"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing Address 2</mat-label>
                <input matInput type="text" formControlName="address2" />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingAddressForm.controls['address2']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingAddressForm.controls["address2"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing Country</mat-label>
                <mat-select formControlName="country">
                  <mat-option
                    *ngFor="let country of countriesList"
                    [value]="country"
                  >
                    {{ country?.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingAddressForm.controls['country']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingAddressForm.controls["country"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing City</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="billingCity"
                  (change)="updateCity($event, billingCity)"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option
                    *ngFor="
                      let option of formGeographyData.billingAddress.cities
                    "
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="ValidationHelperService.isInvalid(billingCity)"
                >
                  {{ ValidationHelperService.getErrorMessage(billingCity) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing State</mat-label>
                <mat-select formControlName="state">
                  <mat-option
                    *ngFor="
                      let state of formGeographyData.billingAddress.states
                    "
                    [value]="state?.id"
                  >
                    {{ state?.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingAddressForm.controls['state']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingAddressForm.controls["state"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Billing Zip Code</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="zipCode"
                />
                <mat-error
                  *ngIf="
                    ValidationHelperService.isInvalid(
                      billingAddressForm.controls['zipCode']
                    )
                  "
                >
                  {{
                    ValidationHelperService.getErrorMessage(
                      billingAddressForm.controls["zipCode"]
                    )
                  }}
                </mat-error>
              </mat-form-field>
            </form>
          </div>
          <div class="mx-auto p-3" style="max-width: 35rem">
            <mat-checkbox
              [checked]="termsAgreed"
              (change)="termsAgreed = !termsAgreed"
              class="flex justify-center items-center gap-1"
            >
              <span> I have read and accepted the </span>
              <a class="clickable" (click)="openTermsModal()"
                >Terms and Conditions</a
              >
              <a href="assets/pdf/Quick%20Board%20Terms.pdf"
                ><mat-icon class="!text-xl pt-[6px] ml-[5px]"
                  >cloud_download</mat-icon
                ></a
              >
            </mat-checkbox>

            <div class="center-buttons full-width mt-5">
              <button
                class="w-full"
                mat-flat-button
                matStepperNext
                color="primary"
                [disabled]="
                  !billingAddressForm.valid ||
                  !billingInformationForm.valid ||
                  !shippingInformationForm.valid ||
                  !termsAgreed
                "
                (click)="register()"
              >
                PLACE ORDER
              </button>
            </div>
          </div>
            <div class="flex justify-center items-center w-full py-5">
                <a routerLink="/privacy-policy" target="_blank" style="font-size: 20px"
                >Privacy Policy</a
                >
                <a
                        href="assets/pdf/Quick%20Board%20Privacy%20Policy.pdf"
                        style="margin-left: 10px"
                ><i style="font-size: 20px" class="material-icons"
                >cloud_download</i
                ></a
                >
            </div>

        </mat-step>

      </mat-stepper>
    </div>
    <div
      class="w-full h-screen flex items-center justify-center"
      *ngIf="isLoading"
    >
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>
<div class="summary !z-40" *ngIf="innerWidth >= 1024" cdkDrag>
    <mat-expansion-panel expanded class="[&_.mat-expansion-panel-body]:p-0">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex gap-3 items-center">
                    <mat-icon> drag_handle </mat-icon>
                    <span>Plan Summary</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <table class="ui unstackable table">
            <tbody class="body" [class]="{ 'mobile-collapsed': collapsed }">
            <!--TODO: change texts when expanded/collapsed-->
            <tr *ngIf="qbSubscriptions.selectedSubscriptionOption">
                <td class="no-border" colspan="1">
                    <p>
                        <b>{{ qbSubscriptions.selectedSubscriptionOption.name }}</b>
                        Plan
                    </p>
                </td>
                <td class="no-border" colspan="1">
                    <ng-container
                            *ngIf="
                      orderDetails.activeCoupon &&
                        orderDetails.activeCoupon.discountType ===
                          discountType.Days;
                      else subscriptionPrice
                    "
                    >
                        <p>${{ 0 | format: 2 }}</p>
                    </ng-container>
                    <ng-template #subscriptionPrice>
                        <p>${{ orderDetails.subscriptionPrice | format: 2 }}</p>
                    </ng-template>
                </td>
            </tr>
            <ng-container *ngFor="let product of productsList">
                <tr *ngIf="product.quantity > 0">
                    <td class="no-border">
                        <p>
                            <b>{{ product.quantity }}</b> {{ product.name
                            }}<span *ngIf="product.quantity > 1">s</span>
                        </p>
                    </td>
                    <td class="no-border">
                        <p>${{ product.quantity * product.price | format: 2 }}</p>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="orderDetails.shippingCost">
                <tr>
                    <td class="no-border">
                        <span>Shipping</span>

                        <button
                                class="shipping-info"
                                mat-icon-button
                                matTooltip="Rate excludes taxes, duties and other charges that may apply to the shipment. Final costs are determined by customs authorities at the time of import."
                        >
                            <mat-icon>info</mat-icon>
                        </button>
                    </td>
                    <td class="no-border">${{ orderDetails.shippingCost }}</td>
                </tr>
            </ng-container>

            <tr *ngIf="orderDetails.activeCoupon">
                <td class="no-border bold-text">
                    Coupon {{ orderDetails.activeCoupon.code }}
                </td>
                <td
                        class="no-border bold-text"
                        *ngIf="
                    orderDetails.activeCoupon.discountType ===
                    discountType.FixedAmount
                  "
                >
                    -${{ orderDetails.activeCoupon.discount | format: 2 }}
                </td>
                <td
                        class="no-border bold-text"
                        *ngIf="
                    orderDetails.activeCoupon.discountType ===
                    discountType.Percentage
                  "
                >
                    -{{ orderDetails.activeCoupon.discount }}%
                </td>
                <td
                        class="no-border bold-text"
                        *ngIf="
                    orderDetails.activeCoupon.discountType === discountType.Days
                  "
                >
                    -{{ orderDetails.activeCoupon.discount }} free days
                </td>
            </tr>
            <ng-container
                    *ngIf="
                  orderDetails.discounts &&
                  (!orderDetails.activeCoupon ||
                    (orderDetails.activeCoupon &&
                      orderDetails.activeCoupon.discountType !==
                        discountType.Days))
                "
            >
                <tr>
                    <td class="no-border bold-text">Discounts</td>
                    <td class="no-border bold-text">
                        -${{ orderDetails.discounts | format: 2 }}
                    </td>
                </tr>
            </ng-container>
            </tbody>
            <tfoot>
            <tr>
                <th class="no-border qb-green-text centered-text" colspan="2">
                    <p class="center-text">
                        Total<br />
                        <ng-container
                        >${{ orderDetails.totalPrice | format: 2 }}
                        </ng-container>
                    </p>
                </th>
            </tr>

            <tr
                    *ngIf="
                  orderDetails.activeCoupon?.discountType ===
                    discountType.Days && orderDetails.activeCoupon.expiresOn
                "
            >
                <th class="no-border qb-green-text centered-text" colspan="2">
                    <p class="center-text">
                        Free trial will expire on<br />
                        {{ orderDetails.activeCoupon.expiresOn | date }} ( ${{
                            orderDetails.subscriptionPrice - orderDetails.discounts
                                | format: 2
                        }}
                        )
                    </p>
                </th>
            </tr>
            </tfoot>
        </table>
    </mat-expansion-panel>
</div>
<div
        class="fixed bottom-0 block lg:hidden w-full bg-white flex flex-col-reverse justify-center items-center !z-40"
>
    <div
            class="flex items-center justify-content-between p-3 w-full bg-white"
    >
        <span class="font-medium drop-shadow">Plan Summary</span>
        <button mat-icon-button (click)="collapsed = !collapsed">
            <mat-icon>
                {{ collapsed ? "keyboard_arrow_down" : "keyboard_arrow_up" }}
            </mat-icon>
        </button>
    </div>
    <table class="ui unstackable table !mt-0" *ngIf="collapsed">
        <tbody class="body" [class]="{ 'mobile-collapsed': collapsed }">
        <!--TODO: change texts when expanded/collapsed-->
        <tr *ngIf="qbSubscriptions.selectedSubscriptionOption">
            <td class="no-border" colspan="1">
                <p>
                    <b>{{ qbSubscriptions.selectedSubscriptionOption.name }}</b>
                    Plan
                </p>
            </td>
            <td class="no-border" colspan="1">
                <ng-container
                        *ngIf="
                    orderDetails.activeCoupon &&
                      orderDetails.activeCoupon.discountType ===
                        discountType.Days;
                    else subscriptionPrice
                  "
                >
                    <p>${{ 0 | format: 2 }}</p>
                </ng-container>
                <ng-template #subscriptionPrice>
                    <p>${{ orderDetails.subscriptionPrice | format: 2 }}</p>
                </ng-template>
            </td>
        </tr>
        <ng-container *ngFor="let product of productsList">
            <tr *ngIf="product.quantity > 0">
                <td class="no-border">
                    <p>
                        <b>{{ product.quantity }}</b> {{ product.name
                        }}<span *ngIf="product.quantity > 1">s</span>
                    </p>
                </td>
                <td class="no-border">
                    <p>${{ product.quantity * product.price | format: 2 }}</p>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="orderDetails.shippingCost">
            <tr>
                <td class="no-border">
                    <span>Shipping</span>

                    <button
                            class="shipping-info"
                            mat-icon-button
                            matTooltip="Rate excludes taxes, duties and other charges that may apply to the shipment. Final costs are determined by customs authorities at the time of import."
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
                <td class="no-border">${{ orderDetails.shippingCost }}</td>
            </tr>
        </ng-container>

        <tr *ngIf="orderDetails.activeCoupon">
            <td class="no-border bold-text">
                Coupon {{ orderDetails.activeCoupon.code }}
            </td>
            <td
                    class="no-border bold-text"
                    *ngIf="
                  orderDetails.activeCoupon.discountType ===
                  discountType.FixedAmount
                "
            >
                -${{ orderDetails.activeCoupon.discount | format: 2 }}
            </td>
            <td
                    class="no-border bold-text"
                    *ngIf="
                  orderDetails.activeCoupon.discountType ===
                  discountType.Percentage
                "
            >
                -{{ orderDetails.activeCoupon.discount }}%
            </td>
            <td
                    class="no-border bold-text"
                    *ngIf="
                  orderDetails.activeCoupon.discountType === discountType.Days
                "
            >
                -{{ orderDetails.activeCoupon.discount }} free days
            </td>
        </tr>
        <ng-container
                *ngIf="
                orderDetails.discounts &&
                (!orderDetails.activeCoupon ||
                  (orderDetails.activeCoupon &&
                    orderDetails.activeCoupon.discountType !==
                      discountType.Days))
              "
        >
            <tr>
                <td class="no-border bold-text">Discounts</td>
                <td class="no-border bold-text">
                    -${{ orderDetails.discounts | format: 2 }}
                </td>
            </tr>
        </ng-container>
        </tbody>
        <tfoot>
        <tr>
            <th class="no-border qb-green-text centered-text" colspan="2">
                <p class="center-text">
                    Total<br />
                    <ng-container
                    >${{ orderDetails.totalPrice | format: 2 }}
                    </ng-container>
                </p>
            </th>
        </tr>

        <tr
                *ngIf="
                orderDetails.activeCoupon?.discountType === discountType.Days &&
                orderDetails.activeCoupon.expiresOn
              "
        >
            <th class="no-border qb-green-text centered-text" colspan="2">
                <p class="center-text">
                    Free trial will expire on<br />
                    {{ orderDetails.activeCoupon.expiresOn | date }} ( ${{
                        orderDetails.subscriptionPrice - orderDetails.discounts
                            | format: 2
                    }}
                    )
                </p>
            </th>
        </tr>
        </tfoot>
    </table>
</div>
