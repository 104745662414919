<form>
  <div class="card w-100 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
    <div class="p-3 border-b font-medium flex justify-between items-center">
      <span>Exercise leaderboard</span>
      <mat-button-toggle-group
        name="fontStyle"
        aria-label="Font Style"
        [formControl]="timeFrame"
        (change)="setTimeRange()"
      >
        <mat-button-toggle [value]="ETimeFilter.Today">Today</mat-button-toggle>
        <mat-button-toggle [value]="ETimeFilter.LastWeek">Last week</mat-button-toggle>
        <mat-button-toggle [value]="ETimeFilter.LastMonth">Last month</mat-button-toggle>
        <mat-button-toggle [value]="ETimeFilter.AllTime">All time</mat-button-toggle>
      </mat-button-toggle-group>
        <button mat-flat-button color="primary"(click)="openSelectExerciseConfigModal()">Select exercise</button>
    </div>
    <div class="p-3 relative max-h-[800px] overflow-auto">
        <div *ngIf="!isLoading">
            <div class="relative overflow-x-auto" *ngIf="leaderboardRows.length > 0">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Athlete name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Average
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Average Comparison
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Best
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Best Comparison
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Number of sets
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-white border-b" *ngFor="let leaderboardRow of leaderboardRows" >
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                {{leaderboardRow.athleteName}}
                            </th>
                            <td class="px-6 py-4">
                                {{leaderboardRow.average}}
                            </td>
                            <td class="px-6 py-4">
                                {{leaderboardRow.averageComparison}}
                            </td>
                            <td class="px-6 py-4">
                                {{leaderboardRow.best}}
                            </td>
                            <td class="px-6 py-4">
                                {{leaderboardRow.bestComparison}}
                            </td>
                            <td class="px-6 py-4">
                                {{leaderboardRow.numberOfSets}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            <div *ngIf="leaderboardRows.length === 0" class="h-[400px] w-full flex items-center justify-center">
                <span class="text-center text-gray-400">The selected exercise has no leaderboard. Please select another exercise</span>
            </div>
        </div>
      <div
        *ngIf="isLoading"
        class="w-full h-[400px] flex justify-center items-center"
      >
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</form>
