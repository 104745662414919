import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {monthVector, yearVector} from "../../core/helpers/calendar-data";
import {CustomValidators} from "../../core/helpers/custom-validators";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {AuthService} from "../../core/services/auth.service";
import {GeographyControllerService} from "../../core/api/controllers/geography-controller.service";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {NotificationService} from "../../core/services/notification.service";
import {ShopControllerService} from "../../core/api/controllers/shop-controller.service";
import {MatDialog} from "@angular/material/dialog";
import {
    SubscriptionPaymentDialogComponent
} from "../../shared/components/subscription-payment-dialog/subscription-payment-dialog.component";
import {AccountControllerService} from "../../core/api/controllers/account-controller.service";
import {SettingsService} from "../../core/services/settings.service";
import {ISettingsDTO} from "../../shared/DTOs/ISettingsDTO";
import {Router} from "@angular/router";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  providers: [ShopControllerService, GeographyControllerService],
})
export class SettingsComponent implements OnInit {
  image = null;
  profilePicture;
  settingsData: ISettingsDTO;
  isLoading;
  showQuickbooks = false;
  subscriptionPaymentForm: FormGroup;
  yearVector: string[];
  currentMonthVector: string[];
  selectedMonth: string;
  selectedYear: string;
  paymentData;

  constructor(
    public accountController: AccountControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private formBuilder: FormBuilder,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    public dialog: MatDialog,
    public settingsService: SettingsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.mixpanelService.track("settings_screen", {});
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.accountController.getUserSettings().subscribe(
      (response) => {
        this.settingsData = response;

        if (this.settingsData.username == "kevin") {
          this.showQuickbooks = true;
        }
        this.profilePicture = response.profilePictureUrl;
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  openSubscriptionPaymentModal() {
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.subscriptionPaymentForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, CustomValidators.nameValidator]],
        lastName: ["", [Validators.required, CustomValidators.nameValidator]],
        cardNumber: ["", [Validators.required, CustomValidators.cardValidator]],
        CVV: ["", [Validators.required]],
      },
      { validator: CustomValidators.variableCvv },
    );

    this.dialog.open(SubscriptionPaymentDialogComponent, {
      data: {
        response: this.paymentData,
      },
      width: "800px",
      height: "600px",
    });
  }

  get isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }
}
