import {Component, Input, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {IReactForm} from "../../../../shared/interfaces/forms/exercises/IReactForm";

export enum ENeurocognitiveType {
  Off = 0,
  Array = 1,
  GoNoGo = 2,
}

@Component({
  selector: "app-react-exercise-form",
  templateUrl: "./react-exercise-form.component.html",
  styleUrls: ["./react-exercise-form.component.scss"],
})
export class ReactExerciseFormComponent implements OnInit {
  @Input() _formGroup: FormGroup<IReactForm>;

  ngOnInit(): void {
    this.subscribeToNeurocognitiveTypeChange();
    this.subscribeToReactSensorsChange();
  }

  subscribeToNeurocognitiveTypeChange(): void {
    this.neurocognitiveType.valueChanges.subscribe((data) => {
      if (data === ENeurocognitiveType.Off) {
        this._formGroup
          .get("dontReactTo")
          .setValue([false, false, false, false, false, false]);
      }
      if (data === ENeurocognitiveType.GoNoGo) {
        this._formGroup.controls.timerPrompt.clearValidators();
        this._formGroup.controls.timerPrompt.addValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(10),
        ]);
        this._formGroup.controls.timerPrompt.updateValueAndValidity();
      } else {
        this._formGroup.controls.timerPrompt.clearValidators();
        this._formGroup.controls.timerPrompt.addValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(10),
        ]);
        this._formGroup.controls.timerPrompt.updateValueAndValidity();
      }
    });
  }

  subscribeToReactSensorsChange(): void {
    this.reactSensors.valueChanges.subscribe(() => {
      this.neurocognitiveType.updateValueAndValidity();
    });
  }

  getSensorValue(color, type): boolean {
    return type !== 2 ? this.react[color] : this.dontReact[color];
  }

  setReact(color: number, type: number) {
    this.toggleReact(color, type);

    if (type !== 2 && this.getSensorValue(color, 2)) {
      this.toggleReact(color, 2);
    } else if (type === 2 && this.getSensorValue(color, 1)) {
      this.toggleReact(color, 1);
    }

    this.neurocognitiveType.updateValueAndValidity();
  }

  toggleReact(color: number, type: number) {
    const typeKey = type !== 2 ? "reactTo" : "dontReactTo";
    if (typeKey === "reactTo") {
      this._formGroup.controls.reactTo.controls[color].setValue(
        !this._formGroup.get(typeKey).value[color],
      );
    } else if (typeKey === "dontReactTo") {
      this._formGroup.controls.dontReactTo.controls[color].setValue(
        !this._formGroup.get(typeKey).value[color],
      );
    }
  }

  setSensor(sensor: number) {
    this.reactSensors.controls[sensor].setValue(
      !this.reactSensors.controls[sensor].value,
    );
    this.isolatedReact.updateValueAndValidity();
  }

  resetReact() {
    this.reactSensors.controls.forEach((control) => control.setValue(true));
    this.reactSensors.updateValueAndValidity();
  }

  get neurocognitiveType(): FormControl {
    return this._formGroup.get("neurocognitiveType") as FormControl;
  }

  get isolatedReact(): FormControl {
    return this._formGroup.get("isolatedReact") as FormControl;
  }

  get react(): boolean[] {
    return this._formGroup.get("reactTo").value;
  }

  get dontReact(): boolean[] {
    return this._formGroup.get("dontReactTo").value;
  }

  get reactDelay(): FormControl {
    return this._formGroup.get("delay") as FormControl;
  }

  get timerPrompt(): FormControl {
    return this._formGroup.get("timerPrompt") as FormControl;
  }

  get reactSensors(): FormArray<FormControl<boolean>> {
    return this._formGroup.get("activeSensors") as FormArray<
      FormControl<boolean>
    >;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
