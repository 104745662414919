<div class="table-striped card w-100 p-3 mt-2 mt-sm-0 mr-sm-5 mb-2">
  <div *ngIf="!isLoading">
    <ng-container *ngFor="let location of locations; let i = index">
      <div
        class="flex justify-content-between items-center w-100 p-3 border-bottom"
      >
        {{ location.name }}
        <div>
          <button
            mat-flat-button
            color="primary"
            (click)="openWhitelistDialog(location)"
          >
            Edit
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
