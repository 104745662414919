import { EMeasurementSystem } from "../enums/EMeasurementSystem";

export interface IWeight {
  valueInKilograms: number;
  measurementSystem: EMeasurementSystem;
}

export class Weight {
  valueInKilograms: number;
  measurementSystem: EMeasurementSystem;

  constructor(weight: IWeight) {
    this.valueInKilograms = weight.valueInKilograms;
    this.measurementSystem = weight.measurementSystem;
  }

  public get isInMetric(): boolean {
    return this.measurementSystem === EMeasurementSystem.METRIC;
  }

  public get isInImperial(): boolean {
    return this.measurementSystem === EMeasurementSystem.IMPERIAL;
  }
}
