import { EMeasurementSystem } from "../enums/EMeasurementSystem";

export interface IHeight {
  valueInCentimeters: number | string;
  measurementSystem: EMeasurementSystem;
}

export class Height {
  valueInCentimeters: number | string;
  measurementSystem: EMeasurementSystem;

  constructor(height: IHeight) {
    this.valueInCentimeters = height.valueInCentimeters;
    this.measurementSystem = height.measurementSystem;
  }

  public get isInMetric(): boolean {
    return this.measurementSystem === EMeasurementSystem.METRIC;
  }

  public get isInImperial(): boolean {
    return this.measurementSystem === EMeasurementSystem.IMPERIAL;
  }

  toCm(): Height {
    if (this.isInMetric) {
      return this;
    }
    const value = this.valueInCentimeters as string;
    const feetAndInches = value.split("'");
    const feet = parseInt(feetAndInches[0]);
    const inches = parseInt(feetAndInches[1]);
    const cm = Math.round(feet * 30.48 + inches * 2.54);
    return new Height({
      valueInCentimeters: cm,
      measurementSystem: EMeasurementSystem.IMPERIAL,
    });
  }
}
