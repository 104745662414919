import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class NotUserGuard implements CanActivate {
  jwtHelper;

  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate() {
    const localToken = AuthService.getOauthTokenFromStorage();
    if (localToken !== null) {
      const token = this.jwtHelper.decodeToken(localToken);
      if (this.jwtHelper.isTokenExpired(localToken, 0)) {
        AuthService.clearStorageData();
        return true;
      }
      if (token.roles != null) {
        return token.roles.includes("User") ? this.refuse() : true;
      }
    }
    return true;
  }

  refuse(): boolean {
    this.router.navigate(["/"]);
    return false;
  }
}
