import {Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators,} from "@angular/forms";
import {from, Observable, of, throwError} from "rxjs/index";
import {catchError, switchMap, tap} from "rxjs/internal/operators";
import {map} from "rxjs/operators";
import {AddressType, DiscountType, SubscriptionBillingPeriod, SubscriptionUserType,} from "../../core/constants/subscription.const";
import {monthVector, yearVector} from "../../core/helpers/calendar-data";
import {CustomValidators} from "../../core/helpers/custom-validators";
import {Country, FormGeographyData, IGeographicalEntity,} from "../../core/models/geography.model";
import {Coupon, OrderDetails, Product} from "../../core/models/product.model";
import {RegistrationPayload} from "../../core/models/register.model";
import {QBSubscription, QBSubscriptionOption,} from "../../core/models/subscription.model";
import {GeographyControllerService} from "../../core/api/controllers/geography-controller.service";
import {LoginService} from "../../core/services/login.service";
import {NotificationService} from "../../core/services/notification.service";
import {ShopControllerService} from "../../core/api/controllers/shop-controller.service";
import {MatDialog} from "@angular/material/dialog";
import {TermsOfSaleComponent} from "../terms-of-sale/terms-of-sale.component";
import {
    IProcessingPaymentDialogData,
    ProcessingPaymentDialogComponent,
} from "../../shared/components/processing-payment-dialog/processing-payment-dialog.component";
import {ValidationHelperService} from "../../core/helpers/validation-helper.service";
import {AccountControllerService} from "../../core/api/controllers/account-controller.service";
import {ActivatedRoute} from "@angular/router";
import {MatAutocomplete} from "@angular/material/autocomplete";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  providers: [GeographyControllerService, LoginService, ShopControllerService],
})
export class RegisterComponent implements OnInit {
    @ViewChild("auto") matAutocomplete: MatAutocomplete;
  currentStep = 1;
  personalDetailsForm: FormGroup;
  addressForm = new FormGroup({
    country: new FormControl(null, [Validators.required]),
    address: new FormControl("", [Validators.required, Validators.maxLength(100)]),
    address2: new FormControl("", Validators.maxLength(100)),
    city: new FormControl<IGeographicalEntity>(null, [Validators.required, Validators.maxLength(100), CustomValidators.cityValidator]),
    state: new FormControl("", [Validators.required]),
    zipCode: new FormControl(
      "",
      [
        Validators.required,
        Validators.maxLength(20),
      ],
    ),
    phone: new FormControl(
      "",
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25),
        CustomValidators.phoneValidator(),
      ],
    ),
  });
  shippingInformationForm = new FormGroup({
      firstName: new FormControl(
          "",
          [
              Validators.required,
              CustomValidators.nameValidator,
              Validators.maxLength(50),
          ],
      ),
      lastName: new FormControl(
          "",
          [
              Validators.required,
              CustomValidators.nameValidator,
              Validators.maxLength(50),
          ],
      ),
      address: new FormControl("", [Validators.required, Validators.maxLength(100)]),
      address2: new FormControl("", [Validators.maxLength(100)]),
      country: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(100), CustomValidators.cityValidator]),
      state: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(
          "",
          [
              Validators.required,
              Validators.maxLength(20),
          ],
      ),
  });
  billingInformationForm: FormGroup;
  billingAddressForm = new FormGroup({
        address: new FormControl("", [Validators.required, Validators.maxLength(100)]),
        address2: new FormControl("", [Validators.maxLength(100)]),
        country: new FormControl(null, [Validators.required]),
        city: new FormControl(null, [Validators.required, Validators.maxLength(100), CustomValidators.cityValidator]),
        state: new FormControl(null),
        zipCode: new FormControl(
            "",
            [
                Validators.required,
                Validators.maxLength(20),
            ],
        ),
    });
  formGeographyData = new FormGeographyData();
  subscriptionUserTypes = SubscriptionUserType;
  subscriptionBillingPeriod = SubscriptionBillingPeriod;
  private _monthVector = monthVector;
  discountType = DiscountType;
  productsList: Product[] = [];
  countriesList: Country[] = [];
  orderDetails = new OrderDetails();
  termsAgreed = false;
  yearVector: string[];
  currentMonthVector: string[];
  useMonthVector = false;
  selectedMonth: string;
  selectedYear: string;
  couponCode = "";
  couponMessageCode: number = null;
  qbSubscriptions: QBSubscription;
  collapsed = true;
  isLoading = false;
  public innerWidth: any;

  constructor(
    private countryService: GeographyControllerService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private shopController: ShopControllerService,
    private accountController: AccountControllerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.generateForm();
    this.getDropdownsData();
    this.innerWidth = window.innerWidth;
  }
  prefillPlan(): void {
    const orderOptions = {
      4: "STARTER",
      5: "BASIC",
      6: "PREMIUM",
      7: "ENTERPRISE",
    };
    const order = parseInt(this.route.snapshot.queryParams["order"]) as number;
    if (order >= 0) {
      const subscriptionName = orderOptions[order] as string;
      const selectedSubscription =
        this?.qbSubscriptions?.selectedSubscriptionType?.options?.find(
          (option) =>
            option?.name
              ?.toUpperCase()
              ?.includes(subscriptionName?.toUpperCase()),
        );
      if (selectedSubscription) this.selectSubscription(selectedSubscription);
    }
    const productOptions = {
      0: "HD Sensor Board",
      1: "HD Pro Sensor Board",
      2: "Tripod",
    };
    const productParam = parseInt(
      this?.route?.snapshot?.queryParams["product"],
    ) as number;
    if (productParam >= 0) {
      const productName = productOptions[productParam] as string;
      const product = this?.productsList?.find((product) =>
        product?.name?.toUpperCase()?.includes(productName?.toUpperCase()),
      );
      if (product) {
        product.quantity = 1;
        this?.orderDetails?.productsChanged(
          this?.productsList,
          this?.shippingInformationForm?.controls?.country?.value,
        );
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  generateForm(): void {
    this.personalDetailsForm = this.formBuilder.group(
      {
        organizationName: [
          "",
          {
            validators: [Validators.required, Validators.maxLength(100)],
            asyncValidators: [this.existingOrganizationValidator()],
            updateOn: "blur",
          },
        ],
        firstName: [
          "",
          [
            Validators.required,
            Validators.maxLength(50),
            CustomValidators.nameValidator,
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.maxLength(50),
            CustomValidators.nameValidator,
          ],
        ],
        email: [
          "",
          [Validators.required, Validators.maxLength(100), CustomValidators.customEmailValidator],
        ],
        username: [
          "",
          {
            validators: [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(50),
              CustomValidators.noWhitespaceValidator
            ],
            asyncValidators: [this.existingUsernameValidator()],
            updateOn: "blur",
          },
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            CustomValidators.passwordValidator(),
          ],
        ],
        confirmPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            CustomValidators.passwordValidator(),
          ],
        ],
      },
      { validator: CustomValidators.passwordMatchValidator },
    );

    this.billingInformationForm = this.formBuilder.group(
      {
        firstName: [
          "",
          [
            Validators.required,
            Validators.maxLength(50),
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.maxLength(50),
          ],
        ],
        cardNumber: [
          "",
          [
            Validators.required,
            CustomValidators.onlyDigitsValidator(),
            Validators.minLength(8),
            Validators.maxLength(19),
          ],
        ],
        CVV: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            CustomValidators.onlyDigitsValidator(),
          ],
        ],
      },
    );
    this.addressForm.controls.country.valueChanges.subscribe(
      (country: Country) => {
        if (!country) {
          return;
        }
        const reachedFinalStep =
          !!this.shippingInformationForm.controls.address.value &&
          !!this.billingAddressForm.controls.address.value;
        const addressType = reachedFinalStep ? AddressType.Personal : null;
        this.getStatesByCountry(country.id, addressType).subscribe();
      },
    );
    this.billingAddressForm.controls.country.valueChanges.subscribe(
      (country: Country) => {
        if (!country) {
          return;
        }
        this.getStatesByCountry(country.id, AddressType.Billing).subscribe();
      },
    );
    this.shippingInformationForm.controls.country.valueChanges.subscribe(
      (country: Country) => {
        if (!country) {
          return;
        }
        this.orderDetails.countryChanged(country, this.productsList);
        this.getStatesByCountry(country.id, AddressType.Shipping).subscribe();
      },
    );
    this.addressForm.controls.state.valueChanges.subscribe(
      (stateId: string) => {
        if (!stateId) {
          return;
        }
        const reachedFinalStep =
          !!this.shippingInformationForm.controls.address.value &&
          !!this.billingAddressForm.controls.address.value;
        const addressType = reachedFinalStep ? AddressType.Personal : null;
        this.GetCitiesByState(stateId, addressType).subscribe();
      },
    );
    this.billingAddressForm.controls.state.valueChanges.subscribe(
      (stateId: string) => {
        if (!stateId) {
          return;
        }
        this.GetCitiesByState(stateId, AddressType.Billing).subscribe();
      },
    );
    this.shippingInformationForm.controls.state.valueChanges.subscribe(
      (stateId: string) => {
        if (!stateId) {
          return;
        }
        this.GetCitiesByState(stateId, AddressType.Shipping).subscribe();
      },
    );
  }

  getCity(
    citiesList: IGeographicalEntity[],
    cityDetails: IGeographicalEntity,
  ): IGeographicalEntity {
    if(!cityDetails) {
      return citiesList[0];
    }
    const addressCity = citiesList.find(
      (city) => city.name === cityDetails.name,
    );
    if (addressCity) {
      return addressCity;
    }
    citiesList.unshift(cityDetails);
    return citiesList[0];
  }

  prefillForm(prefillData): void {
    if (prefillData) {
      this.personalDetailsForm.patchValue(prefillData.personalDetails, {
        emitEvent: false,
      });
      this.addressForm.patchValue(
        {
          ...prefillData.addressInformation,
          country: this.countriesList.find(
            (country) =>
              country.id === prefillData.addressInformation.country.id,
          ),
          city: this.getCity(
            this.formGeographyData.personalAddress.cities,
            prefillData.addressInformation.city,
          ).name,
        },
        { emitEvent: false },
      );
      this.shippingInformationForm.patchValue(
        {
          ...prefillData.shippingInformation,
          country: this.countriesList.find(
            (country) =>
              country.id === prefillData.shippingInformation.country.id,
          ),
          city: this.getCity(
            this.formGeographyData.shippingAddress.cities,
            prefillData.shippingInformation.city,
          ),
        },
        { emitEvent: false },
      );
      this.billingAddressForm.patchValue(
        {
          ...prefillData.billingAddress,
          country: this.countriesList.find(
            (country) => country.id === prefillData.billingAddress.country.id,
          ),
          city: this.getCity(
            this.formGeographyData.billingAddress.cities,
            prefillData.billingAddress.city,
          ),
        },
        { emitEvent: false },
      );
      this.qbSubscriptions.select(prefillData.subscriptionType);
      this.qbSubscriptions.selectedSubscriptionType.selectOption(
        prefillData.subscriptionId,
      );
      this.qbSubscriptions.billingPeriod = prefillData.subscriptionPeriod;
      this.productsList.forEach((p) => {
        const prefillProduct = prefillData.products.find(
          (prod) => prod.id === p.id,
        );
        p.quantity = prefillProduct ? prefillProduct.quantity : 0;
      });
      this.couponMessageCode = prefillData.activeCoupon ? 0 : null;
      this.orderDetails.formPrefilled({
        sub: this.qbSubscriptions.selectedSubscriptionOption,
        period: this.qbSubscriptions.billingPeriod,
        coupon: prefillData.activeCoupon,
        products: this.productsList,
        country: this.shippingInformationForm.controls["country"].value,
      });
      this.goToFinalStep();
    }
  }

  getDropdownsData(): void {
    this.isLoading = true;
    const prefillData = JSON.parse(sessionStorage.getItem("registerProgress"));
    this.getProductsAndSubscriptions(!!prefillData)
      .pipe(
        switchMap(() => {
          return this.getCountries().pipe(
            switchMap(() => {
              const countryId = prefillData
                ? prefillData.addressInformation.country.id
                : this.addressForm.controls["country"].value.id;
              return this.getStatesByCountry(countryId).pipe(
                switchMap(() => {
                  const stateId = prefillData
                    ? prefillData.addressInformation.state
                    : this.addressForm.controls["state"].value;
                  return this.GetCitiesByState(stateId);
                }),
              );
            }),
          );
        }),
      )
      .subscribe(() => {
        if (prefillData) {
          this.prefillForm(prefillData);
        }
      });
  }

  getCountries(): Observable<Country[]> {
    return this.countryService.getAllCountries().pipe(
      tap((response) => {
        this.countriesList = response;
        if (!this.addressForm.controls["country"].value) {
          this.addressForm.controls["country"].setValue(
            this.countriesList.find(
              (country) => country.name === "United States",
            ),
            { emitEvent: false },
          );
        }
      }),
      catchError((error) => {
        this.notificationService.error("Country error: " + error.error.message);
        return throwError(error);
      }),
    );
  }

  getStatesByCountry(
    countryUuid: string,
    addressType?: AddressType,
  ): Observable<Country[]> {
    return this.countryService.getStatesByCountry(countryUuid).pipe(
      tap((response) => {
        if (addressType) {
          this.formGeographyData.setStates(response, addressType);
        } else {
          this.formGeographyData.initStatesLists(response);
        }

        const stateControl =
          addressType === AddressType.Billing
            ? this.billingAddressForm.controls["state"]
            : addressType === AddressType.Shipping
              ? this.shippingInformationForm.controls["state"]
              : this.addressForm.controls["state"];
        stateControl.setValue(
          this.formGeographyData.getStates(addressType)[0].id,
          { emitEvent: addressType !== undefined },
        );
      }),
      catchError((error) => {
        this.notificationService.error("State error: " + error.error.message);
        return throwError(error);
      }),
    );
  }

  GetCitiesByState(
    stateUuid: string,
    addressType?: AddressType,
  ): Observable<Country[]> {
    return this.countryService.getCitiesByState(stateUuid).pipe(
      tap((response) => {
        if (addressType) {
          this.formGeographyData.setCities(response, addressType);
        } else {
          this.formGeographyData.initCitiesLists(response);
        }
        this.prefillPlan();
        this.isLoading = false;
      }),
      catchError((error) => {
        this.notificationService.error("City error: " + error.error.message);
        return throwError(error);
      }),
    );
  }

  getProductsAndSubscriptions(hasPrefillData: boolean): Observable<any> {
    return this.shopController.getRegisterProducts().pipe(
      tap(
        (response: { products: Product[]; subscriptions: QBSubscription }) => {
          this.qbSubscriptions = new QBSubscription(response.subscriptions);
          this.productsList = response.products.sort(
            (a, b) => b.price - a.price,
          );
          if (!this.qbSubscriptions.selectedSubscriptionType.options.length) {
            this.notificationService.error(
              "No subscriptions are currently available.",
            );
          } else if (!hasPrefillData) {
            this.qbSubscriptions.performance.selected = true;
            this.qbSubscriptions.selectedSubscriptionType.options[0].selected =
              true;
            this.qbSubscriptions.billingPeriod =
              SubscriptionBillingPeriod.Monthly;
            this.orderDetails.subscriptionChanged(this.qbSubscriptions);
          }
        },
      ),
      catchError((error) => {
        this.notificationService.error(
          "An error occurred while retrieving the products: " +
            error.error.message,
        );
        return throwError(error);
      }),
    );
  }

  existingUsernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value.length < 7) {
        return of(null);
      }
      return from(this.accountController.validUser(control.value)).pipe(
        map((response) => {
          return response.exists ? { usernameExists: true } : null;
        }),
        catchError((error) => {
          this.notificationService.error(error);
          return of(null);
        }),
      );
    };
  }

  existingOrganizationValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value.length < 1) {
        return of(null);
      }
      return from(
        this.accountController.checkOrganizationName(control.value),
      ).pipe(
        map((response) => {
          return response.exists ? { orgExists: true } : null;
        }),
        catchError((error) => {
          this.notificationService.error(error);
          return of(null);
        }),
      );
    };
  }

  selectUserType(type: SubscriptionUserType): void {
    this.qbSubscriptions.select(type);
    this.orderDetails.subscriptionChanged(this.qbSubscriptions);
  }

  selectSubscription(sub: QBSubscriptionOption): void {
    if (sub.selected) {
      return;
    }
    this.qbSubscriptions.unselectAll();
    sub.selected = true;
    this.removeCoupon();
    this.orderDetails.subscriptionChanged(this.qbSubscriptions);
  }

  selectBillingPeriod(period: SubscriptionBillingPeriod): void {
    this.qbSubscriptions.billingPeriod = period;
    const couponGreaterThan30 =
      this.orderDetails.activeCoupon &&
      this.orderDetails.activeCoupon.discountType === DiscountType.Percentage &&
      this.orderDetails.activeCoupon.discount > 30;
    if (period === SubscriptionBillingPeriod.Monthly && couponGreaterThan30) {
      this.removeCoupon();
      this.couponMessageCode = 2;
    }
    this.removeCoupon();
    this.orderDetails.subscriptionChanged(this.qbSubscriptions);
  }

  omit_special_char(event: { charCode: any }) {
    let k;
    k = event.charCode;
    return k >= 48 && k <= 57;
  }

  goToFinalStep(): void {
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.currentMonthVector.splice(-12, new Date().getMonth());
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];
    if (
      !this.shippingInformationForm.controls.address.value ||
      !this.billingAddressForm.controls.address.value
    ) {
      this.prefillShippingAndBillingInfo();
      this.saveProgress();
    }
  }

  submitStep3(): void {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      this.goToFinalStep();
    }
  }

  prefillShippingAndBillingInfo(): void {
    const country = this.countriesList.find(
      (country) => country.id === this.addressForm.controls.country.value.id,
    );
    if(this.addressForm.controls.city.value.id === null)
    this.formGeographyData.addCityInAllLists(this.addressForm.controls.city.value);
    const shippingCity = this.formGeographyData.shippingAddress.cities[0];
    const billingCity = this.formGeographyData.billingAddress.cities[0];

    this.shippingInformationForm.patchValue(
      {
        ...this.personalDetailsForm.getRawValue(),
        ...this.addressForm.getRawValue(),
      },
      { emitEvent: false },
    );
    this.shippingInformationForm.controls.country.setValue(country, {
      emitEvent: false,
    });
    this.shippingInformationForm.controls.city.setValue(shippingCity, {
      emitEvent: false,
    });
    this.orderDetails.countryChanged(country, this.productsList);
    this.billingAddressForm.patchValue(this.addressForm.getRawValue(), {
      emitEvent: false,
    });
    this.billingAddressForm.controls.country.setValue(country, {
      emitEvent: false,
    });
    this.billingAddressForm.controls.city.setValue(billingCity, {
      emitEvent: false,
    });
  }

  saveProgress(): void {
    const registerProgress = {
      subscriptionType: this.qbSubscriptions.selectedSubscriptionType.type,
      subscriptionId: this.qbSubscriptions.selectedSubscriptionOption.id,
      subscriptionPeriod: this.qbSubscriptions.billingPeriod,
      products: this.productsList
        .filter((p) => p.quantity > 0)
        .map((p) => ({ id: p.id, quantity: p.quantity })),
      activeCoupon: this.orderDetails.activeCoupon,
      personalDetails: this.personalDetailsForm.getRawValue(),
      addressInformation: this.addressForm.getRawValue(),
      shippingInformation: this.shippingInformationForm.getRawValue(),
      billingAddress: this.billingAddressForm.getRawValue(),
    };
    sessionStorage.setItem(
      "registerProgress",
      JSON.stringify(registerProgress),
    );
  }

  validateDate() {
    if (this.selectedYear !== this.yearVector[0]) {
      this.useMonthVector = true;
    } else {
      this.useMonthVector = false;
      this.selectedMonth = this.currentMonthVector[0];
    }
  }

  removeCoupon(): void {
    this.couponCode = "";
    this.orderDetails.couponRemoved();
    this.couponMessageCode = null;
  }

  checkCouponValidity(): void {
    this.couponMessageCode = null;
    this.shopController.isCouponValid(this.couponCode.trim()).subscribe(
      (coupon: Coupon) => {
        this.couponCode = "";
        if (!coupon) {
          return;
        }
        if (
          coupon.discountType === DiscountType.Days &&
          coupon.discount > 30 &&
          this.qbSubscriptions.billingPeriod ===
            SubscriptionBillingPeriod.Monthly
        ) {
          this.couponMessageCode = 2;
        } else {
          this.orderDetails.couponApplied(coupon);
          this.couponMessageCode = 0;
        }
      },
      (error) => {
        this.couponMessageCode = 1;
        this.notificationService.error(error);
      },
    );
  }

  openProcessingModal(registrationPayload: RegistrationPayload): void {
    const data: IProcessingPaymentDialogData = {
      personalDetailsForm: this.personalDetailsForm,
      registrationPayload,
    };
    this.dialog.open(ProcessingPaymentDialogComponent, {
      data,
      width: "500px",
      disableClose: true,
    });
  }

    displayFn(city: {id: string; name: string;}): string {
        return city && city.name ? city.name : '';
    }

    updateCity(event: Event, _control: FormControl): void {
      const value = (event.target as HTMLInputElement).value;
        _control.setValue({
            name: value,
            id: null
        }, {emitEvent: false});
    }


    register(): void
    {
        const cardExpirationDate = this.selectedMonth.toString().substring(0, 2) + this.selectedYear.toString().substring(2);
        const registrationPayload = new RegistrationPayload({
        user: this.personalDetailsForm.getRawValue(),
        organizationAddress: this.addressForm.value,
        shippingAddress: this.shippingInformationForm.value,
        billingAddress: this.billingAddressForm.value,
        creditCard: {
        ...this.billingInformationForm.getRawValue(),
        expiration: cardExpirationDate,
        },
        subscription: {
        id: this.qbSubscriptions.selectedSubscriptionOption.id,
        billingPeriod: this.qbSubscriptions.billingPeriod,
        },
        purchasedProducts: this.productsList
        .filter((p) => p.quantity > 0)
        .map((p) => ({ id: p.id, quantity: p.quantity })),
        couponId: this.orderDetails.activeCoupon
        ? this.orderDetails.activeCoupon.id
        : null,
        });
        this.openProcessingModal(registrationPayload);
    }

  openTermsModal(): void {
    this.dialog.open(TermsOfSaleComponent, {
      width: "1200px",
      height: "90vh",
    });
  }

  get city(): FormControl {
    return this.addressForm.get("city") as FormControl;
  }

  get shippingCity(): FormControl {
      return this.shippingInformationForm.controls.city;
  }

  get billingCity(): FormControl {
    return this.billingAddressForm.get("city") as FormControl;
  }

  get monthVector(): string[] {
    const currentMonthVector = Object.assign([], this._monthVector);
    if (this.yearVector && this.yearVector[0]) {
      if (this.selectedYear === this.yearVector[0]) {
        currentMonthVector.splice(0, new Date().getMonth());
      }
    }

    return currentMonthVector;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
