<div
  class="p-5 flex justify-center items-center gap-5 flex-column"
  *ngIf="!isLoading"
>
  <div>
    <h2>{{ action }} location</h2>
  </div>
  <div>
    <form class="ui form" [formGroup]="locationForm">
      <div class="field">
        <label class="big-label">Location Name</label>
        <input
          class="form-control-register"
          type="text"
          formControlName="name"
          placeholder="Location name"
          (focus)="locationForm.controls['name'].markAsUntouched()"
        />
        <div
          *ngIf="
            !locationForm.controls['name'].valid &&
            locationForm.controls['name'].touched
          "
          class="ui pointing red basic label"
        >
          Location Name must not be empty
        </div>
      </div>
    </form>
    <div class="actions" align="center">
      <button
        type="button"
        class="positive ui button"
        style="margin-top: 10px"
        [disabled]="!locationForm.valid || allreadyPressed"
        (click)="update()"
      >
        Update
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="isLoading"
  class="w-full h-[300px] flex justify-center items-center"
>
  <mat-spinner diameter="50"></mat-spinner>
</div>
