import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {IUser} from "../../models/subscription.model";
import {IEditUserReq} from "../../../shared/interfaces/DTOs/Request/IEditUserReq";
import {ICreateUserReq} from "../../../shared/interfaces/DTOs/Request/ICreateUserReq";

@Injectable({
  providedIn: "root",
})
export class UsersControllerService {
  private readonly _resourceUrl: string = "/users";
  constructor(private apiService: ApiService) {}

  editUser(user: IEditUserReq) {
    return this.apiService.put(
      `${this._resourceUrl}`,
      user,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  changeUserStatus(userId: string, newStatus: boolean) {
    return this.apiService.put(
      `${this._resourceUrl}/${userId}/change-user-status/${newStatus}`,
      {},
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getUsers(): Observable<IUser[]> {
    return this.apiService.get<IUser[]>(
      `${this._resourceUrl}/in-organization`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  addUser(user: ICreateUserReq): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}`,
      user,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteUser(id: any): Observable<any> {
    return this.apiService.delete(
      `${this._resourceUrl}/${id}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  selectLocation(locationId: string) {
    return this.apiService.put(
      `${this._resourceUrl}/change-location`,
      {},
      { locationId },
      AuthService.defaultTokenHeader().headers,
    );
  }
}
