<div [class.disabled-custom]="isLoading" (click)="openRestoreWorkoutDialog()">
    <div class="relative h-[200px]">
        <div class="border-2 rounded-2xl absolute top-0 left-0 w-full h-full hover:opacity-50 transition-all cursor-pointer opacity-75">
            <div class="flex justify-center items-center w-full h-full bg-[#ffffff33]">
                <mat-icon class="scale-[2]">refresh</mat-icon>
            </div>
            <div class="absolute left-[1rem] bottom-[1rem] flex flex-col gap-3 ">
                <p class="text-xl !m-0">{{workout.name}}</p>
                <div class="flex items-center gap-2 text-md text-gray-500">
                    <span>{{workout.playlists.length}} playlists</span>
                    <mat-icon class="scale-50">circle</mat-icon>
                    <span>
                {{ getExercisesCount() }} exercises
            </span>
                </div>
            </div>
        </div>
        <div class="absolute top-0 right-0 w-full h-full flex justify-center items-center " *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>
</div>
