import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class AgilityControllerService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  getAgilityRatingByIdPaging(
    athleteId: string,
    start,
    end,
    page: number,
    size: number,
  ): Observable<any> {
    return this.apiService.get(
      "/agility-rating",
      {
        athleteId,
        startTimestamp: start,
        endTimestamp: end,
        page,
        size,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  downloadAgilityRatingByName(name: string): Observable<any> {
    let t = {
      name: "asd",
    };

    const httpOption: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.getOauthTokenFromStorage(),
      }),
      responseType: "arrayBuffer" as "json",
    };

    return this.http
      .post<Blob>(`/download-rating?name=${name}`, t, httpOption)
      .pipe(
        map((res) => {
          return {
            filename: "file.pdf",
            data: new Blob([res], { type: "application/pdf" }),
          };
        }),
      );
  }
}
