<div class="d-flex flex-column gap-5 p-5">
  <div>
    <h2>{{ data.title }}</h2>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <p>{{ data.message }}</p>
  </div>
  <div class="d-flex justify-content-end flex-grow-1">
    <div class="d-flex justify-content-center align-items-center gap-3">
      <button
        type="button"
        class="btn btn-danger text-uppercase mr-3"
        (click)="dialogRef.close(false)"
      >
        {{ data.cancelButtonLabel ? data.cancelButtonLabel : "No" }}
      </button>
      <button
        type="button"
        class="btn btn-secondary text-uppercase"
        (click)="dialogRef.close(true)"
      >
        {{
          data.confirmationButtonLabel ? data.confirmationButtonLabel : "Yes"
        }}
      </button>
    </div>
  </div>
</div>
