import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ETransactionType } from "../../../shared/enums/ETransactionType";
import { GlobalPreferences } from "../../../core/helpers/global-data";
import { AdminControllerService } from "../../../core/api/controllers/admin-controller.service";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  isLoading = false;
  paymentHistoryForm = this.formBuilder.group({
    searchBy: null,
    isSuccess: null,
    transactionType: null,
    invoiceGenerated: null,
    dateTimeInterval: this.formBuilder.group({
      startDate: this.formBuilder.control({
        value: null,
        disabled: true,
      }),
      endDate: this.formBuilder.control({
        value: null,
        disabled: true,
      }),
    }),
  });

  paymentHistoryFilters: any = { pagination: { page: 0, itemsPerPage: 20 } };
  paymentHistoryDefaultFilters = { pagination: { page: 0, itemsPerPage: 20 } };
  paymentHistoryTransactions = [];
  paymentHistoryTotalPages = 0;
  paymentHistoryCurrentPage = 0;
  paymentHistoryTransactionType = ETransactionType;
  paymentHistoryTransactionTypeValues = ["Full Purchase", "Subscription"];

  constructor(
    private formBuilder: FormBuilder,
    private adminController: AdminControllerService,
    public global: GlobalPreferences,
  ) {}

  ngOnInit(): void {
    this.fetchPaymentHistory();
  }

  handlePaymentHistoryFilters() {
    this.paymentHistoryFilters = {
      ...this.paymentHistoryFilters,
      ...this.formatValues(this.paymentHistoryForm.value),
    };

    this.fetchPaymentHistory();
  }

  formatDate(date) {
    if (!date) {
      return;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  formatValues(value) {
    value.isSuccess = JSON.parse(value.isSuccess);
    value.transactionType = JSON.parse(value.transactionType);
    value.invoiceGenerated = JSON.parse(value.invoiceGenerated);

    value.startDate = this.formatDate(value.dateTimeInterval.startDate);
    value.endDate = this.formatDate(value.dateTimeInterval.endDate);
    return value;
  }

  handlePaymentHistoryResetForm() {
    this.paymentHistoryForm.reset();
    this.paymentHistoryFilters = this.paymentHistoryDefaultFilters;
    this.paymentHistoryCurrentPage = 0;

    this.fetchPaymentHistory();
  }

  fetchPaymentHistory() {
    this.isLoading = true;
    const gmtOffset = this.global.getTimezoneOffset();
    const { dateTimeInterval } = this.paymentHistoryFilters;

    if (dateTimeInterval) {
      this.paymentHistoryFilters = {
        ...this.paymentHistoryFilters,
        dateTimeInterval: {
          startDate: dateTimeInterval.startDate
            ? dateTimeInterval.startDate.toDateString()
            : dateTimeInterval.startDate,
          endDate: dateTimeInterval.endDate
            ? dateTimeInterval.endDate.toDateString()
            : dateTimeInterval.endDate,
        },
      };
    }

    this.isLoading = true;

    this.adminController
      .getPaymentHistory({ ...this.paymentHistoryFilters, gmtOffset })
      .subscribe(({ data, totalCount }: any) => {
        this.paymentHistoryTransactions = data;
        this.paymentHistoryTotalPages = totalCount;
        this.isLoading = false;
      });
  }

  handlePaymentHistoryPageSelect(page) {
    this.paymentHistoryFilters.pagination = {
      ...this.paymentHistoryFilters.pagination,
      page,
    };

    this.fetchPaymentHistory();
  }

  handlePaymentHistoryPageChange(page) {
    this.paymentHistoryCurrentPage = page;
    this.handlePaymentHistoryPageSelect(page);
  }
}
