import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren,} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FileUploader} from "ng2-file-upload";
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {SportsControllerService} from "../../core/api/controllers/sports-controller.service";
import {DatePipe} from "@angular/common";
import {NotificationService} from "../../core/services/notification.service";
import {DomSanitizer} from "@angular/platform-browser";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {APP_DATE_FORMATS, AppDateAdapter,} from "../../core/helpers/date-format";
import * as moment from "moment";
import {AuthService} from "../../core/services/auth.service";
import {MessageService} from "../../core/services/message-service";
import {CustomValidators} from "../../core/helpers/custom-validators";
import {Team} from "../../shared/interfaces/ITeam";
import {Athlete, IAthleteUpdate} from "../../shared/interfaces/IAthlete";
import {AtheleteUnitsAdapter} from "../../core/helpers/AtheleteUnitsAdapter";
import {Category} from "../../shared/interfaces/ICategory";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {EAction} from "../../shared/enums/EAction";
import {MediaControllerService} from "../../core/api/controllers/media-controller.service";
import {AthletesControllerService} from "../../core/api/controllers/athletes-controller.service";
import {ValidationHelperService} from "../../core/helpers/validation-helper.service";
import {EMeasurementSystem} from "../../shared/enums/EMeasurementSystem";
import {SettingsService} from "../../core/services/settings.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-save-athlete",
  templateUrl: "./save-athlete.component.html",
  styleUrls: ["./save-athlete.component.scss"],
  providers: [
    SportsControllerService,
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class SaveAthleteComponent implements OnInit, OnDestroy {
  @ViewChild("dobDatepicker", { static: true }) dobDatepicker;
  @ViewChildren("rehabDatePicker") rehabDatePicker: QueryList<any>;
  @ViewChildren("practiceDatePicker") practiceDatePicker: QueryList<any>;
  @ViewChildren("playDatePicker") playDatePicker: QueryList<any>;
  id = null;
  isEditing = false;
  private sub;
  athleteForm: FormGroup;
  categoryList: Category[] = [];
  isLoading = false;
  levelList = [];
  sportList = [];
  selectedSport: any = {};
  selectedPosition: any = {};
  selectedLevel: any = {};
  teamList: Team[] = [];
  teamListTypeahead = null;
  imageUploader: FileUploader;
  image = null;
  imagePreviewUrl = null;
  categoryMode = "select";
  selectedCategory: any = {};
  teamMode = "select";
  selectedTeam: any = {};
  foundAthlete = null;
  selectedAthlete: Athlete;
  minDate;
  maxDate;
  startDate;
  heightType = 1;
  globalSub;
  emailMandatory: boolean;
  dateOfBirth;
  action: EAction;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private saveAthleteService: SportsControllerService,
    private snackBarService: MatSnackBar,
    private router: Router,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private messageService: MessageService,
    private mediaController: MediaControllerService,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private athletesController: AthletesControllerService
  ) {}

  ngOnInit() {
    this.mixpanelService.track("athlete;_save_screen", {});
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf("save-" + data.athleteName) == -1) {
          this.router.navigate(["/save-" + data.athleteName]);
        }
      },
      (error) => this.notificationService.error(error),
    );
    this.minDate = new Date(1900, 0, 1);
    const today = new Date();
    const currentYear = new Date().getFullYear();
    today.setDate(today.getDate());
    this.maxDate = today;
    this.startDate = new Date(currentYear - 18, 0, 1);
    this.athleteForm = this.formBuilder.group(
      {
        firstName: [
          "",
          [
            Validators.required,
            Validators.maxLength(25),
            CustomValidators.noDigitsValidator,
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.maxLength(25),
            CustomValidators.noDigitsValidator,
          ],
        ],
        category: ["", [Validators.required]],
        dateOfBirth: [null],
        height: [""],
        weight: ["", CustomValidators.floatValidator],
        weightUnit: [0],
        sport: [""],
        position: [""],
        team: [""],
        gender: [],
        level: [""],
        dominantLeg: [],
        email: ["", [Validators.email]],
      },
      { validators: [CustomValidators.heightBoundariesValidator] },
    );

    this.athleteForm.controls['weightUnit'].valueChanges.subscribe((value) => {
        this.changeWeightType(value);
    })

    this.messageService.accessMandatoryEmail().subscribe((data) => {
      this.emailMandatory = data;

      if (this.emailMandatory) {
        this.athleteForm.controls["email"].setValidators([Validators.email]);
      }
    });

    this.imageUploader = new FileUploader({
      queueLimit: 1,
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      const data = new FormData();
      data.append("file", file._file);
      this.mediaController.mediaUpload(data).subscribe(
        (response) => {
          this.image = response.url;
        },
        (error) => {
          this.notificationService.error(error);
        },
      );
      if (file.file.type.indexOf("image") >= 0 && file.file.size <= 10000000) {
        this.image = file.file;
        this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(file._file),
        );
      } else {
        this.notificationService.warn("Invalid image type or size submitted!");
        this.image = null;
        this.imagePreviewUrl = null;
      }
    };

    this.sub = this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        if (this.id !== undefined) {
          this.isEditing = true;
          this.action = EAction.Edit;
          this.retrieveAthlete();
        } else {
          this.action = EAction.Add;
          this.changeWeightType(this.athleteForm.controls["weight"].value);
          this.initTeamsList();
          this.initLists();
        }
      },
      (error) => this.notificationService.error(error),
    );
  }

  initCategory(categoryId: string): void {
    this.isLoading = true;
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getCategories(locationId).subscribe(
      (response) => {
        this.updateCategoryListByResponse(response);
        this.athleteForm.controls["category"].setValue(
          this.categoryList.find((category) => category.id === categoryId),
        );
      },
      (error) => this.notificationService.error(error),
    );
  }

  initTeam(teamId: string): void {
    this.isLoading = true;
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getTeams(locationId).subscribe(
      (response) => {
        this.updateTeamListByResponse(response);
        this.athleteForm.controls["team"].setValue(
          this.teamList.find((team) => team.id === teamId),
        );
        this.isLoading = false;
      },
      (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
      }
    );
  }

  initCategoriesList() {
    this.isLoading = true;
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getCategories(locationId).subscribe(
      (response) => {
        this.updateCategoryListByResponse(response);
          this.isLoading = false;
      },
      (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
      }
    );
  }
  updateCategoryListByResponse(response: Category[]): void {
    this.categoryList = response;
  }

  changeTeamMode(teamMode: string) {
    this.athleteForm.controls["team"].clearValidators();
    this.athleteForm.controls["team"].updateValueAndValidity();
    this.teamMode = teamMode;
    if (teamMode !== "select") {
      this.athleteForm.controls["team"].setValidators([Validators.required]);
    }
  }

  initLists() {
    this.initCategoriesList();
    this.initTeamsList();
    this.initLevelsList();
    this.initSportsList();
  }

  initLevelsList(): void {
      this.isLoading = true;
      this.athletesController.getLevels().subscribe(
      (response) => {
        this.levelList = response;
        this.levelList.unshift({
          lastModified: null,
          creationDate: null,
          isDeleted: false,
          serverModification: null,
          id: null,
          uuid: null,
          name: "No Level",
          locationId: null,
        });
        this.selectedLevel = this.levelList[0];
        this.athleteForm.controls["level"].setValue(this.selectedLevel);
        this.levelList.forEach((value) => {
          if (value.id == this.selectedLevel.id && value.id !== null) {
            this.athleteForm.controls["level"].setValue(value);
          }
        });
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  initSportsList(): void {
      this.isLoading = true;
      this.saveAthleteService.getSports().subscribe(
      (response) => {
        this.sportList = response;
        this.sportList.unshift({
          id: null,
          name: "No Sport",
          uuid: null,
          positions: [],
        });
        let foundPosition = false;
        this.sportList.forEach((value) => {
          if (
            value.id == this.selectedSport.id &&
            this.selectedSport.id != null
          ) {
            this.athleteForm.controls["sport"].setValue(value);
            if (value.positions)
              for (const tempPosition of value.positions) {
                if (tempPosition.id === this.selectedPosition.id) {
                  foundPosition = true;
                  this.athleteForm.controls["position"].setValue(tempPosition);
                  break;
                }
              }
            if (!foundPosition) {
              this.athleteForm.controls["position"].setValue(0);
            }
            this.selectedSport.positions = value.positions;
            this.updateSelectedSport(this.athleteForm.controls["sport"].value);
          }
        });
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  isClinicSuperUser() {
    return this.authService.checkAcconutType("Clinic");
  }

  saveAthlete() {
    this.athleteForm.markAllAsTouched();
    if (this.athleteForm.invalid) return;
    const data = new FormData();
    this.imagePreviewUrl == null
      ? data.append("file", new Blob())
      : data.append("file", this.image.rawFile);
    if (this.isEditing) this.updateAthlete();
    else if(this.settingsService.settings.subscription.athleteLimit <= this.settingsService.settings.organization.athleteCount) {
        this.snackBarService.open(
          "The maximum limit of athletes has been reached. Please upgrade your subscription plan to add more.",
          "OK",
          {duration: 3000}
        )
    }
    else this.createAthlete();
  }

  updateAthlete(): void {
    this.isLoading = true;
    this.athletesController.editAthlete(this.createPayload()).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["/athletes"]);
        this.notificationService.success("Athlete updated successfully.");
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  createAthlete(): void {
    this.isLoading = true;
    this.athletesController.saveAthlete(this.createPayload()).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["/athletes"]);
        this.notificationService.success("Athlete saved successfully.");
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  createPayload(): IAthleteUpdate {
  //  if (!this.image) this.image = "assets/images/no_avatar.png";

    if (!this.selectedCategory) this.selectedCategory = null;
    if (!this.selectedTeam) this.selectedTeam = null;
    if (!this.selectedSport) this.selectedSport = null;
    if (!this.selectedPosition) this.selectedPosition = null;
    if (!this.selectedLevel) this.selectedLevel = null;

    let height = this.athleteForm.controls["height"].value
      ? AtheleteUnitsAdapter.toCm({
          valueInCentimeters: this.athleteForm.controls["height"].value,
          measurementSystem: this.heightType,
        })
      : null;

    let weight = this.athleteForm.controls["weight"].value
      ? {
          valueInKilograms: parseFloat(
            this.athleteForm.controls["weight"].value,
          ),
          measurementSystem: this.athleteForm.controls["weightUnit"].value,
        }
      : null;

    return {
      firstName: this.athleteForm.controls["firstName"].value,
      lastName: this.athleteForm.controls["lastName"].value,
      email: this.athleteForm.controls["email"].value,
      dateOfBirth: this.dateOfBirthControl.value ? moment(new Date(this.dateOfBirthControl.value)).format(
        "YYYY-MM-DD",
      ) : null,
      profilePictureUrl: this.image,
      categoryId: this.athleteForm.controls["category"].value.id,
      sportPositionId: this.athleteForm.controls["position"].value.id,
      levelId: this.athleteForm.controls["level"].value.id,
      teamId: this.athleteForm.controls["team"].value.id,
      dominantLeg: this.athleteForm.controls["dominantLeg"].value,
      gender: this.athleteForm.controls["gender"].value,
      height: height,
      weight: weight,
      id: this.id,
    } as unknown as IAthleteUpdate;
  }

  retrieveAthlete() {
    this.isLoading = true;

    this.athletesController.getAthlete(this.id).subscribe(
      (athlete) => {
        this.foundAthlete = this.id;
        this.selectedAthlete = athlete;
        this.selectedCategory.id = athlete.category.id;

        if (athlete.category) this.initCategory(athlete.category.id);
        else this.initCategoriesList();

        if (athlete.team) this.initTeam(athlete.team.id);
        else this.initTeamsList();

        if (athlete.sport) this.selectedSport.id = athlete.sport.id;
        if (athlete.sportPosition)
          this.selectedPosition.id = athlete.sportPosition.id;
        if (athlete.level) this.selectedLevel.id = athlete.level.id;

        this.initLevelsList();
        this.initSportsList();

        this.athleteForm.controls["firstName"].setValue(athlete.firstName);
        this.athleteForm.controls["lastName"].setValue(athlete.lastName);

        this.dateOfBirth = athlete.dateOfBirth;
        if (this.dateOfBirth) {
          const date = new Date();
          if (this.dateOfBirth.includes("-")) {
            const dateArr = this.dateOfBirth.split("-");
            date.setMonth(dateArr[1] - 1);
            date.setDate(dateArr[2]);
            date.setFullYear(dateArr[0]);
            this.athleteForm.controls["dateOfBirth"].setValue(
              new Date(date).toISOString(),
            );
          } else if (this.dateOfBirth.includes("/")) {
            const dateArr = this.dateOfBirth.split("/");
            date.setMonth(dateArr[0] - 1);
            date.setDate(dateArr[1]);
            date.setFullYear(dateArr[2]);
            this.athleteForm.controls["dateOfBirth"].setValue(
              new Date(date).toISOString(),
            );
          }
        }

        if (athlete.height) {
          if (athlete.height.isInMetric) {
            this.athleteForm.controls["height"].setValue(
              athlete.height.valueInCentimeters,
            );
            this.heightType = 0;
          } else {
            const value = athlete.height.valueInCentimeters as number;
            const feet = Math.floor(value / 30.48);
            const inches = Math.round((value - feet * 30.48) / 2.54);
            this.athleteForm.controls["height"].setValue(
              feet + `\'` + inches + `\"`,
            );
            this.heightType = 1;
          }
        }

        if (athlete.weight) {
          if (athlete.weight.isInMetric) {
            this.athleteForm.controls["weight"].setValue(
              athlete.weight.valueInKilograms,
            );
              this.athleteForm.controls["weightUnit"].setValue(EMeasurementSystem.METRIC);
          } else {
            this.athleteForm.controls["weight"].setValue(
              athlete.weight.valueInKilograms,
            );
              this.athleteForm.controls["weightUnit"].setValue(EMeasurementSystem.IMPERIAL);
          }
        }

        this.athleteForm.controls["gender"].setValue(athlete.gender);

        this.image = athlete.profilePictureUrl;
        this.athleteForm.controls["dominantLeg"].setValue(athlete.dominantLeg);
        this.athleteForm.controls["email"].setValue(athlete.email);
        this.changeTeamMode("select");
        this.isLoading = false;
      },
      (error) => {
        if (error.status === 400) {
          this.router.navigate(["/athletes"]);
        }
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  updateTeamListByResponse(response: Team[]): void {
    this.teamList = response;
    this.teamListTypeahead = this.teamList.map((team) => team.name);
    this.teamListTypeahead.unshift("No Team");
  }

  initTeamsList() {
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getTeams(locationId).subscribe(
      (response) => {
        this.updateTeamListByResponse(response);
      },
      (error) => this.notificationService.error(error),
    );
  }

  updateSelectedSport(event: any) {
    this.selectedSport = event;
    if (this.selectedSport?.id === null) {
      this.athleteForm.controls["position"].setValue(0);
      return;
    }
    this.saveAthleteService
      .getSportsPositions(this.selectedSport.id)
      .subscribe((response) => {
        this.selectedSport.positions = response;
        if (
          this.selectedSport.positions &&
          this.selectedSport.positions.length > 0
        ) {
          this.athleteForm.controls["position"].setValue(
            this.selectedSport.positions.find(
              (position) => position.id === this.selectedPosition.id,
            ) || this.selectedSport.positions[0],
          );
        } else {
          this.athleteForm.controls["position"].setValue(0);
        }
      });
  }

  openBackModal() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message:
            "Are you sure you want to go back? All changes will be discarded!",
          title: "Confirmation",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["/athletes"]);
        }
      });
  }

  public handleHeightInput(value) {
    this.athleteForm.controls["height"].setValue(
      this.transformHeightUnit(value, this.heightType),
    );
  }

  private transformHeightUnit(value, heightType) {
    const isImperialUnit = new RegExp(/^\d+'(\d+")?$/gm).test(value);

    if (!Boolean(value) || (isNaN(value) && !isImperialUnit)) {
      return "";
    }

    switch (heightType) {
      case 0: //metric
        if (isImperialUnit) {
          const [_, feet, inches]: any = new RegExp(/^(\d+)'(\d+)"$/).exec(
            value,
          );
          return Math.floor(feet * 30.48 + inches * 2.54);
        }
        return value;
      case 1: //imperial
        if (isImperialUnit) {
          return value;
        }
        let decimalValue = Number(value);
        if (!isImperialUnit && Number.isInteger(decimalValue)) {
          decimalValue = (value * 0.3937) / 12;
        }
        const feet = Math.floor(decimalValue);
        const inches = Math.round((decimalValue - feet) * 12);
        return `${feet || "0"}'${inches || "0"}"`;
    }
  }

  changeWeightType(value) {
    if (!this.athleteForm.controls["weight"].valid) {
      return;
    }
    this.athleteForm.controls["weight"].setValue(
        this.transformWeightUnit(this.athleteForm.controls["weight"].value, value),
    );
    this.athleteForm.controls["weight"].updateValueAndValidity();
  }

  private transformWeightUnit(value, weightType) {
    switch (weightType) {
      case 0: //metric
        return (value / 2.2046).toFixed(2);

      case 1: //imperial
        return Math.round(value * 2.2046);
    }
  }

  changeDateOfBirth(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dateOfBirth = moment(event.value).format("MMM D YYYY");
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.globalSub.unsubscribe();
  }

  get hasLevel(): boolean {
    return !(this.athleteForm.controls['level'].value.name === "No Level" ||
        this.athleteForm.controls['level'].value.name === "Normal Routine" ||
        this.athleteForm.controls['level'].value.name === "Recreational");
  }

  get category(): FormControl {
    return this.athleteForm.get("category") as FormControl;
  }

  get team(): FormControl {
    return this.athleteForm.get("team") as FormControl;
  }

  get dateOfBirthControl(): FormControl {
    return this.athleteForm.get("dateOfBirth") as FormControl;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
