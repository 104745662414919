<mat-form-field class="w-full" appearance="outline">
    <mat-label>Body Part</mat-label>
    <mat-select [formControl]="_control">
        <mat-option [value]="EBodyPart.BothArms">
            Both Arms
        </mat-option>
        <mat-option [value]="EBodyPart.BothLegs">
            Both Legs
        </mat-option>
        <mat-option [value]="EBodyPart.LeftArm">
            Left Arm
        </mat-option>
        <mat-option [value]="EBodyPart.LeftLeg">
            Left Leg
        </mat-option>
        <mat-option [value]="EBodyPart.RightArm">
            Right Arm
        </mat-option>
        <mat-option [value]="EBodyPart.RightLeg">
            Right Leg
        </mat-option>
    </mat-select>
</mat-form-field>
