import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/services/auth.service";
import {AccountControllerService} from "../../core/api/controllers/account-controller.service";
import {NotificationService} from "../../core/services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidationHelperService} from "../../core/helpers/validation-helper.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  resetForm =  new FormGroup({
    loginUsernameEmail: new FormControl("", [
      Validators.required,
    ]),
  });
  isMobile = false;
  isLoading = false;

  constructor(
    private authService: AuthService,
    private resetPasswordService: AccountControllerService,
    private notificationService: NotificationService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  get loggedIn() {
    return this.authService.loggedIn();
  }

  ngOnInit() {
    if (
      this.activeRoute.snapshot.url &&
      this.activeRoute.snapshot.url.length > 1 &&
      this.activeRoute.snapshot.url[1].path == "m"
    )
      this.isMobile = true;
  }

  reset() {
    if (!this.resetForm.valid) {
      this.resetForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    this.resetPasswordService
      .requestNewPassword(this.resetForm.controls["loginUsernameEmail"].value)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.router.navigateByUrl("/login");
          this.notificationService.success(
            "Thank you, you will receive an email with instructions",
          );
        },
        ({ error }) => {
          this.isLoading = false;

          this.notificationService.error(
            error.error
              ? error.error
              : "Sorry, we could not find an user with this username/email",
          );
        },
      );
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
