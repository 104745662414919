import {Component, OnInit} from "@angular/core";
import {NotificationService} from "../../core/services/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateInjuryDialogComponent} from "./create-injury-dialog/create-injury-dialog.component";
import {getInjuryLocationName, IInjury} from "../../shared/interfaces/IInjury";
import {InjuriesControllerService} from "../../core/api/controllers/injuries-controller.service";

export interface IInjuriesLocation {
  name: string;
  injuries: IInjury[];
}

@Component({
  selector: 'app-injuries',
  templateUrl: './injuries.component.html',
  styleUrls: ['./injuries.component.scss']
})
export class InjuriesComponent implements OnInit {
  fetchedInjuries: IInjury[];
  injuries: IInjury[];
  injuriesLocations: IInjuriesLocation[];
  isLoading = false;
  searchField = "";

  constructor(
      public injuriesController: InjuriesControllerService,
      public notificationService: NotificationService,
      private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.getInjuries();
  }

  getInjuries(): void {
    this.isLoading = true;
    this.injuriesController.getInjuries().subscribe({
      next: (res) => {
        this.fetchedInjuries = res;
        this.search();
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      }
    })
  }

  search(): void {
      if(this.searchField === "") {
        this.injuries = this.fetchedInjuries;
      } else {
        this.injuries = this.fetchedInjuries.filter(sport => sport.name.toLowerCase().includes(this.searchField.toLowerCase()))
      }
      this.injuriesLocations = this.computeInjuriesLocations();
  }

  openCreateSportDialog(): void {
    this.dialog.open(CreateInjuryDialogComponent, {
     maxWidth: "800px",
     maxHeight: "400px",
      width: "90vw"
    }).afterClosed().subscribe(res => {
      if(res) this.getInjuries();
    })
  }

  toggleIsActive(injury: IInjury): void {
    this.isLoading = true;
    injury.isActive = !injury.isActive;
    this.injuriesController.editInjury(injury).subscribe({
      next: (res) => {
        injury = res;
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      }
    })
  }

  computeInjuriesLocations(): IInjuriesLocation[] {
    let locationKeys = [ ...new Set(this.injuries.map(injury => injury.injuryLocation)) ].sort((a, b) => a > b ? 1 : -1);
    const injuriesLocations: IInjuriesLocation[] = [];
    locationKeys.forEach(location => {
      let locationInjuries: IInjury[] = [];
      this.injuries.forEach(injury => {
        if(injury.injuryLocation === location) {
          locationInjuries.push(injury)
        }
      });
      injuriesLocations.push({name: getInjuryLocationName(location), injuries: locationInjuries})
    });
    return injuriesLocations;
  }
}
