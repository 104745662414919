import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { Category, ICategory } from "../../../shared/interfaces/ICategory";
import { map } from "rxjs/operators";
import { ApiService } from "../api.service";
import * as moment from "moment/moment";

@Injectable({
  providedIn: "root",
})
export class ExerciseControllerService {
  private readonly _resourceUrl: string = "/exercise";

  constructor(private apiService: ApiService) {}

  getLeaderboardForExercise(
    exerciseId: string,
    timeframe: number,
    gmtOffset: string = moment().format("Z"),
  ): Observable<any> {
    return this.apiService.get(
      `/leaderboard`,
      {
        exerciseId,
        timeframe,
        gmtOffset,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getExercise(exerciseId: string, playlistId: string) {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {
        id: exerciseId,
        playlist: playlistId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getCategories(locationId): Observable<Category[]> {
    return this.apiService
      .get<
        ICategory[]
      >(`${this._resourceUrl}/categories`, { locationId }, AuthService.defaultTokenHeader().headers)
      .pipe(
        map((response) => response.map((category) => new Category(category))),
      );
  }

  saveCategory(data): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/categories`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  updateCategory(data): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/categories`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteCategory(selectedCategory: string) {
    return this.apiService.delete(
      `${this._resourceUrl}/categories/${selectedCategory}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
