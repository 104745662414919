import {Pipe, PipeTransform} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {IAthleteForm} from "../../features/athletes/athletes.component";

export interface IAthletesCategoryForm {
  categoryName: FormControl<string>;
  athletes: FormArray<FormGroup<IAthleteForm>>
}

@Pipe({standalone: true, name: "athletesGroupBy"})
export class AthletesGroupBy implements PipeTransform {

  transform(athletesForm: FormGroup<{ athletes: FormArray<FormGroup<IAthleteForm>>}>): IAthletesCategoryForm[] {
    return this.groupBy(athletesForm.controls.athletes.controls, athlete => athlete.controls.athleteObject.value.categoryName);
  }

  groupBy(
      list: FormGroup<IAthleteForm>[],
      keyGetter: (athlete: FormGroup<IAthleteForm>) => string
  ): IAthletesCategoryForm[] {
    const map = new Map<string, FormGroup<IAthleteForm>[]>();

    list.forEach((athlete) => {
      const categoryName = keyGetter(athlete);
      if (!map.has(categoryName)) {
        map.set(categoryName, []);
      }
      map.get(categoryName)!.push(athlete);
    });

    return Array.from(map, ([categoryName, athletes]) => ({
      categoryName: new FormControl(categoryName, {nonNullable: true}),
      athletes: new FormArray(athletes)
    }));
  }
}
