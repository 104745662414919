<div>
  <div class="header">Your subscription</div>
  <div class="content">
    <div class="ui centered dividing header">
      <p>Hello!</p>
      <p>
        You have to pay ${{ paymentData.amount | format: 2 }} for your
        {{ paymentData.subscriptionName }} plan!
      </p>
      <p>
        You can still use the application for
        {{ 7 - paymentData.triesToReBill }} days.
      </p>
    </div>
    <form class="ui form" [formGroup]="subscriptionPaymentForm">
      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">First Name on Account</label>
          <input
            class="form-control-register"
            type="text"
            formControlName="firstName"
            placeholder="First name"
            (focus)="
              subscriptionPaymentForm.controls['firstName'].markAsUntouched()
            "
          />
          <div
            *ngIf="
              !subscriptionPaymentForm.controls['firstName'].valid &&
              subscriptionPaymentForm.controls['firstName'].touched
            "
            class="ui pointing red basic label"
          >
            First name must not be empty and cannot contain special characters.
          </div>
        </div>

        <div class="field">
          <label class="big-label right-pushed">Last Name on Account</label>
          <input
            class="form-control-register"
            type="text"
            formControlName="lastName"
            placeholder="Last name"
            (focus)="
              subscriptionPaymentForm.controls['lastName'].markAsUntouched()
            "
          />
          <div
            *ngIf="
              !subscriptionPaymentForm.controls['lastName'].valid &&
              subscriptionPaymentForm.controls['lastName'].touched
            "
            class="ui pointing red basic label"
          >
            Last name must not be empty and cannot contain special characters.
          </div>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">Card Number</label>
          <input
            class="form-control-register"
            type="number"
            formControlName="cardNumber"
            placeholder="Card number"
            (focus)="
              subscriptionPaymentForm.controls['cardNumber'].markAsUntouched()
            "
          />
          <div
            *ngIf="
              !subscriptionPaymentForm.controls['cardNumber'].valid &&
              subscriptionPaymentForm.controls['cardNumber'].touched
            "
            class="ui pointing red basic label"
          >
            This is not a valid or supported card number format.
          </div>
        </div>

        <div *ngIf="useMonthVector" class="field select-field">
          <label class="big-label right-pushed">Expiration Month</label>
          <select
            class="form-control-register"
            [(ngModel)]="selectedMonth"
            [ngModelOptions]="{ standalone: true }"
          >
            <option *ngFor="let month of monthVector" [ngValue]="month">
              {{ month }}
            </option>
          </select>
        </div>

        <div *ngIf="!useMonthVector" class="field select-field">
          <label class="big-label right-pushed">Expiration Month</label>
          <select
            class="form-control-register"
            [(ngModel)]="selectedMonth"
            [ngModelOptions]="{ standalone: true }"
          >
            <option *ngFor="let month of currentMonthVector" [ngValue]="month">
              {{ month }}
            </option>
          </select>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">CVV</label>
          <input
            class="form-control-register"
            type="texy"
            formControlName="CVV"
            placeholder="CVV"
            (focus)="subscriptionPaymentForm.controls['CVV'].markAsUntouched()"
          />
          <div
            *ngIf="
              subscriptionPaymentForm.controls['CVV'].hasError(
                'invalidAmexCVV'
              ) && subscriptionPaymentForm.controls['CVV'].touched
            "
            class="ui pointing red basic label"
          >
            The CVV must be 3 or 4 digits.
          </div>
          <div
            *ngIf="
              subscriptionPaymentForm.controls['CVV'].hasError('invalidCVV') &&
              subscriptionPaymentForm.controls['CVV'].touched
            "
            class="ui pointing red basic label"
          >
            The CVV must be 3 digits.
          </div>
        </div>

        <div class="field select-field">
          <label class="big-label right-pushed">Expiration Year</label>
          <select
            class="form-control-register"
            [(ngModel)]="selectedYear"
            (change)="validateDate()"
            [ngModelOptions]="{ standalone: true }"
          >
            <option *ngFor="let year of yearVector" [ngValue]="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="actions">
    <button
      type="button"
      class="negative ui button"
      *ngIf="paymentData?.triesToReBill < 7"
      (click)="modal.deny(); paymentData = undefined"
    >
      Pay Later
    </button>
    <button
      type="button"
      class="qb-blue-background positive ui button"
      [disabled]="!subscriptionPaymentForm.valid || attemptingPurchase"
      (click)="attemptPayment()"
    >
      <ng-container *ngIf="!attemptingPurchase">Purchase</ng-container>
      <ng-container *ngIf="attemptingPurchase">Purchasing...</ng-container>
    </button>
  </div>
</div>
