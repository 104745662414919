import { Component, Input, OnInit } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { MediaControllerService } from "../../../core/api/controllers/media-controller.service";
import { NotificationService } from "../../../core/services/notification.service";
import { FormControl } from "@angular/forms";
import { ValidationHelperService } from "../../../core/helpers/validation-helper.service";
import { MatDialog } from "@angular/material/dialog";
import { ImagePreviewComponent } from "../image-preview/image-preview.component";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})
export class ImageUploaderComponent implements OnInit {
  @Input() public _control: FormControl;
  isImgThumbUploading = false;
  imgUploader: FileUploader;

  constructor(
    private mediaController: MediaControllerService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initImageUploader();
  }

  initImageUploader(): void {
    this.imgUploader = new FileUploader({
      queueLimit: 1,
    });

    this.imgUploader.onAfterAddingFile = ({ file }) => {
      if (file.type.indexOf("image") >= 0 && file.size <= 200000000) {
        const data = new FormData();
        data.append("file", file.rawFile);
        this.uploadImage(data);
      } else {
        this.notificationService.warn("Invalid image type or size submitted!");
        this._control.setValue(null);
      }
    };
  }

  uploadImage(data: FormData): void {
    this.isImgThumbUploading = true;
    this.mediaController.mediaUpload(data).subscribe({
      next: (res) => {
        this._control.setValue(res.url);
        this.isImgThumbUploading = false;
      },
      error: () => {
        this.isImgThumbUploading = false;
      },
    });
  }

  openImageDialog(): void {
    this.dialog.open(ImagePreviewComponent, {
      data: {
        img: this._control.value,
      },
    });
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
