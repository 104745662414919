import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthControllerService } from "../api/controllers/auth-controller.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  jwtHelper;

  constructor(
    private authController: AuthControllerService,
    private router: Router,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  static getOauthTokenFromStorage() {
    return localStorage.getItem("token") || sessionStorage.getItem("token");
  }

  static getOauthRefreshTokenFromStorage() {
    return (
      localStorage.getItem("refresh_token") ||
      sessionStorage.getItem("refresh_token")
    );
  }

  static clearStorageData() {
    AuthService.removeOauthTokenFromStorage();
    AuthService.removeOauthRefreshTokenFromStorage();
  }

  static removeOauthTokenFromStorage() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
  }

  static removeOauthRefreshTokenFromStorage() {
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("refresh_token");
  }

  static defaultTokenHeader() {
    return { headers: AuthService.setTokenHeader() };
  }

  static setTokenHeader(): HttpHeaders {
    return new HttpHeaders().set(
      "Authorization",
      "Bearer " + AuthService.getOauthTokenFromStorage(),
    );
  }

  loggedIn() {
    return !this.jwtHelper.isTokenExpired(
      AuthService.getOauthTokenFromStorage(),
    );
  }

  checkTokenAccess(role: string): boolean {
    const local_token = AuthService.getOauthTokenFromStorage();

    if (local_token !== null) {
      const token = this.jwtHelper.decodeToken(local_token);

      if (token.roles != null) {
        return !!token.roles.includes(role);
      }
    }
    return false;
  }

  checkAcconutType(type): boolean {
    const local_token = AuthService.getOauthTokenFromStorage();
    if (local_token != null) {
      const token = this.jwtHelper.decodeToken(local_token);
      if (token.accountType != null) {
        return token.accountType == type;
      }
    }
    return false;
  }

  logout() {
    AuthService.clearStorageData();
    this.router.navigate(["/login"]);
    localStorage.clear();
    sessionStorage.clear();
  }
}
