<div class="header-wrapper">
  <div class="mobile-create">
    <button
      type="button"
      class="qb-blue-background primary ui button inline-display left-floated"
      (click)="filtering = true"
      *ngIf="!filtering"
    >
      Filters
    </button>
    <button
      type="button"
      class="qb-blue-background primary ui button inline-display left-floated"
      (click)="filtering = false"
      *ngIf="filtering"
    >
      Done
    </button>
    <button
      type="button"
      class="qb-blue-background primary ui button inline-display right-floated"
      (click)="createActivityData()"
    >
      Add Activity
    </button>
  </div>
</div>

<div class="container">
  <div class="top-wrapper">
    <div class="top-row-wrapper">
      <button
        type="button"
        class="qb-blue-background primary ui button inline-display right-floated"
        (click)="createActivityData()"
      >
        Add Activity
      </button>
      <p class="inline-display top-header large-text bold-text">
        Activity Data
      </p>
    </div>
  </div>

  <div class="ui clearing divider"></div>
  <div class="ui hidden divider"></div>

  <div class="active-filters-list box-shadow-bottom">
    <div class="ui grid">
      <div class="filter-column column">
        <p class="filter-type">Filters</p>

        <div class="ui large label flex-center">
          <p class="inline-display filter-name">Filter</p>

          <i class="delete icon inline-display clickable"> </i>
        </div>
        <div class="ui large label flex-center">
          <p class="inline-display filter-name">Filter</p>

          <i class="delete icon inline-display clickable"> </i>
        </div>
        <div class="ui large label flex-center">
          <p class="inline-display filter-name">Filter</p>

          <i class="delete icon inline-display clickable"> </i>
        </div>
        <div class="ui large label flex-center">
          <p class="inline-display filter-name">Filter</p>

          <i class="delete icon inline-display clickable"> </i>
        </div>
        <div class="ui large label flex-center">
          <p class="inline-display filter-name">Filter</p>

          <i class="delete icon inline-display clickable"> </i>
        </div>
      </div>
    </div>
  </div>

  <div class="flex content-wrapper">
    <div
      [class]="{ toggled: !filtering }"
      class="filter-athlete-wrapper overflowing"
    >
      <div class="filters-list inline-display">
        <div class="filter box-shadow-bottom" id="delete">
          <div title class="medium-font medium-text">
            <span
              >Delete all terms
              <i class="plus icon delete-terms"></i>
            </span>
          </div>
        </div>

        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="filter box-shadow-bottom">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div title class="medium-text medium-font">
                <span
                  >Category
                  <ng-container> (3) </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div content>
                <!--                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox class="medium-label">
                    <p class="filter-text">Filter name</p>
                  </sui-checkbox>
                </div>-->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
