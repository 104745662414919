import { FormArray, FormControl, FormGroup } from "@angular/forms";

export class FormHelper {
  static clone(
    formControl: FormControl | FormGroup | FormArray,
  ): FormControl | FormGroup | FormArray {
    if (formControl instanceof FormControl) {
      const newFormControl = new FormControl(
        formControl?.value,
        formControl?.validator,
      );
      newFormControl.valueChanges.subscribe((value) => {
        formControl.setValue(value, { emitEvent: false });
        formControl.markAsTouched();
      });
      formControl.valueChanges.subscribe((value) => {
        newFormControl.setValue(value, { emitEvent: false });
        newFormControl.markAsTouched();
      });
      return newFormControl;
    } else if (formControl instanceof FormGroup) {
      const newFormGroup = new FormGroup({});
      Object.keys(formControl.controls).forEach((key) => {
        if (formControl.controls[key] instanceof FormGroup) {
          newFormGroup.addControl(
            key,
            FormHelper.clone(formControl.controls[key] as FormGroup),
          );
        } else if (formControl.controls[key] instanceof FormControl) {
          newFormGroup.addControl(
            key,
            FormHelper.clone(formControl.controls[key] as FormControl),
          );
        } else if (formControl.controls[key] instanceof FormArray) {
          newFormGroup.addControl(
            key,
            FormHelper.clone(formControl.controls[key] as FormArray),
          );
        }
      });
      return newFormGroup;
    } else if (formControl instanceof FormArray) {
      const newFormArray = new FormArray([]);
      formControl.controls.forEach((control) => {
        if (control instanceof FormGroup) {
          newFormArray.push(FormHelper.clone(control as FormGroup));
        } else if (control instanceof FormControl) {
          newFormArray.push(FormHelper.clone(control as FormControl));
        } else if (control instanceof FormArray) {
          newFormArray.push(FormHelper.clone(control as FormArray));
        }
      });
      return newFormArray;
    }
    return null;
  }
}
