import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ApiService} from "../api.service";
import {IGetExercisesReq} from "../../../shared/interfaces/DTOs/Request/IGetExercisesReq";
import {IGetExercisesRes} from "../../../shared/interfaces/DTOs/Response/IGetExercisesRes";
import {IGetActiveCategoriesReq} from "../../../shared/interfaces/DTOs/Request/IGetActiveCategoriesReq";
import {IGetActiveCategoriesRes} from "../../../shared/interfaces/DTOs/Response/IGetActiveCategoriesRes";
import {ICategory} from "../../../shared/interfaces/ICategory";
import {IGetExercisesLeaderboardReq} from "../../../shared/interfaces/DTOs/Request/IGetExercisesLeaderboardReq";
import {IGetExercisesLeaderboardRes} from "../../../shared/interfaces/DTOs/Response/IGetExercisesLeaderboardRes";

@Injectable({
  providedIn: "root",
})
export class ExercisesControllerService {
  private readonly _resourceUrl: string = "/exercises";

  constructor(private apiService: ApiService) {}

  removeExercise(id: string): Observable<any> {
    return this.apiService.delete(
      `${this._resourceUrl}`,
      {
        id,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  moveToCategory({ id, categoryId }): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/move-to-category`,
      {},
      { id, categoryId },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getActiveCategories(
    payload: IGetActiveCategoriesReq,
  ): Observable<IGetActiveCategoriesRes> {
    return this.apiService.get<IGetActiveCategoriesRes>(
      `${this._resourceUrl}/categories/active`,
      payload,
      AuthService.defaultTokenHeader().headers,
    );
  }


  getLeaderboard(
      payload: IGetExercisesLeaderboardReq,
  ): Observable<IGetExercisesLeaderboardRes> {
    return this.apiService.get<IGetExercisesLeaderboardRes>(
        `${this._resourceUrl}/leaderboard`,
        payload,
        AuthService.defaultTokenHeader().headers,
    );
  }

  getExerciseDetails(
    page: number,
    size: number,
    exercisesToFilter,
    search: string = "",
  ): Observable<any> {
    const details =
      search == ""
        ? `?page=${page}&size=${size}`
        : `?page=${page}&size=${size}&search=${search}`;
    return this.apiService.post(
      "/exercise-details" + details,
      exercisesToFilter,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getCategories(locationId?: string): Observable<ICategory[]> {
    return this.apiService.get(
      `${this._resourceUrl}/categories-with-exercises`,
      { locationId: locationId ?? localStorage.getItem("selectedLocationId") },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getPairedExercise(id: string): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/paired-exercise`,
      { id },
      AuthService.defaultTokenHeader().headers,
    );
  }

  savePairedExercise(data): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/paired-exercise`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  editPairedExercise(data): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/paired-exercise`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getRegularExercise(id: string): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/regular-exercise`,
      {
        id,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  createRegularExercise(data): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/regular-exercise`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  editRegularExercise(data): Observable<any> {
    return this.apiService.put(
      `${this._resourceUrl}/regular-exercise`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  findAllByCategory(locationId): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/categories-with-exercises`,
      { locationId },
      AuthService.defaultTokenHeader().headers,
    );
  }

  setField(data): Observable<any> {
    return this.apiService.post(
      "/set-exercise-field",
      data,
      AuthService.defaultTokenHeader(),
    );
  }

  addExerciseToAll(exerciseId: string[]) {
    let body = { exercisesId: exerciseId };
    return this.apiService.post(
      `/add-exercise-to-all?exerciseId=${exerciseId}`,
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  updateExerciseVideo(exerciseId: string) {
    let body = {};
    return this.apiService.post(
      `/updateExerciseVideo?exId=${exerciseId}`,
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getExercisesOrganization({ page, itemsPerPage, search }): Observable<any> {
    return this.apiService.get(
      "/admin/organizations-with-locations",
      {
        page,
        itemsPerPage,
        search,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  addExercisesToLocation(
    exercisesIds: string[],
    locationId: string,
  ): Observable<any> {
    return this.apiService.post(
      "/admin/push-exercises-to-location",
      {},
      { id: exercisesIds, locationId },
      AuthService.defaultTokenHeader().headers,
    );
  }

  updateVideos(exercisesIds, locationsIds): Observable<any> {
    let body = { exercisesIds, locationsIds };

    return this.apiService.post(
      "/update-videos",
      body,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  getExercises(payload: IGetExercisesReq): Observable<IGetExercisesRes> {
    return this.apiService.get<IGetExercisesRes>(`${this._resourceUrl}`, {
      ...payload,
    });
  }
}
