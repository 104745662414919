import { Component, Input, OnInit } from "@angular/core";
import { ValidationHelperService } from "../../../core/helpers/validation-helper.service";
import { FormControl, Validators } from "@angular/forms";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from "../confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../../core/services/notification.service";
import { Team } from "../../interfaces/ITeam";
import { EAction } from "../../enums/EAction";
import { AthletesControllerService } from "../../../core/api/controllers/athletes-controller.service";

@Component({
  selector: "app-select-team",
  templateUrl: "./select-team.component.html",
  styleUrls: ["./select-team.component.scss"],
})
export class SelectTeamComponent implements OnInit {
  @Input() _control: FormControl;
  @Input() action: EAction;
  _modifyControl = new FormControl("", [Validators.required]);
  teamMode = "select";
  @Input() teamList: Team[];
  teamListTypeahead = null;
  isLoading = false;

  protected readonly ValidationHelperService = ValidationHelperService;

  constructor(
    private dialog: MatDialog,
    private athletesController: AthletesControllerService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    if (this.action === EAction.Add) {
      this.updateTeamList();
    } else {
      this.updateTeamListByResponse(this.teamList);
    }
  }

  openConfirmModal() {
    const data: IConfirmationDialogData = {
      title: "Remove team",
      message: `Are you sure you want to delete the following team: ${this._control.value.name}?`,
      cancelButtonLabel: "Cancel",
      confirmButtonLabel: "Delete",
    };

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteAthleteTeam();
        }
      });
  }

  deleteAthleteTeam() {
    this.athletesController.deleteTeam(this._control.value.id).subscribe(
      () => {
        this.updateTeamList();
        this._control.setValue("");
        this.notificationService.success("Athlete team deleted successfully.");
      },
      (error) =>
        error.status === 409
          ? this.notificationService.warn(error)
          : this.notificationService.error(error),
    );
  }

  updateTeamList() {
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getTeams(locationId).subscribe(
      (response) => {
        this.updateTeamListByResponse(response);
      },
      (error) => this.notificationService.error(error),
    );
  }

  updateTeamListByResponse(response: Team[]): void {
    this.teamList = response;
    this.teamListTypeahead = this.teamList.map((team) => team.name);
  }

  selectTeamObject(team: { value: string }) {
    this.selectTeam(team.value);
  }

  selectTeam(newTeam: string) {
    for (const team of this.teamList) {
      if (team.name === newTeam) {
        this._control.setValue(team);
      }
    }
  }

  updateAthleteTeam() {
    const updatedTeamName = this._modifyControl.value;
    // Check if the team already exists
    let found = false;
    for (const team of this.teamListTypeahead) {
      if (team.toLowerCase().trim() === updatedTeamName.toLowerCase().trim()) {
        found = true;
        break;
      }
    }
    if (found) {
      this.notificationService.error("This team already exists.");
      return;
    }

    this.athletesController
      .updateTeam({
        name: updatedTeamName,
        id: this._control.value.id,
      })
      .subscribe(
        (response) => {
          this.updateTeamListByResponse(response);
          this.selectTeam(updatedTeamName);
          this.notificationService.success(
            "Athlete team updated successfully.",
          );
        },
        (error) => this.notificationService.error(error),
      );
  }

  addAthleteTeam(): void {
    const newTeamName = this._modifyControl.value;
    const locationId = localStorage.getItem("selectedLocationId");
    let found = false;
    for (const team of this.teamListTypeahead) {
      if (team.toLowerCase().trim() === newTeamName.toLowerCase().trim()) {
        found = true;
        break;
      }
    }
    found
      ? this.notificationService.error("This team already exists.")
      : this.saveTeam(newTeamName, locationId);
  }

  saveTeam(newTeamName: string, locationId: any): void {
    this.isLoading = true;
    this.teamMode = "select";
    this.athletesController
      .saveTeam({
        name: newTeamName,
        locationId,
      })
      .subscribe(
        (response) => {
          this.updateTeamListByResponse(response);
          this.selectTeam(newTeamName);
          this.isLoading = false;
          this.notificationService.success("Athlete team added successfully.");
        },
        (error) => {
          this.isLoading = false;
          this.notificationService.error(error);
        },
      );
  }

  closeTeam(): void {
    this.teamMode = "select";
    this.updateEditTeamList(this._control.value.id);
  }

  updateEditTeamList(teamId: string) {
    const locationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getTeams(locationId).subscribe(
      (response) => {
        this.teamList = response;
        this.teamListTypeahead = this.teamList.map((team) => team.name);
        if (!teamId) {
          return;
        }
        for (const team of this.teamList) {
          if (team.id === teamId) {
            this._control.setValue(team);
          }
        }
        if (this._control.value === "") {
          this.notificationService.error("Could not find the athlete's team.");
        }
      },
      (error) => this.notificationService.error(error),
    );
  }
}
