import {Component, Input} from "@angular/core";
import {IUserLocation} from "../../../../core/models/subscription.model";

@Component({
  selector: "app-location-badge",
  templateUrl: "./location-badge.component.html",
  standalone: true,
  styleUrls: ["./location-badge.component.scss"]
})
export class LocationBadgeComponent {
  @Input() location: IUserLocation;
}
