<div class="flex flex-col">
  <div class="flex gap-3 items-center justify-start py-3 border-b">
    <button
      (click)="fileInput.click()"
      type="button"
      mat-flat-button
      color="primary"
      class="flex justify-center items-center gap-3"
      [disabled]="isVideoUploading"
    >
      <span *ngIf="!isVideoUploading">{{
        !!_control?.value ? "Upload another video" : "Upload video"
      }}</span>
      <mat-icon *ngIf="!isVideoUploading">cloud_upload</mat-icon>
      <mat-spinner *ngIf="isVideoUploading" diameter="30"></mat-spinner>
      <input
        type="file"
        #fileInput
        name="file"
        ng2FileSelect
        [uploader]="videoUploader"
        (click)="_control.setValue(null); videoUploader.clearQueue()"
        accept="video/*"
        hidden
      />
    </button>
    <button
      mat-flat-button
      color="accent"
      type="button"
      (click)="openPreviewDialog()"
    >
      View current video
    </button>
  </div>
  <mat-error *ngIf="ValidationHelperService.isInvalid(_control)">
    {{ ValidationHelperService.getErrorMessage(_control) }}
  </mat-error>
</div>
