import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "endType",
})
export class EndTypePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return value === 1 ? "seconds" : "touches";
  }
}
