import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class CodeGuard implements CanActivate {
  jwtHelper;

  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate() {
    const localToken = AuthService.getOauthTokenFromStorage();
    if (localToken !== null) {
      const token = this.jwtHelper.decodeToken(localToken);

      if (token.roles != null) {
        // todo: wtf is ROLE_PRE_AUTH?!
        return token.roles.includes("ROLE_PRE_AUTH") ||
          token.roles.includes("ROLE_ADMIN_PRE_AUTH")
          ? this.refuse()
          : true;
      }
    }
    return true;
  }

  refuse(): boolean {
    this.router.navigate(["/login"]);
    return false;
  }
}
