<form [formGroup]="_formGroup" class="w-100 border-t pt-3">
  <div class="mb-5">
    <span class="h4">Countdowns</span>
  </div>

  <div class="flex flex-col gap-5 w-full">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Start Type</mat-label>
      <mat-select
        placeholder="Start"
        [formControl]="startType"
        [value]="startType.value"
      >
        <mat-option [value]="EExerciseStartType.FULL_COUNTDOWN"
          >Full Countdown</mat-option
        >
        <mat-option [value]="EExerciseStartType.RANDOM">Random</mat-option>
      </mat-select>
      <mat-error *ngIf="ValidationHelperService.isInvalid(startType)">
        {{ ValidationHelperService.getErrorMessage(startType) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="w-full"
      appearance="outline"
      *ngIf="startType.value === EExerciseStartType.FULL_COUNTDOWN"
    >
      <mat-label>After how many seconds</mat-label>
      <input
        type="number"
        [formControl]="startValue"
        matInput
        [value]="startValue.value"
      />
      <mat-error *ngIf="ValidationHelperService.isInvalid(startValue)">
        {{ ValidationHelperService.getErrorMessage(startValue) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>End Type</mat-label>
      <mat-select
        placeholder="End"
        [formControl]="endType"
        [value]="endType.value"
      >
        <mat-option [value]=ECountdownEndType.TOUCHES>Touches</mat-option>
        <mat-option [value]="ECountdownEndType.TIME">Seconds</mat-option>
      </mat-select>
      <mat-error *ngIf="ValidationHelperService.isInvalid(endType)">
        {{ ValidationHelperService.getErrorMessage(endType) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>After how many {{ endType.value | endType }}</mat-label>
      <input
        matInput
        type="number"
        [formControl]="endValue"
        [value]="endValue.value"
      />
      <mat-error *ngIf="ValidationHelperService.isInvalid(endValue)">
        {{ ValidationHelperService.getErrorMessage(endValue) }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
