import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ValidationHelperService } from "../../../../core/helpers/validation-helper.service";

@Component({
  selector: "app-exercise-config-form",
  templateUrl: "./exercise-config-form.component.html",
  styleUrls: ["./exercise-config-form.component.scss"],
})
export class ExerciseConfigFormComponent {
  @Input() _formGroup: FormGroup;
  @Input() _formControl: FormControl;
  protected readonly ValidationHelperService = ValidationHelperService;

  get difficulty(): FormControl {
    return this._formGroup.get("difficulty") as FormControl;
  }

  get numberOfLegs(): FormControl {
    return this.verticalForm.get("numberOfLegs") as FormControl;
  }

  get reactTo(): boolean[] {
    return this.reactForm.get("reactTo").value;
  }

  get react(): number[] {
    return this.reactTo
      .map((value, index) => (value ? index : null))
      .filter((value) => value !== null);
  }

  get dontReactTo(): boolean[] {
    return this.reactForm.get("dontReactTo").value;
  }

  get verticalForm(): FormGroup {
    return this._formGroup.get("verticalForm") as FormGroup;
  }

  get countdownsForm(): FormGroup {
    return this._formGroup.get("countdownForm") as FormGroup;
  }

  get startType(): FormControl {
    return this.countdownsForm.get("startType") as FormControl;
  }

  get startValue(): FormControl {
    return this.countdownsForm.get("startValue") as FormControl;
  }

  get endType(): FormControl {
    return this.countdownsForm.get("endType") as FormControl;
  }

  get endValue(): FormControl {
    return this.countdownsForm.get("endValue") as FormControl;
  }

  get reactForm(): FormGroup {
    return this._formGroup.get("reactForm") as FormGroup;
  }

  get neurocognitiveType(): FormControl {
    return this.reactForm.get("neurocognitiveType") as FormControl;
  }

  get countForm(): FormGroup {
    return this._formGroup.get("countForm") as FormGroup;
  }

  get countType(): FormControl {
    return this.countForm.get("countType") as FormControl;
  }

  get sequenceForm(): FormGroup {
    return this._formGroup.get("sequenceForm") as FormGroup;
  }

  get agilityForm(): FormGroup {
    return this._formGroup.get("agilityForm") as FormGroup;
  }

  get title(): FormControl {
    return this._formGroup.get("name") as FormControl;
  }

  get videoUrl(): FormControl {
    return this._formGroup.get("videoUrl") as FormControl;
  }

  get activeSensors(): boolean[] {
    return this.reactForm.get("activeSensors").value;
  }

  get timerPrompt(): FormControl {
    return this.reactForm.get("timerPrompt") as FormControl;
  }

  get sequenceSensors(): number[] {
    return this.sequenceForm.get("sequenceSensors").value;
  }

  get isolatedReact(): FormControl {
    return this.reactForm.get("isolatedReact") as FormControl;
  }

  get type(): FormControl {
    return this._formControl;
  }
}
