import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {Subject} from "rxjs";
import {NotificationService} from "../../../core/services/notification.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-sync-exercise",
  templateUrl: "./sync-exercise.component.html",
  styleUrls: ["./sync-exercise.component.scss"],
  providers: [ExercisesControllerService],
})
export class SyncExerciseComponent implements OnInit {
  showData: boolean;
  updateVideos: boolean;
  exerciseId: string[];
  paired: string;
  organizations: any = [];
  canLoadOrganizations = true;
  collapseFlag = false;
  organizationsLoaded = false;
  checkFinished = false;
  searchText = "";
  page = 0;

  private organizationName: Subject<string> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private exerciseService: ExercisesControllerService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(
      ({ exerciseId, exercisePaired, updateVideos }) => {
        if (exerciseId instanceof Array) {
          this.exerciseId = exerciseId;
        } else {
          this.exerciseId = [exerciseId];
        }

        this.paired = exercisePaired;

        this.updateVideos = updateVideos;
      },
    );

    this.getOrganizations();

    this.organizationName
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(
        () => {
          const page = 0;
          const itemsPerPage = 20;
          this.exerciseService
            .getExercisesOrganization({
              page,
              itemsPerPage,
              search: this.searchText,
            })
            .subscribe(({ data }) => {
              this.organizations = data;

              const canLoadLength = data.length;
              this.canLoadOrganizations =
                canLoadLength % 20 === 0 && canLoadLength !== 0;
              this.showData = true;
            });
        },
        (error) => {
          this.notificationService.error(error);
        },
      );
  }

  onSearch(search: string) {
    this.organizationName.next(search);
  }

  getOrganizations() {
    const itemsPerPage = 20;
    this.exerciseService
      .getExercisesOrganization({
        page: this.page,
        itemsPerPage,
        search: this.searchText,
      })
      .subscribe(
        ({ data, totalCount }) => {
          this.organizations.push(...data);

          this.showData = true;
          this.organizationsLoaded = true;

          if (this.organizations.length < totalCount) {
            this.page += 1;
          } else {
            this.canLoadOrganizations = false;
          }
        },
        (error) => {
          this.notificationService.error(error);
        },
      );
  }

  onSelectCollapse() {
    this.collapseFlag = !this.collapseFlag;
  }

  addExerciseToAll() {
    this.dialog.open(ConfirmationDialogComponent, {
        data: {
            title: 'Confirmation',
            message: `Are you sure you want to add the selected exercises to all locations?`
        }
    }).afterClosed().subscribe(res => {
        if(res) this.handleAddExerciseToAll();
    })
  }

  handleAddExerciseToAll(): void {
      this.exerciseService.addExerciseToAll(this.exerciseId).subscribe(
          (data) => {
              this.router.navigate(["/exercises"]);
              this.notificationService.success(data);
          },
          (error) => {
              this.notificationService.error(error);
          },
      );
  }

  syncExercise(loc, i, j) {
    this.dialog.open(ConfirmationDialogComponent, {
        data: {
            title: 'Confirmation',
            message: `Are you sure you want to add the selected exercises to the location ${this.organizations[i].locations[j].name} of the organization ${this.organizations[i].name}?`
        }
    }).afterClosed().subscribe(res => {
        if(res) this.handleSyncExercise(loc, i, j);
    })
  }

  handleSyncExercise(loc, i, j): void {
      this.exerciseService
          .addExercisesToLocation(this.exerciseId, loc.id)
          .subscribe(
              () => {
                  this.organizations[i].locations[j].synced = true;

                  this.notificationService.success("Update successfull");
              },
              (error) => this.notificationService.error(error),
          );
  }

  handleVideoUpdate(locationId, i, j) {
    this.exerciseService.updateVideos(this.exerciseId, [locationId]).subscribe(
      (status) => {
        this.organizations[i].locations[j].synced = true;

        if (status) {
          this.notificationService.success("Update successfull");
        } else {
          this.notificationService.error("Error!");
        }
      },
      (error) => this.notificationService.error(error),
    );
  }
}
