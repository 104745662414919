import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LocationsService } from "../services/locations.service";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  jwtHelper;

  constructor(
    private router: Router,
    private locationService: LocationsService,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const localToken = AuthService.getOauthTokenFromStorage();

    if (localToken !== null) {
      const token = this.jwtHelper.decodeToken(localToken);

      if (token.roles != null) {
        return token.roles.includes("User");
      }
    }
    return this.refuse();
  }

  refuse(): boolean {
    this.router.navigate(["/login"]);
    return false;
  }
}
