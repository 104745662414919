import { EMeasurementSystem } from "../../shared/enums/EMeasurementSystem";
import { IHeight } from "../../shared/interfaces/IHeight";

export class AtheleteUnitsAdapter {
  public static toCm(height: IHeight): IHeight {
    if (height.measurementSystem === EMeasurementSystem.METRIC) {
      return height;
    }
    const value = height.valueInCentimeters as string;
    const feetAndInches = value.split("'");
    const feet = parseInt(feetAndInches[0]);
    const inches = parseInt(feetAndInches[1]);
    const cm = Math.round(feet * 30.48 + inches * 2.54);
    return {
      valueInCentimeters: cm,
      measurementSystem: EMeasurementSystem.IMPERIAL,
    } as IHeight;
  }

  public static valueToCm(value: string | number): number {
    if (typeof value === "number") {
      return value;
    }

    const feetAndInches = value.split("'");
    const feet = parseInt(feetAndInches[0]);
    const inches = parseInt(feetAndInches[1]);
    return Math.round(feet * 30.48 + inches * 2.54);
  }
}
