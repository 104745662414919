import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomValidators } from "../../core/helpers/custom-validators";
import { NotificationService } from "../../core/services/notification.service";
import { AccountControllerService } from "../../core/api/controllers/account-controller.service";
import { ValidationHelperService } from "../../core/helpers/validation-helper.service";
import inOutAnimation from "../../shared/animations/in-out.animation";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  animations: [inOutAnimation],
})
export class ResetPasswordComponent implements OnInit {
  public resetPassForm: FormGroup<{
    password: FormControl<string>;
    confirmPassword: FormControl<string>;
  }>;

  constructor(
    private readonly resetPasswordService: AccountControllerService,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly activeRoute: ActivatedRoute,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.resetPassForm = this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            CustomValidators.passwordValidator(),
          ],
        ],
        confirmPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            CustomValidators.passwordValidator(),
          ],
        ],
      },
      {
        validator: CustomValidators.passwordMatchValidator,
      },
    );
  }

  reset() {
    this.resetPasswordService
      .resetPassword(
        this.resetPassForm.controls["password"].value,
        this.activeRoute.snapshot.params["token"],
      )
      .subscribe(
        () => {
          this.notificationService.success(
            "Thank you, password changed. You can login now.",
          );
          this.router.navigate(["/login"]);
        },
        (error) => {
          this.notificationService.error(
            "Sorry, invalid token or token expired",
          );
        },
      );
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
