<div class="w-full bg-gray-300 font-medium p-3 rounded-2xl">
    {{athletesCategory.categoryName.value}}
</div>
<div class="grid grid-cols-3 gap-5 flex-grow py-3">
    <div *ngFor="let athlete of athletesCategory.athletes.controls ">
          <app-athlete-block
                  (click)="athlete.controls.isSelected.setValue(!athlete.value.isSelected)"
                  [athlete]="athlete">
          </app-athlete-block>
    </div>
</div>
