import {Component, OnInit} from "@angular/core";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../core/services/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {EAction} from "../../../shared/enums/EAction";
import {Subscription} from "rxjs";
import {PairedExerciseFormFactory} from "../../../core/forms/factory/PairedExerciseFormFactory";
import {PairedExerciseHelper} from "../../../core/forms/helpers/PairedExerciseHelper";
import {FormGroup} from "@angular/forms";
import {IPairedExerciseForm} from "../../../shared/interfaces/forms/exercises/IPairedExerciseForm";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {IRegularExercise} from "../exercise-form/exercise-form.component";
import {EBodyPart} from "../../../shared/enums/EBodyPart";

export interface IPairedExercise {
  id: string
  defaultConfigId: string
  firstExercise: IRegularExercise
  secondExercise: IRegularExercise
  pairedExerciseConfigs: IPairedExerciseConfig[]
  name: string
  categoryId: string
  boardType: number
  bodyPart: EBodyPart
  type: number
  isFavorite: boolean
}

export interface IPairedExerciseConfig {
  id: string
  firstConfigId: string
  secondConfigId: string
}

@Component({
  selector: "app-save-paired-exercise",
  templateUrl: "./save-paired-exercise.component.html",
  styleUrls: ["./save-paired-exercise.component.scss"],
})
export class SavePairedExerciseComponent implements OnInit {
  action = EAction.Add;
  isLoading = false;
  sub: Subscription;
  id: string;
  exerciseForm: FormGroup<IPairedExerciseForm>;
  pairedExercise: IPairedExercise;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private exercisesController: ExercisesControllerService,
  ) {}

  ngOnInit(): void {
    this.exerciseForm = PairedExerciseFormFactory.create();
    this.identifyExerciseType();
  }

  identifyExerciseType(): void {
    this.sub = this.route.queryParams.subscribe(
      (params) => {
        this.id = params["id"];
        this.action = this.id ? EAction.Edit : EAction.Add;
        if (this.action === EAction.Edit) {
          this.retrieveExercise();
        }
      },
      (error) => this.notificationService.error(error),
    );
  }

  retrieveExercise(): void {
    this.isLoading = true;
    this.exercisesController.getPairedExercise(this.id).subscribe(
      (data: IPairedExercise) => {
        this.pairedExercise = data;
        PairedExerciseHelper.patchFormValue(this.exerciseForm, data);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.error(error);
      },
    );
  }

  openBackModal() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Confirmation",
          message:
            "Are you sure you want to go back? All changes will be discarded!",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["/exercises"]);
        }
      });
  }

  saveExercise(data: any): void {
    if (this.action === EAction.Add) {
      this.createPairedExercise(data);
    } else {
      this.editPairedExercise({
        ...data,
        id: this.id,
      });
    }
  }

  editPairedExercise(data: any): void {
    this.isLoading = true;
    this.exercisesController.editPairedExercise(data).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.success("Exercise saved successfully!");
        this.router.navigate(["/exercises"]);
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.error(error);
      },
    );
  }

  createPairedExercise(data: any): void {
    this.isLoading = true;
    this.exercisesController.savePairedExercise(data).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.success("Exercise saved successfully!");
        this.router.navigate(["/exercises"]);
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.error(error);
      },
    );
  }

  protected readonly EAction = EAction;
}
