import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../../core/services/notification.service";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";

export interface IMoveExercisesDialogData {
  exercisesSelected: any;
  destinationCategory: any;
  exercises: any;
  exerciseCategories: any;
}

@Component({
  selector: "app-move-exercises-dialog",
  templateUrl: "./move-exercises-dialog.component.html",
  styleUrls: ["./move-exercises-dialog.component.scss"],
})
export class MoveExercisesDialogComponent {
  destinationCategory;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMoveExercisesDialogData,
    private notificationService: NotificationService,
    private exercisesController: ExercisesControllerService,
    protected dialogRef: MatDialogRef<MoveExercisesDialogComponent>,
  ) {}

  moveToCategory() {
    const exercises = [];
    for (let i = 0; i < this.data.exercisesSelected.length; i++) {
      const currentExercise = this.data.exercisesSelected[i];
      if (currentExercise.categoryId !== this.destinationCategory) {
        exercises.push(currentExercise.id);
        this.data.exercises[currentExercise.categoryId].splice(
          this.data.exercises[currentExercise.categoryId].indexOf(
            currentExercise,
          ),
          1,
        );
        let indexToPush;
        const nameToCompare = currentExercise.name;
        for (
          let i = 0;
          i < this.data.exercises[this.destinationCategory].length;
          i++
        ) {
          if (
            nameToCompare <
            this.data.exercises[this.destinationCategory][i].name
          ) {
            indexToPush = i;
            break;
          }
        }
        indexToPush === undefined
          ? this.data.exercises[this.destinationCategory].push(currentExercise)
          : this.data.exercises[this.destinationCategory].splice(
              indexToPush,
              0,
              currentExercise,
            );
        currentExercise.categoryId = this.destinationCategory;
      }
    }
    this.exercisesController
      .moveToCategory({
        id: exercises,
        categoryId: this.destinationCategory,
      })
      .subscribe(
        () => {
          this.data.exercisesSelected = [];
          this.notificationService.success("Successfully moved exercises.");
        },
        (error) => this.notificationService.error(error),
      );
  }

  handleCategorySelect(e) {
    this.destinationCategory = e;
  }
}
