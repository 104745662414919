import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../core/services/notification.service";
import { ReportsControllerService } from "../../core/api/controllers/reports-controller.service";
import {
  IReportDialogData,
  ReportDialogComponent,
} from "../../shared/components/report-dialog/report-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {
  AddReportDialogComponent,
  IAddReportDialogData,
} from "../../shared/components/add-report-dialog/add-report-dialog.component";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: [
    "./reports.component.scss",
    "../athletes/athletes.component.scss",
  ],
  providers: [ReportsControllerService],
})
export class ReportsComponent implements OnInit {
  active = true;
  creatingReport = false;
  reports: any;
  height = window.innerHeight - 100;
  searchAddAthlete = "";
  filteredAthleteList = [];
  addAthletesSelected = [];
  athleteList = [];
  canLoadAthleteList;
  type;

  constructor(
    private notificationService: NotificationService,
    private reportService: ReportsControllerService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.updateReports();
    this.getAthletes();
  }

  updateReports() {
    this.reportService.getReports().subscribe(
      (response) => {
        this.reports = response;
      },
      (error) => this.notificationService.error(error),
    );
  }

  openGenerateModal() {
    this.type = 0;
    this.addAthletesSelected = [];
    this.searchAddAthlete = "";
    this.filteredAthleteList = Object.assign([], this.athleteList);
    const data: IAddReportDialogData = {
      type: this.type,
      searchAddAthlete: this.searchAddAthlete,
      filteredAthleteList: this.filteredAthleteList,
      addAthletesSelected: this.addAthletesSelected,
      canLoadAthleteList: this.canLoadAthleteList,
    };
    this.dialog
      .open(AddReportDialogComponent, {
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.generateReport();
        }
      });
  }

  openReportModal(report: any) {
    const data: IReportDialogData = {
      height: this.height,
      url: report.reportUrl,
    };
    this.dialog.open(ReportDialogComponent, {
      data: data,
    });
  }

  generateReport() {
    this.creatingReport = true;
    this.reportService
      .generateReport({
        athleteIds: this.addAthletesSelected,
        type: this.type,
      })
      .subscribe(
        () => {
          this.notificationService.success("Reports generated successfully");
          this.creatingReport = false;
          this.updateReports();
        },
        (error) => {
          this.notificationService.error(error);
          this.creatingReport = false;
        },
      );
  }

  deleteReport(id: number, i: number) {
    this.reportService.deleteReport(id).subscribe(
      () => {
        this.reports.splice(i, 1);
        this.notificationService.success("Report deleted successfully.");
      },
      (error) => this.notificationService.error(error),
    );
  }

  getAthletes() {
    const length = this.athleteList.length;
    const page = length > 0 ? Math.floor(length / 20) : 0;
    const size = 20;
    this.reportService.getAthletes(page, size, this.searchAddAthlete).subscribe(
      (response) => {
        if (this.searchAddAthlete == "") {
          for (const athlete of response) {
            this.athleteList.push(athlete);
          }
          this.filteredAthleteList = Object.assign([], this.athleteList);
        } else {
          for (const athlete of response) {
            this.filteredAthleteList.push(athlete);
          }
        }
        const athletes = response.length;
        this.canLoadAthleteList = athletes % 20 === 0 && athletes !== 0;
      },
      (error) => this.notificationService.error(error),
    );
  }
}
