export const ValidationMessageList = [
  { type: "notAnHour", message: "Is not a valid hour." },
  { type: "required", message: "This field is required." },
  { type: "isAlsoRequired", message: "This field is also required." },
  { type: "maxlength", message: "This field has exceeded its maximum length." },
  {
    type: "minlength",
    message: "The minimum length for this field has not been reached.",
  },
  { type: "pattern", message: "The format is invalid." },
  { type: "min", message: "This field cannot be negative." },
  { type: "max", message: "This field has exceeded its maximum value." },
  { type: "email", message: "This field value should be an email." },
  {
    type: "startHourIsGreaterThanEndHour",
    message: "Start hour cannot be greater than end hour.",
  },
  {
    type: "specialCharacterError",
    message: "This field cannot contain special characters.",
  },
  {
    type: "notAVendorCode",
    message: 'Vendor code cannot have the value "all" or "none".',
  },
  {
    type: "invalidNeurocognitiveArray",
    message:
      "Invalid neurocognitive array. There should be one color selected in the 'react to' field and as many colors selected in the 'don't react to' field as there are active sensors.",
  },
  {
    type: "invalidNeurocognitiveGo",
    message:
      "Invalid neurocognitive Go/ No Go. 'React to' and 'Don't react to' fields should have at least one color selected.",
  },
  {
    type: "invalidSequence",
    message: "The sequence has to contain between 1 and 200 steps.",
  },
  {
    type: "invalidArrows",
    message: "The arrows have to contain between 1 and 200 steps.",
  },
  {
    type: "invalidActiveArrows",
    message: "At least one sensor must be active.",
  },
  {
    type: "invalidSensors",
    message: "At least one sensor must be active.",
  },
  {
    type: "invalidReactSensors",
    message:
      "The number of sensors to react cannot exceed the number of active sensors.",
  },
  {
    type: "invalidDontReactSensors",
    message:
      "The number of sensors to not react cannot exceed the number of active sensors.",
  },
  {
    type: "invalidPassword",
    message:
      "The password must contain a lowercase letter, an uppercase letter, a number, a special character, and have min 8 characters.",
  },
  {
    type: "passwordMatch",
    message: "The new password and password confirmation field do not match.",
  },
  {
    type: "noWhitespace",
    message: "The field cannot contain whitespaces.",
  },
  {
    type: "usernameExists",
    message: "This username has already been taken.",
  },
  {
    type: "orgExists",
    message: "This organization name has already been taken.",
  },
  {
    type: "invalidAmexCVV",
    message: "The CVV is not valid. It should be 3 or 4 digits.",
  },
  {
    type: "invalidCVV",
    message: "The CVV is not valid.",
  },
  {
    type: "invalidPhone",
    message: "The phone number is not valid.",
  },
  {
    type: "invalidZipCode",
    message: "The zip code is not valid.",
  },
  {
    type: "invalidCardNumber",
    message: "The credit card number is not valid.",
  },
  {
    type: "noDigits",
    message: "The field cannot contain digits or special characters.",
  },
  {
    type: "invalidHeight",
    message: "The height is not valid.",
  },
  {
    type: "minReact",
    message: "You must select at least one 'react to' color.",
  }
];
