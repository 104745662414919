<form [formGroup]="_formGroup" class="select-none flex flex-col gap-5">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Neurocognitive</mat-label>
    <mat-select
      placeholder="Neurocognitive"
      [formControl]="neurocognitiveType"
      [value]="neurocognitiveType"
      (selectionChange)="isolatedReact.updateValueAndValidity()"
    >
      <mat-option [value]="0">Off</mat-option>
      <mat-option [value]="1">Array</mat-option>
      <mat-option [value]="2">Go/No Go</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(neurocognitiveType)">
      {{ ValidationHelperService.getErrorMessage(neurocognitiveType) }}
    </mat-error>
  </mat-form-field>
  <div class="w-100">
    <div class="w-100 d-flex !cursor-default">
      <div class="w-50">
        <label class="w-100">React</label>

        <div>
          <div
            class="react-option yellow-background"
            [class.full-opacity]="react[0]"
            (click)="setReact(0, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[0]"
              >done</i
            >
          </div>

          <div
            class="react-option green-background"
            [class.full-opacity]="react[1]"
            (click)="setReact(1, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[1]"
              >done</i
            >
          </div>

          <div
            class="react-option red-background"
            [class.full-opacity]="react[2]"
            (click)="setReact(2, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[2]"
              >done</i
            >
          </div>

          <div
            class="react-option blue-background"
            [class.full-opacity]="react[3]"
            (click)="setReact(3, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[3]"
              >done</i
            >
          </div>

          <div
            class="react-option orange-background"
            [class.full-opacity]="react[4]"
            (click)="setReact(4, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[4]"
              >done</i
            >
          </div>

          <div
            class="react-option violet-background"
            [class.full-opacity]="react[5]"
            (click)="setReact(5, 1)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="react[5]"
              >done</i
            >
          </div>
        </div>
      </div>
      <div
        class="w-50"
        [class.disabled-custom]="neurocognitiveType.value === 0"
      >
        <label class="w-100">Don't react</label>

        <div>
          <div
            class="react-option yellow-background"
            [class.full-opacity]="dontReact[0]"
            (click)="setReact(0, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[0]"
              >done</i
            >
          </div>

          <div
            class="react-option green-background"
            [class.full-opacity]="dontReact[1]"
            (click)="setReact(1, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[1]"
              >done</i
            >
          </div>

          <div
            class="react-option red-background"
            [class.full-opacity]="dontReact[2]"
            (click)="setReact(2, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[2]"
              >done</i
            >
          </div>

          <div
            class="react-option blue-background"
            [class.full-opacity]="dontReact[3]"
            (click)="setReact(3, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[3]"
              >done</i
            >
          </div>

          <div
            class="react-option orange-background"
            [class.full-opacity]="dontReact[4]"
            (click)="setReact(4, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[4]"
              >done</i
            >
          </div>

          <div
            class="react-option violet-background"
            [class.full-opacity]="dontReact[5]"
            (click)="setReact(5, 2)"
            matRipple
          >
            <i class="material-icons react-overlay-icon" *ngIf="dontReact[5]"
              >done</i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Isolated React</mat-label>
      <mat-select
        placeholder="Isolated React"
        [formControl]="isolatedReact"
        [value]="isolatedReact.value"
        (selectionChange)="resetReact()"
      >
        <mat-option [value]="false">Off</mat-option>
        <mat-option [value]="true">On</mat-option>
      </mat-select>
      <mat-error *ngIf="ValidationHelperService.isInvalid(isolatedReact)">
        {{ ValidationHelperService.getErrorMessage(isolatedReact) }}
      </mat-error>
    </mat-form-field>
    <div
      class="flex flex-col items-center justify-center w-100 my-5"
      *ngIf="isolatedReact.value"
    >
      <div
        class="grid grid-cols-2 grid-rows-2 gap-3 items-center justify-end py-3 align-self-start"
      >
        <div class="w-[30px] h-[10px] bg-amber-300 justify-self-end"></div>
        <span> - Active sensor </span>
        <div class="w-[30px] h-[10px] bg-red-500 justify-self-end"></div>
        <span> - Inactive sensor </span>
      </div>
      <div
        class="max-w-[600px] border grid grid-cols-3 grid-rows-3 items-center justify-center content-center w-full h-auto [&>*]:aspect-square bg-[#3E3E3E] rounded-3xl"
      >
        <div class="w-full h-full flex justify-center items-center">
          <div
            class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white content-center"
            matRipple
            (click)="setSensor(0)"
            [class.bg-red-500]="!reactSensors.value[0]"
          >
            1
          </div>
        </div>
        <div></div>
        <div class="w-full h-full flex justify-center items-center">
          <div
            matRipple
            class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
            (click)="setSensor(1)"
            [class.bg-red-500]="!reactSensors.value[1]"
          >
            2
          </div>
        </div>
        <div></div>
        <div class="w-full h-full flex justify-center items-center">
          <div
            matRipple
            class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
            (click)="setSensor(2)"
            [class.bg-red-500]="!reactSensors.value[2]"
          >
            3
          </div>
        </div>
        <div></div>
        <div class="w-full h-full flex justify-center items-center">
          <div
            matRipple
            class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
            (click)="setSensor(3)"
            [class.bg-red-500]="!reactSensors.value[3]"
          >
            4
          </div>
        </div>
        <div></div>
        <div class="w-full h-full flex justify-center items-center">
          <div
            matRipple
            class="toggle-circle flex justify-center items-center bg-amber-300 font-bold text-white"
            (click)="setSensor(4)"
            [class.bg-red-500]="!reactSensors.value[4]"
          >
            5
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Timer delay</mat-label>
    <input type="number" [step]="0.1" [formControl]="reactDelay" matInput />
    <mat-error *ngIf="ValidationHelperService.isInvalid(reactDelay)">
      {{ ValidationHelperService.getErrorMessage(reactDelay) }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Timer prompt</mat-label>
    <input
      type="number"
      [step]="0.1"
      [formControl]="timerPrompt"
      matInput
      [value]="timerPrompt.value"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(timerPrompt)">
      {{ ValidationHelperService.getErrorMessage(timerPrompt) }}
    </mat-error>
  </mat-form-field>
</form>
