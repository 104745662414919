import { Component, Input, OnInit } from "@angular/core";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { LogsReportDialogComponent } from "./logs-report-dialog/logs-report-dialog.component";
import { UsageReportDialogComponent } from "./usage-report-dialog/usage-report-dialog.component";
import { AccountInfoDialogComponent } from "./account-info/account-info-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotifyParentService } from "../../../core/services/notify-parent.service";
import { PaymentControllerService } from "../../../core/api/controllers/payment-controller.service";
import { NotificationService } from "../../../core/services/notification.service";
import { GlobalPreferences } from "../../../core/helpers/global-data";
import { MessageService } from "../../../core/services/message-service";
import { FileUploader } from "ng2-file-upload";
import { DomSanitizer } from "@angular/platform-browser";
import { monthVector, yearVector } from "../../../core/helpers/calendar-data";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "../../../core/helpers/custom-validators";
import { SubscriptionPaymentDialogComponent } from "../../../shared/components/subscription-payment-dialog/subscription-payment-dialog.component";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { MediaControllerService } from "../../../core/api/controllers/media-controller.service";
import { AccountControllerService } from "../../../core/api/controllers/account-controller.service";

@Component({
  selector: "app-generic-account-card",
  templateUrl: "./generic-account-card.component.html",
  styleUrls: ["./generic-account-card.component.scss"],
})
export class GenericAccountCardComponent implements OnInit {
  imagePreviewUrl = null;
  imageUploader: FileUploader;
  imageNeedsUpdate = false;
  image = null;
  profilePicture;
  showQuickbooks = false;
  mandatoryEmail = false;
  subscriptionPaymentForm: FormGroup;
  yearVector: string[];
  currentMonthVector: string[];
  selectedMonth: string;
  selectedYear: string;
  isSyncDisabled: boolean;
  isLoadingSync = false;

  @Input() settingsData;
  @Input() isLoading = false;
  @Input() paymentData;

  constructor(
    public settingsService: PaymentControllerService,
    private mediaController: MediaControllerService,
    private parentNotifier: NotifyParentService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private messageService: MessageService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private accountController: AccountControllerService,
  ) {}

  ngOnInit(): void {
    this.initImageUploader();
    this.initFields();
    this.getSyncState();
  }

  getSyncState(): void {
    this.isLoadingSync = true;
    this.accountController.getSyncState().subscribe({
      next: (res) => {
        this.isSyncDisabled = res.syncDisabled;
        this.isLoadingSync = false;
      },
    });
  }

  openToggleModal(): void {
    if (this.isSyncDisabled)
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: "Enable Data Sync",
            message:
              "By enabling data sync, you will allow the iPad app to send data to our servers to be stored and reused on another device. Are you sure?",
            confirmButtonLabel: "Enable",
            cancelButtonLabel: "Cancel",
          } as IConfirmationDialogData,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.toggleSyncState();
          }
        });

    if (!this.isSyncDisabled)
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: "Disable Data Sync",
            message:
              "Data sync is enabled, allowing the iPad app to send data to our servers to be stored and reused on another device. Are you sure you want to proceed?",
            confirmButtonLabel: "Disable",
            cancelButtonLabel: "Cancel",
          } as IConfirmationDialogData,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.toggleSyncState();
          }
        });
  }

  toggleSyncState(): void {
    this.isLoadingSync = true;
    this.accountController.toggleSyncState().subscribe({
      next: () => {
        this.getSyncState();
      },
    });
  }

  initImageUploader(): void {
    this.imageUploader = new FileUploader({
      queueLimit: 1,
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      this.imageNeedsUpdate = true;
      this.image =
        file.file.type.indexOf("image") >= 0 && file.file.size <= 2000000
          ? file.file
          : null;
      this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file._file),
      );
    };
  }

  initFields() {
    if (this?.settingsData?.username == "kevin") {
      this.showQuickbooks = true;
    }
    this.profilePicture = this?.settingsData?.profilePictureUrl;
  }

  updateProfilePicture() {
    const data = new FormData();
    data.append("file", this.image.rawFile);
    this.mediaController.mediaUpload(data).subscribe(
      ({ url }) => {
        this.accountController.updateProfilePicture(url).subscribe(() => {
          this.notificationService.success(
            "Profile picture updated successfully.",
          );
          this.profilePicture = this.imagePreviewUrl;
          this.imagePreviewUrl = null;
          this.parentNotifier.sendData("update");
        });
      },
      (error) => this.notificationService.error(error),
    );
  }

  openPasswordModal() {
    this.dialog
      .open(ChangePasswordComponent, {
        width: "600px",
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.notificationService.success("Password changed successfully");
        }
      });
  }

  openLogsModal() {
    this.dialog.open(LogsReportDialogComponent, {
      data: {
        organizationId: this.settingsData.organization.id,
      },
      width: "800px",
    });
  }

  openUsageModal() {
    this.dialog.open(UsageReportDialogComponent, {
      data: {
        organizationId: this.settingsData.organization.id,
      },
      width: "800px",
    });
  }

  connectQuickbooks() {
    this.settingsService.connectQuickbooks().subscribe(
      (response) => {
        this.notificationService.success("Quickbooks connection in progress");
        window.open(response.body, "Quickbooks connect");
      },
      (error) => {
        this.notificationService.error(error);
      },
    );
  }

  openAccountInformationModal() {
    this.dialog.open(AccountInfoDialogComponent);
  }

  updateMandatoryEmail() {
    this.accountController.updateUserPreferences(this.mandatoryEmail).subscribe(
      () => {
        this.messageService.sendMandatoryEmail(this.mandatoryEmail);
      },
      (error) => this.notificationService.error(error),
    );
  }

  openSubscriptionPaymentModal() {
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.subscriptionPaymentForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, CustomValidators.nameValidator]],
        lastName: ["", [Validators.required, CustomValidators.nameValidator]],
        cardNumber: ["", [Validators.required, CustomValidators.cardValidator]],
        CVV: ["", [Validators.required]],
      },
      { validator: CustomValidators.variableCvv },
    );

    this.dialog.open(SubscriptionPaymentDialogComponent, {
      data: {
        response: this.paymentData,
      },
      width: "800px",
      height: "600px",
    });
  }
}
