import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ShopControllerService {
  private readonly _resourceUrl: string = "/shop";

  constructor(private apiService: ApiService) {}

  isCouponValid(code: string): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}/coupon`, { code });
  }

  getRegisterProducts(): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}`);
  }
}
