<form [formGroup]="form">
  <div class="card w-100 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
    <div class="p-3 border-b font-medium flex justify-between items-center">
      <span>Most Active Exercise Categories</span>
      <mat-button-toggle-group
        name="fontStyle"
        aria-label="Font Style"
        [formControl]="form.controls.timeframe"
        [disabled]="isLoadingCategories"
      >
        <mat-button-toggle [value]="0">Today</mat-button-toggle>
        <mat-button-toggle [value]="1">Last week</mat-button-toggle>
        <mat-button-toggle [value]="2">Last month</mat-button-toggle>
        <mat-button-toggle [value]="3">All time</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="p-3">
      <div *ngIf="!isLoadingCategories">
        <div class="w-full flex flex-col gap-5" *ngIf="categories?.length > 0">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Select a category</mat-label>
            <mat-select [formControl]="form.controls.category">
              <mat-option
                *ngFor="let category of categories"
                [value]="category"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="ValidationHelperService.isInvalid(form.controls.category)"
              >{{
                ValidationHelperService.getErrorMessage(form.controls.category)
              }}</mat-error
            >
          </mat-form-field>
          <div *ngIf="!isLoadingExercises">
            <div class="p-3 border-bottom">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Select Exercise for Leaderboard</mat-label>
                <mat-select [formControl]="form.controls.exercise">
                  <mat-option
                    *ngFor="let exercise of exercises"
                    [value]="exercise"
                  >
                    {{ exercise?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="btn btn-primary text-white mr-3 ml-3"
                (click)="getLeaderBoardForExercise()"
              >
                Select Exercise
              </button>
            </div>
            <div class="p-3" *ngIf="form?.controls?.category?.value">
              <div *ngIf="mostActiveExercise">
                <span class="h4 p-3 alt-color-text">
                  Most Active Exercise Today
                </span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-4 p-3 border-top border-bottom"
              >
                <div>
                  <span class="h6">{{
                    form.controls.exercise.value?.name
                  }}</span>
                </div>
              </div>

              <div *ngIf="leaderboardResults.length == 0">
                <span class="h4 p-3 alt-color-text"
                  >No active exercises for selected timeframe, select a
                  different period</span
                >
              </div>

              <table
                *ngIf="leaderboardResults.length > 0"
                class="table table-bordered table-striped"
              >
                <tr class="">
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3">Rank</span>
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Athlete Name</span
                    >
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Average</span
                    >
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Average Comparison</span
                    >
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3">Best</span>
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Best Comparison</span
                    >
                  </td>
                  <td class="text-center">
                    <span class="small font-weight-bold mt-2 mr-3"
                      >Number of Sets</span
                    >
                  </td>
                </tr>
                <ng-container
                  *ngFor="
                    let result of leaderboardResults;
                    let resultIndex = index
                  "
                >
                  <tr class="">
                    <th scope="row" class="radio">
                      <div class="d-flex align-items-center">
                        <span class="">{{ resultIndex + 1 }}</span>
                      </div>
                    </th>

                    <td>
                      <div class="d-flex align-items-center">
                        <img
                          *ngIf="
                            result.profilePicturePath == null ||
                            result.profilePicturePath == ''
                          "
                          src="assets/images/no_avatar.png"
                          class="rounded-circle border border-light mr-2 rounded-circle"
                          width="38"
                          height="38"
                        />
                        <img
                          *ngIf="
                            result.profilePicturePath != null &&
                            result.profilePicturePath != ''
                          "
                          src="{{ result.profilePicturePath }}"
                          class="rounded-circle border border-light mr-2 rounded-circle"
                          width="38"
                          height="38"
                        />

                        <div class="d-flex align-items-centers">
                          <span>{{ result.athleteName }} </span>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <p>
                          <span
                            >{{
                              result.averageScore | number: "1.1-1"
                            }}
                            touches</span
                          >
                        </p>
                      </div>
                    </td>

                    <!-- Add Signs and collors for variations -->
                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <p>
                          {{
                            result.averageComparison
                              ? (result.averageComparison | percent)
                              : "-"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <p>{{ result.bestScore }} touches</p>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <p>
                          {{
                            result.bestComparison
                              ? (result.bestComparison | percent)
                              : "-"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="d-flex align-items-center">
                        <p>{{ result.numberOfSets }}</p>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
          <div
            *ngIf="isLoadingExercises"
            class="h-[200px] w-full flex justify-center items-center"
          >
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </div>
        <div
          *ngIf="categories?.length === 0"
          class="h-[200px] w-full flex justify-center items-center"
        >
          <p class="text-3xl">
            There are no categories in the selected time frame.
          </p>
        </div>
      </div>
      <div
        *ngIf="isLoadingCategories"
        class="w-full h-[200px] flex justify-center items-center"
      >
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</form>
