import {Component, Input, OnInit} from "@angular/core";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {FormControl, Validators} from "@angular/forms";
import {ConfirmationDialogComponent, IConfirmationDialogData,} from "../confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Category} from "../../interfaces/ICategory";
import {NotificationService} from "../../../core/services/notification.service";
import {EAction} from "../../enums/EAction";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-select-category",
  templateUrl: "./select-category.component.html",
  styleUrls: ["./select-category.component.scss"],
})
export class SelectCategoryComponent implements OnInit {
  @Input() _control: FormControl;
  @Input() action: EAction;

  @Input() getCategories: any;
  @Input() saveCategory: any;
  @Input() updateCategory: any;
  @Input() deleteCategory: any;
  @Input() manualLoad = false;

  isLoading = false;
  _modifyControl = new FormControl("", [Validators.required]);
  categoryMode = "select";
  @Input() categoryList: Category[]; // Only if manualLoad is activated can this be passed down
  categoryListTypeahead = [];
  isLoadingCategory = false;

  protected readonly ValidationHelperService = ValidationHelperService;

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private matsnackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.onCategoryChange();
    if(!this.manualLoad)
      this.updateCategoryList();
  }

  onCategoryChange(): void {
    this._control.valueChanges.subscribe(async (value) => {
      if (this.categoryMode === "select") {
        await this.selectCategoryAsync(value);
      }
    });
  }

  openConfirmModal() {
    const data: IConfirmationDialogData = {
      title: "Remove category",
      message: `Are you sure you want to delete the following category: ${this._control.value.name}?`,
      cancelButtonLabel: "Cancel",
      confirmButtonLabel: "Delete",
    };

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteAthleteCategory();
        }
      });
  }

  deleteAthleteCategory() {
    this.deleteCategory(this._control.value.id).subscribe({
    next: () => {
      this.updateCategoryList();
      this._control.setValue("");
      this.notificationService.success("Category deleted successfully.");
    },
    error: (error: {error: {error: string}}) => {
      this.matsnackbar.open(error.error.error, null, {
        duration: 3000,
      });
    },
    });
    }

  updateCategoryList() {
    this._control.disable();
    this.isLoading = true;
    const locationId = localStorage.getItem("selectedLocationId");
    this.getCategories(locationId).subscribe(
      (response) => {
        this.updateCategoryListByResponse(response);
        this.isLoading = false;
        this._control.enable();
      },
      (error) => this.notificationService.error(error),
    );
  }

  updateCategoryListByResponse(response: Category[]): void {
    this.categoryList = response;
    this.categoryListTypeahead = this.categoryList.map(
      (category) => category.name,
    );
  }

  selectCategoryObject(category: { value: string }) {
    this.selectCategory(category.value);
  }

  selectCategory(newCategory: string) {
    for (const category of this.categoryList) {
      if (category.name === newCategory) {
        this._control.setValue(category);
      }
    }
  }

  async selectCategoryAsync(newCategory: Category): Promise<void> {
    if (!this.categoryList) {
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      await this.selectCategoryAsync(newCategory);
      return;
    }
    for (const category of this.categoryList) {
      if (
        category.name === newCategory.name ||
        category.id === newCategory.id
      ) {
        this._control.setValue(category, { emitEvent: false });
      }
    }
  }

  updateAthleteCategory() {
    const updatedCategoryName = this._modifyControl.value;
    // Check if the category already exists
    let found = false;
    for (const category of this.categoryListTypeahead) {
      if (
        category.toLowerCase().trim() ===
        updatedCategoryName.toLowerCase().trim()
      ) {
        found = true;
        break;
      }
    }
    if (found) {
      this.notificationService.error("This category already exists.");
      return;
    }

    this.updateCategory({
      name: updatedCategoryName,
      id: this._control.value.id,
    }).subscribe(
      (response) => {
        this.updateCategoryListByResponse(response);
        this.selectCategory(updatedCategoryName);
        this.notificationService.success("Category updated successfully.");
      },
      (error) => this.notificationService.error(error),
    );
  }

  addAthleteCategory(): void {
    const newCategoryName = this._modifyControl.value;
    const locationId = localStorage.getItem("selectedLocationId");
    let found = false;
    for (const category of this.categoryListTypeahead) {
      if (
        category.toLowerCase().trim() === newCategoryName.toLowerCase().trim()
      ) {
        found = true;
        break;
      }
    }
    found
      ? this.notificationService.error("This category already exists.")
      : this.createCategory(newCategoryName, locationId);
  }

  createCategory(newCategoryName: string, locationId: any): void {
    this.isLoadingCategory = true;
    this.categoryMode = "select";
    this.saveCategory({
      name: newCategoryName,
      locationId,
    }).subscribe(
      (response) => {
        this.updateCategoryListByResponse(response);
        this.selectCategory(newCategoryName);
        this.isLoadingCategory = false;
        this.notificationService.success("Category added successfully.");
      },
      (error) => {
        this.isLoadingCategory = false;
        this.notificationService.error(error);
      },
    );
  }

  closeCategory(): void {
    this.categoryMode = "select";
    this.updateEditCategoryList(this._control.value.id);
  }

  updateEditCategoryList(categoryId: string) {
    const locationId = localStorage.getItem("selectedLocationId");
    this.getCategories(locationId).subscribe(
      (response) => {
        this.categoryList = response;
        this.categoryListTypeahead = this.categoryList.map(
          (category) => category.name,
        );
        if (!categoryId) {
          return;
        }
        for (const category of this.categoryList) {
          if (category.id === categoryId) {
            this._control.setValue(category);
          }
        }
        if (this._control.value === "") {
          this.notificationService.error("Could not find the category.");
        }
      },
      (error) => this.notificationService.error(error),
    );
  }
}
