import {Component, OnInit} from "@angular/core";
import {WorkoutsControllerService} from "../../core/api/controllers/workouts/workouts-controller.service";
import {possibleRestTime, possibleSets,} from "../../core/helpers/exercise-data";
import {NotificationService} from "../../core/services/notification.service";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {Router} from "@angular/router";
import {WorkoutControllerService} from "../../core/api/controllers/workout-controller.service";
import {AuthService} from "../../core/services/auth.service";
import {IGetWorkoutsPayload} from "../../core/api/controllers/workouts/data/IGetWorkoutsPayload";
import {IWorkout} from "../../shared/interfaces/IWorkout";

@Component({
  selector: "app-workouts",
  templateUrl: "./workouts.component.html",
  styleUrls: ["./workouts.component.scss"],
  providers: [WorkoutsControllerService, WorkoutControllerService],
})
export class WorkoutsComponent implements OnInit {
  workoutList: IWorkout[] = [];
  selectedIndex = null;
  possibleRestTime = possibleRestTime;
  possibleSets = possibleSets;
  toggle = true;
  canLoadPagedWorkouts = false;
  isLoading = false;
  locations: Array<string> = [];
  selectedWorkouts = [];

  constructor(
    private workoutsService: WorkoutsControllerService,
    private workoutController: WorkoutControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf(data.workoutName + "s") == -1) {
          this.router.navigate(["/" + data.workoutName + "s"]);
        }
      },
      (error) => this.notificationService.error(error),
    );
    this.getWorkouts();
  }

  getWorkouts() {
    this.isLoading = true;
    this.workoutsService.getWorkouts(this.createPayload()).subscribe(
      (response) => {
        this.workoutList = response.data;
        const canLoadLength = response.totalCount;
        this.canLoadPagedWorkouts =
          canLoadLength % 20 === 0 && canLoadLength !== 0;
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      }
    );
  }

  createPayload(): IGetWorkoutsPayload {
    return {
      page: this.workoutList.length > 0 ? Math.floor(this.workoutList.length / 20) : 0,
      itemsPerPage: 20,
      locationId: localStorage.getItem("selectedLocationId"),
    }
  }

  deleteWorkout(workoutId: string) {
    this.isLoading = true;
    this.workoutController.deleteWorkout(workoutId).subscribe(
      () => {
        this.getWorkouts();
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      }
    );
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  syncWorkout(workoutId: string) {
    this.router.navigate([`workouts/sync-workout`], {
      queryParams: {
        id: workoutId ? workoutId : this.selectedWorkouts.map((id) => id),
      },
    });
  }

  handleWorkoutSelection(id) {
    if (this.selectedWorkouts.includes(id)) {
      this.selectedWorkouts.splice(this.selectedWorkouts.indexOf(id), 1);
    } else {
      this.selectedWorkouts.push(id);
    }
  }
}
