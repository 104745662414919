import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class WorkoutsControllerService {
  private readonly _resourceUrl: string = "/workouts";

  constructor(private apiService: ApiService) {}

  getWorkouts(page, size): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {
        page,
        size,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }
}
