import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "resultNumber",
})
export class ResultNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (this.isInteger(value)) return value;
    else if (this.isFloat(value)) return Math.round(value * 100) / 100;

    return null;
  }

  isFloat(n) {
    return n === +n && n !== (n | 0);
  }

  isInteger(n) {
    return n === +n && n === (n | 0);
  }
}
