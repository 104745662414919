import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { AccountInfoDialogComponent } from "./generic-account-card/account-info/account-info-dialog.component";
import { ChangePasswordComponent } from "./generic-account-card/change-password/change-password.component";
import { LocationActionComponent } from "./locations/location-action/location-action.component";
import { LogsReportDialogComponent } from "./generic-account-card/logs-report-dialog/logs-report-dialog.component";
import { UserActionDialogComponent } from "./team/user-action/user-action-dialog.component";
import { WhitelistDialogComponent } from "./whitelist/whitelist-dialog/whitelist-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { UsageReportDialogComponent } from "./generic-account-card/usage-report-dialog/usage-report-dialog.component";
import { SettingsRoutingModule } from "./settings-routing.module";
import { TeamComponent } from "./team/team.component";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { LocationsComponent } from "./locations/locations.component";
import { WhitelistComponent } from "./whitelist/whitelist.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { HistoryComponent } from "./history/history.component";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { GenericAccountCardComponent } from "./generic-account-card/generic-account-card.component";
import { FileUploadModule } from "ng2-file-upload";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    AccountInfoDialogComponent,
    ChangePasswordComponent,
    LocationActionComponent,
    LogsReportDialogComponent,
    UserActionDialogComponent,
    WhitelistDialogComponent,
    UsageReportDialogComponent,
    TeamComponent,
    LocationsComponent,
    WhitelistComponent,
    InvoicesComponent,
    HistoryComponent,
    GenericAccountCardComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    FileUploadModule,
    MatSlideToggleModule,
    NgOptimizedImage,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [
    AccountInfoDialogComponent,
    ChangePasswordComponent,
    LocationActionComponent,
    LogsReportDialogComponent,
    UserActionDialogComponent,
    WhitelistDialogComponent,
    UsageReportDialogComponent,
    TeamComponent,
    LocationsComponent,
    WhitelistComponent,
    InvoicesComponent,
    HistoryComponent,
    GenericAccountCardComponent,
  ],
})
export class SettingsModule {}
