import { Directive, ElementRef, Input, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[appHighlight]",
})
export class HighlightDirective implements AfterViewInit {
  @Input() orgName = "";
  @Input() searchText = "";

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const index = this.orgName
      .toLowerCase()
      .indexOf(this.searchText.toLowerCase());

    if (!this.searchText || !this.orgName || index === -1) {
      return;
    }

    this.el.nativeElement.innerHTML =
      this.orgName.substring(0, index) +
      "<span style='background: #0069d9; color:#fff'>" +
      this.orgName.substring(index, index + this.searchText.length) +
      "</span>" +
      this.orgName.substring(index + this.searchText.length);
  }
}
