<div class="flex flex-col gap-3 p-5">
  <div>
    <h2>Move Exercises to Category</h2>
  </div>

  <mat-selection-list [multiple]="false">
    <mat-list-option
      *ngFor="let category of data.exerciseCategories"
      [class.bg-primary]="category.id === destinationCategory"
      class="transition-all cursor-pointer hover:bg-gray-300"
      (click)="handleCategorySelect(category.id)"
    >
      {{ category.name }}
    </mat-list-option>
  </mat-selection-list>

  <div class="flex gap-3 justify-end">
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      (click)="moveToCategory(); dialogRef.close(true)"
      autofocus
    >
      Move
    </button>
  </div>
</div>
