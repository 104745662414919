import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface IAdvancedConfirmationDialogData {
  title: string;
  message: string;
  buttons: {
    label: string;
    color: string;
    result: number;
  }[];
}

@Component({
  selector: "app-advanced-confirmation-dialog",
  templateUrl: "./advanced-confirmation-dialog.component.html",
  styleUrls: ["./advanced-confirmation-dialog.component.scss"],
})
export class AdvancedConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAdvancedConfirmationDialogData,
    public dialogRef: MatDialogRef<AdvancedConfirmationDialogComponent>,
  ) {}

  ngOnInit(): void {}
}
