import {Component} from "@angular/core";
import {InjuryFormFactory} from "../injury-form/InjuryFormFactory";
import {MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../../core/services/notification.service";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {InjuriesControllerService} from "../../../core/api/controllers/injuries-controller.service";
import {IInjury} from "../../../shared/interfaces/IInjury";

@Component({
  selector: 'app-create-injury-dialog',
  templateUrl: './create-injury-dialog.component.html',
  styleUrls: ['./create-injury-dialog.component.scss']
})
export class CreateInjuryDialogComponent {
  isLoading = false;
  injuryForm = InjuryFormFactory.create();

  constructor(
      private injuriesController: InjuriesControllerService,
      private matDialogRef: MatDialogRef<CreateInjuryDialogComponent>,
      private notificationService: NotificationService
  ) {}


  onSubmit(): void {
    this.injuryForm.markAllAsTouched();
    if(this.injuryForm.valid) this.createInjury();
  }

  createInjury(): void {
    this.isLoading = true;
    this.injuriesController.createInjury(this.createPayload() as IInjury).subscribe({
      next: () => {
        this.notificationService.success("The injury has been created succesfully!");
        this.matDialogRef.close(true);
        this.isLoading = false;
      },
      error: (error: {error: string}) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      }
    })
  }

  createPayload(): Partial<IInjury> {
    return {
      name: this.injuryForm.controls.name.value
    }
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
