import {Component, OnDestroy, OnInit} from "@angular/core";
import {NotificationService} from "../../core/services/notification.service";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {AuthService} from "../../core/services/auth.service";
import {MatTableDataSource} from "@angular/material/table";
import {ISubscription, IUser} from "../../core/models/subscription.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {AdminControllerService} from "../../core/api/controllers/admin-controller.service";
import {PaymentControllerService} from "../../core/api/controllers/payment-controller.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {ISubscriptionDropdownData} from "../../shared/interfaces/DTOs/Response/ISubscriptionDropdownItem";
import {EAccountType} from "../../shared/enums/EAccountType";
import {ESubscriptionStatus} from "../../shared/enums/ESubscriptionStatus";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  isLoading = false;
  subscription = { type: "", endDate: "" };
  favoriteExercises = [];
  mostActiveAthletesChart = { value: null, fromDate: 3, loading: false };
  totalItems;
  usersPage = 0;
  usersSize = 10;
  usersDataSource: MatTableDataSource<IUser>;
  users: IUser[];
  subscriptions: ISubscription[] = [];
  subscriptionForm: FormGroup;
  updatedUsers = [];
  searchText = "";
  displayedColumns: string[] = [
    "userName",
    "organizationName",
    "firstName",
    "lastName",
    "email",
    "status",
    "lastBilled",
    "subscription",
    "price",
    "athleteLimit",
    "creditCardLast4",
  ];
  valueChanged = false;
  refreshTimer;
  athleteCount: number;
  organizationCount: number;
  userCount: number;
  dropdownSubscriptions: ISubscriptionDropdownData;

  private searchText$ = new Subject<string>();


  constructor(
    private paymentController: PaymentControllerService,
    private adminController: AdminControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("dashboard", {});
    this.searchText$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
    ).subscribe(() => {
      this.getUsers();
    });
    this.getSubscription();
    if (this.isSuperAdmin()) {
      this.getOrganizationsActiveCount();
      this.getUsers();
      this.getSubscriptions();
    }
    this.subscriptionForm = this.fb.group({
      subscriptionControl: this.fb.array([
        this.fb.group({
          name: "",
        }),
      ]),
    });
  }

  getSubscription() {
    this.subscription = JSON.parse(localStorage.getItem("userSubscription"));
  }

  debouncedGetUsers(evt: any): void {
    this.searchText$.next(evt.target.value);
  }

  getUsers() {
    this.isLoading = true;
    this.adminController
      .getUsers(this.usersPage, this.usersSize, this.searchText)
      .subscribe(
        (res) => {
          res.data = res.data.map(user => {
            return {
              ...user,
              removeCreditCardInfo: false
            }
          });
          this.users = res.data;

          this.users.forEach(user => {
            user.selectedSubscription = user.organizationSubscription;
          })
          this.usersDataSource = new MatTableDataSource<IUser>(
            res.data
          );
          this.totalItems = res.totalCount;
          this.isLoading = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
        }
      );
  }

  getSubscriptions(): void {
    this.isLoading = true;
    this.adminController.getSubscriptions().subscribe({
      next: (res) => {
        this.dropdownSubscriptions = res;
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.error(error)
        this.isLoading = false;
      }
    })
  }

  changed(element) {
    this.valueChanged = true;
    this.userUpdated(element);
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  pageChanged(event) {
    this.usersPage = event.pageIndex;
    this.usersSize = event.pageSize;
    this.cancelUpdate();
  }

  userUpdated(user: IUser & {removeCreditCardInfo: boolean}) {
    const newUser = {
      organizationSubscriptionId:  user.organizationSubscription.id,
      subscriptionId: user.organizationSubscription.id === user.selectedSubscription.id ? null : user.selectedSubscription.id ,
      subscriptionStatus: user.organizationSubscription.subscriptionStatus,
      lastBilled: user.organizationSubscription.lastBilled,
      athleteLimit: user.selectedSubscription.athleteLimit,
      price: user.selectedSubscription.price,
      removeCreditCardInfo: user.removeCreditCardInfo
    };
    console.log(newUser);
    this.updatedUsers.push(newUser);
  }

  cancelUpdate() {
    this.updatedUsers = [];
    this.getUsers();
  }

  updateUsers() {
    this.isLoading = true;
    this.adminController
      .updateUsers({ editedSubscriptions: this.updatedUsers })
      .subscribe(
        (data) => {
          this.updatedUsers = [];
          this.getUsers();
        },
        ({ error }) => {
          this.updatedUsers = [];
          this.getUsers();
          if (error.validationErrors && error.validationErrors.length) {
            error.validationErrors.forEach((er) => {
              this.notificationService.error(er);
            });
          } else {
            this.notificationService.error(error.error);
          }
        },
      );
  }

  deleteCreditCard(updatedUser: IUser) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Delete Credit Card",
          message: "Are you sure you want to delete this credit card?",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.isLoading = true;
          this.adminController
              .updateUsers({ editedSubscriptions: [
                  {
                    organizationSubscriptionId: updatedUser.organizationSubscription.id,
                    subscriptionId: null,
                    subscriptionStatus: updatedUser.organizationSubscription.subscriptionStatus,
                    lastBilled: updatedUser.organizationSubscription.lastBilled,
                    athleteLimit: updatedUser.selectedSubscription.athleteLimit,
                    price: updatedUser.selectedSubscription.price,
                    removeCreditCardInfo: true,
                  }
                ] })
              .subscribe(
                  (data) => {
                    this.updatedUsers = [];
                    this.getUsers();
                  },
                  ({ error }) => {
                    this.updatedUsers = [];
                    this.getUsers();
                    if (error.validationErrors && error.validationErrors.length) {
                      error.validationErrors.forEach((er) => {
                        this.notificationService.error(er);
                      });
                    } else {
                      this.notificationService.error(error.error);
                    }
                  },
              );
        }
      });
  }

  getOrganizationsActiveCount() {
    this.isLoading = true;
    this.adminController
      .getOrganizationsActiveCount()
      .subscribe(({ athletesCount, usersCount, organizationsCount }) => {
        this.athleteCount = athletesCount;
        this.userCount = usersCount;
        this.organizationCount = organizationsCount;
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.favoriteExercises.forEach((exercise) => {
      clearTimeout(exercise.refreshTimer);
    });
  }

  protected readonly EAccountType = EAccountType;
    protected readonly ESubscriptionStatus = ESubscriptionStatus;
}
