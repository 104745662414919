<form [formGroup]="_formGroup" class="flex flex-col gap-5">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Delay</mat-label>
    <mat-select
      placeholder="Delay"
      [formControl]="delay"
      [value]="delay.value"
    >
      <mat-option *ngFor="let delay of arrowDelayArray" [value]="delay">
        {{ delay | number: "1.2-2" }} Delay
      </mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(delay)">
      {{ ValidationHelperService.getErrorMessage(delay) }}
    </mat-error>
  </mat-form-field>
    <mat-form-field  class="w-full" appearance="outline">
      <mat-label>Timer prompt</mat-label>
      <input
        matInput
        type="number"
        min="0"
        formControlName="timerPrompt"
      />
      <mat-error
        *ngIf="ValidationHelperService.isInvalid(timerPrompt)"
      >
        {{ ValidationHelperService.getErrorMessage(timerPrompt) }}
      </mat-error>
    </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Agility custom type</mat-label>
    <mat-select
      placeholder="Agility custom type"
      [formControl]="agilityCustomType"
      [value]="agilityCustomType.value"
    >
      <mat-option [value]="0">Sequence</mat-option>
      <mat-option [value]="1">React</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(agilityCustomType)">
      {{ ValidationHelperService.getErrorMessage(agilityCustomType) }}
    </mat-error>
  </mat-form-field>
  <div class="w-100">
    <div class="agility-container position-relative">
      <div
        class="flex gap-5 items-center pb-5"
        *ngIf="agilityCustomType.value !== 1"
      >
        <h4 class="text-3xl">Sequence</h4>
        <button
          type="button"
          mat-flat-button
          color="warn"
          (click)="resetAgilitySensors(agilityCustomType.value)"
        >
          Reset sequence
        </button>
      </div>

      <div
        class="w-full flex justify-center [&>div]:w-[50%] gap-5 !select-none relative pb-5"
      >
        <div
          class="flex items-center flex-wrap gap-5 bg-[#3E3E3E] rounded-3xl p-5 content-start border-2"
          [class.border-rose-500]="
            ValidationHelperService.isInvalid(_formGroup.get('agilitySensors'))
          "
          *ngIf="agilityCustomType.value !== 1"
        >
          <ng-container *ngIf="agilitySensors.length === 0">
            <div
              class="text-white w-full h-full flex justify-center items-center"
            >
              <span class="text-2xl">No sensors selected</span>
            </div>
          </ng-container>
          <ng-container *ngIf="agilitySensors.length > 0">
            <div
              class="[&:hover>mat-icon]:!block [&:hover>span]:!hidden relative flex items-center justify-center cursor-pointer bg-amber-300 font-bold text-white rounded-full p-2 w-[50px] h-[50px] hover:brightness-75"
              *ngFor="let sensor of agilitySensors.value; let i = index"
              (click)="removeAgilitySensorAtIndex(i)"
            >
              <app-agility-icon [sensor]="sensor"></app-agility-icon>
              <mat-icon
                class="!hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-red-400"
                >close</mat-icon
              >
            </div>
          </ng-container>
        </div>

        <div
          class="mx-auto grid grid-cols-3 grid-rows-3 gap-5 [&>*]:flex [&>*]:justify-center [&>*]:items-center [&>*]:aspect-square bg-[#3E3E3E] rounded-3xl p-3 max-w-[600px] w-full h-auto"
        >
          <div>
            <mat-icon
              class="flex flex-center text-3xl cursor-pointer -rotate-45 scale-[4] hover:brightness-75"
              [class]="{
                'text-black': !isSelected(8),
                'text-amber-300': isSelected(8),
              }"
              (click)="clickArrow(8)"
              >arrow_upward
            </mat-icon>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(1),
                'text-amber-300': isSelected(1),
              }"
              (click)="clickArrow(1)"
              >arrow_upward
            </mat-icon>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center rotate-45 scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(2),
                'text-amber-300': isSelected(2),
              }"
              (click)="clickArrow(2)"
              >arrow_upward
            </mat-icon>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(7),
                'text-amber-300': isSelected(7),
              }"
              (click)="clickArrow(7)"
              >arrow_back
            </mat-icon>
          </div>
          <div>
            <div
              class="w-[50%] h-[50%] bg-amber-300 cursor-pointer hover:brightness-75"
              [class]="{
                'bg-black': !isSelected(9),
                'bg-amber-300': isSelected(9),
              }"
              (click)="clickArrow(9)"
            ></div>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(3),
                'text-amber-300': isSelected(3),
              }"
              (click)="clickArrow(3)"
              >arrow_forward
            </mat-icon>
            3
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center rotate-45 scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(6),
                'text-amber-300': isSelected(6),
              }"
              (click)="clickArrow(6)"
              >arrow_downward
            </mat-icon>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(5),
                'text-amber-300': isSelected(5),
              }"
              (click)="clickArrow(5)"
              >arrow_downward
            </mat-icon>
          </div>
          <div>
            <mat-icon
              class="material-icons flex flex-center -rotate-45 scale-[4] cursor-pointer hover:brightness-75"
              [class]="{
                'text-black': !isSelected(4),
                'text-amber-300': isSelected(4),
              }"
              (click)="clickArrow(4)"
              >arrow_downward
            </mat-icon>
          </div>
        </div>
      </div>

      <mat-error *ngIf="ValidationHelperService.isInvalid(agilitySensors)">
        {{ ValidationHelperService.getErrorMessage(agilitySensors) }}
      </mat-error>
    </div>
  </div>
</form>
