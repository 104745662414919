import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../../core/services/notification.service";
import { GlobalPreferences } from "../../../core/helpers/global-data";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidationHelperService } from "../../../core/helpers/validation-helper.service";
import { IGetActiveCategoriesReq } from "../../../shared/interfaces/DTOs/Request/IGetActiveCategoriesReq";
import { ActiveCategory } from "../../../shared/interfaces/DTOs/Response/IGetActiveCategoriesRes";
import { IGetExercisesReq } from "../../../shared/interfaces/DTOs/Request/IGetExercisesReq";
import {
  IPairedExercise,
  IRegularExercise,
} from "../../../shared/interfaces/DTOs/Response/IGetExercisesRes";
import { ExercisesControllerService } from "../../../core/api/controllers/exercises-controller.service";
import { ExerciseControllerService } from "../../../core/api/controllers/exercise-controller.service";

@Component({
  selector: "app-most-active-exercise-categories",
  templateUrl: "./most-active-exercise-categories.component.html",
  host: {
    class: "w-full",
  },
  styleUrls: ["./most-active-exercise-categories.component.scss"],
})
export class MostActiveExerciseCategoriesComponent implements OnInit {
  mostActiveExercise = false;
  categories: ActiveCategory[] = [];
  leaderboardResults = [];
  selectedBoolean = false;
  exercises: (IPairedExercise | IRegularExercise)[];
  isLoadingCategories = false;
  isLoadingExercises = false;
  protected readonly ValidationHelperService = ValidationHelperService;

  form = new FormGroup({
    timeframe: new FormControl<number>(0, [Validators.required]),
    category: new FormControl(null, [Validators.required]),
    exercise: new FormControl(null, [Validators.required]),
  });

  constructor(
    private exercisesController: ExercisesControllerService,
    private exerciseController: ExerciseControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
  ) {}

  ngOnInit(): void {
    this.subscribeToTimeFrameChange();
    this.subscribeToCategoryChange();
    this.subscribeToExerciseChange();
    this.fetchCategories();
  }

  subscribeToTimeFrameChange(): void {
    this.form.controls.timeframe.valueChanges.subscribe(() => {
      this.fetchCategories();
    });
  }

  subscribeToCategoryChange(): void {
    this.form.controls.category.valueChanges.subscribe(() => {
      this.fetchExercises();
    });
  }

  subscribeToExerciseChange(): void {
    this.form.controls.exercise.valueChanges.subscribe(() => {
      this.getLeaderBoardForExercise();
    });
  }

  fetchCategories(): void {
    this.isLoadingCategories = true;
    this.exercisesController
      .getActiveCategories(this.getCategoriesPayload())
      .subscribe(
        (data) => {
          this.categories = data;
          if (this?.categories?.length > 0)
            this.form.controls.category.setValue(this.categories[0]);
          this.isLoadingCategories = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.isLoadingCategories = false;
        },
      );
  }

  fetchExercises(): void {
    this.isLoadingExercises = true;
    this.exercisesController
      .getExercises(this.getExercisesPayload())
      .subscribe({
        next: (res) => {
          this.exercises = [...res.pairedExercises, ...res.regularExercises];
          if (this?.exercises?.length > 0)
            this.form.controls.exercise.setValue(this.exercises[0]);
          this.isLoadingExercises = false;
        },
        error: (error) => {
          this.notificationService.error(error);
          this.isLoadingExercises = false;
        },
      });
  }

  getLeaderBoardForExercise() {
    this.selectedBoolean = true;
    this.exerciseController
      .getLeaderboardForExercise(
        this.form.controls.exercise.value.id,
        this.form.controls.timeframe.value,
      )
      .subscribe(
        (data) => {
          this.leaderboardResults = data.map((el) => {
            return {
              ...el,
              averageComparison: this.calculateComparisonFormula(
                el.averageScore,
                el.averageComparison,
              ),
              bestComparison: this.calculateComparisonFormula(
                el.bestScore,
                el.bestComparison,
              ),
            };
          });
        },
        (error) => this.notificationService.error(error),
      );
  }

  private calculateComparisonFormula(current: number, past: number): number {
    if (!past) {
      return null;
    }
    return (Math.abs(current - past) / ((current + past) / 2)) * 100;
  }

  getCategoriesPayload(): IGetActiveCategoriesReq {
    const locationId = localStorage.getItem("selectedLocationId");
    const gmtOffset = this.global.getTimezoneOffset();
    const timeFrame = this.form.controls.timeframe.value;
    return {
      locationId,
      timeFrame,
      gmtOffset,
    };
  }

  getExercisesPayload(): IGetExercisesReq {
    const gmtOffset = this.global.getTimezoneOffset();
    const timeFrame = this.form.controls.timeframe.value;
    return {
      categoryId: this.form.controls.category.value.id,
      timeFrame,
      gmtOffset,
    };
  }
}
