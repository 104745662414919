<div class="card position-relative w-100 p-3 mt-2 mt-sm-0 mr-sm-5 mb-2">
    <form [formGroup]="paymentHistoryForm" class="flex flex-col gap-3 items-center justify-center p-3">
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Search</mat-label>
            <input
                    matInput
                    placeholder="Search"
                    formControlName="searchBy"
            />
        </mat-form-field>

        <div class="w-full flex gap-3">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Payment Status</mat-label>
                <mat-select
                        placeholder="Payment Status"
                        formControlName="isSuccess"
                >
                    <mat-option [value]="true">OK</mat-option>
                    <mat-option [value]="false">Failed</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Transaction Type</mat-label>

                <mat-select
                        placeholder="Transaction Type"
                        formControlName="transactionType"
                >
                    <mat-option [value]="transactionType.FullPurchase"
                    >Full Purchase</mat-option
                    >
                    <mat-option [value]="transactionType.Subscription"
                    >Subscription</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Invoice Status</mat-label>
                <mat-select
                        placeholder="Invoice Status"
                        formControlName="invoiceGenerated"
                >
                    <mat-option [value]="true">OK</mat-option>
                    <mat-option [value]="false">Failed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div
                class="flex gap-3 w-full"
                formGroupName="dateTimeInterval"
        >
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input
                        matInput
                        [max]="paymentHistoryForm?.value?.dateTimeInterval?.endDate"
                        [matDatepicker]="pickerStartDate"
                        formControlName="startDate"
                />
                <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerStartDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #pickerStartDate
                ></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>End date</mat-label>
                <input
                        matInput
                        [min]="
                      paymentHistoryForm?.value?.dateTimeInterval?.startDate
                    "
                        [matDatepicker]="pickerEndDate"
                        formControlName="endDate"
                />
                <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerEndDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #pickerEndDate
                ></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex justify-end items-center gap-3 w-full">
            <button
                    mat-flat-button
                    color="primary"
                    (click)="handlePaymentHistoryFilters()"
            >
                Filter
            </button>

            <button
                    mat-flat-button
                    color="warn"
                    (click)="handlePaymentHistoryResetForm()"
            >
                Clear Filters
            </button>
        </div>
    </form>
    <div *ngIf="!isLoading">
        <h2 *ngIf="!transactions.length" class="text-center">
          No transactions found.
        </h2>

        <div *ngIf="transactions.length">
          <table class="table">
            <thead>
              <tr class="text-center">
                <th>Username</th>
                <th>Organisation</th>
                <th>Payment Attempt Date</th>
                <th>Amount Paid</th>
                <th>Transaction Type</th>
                <th>Payment Status</th>
                <th>Invoice Status</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let transaction of transactions"
                class="text-center"
              >
                <td>{{ transaction.ownerUsername || "-" }}</td>
                <td>{{ transaction.organizationName || "-" }}</td>
                <td>{{ (transaction.paymentAttemptDate | date) || "-" }}</td>
                <td>{{ transaction.amountPaid || "-" }}</td>
                <td>
                  {{
                    transactionTypeValues[transaction.transactionType]
                  }}
                </td>
                <td>
                  <i
                    *ngIf="!transaction.isSuccess"
                    style="font-size: 20px; color: red"
                    class="material-icons"
                    >cancel</i
                  >
                  <i
                    *ngIf="transaction.isSuccess"
                    style="font-size: 20px; color: green"
                    class="material-icons"
                    >check_circle</i
                  >
                </td>
                <td>
                  <i
                    *ngIf="transaction.invoiceGenerated"
                    style="font-size: 20px; color: green"
                    class="material-icons"
                    >check_circle</i
                  >
                  <i
                    *ngIf="!transaction.invoiceGenerated"
                    style="font-size: 20px; color: red"
                    class="material-icons"
                    >cancel</i
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container" *ngIf="totalPages">
            <div class="row justify-content-center">
                <button
                        mat-flat-button
                        color="primary"
                        (click)="
                  handlePaymentHistoryPageChange(currentPage - 1)
                "
                        [disabled]="currentPage == 0"
                >
                    Prev
                </button>

                <div class="mx-3">
                    <select
                            class="custom-select"
                            [(ngModel)]="currentPage"
                            (ngModelChange)="handlePaymentHistoryPageSelect($event)"
                    >
                        <option
                                *ngFor="
                      let _ of [].constructor(totalPages);
                      let i = index
                    "
                                [selected]="i === currentPage"
                                [value]="i"
                        >
                            {{ i + 1 }}
                        </option>
                    </select>
                </div>

                <button
                        mat-flat-button
                        color="primary"
                        (click)="
                  handlePaymentHistoryPageChange(currentPage + 1)
                "
                        [disabled]="
                  currentPage == totalPages - 1
                "
                >
                    Next
                </button>
            </div>
        </div>
    </div>
    <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
</div>
