<div
  class="w-100 flex justify-center items-center py-3 border-bottom"
  *ngFor="let exercise of exercises; let i = index"
>
  <div class="w-[10%]">
    <mat-checkbox
      type="checkbox"
      [checked]="selectedCategories.includes(category.id)"
      (click)="updateSelectedExercise(exercise)"
    />
  </div>
  <div class="w-[70%]">
    <span *ngIf="exercise.regularExercise">
      Regular exercise:
      {{ exercise.regularExercise.name }}
    </span>
    <span class="small-text" *ngIf="exercise.pairedExercise">
      Paired exercise:
      {{ exercise.pairedExercise.name }}
      (Composed of:
      {{ exercise.pairedExercise.firstExercise.name }} and
      {{ exercise.pairedExercise.secondExercise.name }})
    </span>
  </div>
  <div class="d-flex align-items-center justify-content-center w-[20%]">
    <div class="ui dropdown center-text button-dropdown">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDeleteConfirmationDialog(exercise)">
          Delete
        </button>
        <button
          mat-menu-item
          *ngIf="
            !(isPairedExercise(exercise)
              ? exercise.pairedExercise.isFavorite
              : exercise.regularExercise.isFavorite) &&
            category.name != 'Agility Exercises'
          "
          (click)="setFavorited(exercise)"
        >
          Add to Dashboard
        </button>
        <button
          mat-menu-item
          *ngIf="
            (isPairedExercise(exercise)
              ? exercise.pairedExercise.isFavorite
              : exercise.regularExercise &&
                exercise.regularExercise.isFavorite) &&
            category.name != 'Agility Exercises'
          "
          (click)="setFavorited(exercise)"
        >
          Remove from Dashboard
        </button>
        <button
          mat-menu-item
          *ngIf="isSuperAdmin()"
          (click)="onUpdateExerciseVideo(exercise)"
        >
          Update Video
        </button>
        <button
          mat-menu-item
          *ngIf="isSuperAdmin()"
          (click)="addToSingleLocation(exercise)"
        >
          Push to location
        </button>
        <button
          mat-menu-item
          (click)="playVideo(exercise.regularExercise.videoUrl)"
          *ngIf="
            !isPairedExercise(exercise) && exercise.regularExercise.videoUrl
          "
        >
          Play video
        </button>
        <button
          mat-menu-item
          *ngIf="
            !isPairedExercise(exercise) && !exercise.regularExercise.videoUrl
          "
          disabled
        >
          No video available.
        </button>
        <a
          mat-menu-item
          *ngIf="!isPairedExercise(exercise)"
          [routerLink]="['/save-exercise']"
          [queryParams]="{
            id: exercise.regularExercise.id,
          }"
        >
          Edit
        </a>
        <button
          mat-menu-item
          *ngIf="isPairedExercise(exercise) && exercise?.pairedExercise?.firstExercise?.videoUrl"
          (click)="
            playVideo(
              exercise.pairedExercise.firstExercise.videoUrl
            )
          "
        >
          Play Left Exercise Video
        </button>
        <button
          mat-menu-item
          *ngIf="isPairedExercise(exercise) && exercise?.pairedExercise?.secondExercise?.videoUrl"
          (click)="
            playVideo(
              exercise.pairedExercise.secondExercise.videoUrl
            )
          "
        >
          Play Right Exercise Video
        </button>
        <a
          mat-menu-item
          [href]="exercise.regularExercise.thumbnailUrl"
          target="_blank"
          *ngIf="!isPairedExercise(exercise) && exercise.thumbnailUrl"
        >
          See Thumbnail
        </a>
        <a
          mat-menu-item
          [href]="exercise.pairedExercise.firstExercise.thumbnailUrl"
          target="_blank"
          *ngIf="
            isPairedExercise(exercise) &&
            exercise.firstExercise &&
            exercise.firstExercise.thumbnailUrl
          "
        >
          See Left Exercise Thumbnail
        </a>
        <a
          mat-menu-item
          [href]="exercise.pairedExercise.secondExercise.thumbnailUrl"
          target="_blank"
          *ngIf="
            isPairedExercise(exercise) &&
            exercise.secondExercise &&
            exercise.secondExercise.thumbnailUrl
          "
        >
          See Right Exercise Thumbnail
        </a>
        <a
          mat-menu-item
          *ngIf="isPairedExercise(exercise)"
          [routerLink]="['/save-paired-exercise']"
          [queryParams]="{
            id: exercise.pairedExercise.id,
          }"
        >
          Edit
        </a>
      </mat-menu>
    </div>
  </div>
</div>
