import {Component, Input} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {EExerciseJumpType} from "../../../../shared/enums/EExerciseJumpType";

@Component({
  selector: "app-vertical-exercise-form",
  templateUrl: "./vertical-exercise-form.component.html",
  styleUrls: ["./vertical-exercise-form.component.scss"],
})
export class VerticalExerciseFormComponent {
  @Input() _formGroup: FormGroup;
  protected readonly ValidationHelperService = ValidationHelperService;

  get verticalJumpType(): FormControl {
    return this._formGroup.get("verticalJumpType") as FormControl;
  }

  get numberOfLegs(): FormControl {
    return this._formGroup.get("numberOfLegs") as FormControl;
  }

  get numberOfJumps(): FormControl {
    return this._formGroup.get("numberOfJumps") as FormControl;
  }

    protected readonly EExerciseJumpType = EExerciseJumpType;
}
