<mat-icon *ngIf="arrow === EAgilityArrow.TopCenter"> arrow_upward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.TopRight" class="rotate-45"> arrow_upward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.MiddleRight"> arrow_forward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.BottomRight" class="-rotate-45"> arrow_downward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.BottomCenter"> arrow_downward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.BottomLeft" class="rotate-45"> arrow_downward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.MiddleLeft"> arrow_backward </mat-icon>

<mat-icon *ngIf="arrow === EAgilityArrow.TopLeft" class="-rotate-45"> arrow_upward </mat-icon>

<div *ngIf="arrow === EAgilityArrow.MiddleCenter" class="w-[24px] h-[24px] bg-white"></div>
