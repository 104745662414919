<div class="card p-3 mt-2 mt-sm-0 mr-sm-5 mb-2 w-full">
  <div *ngIf="!isLoading">
    <div class="mr-1">
      <button mat-flat-button color="primary" (click)="addUser()">
        Add User
      </button>
    </div>
    <div class="d-flex flex-column gap-5 py-5">
      <div
        *ngFor="let currentUser of users"
        class="flex-grow-1 d-flex align-items-center gap-3 border-bottom py-3"
      >
        <div
          class="d-flex justify-content-start align-items-center gap-3 w-[30%]"
        >
          <div>
            <div class="d-flex align-items-center">
              <div>
                <p class="grayish bold-text no-text-align no-bottom-margin">
                  {{ currentUser.firstName }} {{ currentUser.lastName }}
                </p>
                <p class="grayish no-text-align">
                  {{ currentUser.username }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-[50%] flex flex-wrap gap-2 items-center">
            <app-location-badge [location]="location" *ngFor="let location of currentUser.userLocations"></app-location-badge>
        </div>
        <div class="d-flex align-items-center justify-content-end w-[20%]">
            <app-is-enabled-indicator [value]="currentUser.isActive"></app-is-enabled-indicator>
          <button mat-button [matMenuTriggerFor]="menu">
            <i class="default text ellipsis horizontal icon zoomed"></i>
          </button>
          <mat-menu class="menu pushed-menu" #menu="matMenu">
            <button mat-menu-item (click)="editUser(currentUser)">Edit</button>
            <button mat-menu-item (click)="toggleUserStatus(currentUser)">
              {{currentUser.isActive ? 'Disable' : 'Enable'}}
            </button>
            <button mat-menu-item (click)="deleteUser(currentUser)">
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
