<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
    <div class="p-3">
      <button class="text-uppercase btn btn-primary" (click)="openBackModal()">
        Back
      </button>
    </div>
  </div>

  <div class="card w-100 bg-white d-flex justify-content-between">
    <div class="d-flex justify-content-between w-100 p-3 border-bottom">
      <div class="modify-wrapper toggled">
        <button
          class="text-uppercase btn btn-secondary"
          (click)="removeAllSelected()"
        >
          Remove
        </button>
      </div>
    </div>

    <div class="w-100">
      <div
        class="d-flex justify-content-between align-items-center border-bottom"
      >
        <div class="p-3 w-100">
          <div class="ui input w-50">
            <input
              type="text"
              placeholder="{{ global?.data?.uppercaseWorkoutName }} Name"
              [(ngModel)]="newWorkout.name"
            />
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div
            class="text-center"
            (click)="addPlaylist(); newWorkout.collapsed = true"
          >
            <i class="plus icon black"></i>
            <p>Playlist</p>
          </div>

          <div class="p-3">
            <div
              class=""
              (click)="newWorkout.collapsed = !newWorkout.collapsed"
            >
              <i
                class="angle down icon"
                style="font-size: 20px"
                *ngIf="!newWorkout.collapsed"
              ></i>
              <i
                class="angle up icon"
                style="font-size: 20px"
                *ngIf="newWorkout.collapsed"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-100"
    >
      <div
        class="w-100"
        *ngFor="let newPlaylist of newWorkout.playlists; let i = index"
      >
        <div
          class="d-flex border-bottom align-items-lg-center justify-content-lg-between"
        >
          <div class="p-3 m-1 ml-2 mt-4 mt-lg-1 mr-4 radio">
            <label class="check-container">
              <input
                type="checkbox"
                (click)="updateSelected(newPlaylist, playlistsSelected)"
                [checked]="playlistsSelected.indexOf(newPlaylist) != -1"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <div
            class="d-flex align-items-center justify-content-between flex-column flex-lg-row p-2 w-100"
          >
            <div class="playlist-name w-100 pt-3 pt-lg-0">
              <div class="ui input mt-1 mt-lg-0 mr-lg-2 w-100">
                <input
                  type="text"
                  class="mr-lg-2"
                  placeholder="Playlist Name"
                  [(ngModel)]="newPlaylist.name"
                />
              </div>
            </div>

            <div class="d-flex flex-column flex-lg-row w-100 pb-lg-0 pb-3">
              <!-- <button (click)="onClickerrr()">Clickkk</button> -->
              <div
                class="select-field parameter-select mt-2 mt-lg-0 mr-lg-2 align-middle"
              >
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Automated</span
                  >
                </div>
                <div class="inline fields">
                  <mat-slide-toggle
                    (change)="toggleAutomated(newPlaylist, i)"
                    [(ngModel)]="automatedArray[i]"
                  ></mat-slide-toggle>
                </div>
              </div>

              <div
                class="select-field parameter-select mt-2 mt-lg-0 mr-lg-2 align-middle"
              >
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3">Circuit</span>
                </div>
                <div class="inline fields">
                  <mat-slide-toggle
                    (change)="toggleCircuit(newPlaylist, i)"
                    [(ngModel)]="circuitArray[i]"
                  ></mat-slide-toggle>
                </div>
              </div>

              <div class="select-field parameter-select mt-2 mt-lg-0 mr-lg-2">
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Circuit Sets</span
                  >
                </div>
                <div class="inline fields">
                  <select
                    class="browser-default custom-select"
                    [(ngModel)]="newPlaylist.circuitSets"
                    [disabled]="!circuitArray[i]"
                  >
                    <option *ngFor="let set of possibleSets" [ngValue]="set">
                      {{ set }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="select-field parameter-select mt-2 mt-lg-0 mr-lg-2">
                <div class="inline fields">
                  <span class="small font-weight-bold mt-2 mr-3"
                    >Rest Time</span
                  >
                </div>
                <div class="inline fields">
                  <select
                    class="browser-default custom-select"
                    [(ngModel)]="newPlaylist.restTime"
                    [disabled]="!automatedArray[i]"
                  >
                    <option
                      *ngFor="let time of possibleRestTime"
                      [ngValue]="time"
                    >
                      {{ time | secondsToTime }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-lg-row align-items-center p-2">
            <div
              class="mt-4 mt-lg-0 mr-lg-2"
              (click)="beginAddingExercises(i); newPlaylist.collapsed = true"
            >
              <div class="text-center">
                <i class="plus icon black"></i>
                <p>Exercises</p>
              </div>
            </div>

            <div class="mt-2 mt-lg-0 mr-lg-2">
              <div class="text-center" (click)="removePlaylist(i)">
                <i class="trash icon red"></i>
                <p>Delete</p>
              </div>
            </div>

            <div class="mt-2 mt-lg-0 mr-lg-2">
              <div class="workout-header">
                <div
                  class="m-1"
                  (click)="newPlaylist.collapsed = !newPlaylist.collapsed"
                >
                  <i
                    class="angle down icon"
                    style="font-size: 20px"
                    *ngIf="!newPlaylist.collapsed"
                  ></i>
                  <i
                    class="angle up icon"
                    style="font-size: 20px"
                    *ngIf="newPlaylist.collapsed"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="full-width"
        >
          <table class="table table-bordered table-striped mb-0">
            <tbody>
              <tr
                *ngFor="let exercise of newPlaylist.exerciseList; let j = index"
                id="{{ exercise.exerciseId }}"
              >
                <td class="radio">
                  <label class="check-container">
                    <input
                      type="checkbox"
                      (click)="
                        updateSelected(exercise, playlistExercisesSelected)
                      "
                      [checked]="
                        playlistExercisesSelected.indexOf(exercise) != -1
                      "
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>

                <td>
                  <select
                    class="browser-default custom-select"
                    [(ngModel)]="exercise.sets"
                  >
                    <option *ngFor="let set of possibleSets" [ngValue]="set">
                      {{ set }} sets
                    </option>
                  </select>
                </td>

                <td>
                  <p class="medium-text grayish element-name">
                    {{ exercise.name }}<br />
                    <span class="small-text" *ngIf="exercise.paired === 2">
                      (Composed of: {{ exercise.firstExerciseName }} and
                      {{ exercise.secondExerciseName }})
                    </span>
                  </p>
                </td>

                <td class="radio">
                  <div class="d-flex align-items-center justify-content-center">
                    <div
                      class="ui dropdown center-text button-dropdown"

                    >
                      <i
                        class="default text ellipsis horizontal icon zoomed"
                      ></i>
                      <div class="menu pushed-menu" >
                        <div class="item">
                          <!--                                            (click)="$event.stopPropagation(); openEditModal(exercise.exerciseId)">-->
                          Edit
                        </div>
                        <div class="item" (click)="removeExercise(i, j)">
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="w-100 p-3">
      <button
        class="btn btn-primary text-uppercase d-block mx-auto"
        [disabled]="cannotSaveWorkout() || loading"
        (click)="saveWorkout()"
      >
        <ng-container *ngIf="!loading"
          >Save {{ global?.data?.uppercaseWorkoutName }}</ng-container
        >
        <ng-container *ngIf="loading">Saving...</ng-container>
      </button>
    </div>
  </div>
</div>

<ng-template let-modal="modal" #confirmEditModal>
  <div class="header">Edit</div>
  <div class="content" id="edit-content">Edit exercise</div>
  <div class="actions">
    <button type="button" class="ui negative button" (click)="modal.deny()">
      No
    </button>
    <button type="button" class="ui positive button" (click)="modal.approve()">
      Yes
    </button>
  </div>
</ng-template>
