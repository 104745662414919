<div class="flex flex-col">
  <div class="flex gap-5 py-3 border-b">
    <button
      (click)="imgInput.click()"
      mat-flat-button
      color="primary"
      type="button"
      class="flex justify-center items-center gap-3"
      [disabled]="isImgThumbUploading"
    >
      <span *ngIf="!isImgThumbUploading">{{
        !!_control?.value ? "Upload another thumbnail" : "Upload thumbnail"
      }}</span>
      <mat-icon *ngIf="!isImgThumbUploading">cloud_upload</mat-icon>
      <mat-spinner *ngIf="isImgThumbUploading" diameter="30"></mat-spinner>
      <input
        type="file"
        #imgInput
        name="file"
        ng2FileSelect
        [uploader]="imgUploader"
        (click)="_control.setValue(null); imgUploader.clearQueue()"
        accept="image/*"
        hidden
      />
    </button>
    <button
      mat-flat-button
      color="accent"
      (click)="openImageDialog()"
      type="button"
    >
      View current thumbnail
    </button>
  </div>
  <mat-error *ngIf="ValidationHelperService.isInvalid(_control)">
    {{ ValidationHelperService.getErrorMessage(_control) }}
  </mat-error>
</div>
