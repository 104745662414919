import {Component, OnInit, ViewChild} from "@angular/core";
import {AthletesControllerService} from "../../core/api/controllers/athletes-controller.service";
import {NotificationService} from "../../core/services/notification.service";
import {ISingleAthleteDto} from "../../shared/DTOs/ISingleAthleteDto";
import {MatAccordion, MatExpansionModule} from "@angular/material/expansion";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SettingsService} from "../../core/services/settings.service";
import {FileItem, FileUploader, FileUploadModule} from "ng2-file-upload";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../core/services/auth.service";
import {AthletesGroupBy} from "../../shared/pipes/athletesGroupBy.pipe";
import {NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {AthleteBlockComponent} from "./athlete-block/athlete-block.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RouterLink} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {AthletesCategoryComponent} from "./athletes-category/athletes-category.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatBadgeModule} from "@angular/material/badge";

export interface IAthleteFilter {
    name: string;
    id: string;
}

export interface IAthleteSportFilter {
    id: string;
    name: string;
    noPositionId: string;
    positions: IAthleteFilter[];
}

export interface IGetFilters {
    categoryFilters: IAthleteFilter[];
    levelFilters: IAthleteFilter[];
    sportFilters: IAthleteSportFilter[];
    teamFilters: IAthleteFilter[];
}

export interface IAthleteForm {
    athleteObject: FormControl<ISingleAthleteDto>;
    isSelected: FormControl<boolean>;
}

@Component({
    selector: "app-athletes",
    templateUrl: "./athletes.component.html",
    styleUrls: ["./athletes.component.scss"],
    providers: [AthletesControllerService],
    standalone: true,
    imports: [AthletesGroupBy, NgForOf, MatButtonModule, AthleteBlockComponent, NgIf, MatCheckboxModule, ReactiveFormsModule, MatExpansionModule, FileUploadModule, RouterLink, MatTooltipModule, MatIconModule, AthletesCategoryComponent, MatProgressSpinnerModule, MatPaginatorModule, MatBadgeModule]
})
export class AthletesComponent implements OnInit {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    athletesForm = new FormGroup({
        athletes: new FormArray<FormGroup<IAthleteForm>>([])
    });
    athleteList: ISingleAthleteDto[] = [];
    possibleFilters = [];
    filters = [];
    selectedFilters = [];
    filtering = false;
    active = true;
    isLoading: boolean;
    filtersForm: FormGroup<{
    categories: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
    levels: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
    sports: FormArray<
        FormGroup<{
          id: FormControl<string>,
          name: FormControl<string>,
          isSelected: FormControl<boolean>,
            noPositionId: FormControl<string>,
          positions: FormArray<
              FormGroup<{
                id: FormControl<string>,
                name: FormControl<string>,
                isSelected: FormControl<boolean>
              }>
          >
        }>>,
    teams: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
  }>;
    athletesLimit = 0;
    imageUploader: FileUploader;

    constructor(
    private athletesController: AthletesControllerService,
    private notificationService: NotificationService,
    private snackBarService: MatSnackBar,
    private settingsService: SettingsService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    const previousFilters = JSON.parse(
      sessionStorage.getItem("athleteFilters"),
    );
    if (previousFilters != null) {
      this.filters = previousFilters.filters;
      this.selectedFilters = previousFilters.selectedFilters;
      this.active = previousFilters.active;
    }
    this.updateAthleteList();
    this.getPossibleFilters();
    this.subscribeToSettingsService();
      this.imageUploader = new FileUploader({
          queueLimit: 1,
      });
      this.imageUploader.onAfterAddingFile = (file: FileItem) => {
          const uploadData = new FormData();
          uploadData.append('CSV', file.file.rawFile);
          if(this.isLoading) return;
          this.isLoading = true;
          this.httpClient.post(`${environment.baseURL}/athletes/import?locationId=${localStorage.getItem("selectedLocationId")}`, uploadData, {
              headers: AuthService.defaultTokenHeader().headers,
          })
              .subscribe({
                  next: (res: any) => {
                      this.notificationService.success("Bulk import has been executed successfully!");
                      this.getAthletes();
                  },
                  error: (res) => {
                      this.downloadErrorAsCSV(res.error.text);
                      this.notificationService.error("Some athletes couldn't be imported. Check the downloaded report.");
                      this.getAthletes();
                  }
              });
      };
  }

    private downloadErrorAsCSV(errorText: string) {
        const blob = new Blob([errorText], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'remaining_athletes.csv';
        link.click();
        window.URL.revokeObjectURL(url);
    }


  subscribeToSettingsService(): void {
        this.athletesLimit = this.settingsService.settings?.subscription?.athleteLimit;
      this.settingsService.$settings.subscribe(res => {
          this.athletesLimit = res.subscription.athleteLimit;
      });
  }

  initForm(response: IGetFilters): void {
    this.filtersForm = new FormGroup({
      categories: new FormArray([]),
      levels: new FormArray([]),
      sports: new FormArray([]),
      teams: new FormArray([]),
    });
    response.categoryFilters.forEach((filter) => {
      this.filtersForm.controls.categories.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
    response.levelFilters.forEach((filter) => {
      this.filtersForm.controls.levels.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
    response.sportFilters.forEach((filter) => {
      const newSportFilter =  new FormGroup({
            id: new FormControl(filter.id),
            name: new FormControl(filter.name),
            isSelected: new FormControl(false),
            positions: new FormArray([]),
            noPositionId: new FormControl(filter.noPositionId),
          });

      filter.positions.forEach((position) => {
        newSportFilter.controls.positions.push(
          new FormGroup({
            id: new FormControl(position.id),
            name: new FormControl(position.name),
            isSelected: new FormControl(false),
          }),
        );
      });

      this.filtersForm.controls.sports.push(newSportFilter);
    });
    response.teamFilters.forEach((filter) => {
      this.filtersForm.controls.teams.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
  }

  removeSelectedAthletes(): void {
    this.isLoading = true;
    this.athletesController
      .deleteMultipleAthletes(this.selectedAthletes)
      .subscribe({
        next: () => {
          this.athleteList = this.athleteList.filter(
              (athlete) => !this.selectedAthletes.includes(athlete.id),
          );
          this.setAthletesFormValue(this.athleteList);
          this.isLoading = false;
        },
        error: () => {
          this.snackBarService.open("Something went wrong trying to delete athletes. Please try again later.", "Close", {
            duration: 2000,
          });
          this.isLoading = false;
        }
      });
  }

  updateAthleteList(shouldFilter: boolean = false) {
    if (shouldFilter) {
      this.athleteList = [];
      sessionStorage.setItem(
        "athleteFilters",
        JSON.stringify({
          selectedFilters: this.selectedFilters,
          filters: this.filters,
          active: this.active,
        }),
      );
    }
    this.getAthletes();
  }

  getAthletes(): void {
      this.isLoading = true;
      const selectedLocationId = localStorage.getItem("selectedLocationId");
      this.athletesController
          .getAthletes(selectedLocationId, this.constructFilters())
          .subscribe(
              (response) => {
                  this.athleteList = response;
                  this.setAthletesFormValue(this.athleteList);
                  this.isLoading = false;
              },
              (error) => this.notificationService.error(error),
          );
  }

  setAthletesFormValue(athletesList: ISingleAthleteDto[]) {
    this.athletesForm.controls.athletes.clear();
    athletesList.forEach((athlete) => {
      this.athletesForm.controls.athletes.push(
          new FormGroup({
            athleteObject: new FormControl(athlete),
            isSelected: new FormControl(false),
          })
      )
    });
  }

  constructFilters() {
      let filters = {
          categoryIds: [],
          sportPositionIds: [],
          levelIds: [],
          teamIds: []
      };
      if(this.filtersForm){
          filters = {
              categoryIds: this.filtersForm.value.categories.filter(category => category.isSelected).map(category => category.id),
              sportPositionIds: [],
              levelIds: this.filtersForm.value.levels.filter(level => level.isSelected).map(level => level.id),
              teamIds: this.filtersForm.value.teams.filter(team => team.isSelected).map(team => team.id),
          };
          const sportPositionIds = this.filtersForm.value.sports.reduce((acc, sport) => {
              if(sport.isSelected) {
                  const selectedPositions = sport.positions.filter(position => position.isSelected);
                  if(selectedPositions.length > 0)
                    return acc.concat(selectedPositions.map(position => position.id));
                  else return acc.concat([sport.noPositionId]);
              } else return acc;
          }, []);
          filters.sportPositionIds = sportPositionIds || [];
      }
     return filters;
  }

  getPossibleFilters() {
      this.isLoading = true;
    const selectedLocationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getPossibleFilters(selectedLocationId).subscribe(
      (response) => {
        this.possibleFilters = response;
        this.initForm(response);
        this.isLoading = false;
      },
      (error) => this.notificationService.error(error),
    );
  }

    resetFilters(): void {
      this.filtersForm.controls.categories.controls.forEach((category) => {
        category.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.levels.controls.forEach((level) => {
        level.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.teams.controls.forEach((team) => {
        team.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.sports.controls.forEach((sport) => {
        sport.controls.isSelected.setValue(false);
        sport.controls.positions.controls.forEach((position) => {
          position.controls.isSelected.setValue(false);
        });
      });
        this.resetAthletesList();
    }

    resetAthletesList(): void {
        this.athleteList = [];
        this.updateAthleteList();
    }

  get hasFilters() {
    let isFiltered = false;
    if(!this.filtersForm) return false;
    isFiltered = isFiltered || this.filtersForm.value.categories.some(category => category.isSelected);
    isFiltered = isFiltered || this.filtersForm.value.levels.some(level => level.isSelected);
    isFiltered = isFiltered || this.filtersForm.value.teams.some(team => team.isSelected);
    this.filtersForm.controls.sports.value.forEach(sport => {
      isFiltered = isFiltered || sport.isSelected;
      isFiltered = isFiltered || sport.positions.some(position => position.isSelected);
    })
    return isFiltered;
  }

  get selectedAthletes(): string[] {
    return this.athletesForm.controls.athletes.controls.filter(
        (control) => control.value.isSelected,
    ).map(
        athlete => athlete.value.athleteObject.id
    );
  }

  get athletesLimitReached(): boolean {
    return this.athletesForm.controls.athletes.length >= this.athletesLimit;
  }
}
