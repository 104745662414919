import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms-of-sale",
  templateUrl: "./terms-of-sale.component.html",
  styleUrls: ["./terms-of-sale.component.scss"],
})
export class TermsOfSaleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
