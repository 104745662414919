import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class InvoicesControllerService {
  private readonly _resourceUrl: string = "/invoices";

  constructor(private apiService: ApiService) {}

  getInvoices(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
