<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-bold ml-1 h-5"
          >Most Active {{ global?.data?.uppercaseAthleteName }}s</span
        >
        <select
          style="width: 100px"
          class="selectpicker custom-select"
          [(ngModel)]="mostActiveAthletesChart.fromDate"
        >
          <option [ngValue]="0">Daily</option>
          <option [ngValue]="1">Weekly</option>
          <option [ngValue]="2">Monthly</option>
          <option [ngValue]="3">Complete</option>
        </select>
      </div>
    </div>
  </div>

  <div
    class="card w-100 p-3 border-bottom mb-2 mb-sm-3 mb-md-4 mb-lg-5"
    *ngIf="isSuperAdmin()"
  >
    <div class="pb-3 border-bottom">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-bold ml-1 h-5">Statistics</span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2>{{ athleteCount || "-" }}</h2>
        Athletes
      </div>
      <div class="col">
        <h2>{{ organizationCount || "-" }}</h2>
        Organizations
      </div>
      <div class="col">
        <h2>{{ userCount || "-" }}</h2>
        Users
      </div>
    </div>
  </div>

  <app-most-active-exercise-categories></app-most-active-exercise-categories>

  <div class="card w-full mb-2 mb-sm-3 mb-md-4 mb-lg-5" *ngIf="isSuperAdmin()">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-bold ml-1 h-5">Registered users</span>
      </div>
    </div>
    <div class="w-full flex flex-col gap-3">
        <div class="flex gap-3 items-center p-3">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input [(ngModel)]="searchText" #ctrl="ngModel" type="text" matInput placeholder="Search" (keydown)="debouncedGetUsers($event)"/>
        </mat-form-field>
    </div>
        <div class="w-full overflow-x-auto">
            <table mat-table [dataSource]="usersDataSource">
                <ng-container matColumnDef="userName">
                    <th mat-header-cell *matHeaderCellDef>userName</th>
                    <td mat-cell *matCellDef="let element">{{ element.username }}</td>
                </ng-container>
                <ng-container matColumnDef="organizationName">
                    <th mat-header-cell *matHeaderCellDef>organizationName</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.organizationName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>firstName</th>
                    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>lastName</th>
                    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>email</th>
                    <td mat-cell *matCellDef="let user">{{ user.email }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>status</th>
                    <td mat-cell *matCellDef="let element">
                        <input
                                (change)="userUpdated(element)"
                                [(ngModel)]="element.subscriptionDetails.subscriptionStatus"
                                #ctrl="ngModel"
                                type="number"
                                min="-1"
                                max="1"
                                style="width: 50px"
                        />
                        <!--{{element.status}} -->
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastBilled">
                    <th mat-header-cell *matHeaderCellDef>lastBilled</th>
                    <td mat-cell *matCellDef="let element">
                        <input
                                (change)="userUpdated(element)"
                                [(ngModel)]="element.subscriptionDetails.lastBilled"
                                #ctrl="ngModel"
                                type="datetime-local"
                                class="w-full"
                        />
                        <!--{{element.lastBilled}}-->
                    </td>
                </ng-container>
                <ng-container matColumnDef="subscription">
                    <th mat-header-cell *matHeaderCellDef>subscription</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <select
                                (ngModelChange)="changed(element)"
                                name="selectedQuantity"
                                class="form-control"
                                [(ngModel)]="element.subscriptionDetails"
                        >
                            <option
                                    [selected]="true"
                                    [hidden]="
                  valueChanged ||
                  element.subscriptionDetails == null ||
                  element.subscriptionDetails == undefined
                "
                                    [ngValue]="element.subscriptionDetails"
                            >
                                {{
                                    element.subscriptionDetails != null
                                        ? element.subscriptionDetails.name
                                        : null
                                }}
                            </option>
                            <option
                                    *ngFor="let option of subscriptionDetails"
                                    [ngValue]="option"
                            >
                                {{ option.name }}
                            </option>
                        </select>
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>price</th>
                    <td mat-cell *matCellDef="let element">
                        {{
                            element.subscriptionDetails != null &&
                            element.subscriptionDetails != undefined
                                ? element.subscriptionDetails.price
                                : null
                        }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="athleteLimit">
                    <th mat-header-cell *matHeaderCellDef style="padding-right: 5px">
                        athleteLimit
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <input
                                (change)="userUpdated(element)"
                                *ngIf="element.subscriptionDetails"
                                [(ngModel)]="element.subscriptionDetails.athleteLimit"
                                #ctrl="ngModel"
                                type="text"
                                style="width: 50px"
                        />
                    </td>
                </ng-container>
                <ng-container matColumnDef="creditCardLast4">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 5px">
                        creditCardLast4
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.subscriptionDetails.creditCardLast4 }}
                        <button
                                *ngIf="element.subscriptionDetails.creditCardLast4"
                                (click)="deleteCreditCard(element.subscriptionDetails.id)"
                                class="btn"
                                matTooltip="Delete credit card."
                        >
                            <i style="font-size: 20px; color: red" class="material-icons"
                            >delete</i
                            >
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        No data matching the filter: "{{ searchText }}"
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <mat-paginator
      (page)="pageChanged($event)"
      [length]="totalItems"
      [pageSizeOptions]="[10, 5, 20]"
      showFirstLastButtons
    ></mat-paginator>
    <div>
      <button
        [disabled]="updatedUsers.length <= 0"
        mat-flat-button
        color="primary"
        class="btn btn-primary text-white mr-3 ml-3 bottom-button"
        (click)="updateUsers()"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="warn"
        [disabled]="updatedUsers.length <= 0"
        class="btn btn-primary text-white mr-3 ml-3 bottom-button"
        (click)="cancelUpdate()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
