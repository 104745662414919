import {RegularExerciseHelper} from "./RegularExerciseHelper";
import {FormGroup} from "@angular/forms";
import {IPairedExerciseForm} from "../../../shared/interfaces/forms/exercises/IPairedExerciseForm";

export class PairedExerciseHelper {
  static isFormValid(formGroup: FormGroup<IPairedExerciseForm>): boolean {
    return (
      formGroup.controls.category.valid &&
      formGroup.controls.name.valid &&
      formGroup.controls.type.valid &&
      RegularExerciseHelper.isFormValid(
        formGroup.controls.firstExercise,
        formGroup.controls.type,
      ) &&
      RegularExerciseHelper.isFormValid(
        formGroup.controls.secondExercise,
        formGroup.controls.type,
      )
    );
  }

  static createPayload(formGroup: FormGroup<IPairedExerciseForm>) {
    const value = {
      categoryId: formGroup.controls.category.value.id,
      name: formGroup.controls.name.value,
      type: formGroup.controls.type.value,
      firstExercise: RegularExerciseHelper.createPayload(
        formGroup.controls.firstExercise,
      ),
      secondExercise: RegularExerciseHelper.createPayload(
        formGroup.controls.secondExercise,
      ),
    };
    return value;
  }

  static patchFormConfig(formGroup: FormGroup<IPairedExerciseForm>, value: any) {
    const defaultPairedConfig = value.pairedExerciseConfigs.find(
        (config) => config.id === value.defaultConfigId
    );

    value.firstExercise.defaultConfigId = defaultPairedConfig.firstConfigId;
    value.secondExercise.defaultConfigId = defaultPairedConfig.secondConfigId;
    value.firstExercise.categoryId = value.categoryId;
    value.secondExercise.categoryId = value.categoryId;

    RegularExerciseHelper.patchFormConfig(
        formGroup.controls.firstExercise,
        value.firstExercise,
    );
    RegularExerciseHelper.patchFormConfig(
        formGroup.controls.secondExercise,
        value.secondExercise,
    );
  }

  static patchFormValue(formGroup: FormGroup<IPairedExerciseForm>, value: any) {
    const defaultPairedConfig = value.pairedExerciseConfigs.find(
      (config) => config.id === value.defaultConfigId
    );

    value.firstExercise.defaultConfigId = defaultPairedConfig.firstConfigId;
    value.secondExercise.defaultConfigId = defaultPairedConfig.secondConfigId;
    value.firstExercise.type = value.type;
    value.secondExercise.type = value.type;
    value.firstExercise.category = {
      id: value.categoryId,
      name: null,
    };
    value.firstExercise.categoryId = value.categoryId;
    value.secondExercise.category = {
      id: value.categoryId,
      name: null,
    };
    value.secondExercise.categoryId = value.categoryId;

    formGroup.patchValue({
      category: {
        id: value.categoryId,
        name: null,
      },
      name: value.name,
      type: value.type,
    });


    RegularExerciseHelper.patchFormValue(
      formGroup.controls.firstExercise,
      value.firstExercise,
    );
    RegularExerciseHelper.patchFormValue(
      formGroup.controls.secondExercise,
      value.secondExercise,
    );
  }
}
