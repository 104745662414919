import {Component, Input, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {delayArray} from "../../../../core/helpers/exercise-data";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {NotificationService} from "../../../../core/services/notification.service";
import {IAgilityForm} from "../../../../shared/interfaces/forms/exercises/IAgilityForm";
import {EAgilityArrow} from "../../../../shared/enums/EAgilityArrow";

@Component({
  selector: "app-agility-exercise-form",
  templateUrl: "./agility-exercise-form.component.html",
  styleUrls: ["./agility-exercise-form.component.scss"],
})
export class AgilityExerciseFormComponent implements OnInit {
  @Input() _formGroup: FormGroup<IAgilityForm>;
  delayArray = delayArray;
  arrowDelayArray = this.delayArray;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.subscribeToagilityCustomTypeChanges();
  }

  subscribeToagilityCustomTypeChanges(): void {
    this.agilityCustomType.valueChanges.subscribe(() => {
      if (this.agilityCustomType.value === 1)
        this.populateAgilitySensorsWithAllSensors();
      else this.agilitySensors.clear();
    });
  }

  populateAgilitySensorsWithAllSensors() {
    this.agilitySensors.clear();
    for (let i = 1; i <= 9; i++) {
      this.agilitySensors.push(new FormControl(i));
    }
  }

  isSelected(arrow: number) {
    return (
      (this.agilityCustomType.value === 1 &&
        this.agilitySensors.value.includes(arrow)) ||
      this.agilityCustomType.value === 0
    );
  }

  clickArrow(arrow: number) {
    if (this.agilityCustomType.value === 0) this.addArrow(arrow);
    else this.toggleArrow(arrow);
  }

  addArrow(arrow: number) {
    const length = this.agilitySensors.value.length;
    if (length < 200) {
      this.agilitySensors.push(new FormControl(arrow));
    } else {
      this.notificationService.error(
        "The sequence length cannot exceed 26 steps.",
      );
    }
  }

  toggleArrow(arrow: number) {
    if (this.isSelected(arrow)) {
      this.removeAgilitySensorByValue(arrow);
    } else {
      this.addArrow(arrow);
    }
  }

  resetAgilitySensors(type: number) {
    this.agilitySensors.clear();
    if (type === 1) {
      this.agilitySensors.patchValue([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    }
  }

  removeAgilitySensorAtIndex(index: number) {
    this.agilitySensors.removeAt(index);
  }

  removeAgilitySensorByValue(value: number) {
    const index = this.agilitySensors.value.indexOf(value);
    this.agilitySensors.removeAt(index);
  }

  get agilityCustomType(): FormControl {
    return this._formGroup.get("agilityCustomType") as FormControl;
  }

  get delay(): FormControl {
    return this._formGroup.get("delay") as FormControl;
  }
  get timerPrompt(): FormControl {
    return this._formGroup.get("timerPrompt") as FormControl;
  }

  get agilitySensors(): FormArray<FormControl<number>> {
    return this._formGroup.controls.agilitySensors;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
    protected readonly EAgilityArrow = EAgilityArrow;
}
