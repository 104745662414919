<form [formGroup]="_formGroup">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Count Type</mat-label>
    <mat-select
      placeholder="Count Type"
      [formControl]="countType"
      [value]="countType.value"
    >
      <mat-option [value]="0">Sum All</mat-option>
      <mat-option [value]="1">Left Right</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(countType)">
      {{ ValidationHelperService.getErrorMessage(countType) }}
    </mat-error>
  </mat-form-field>
</form>
