import {Component, OnInit} from "@angular/core";
import {PlaylistControllerService} from "../../core/api/controllers/playlist-controller.service";
import {MediaControllerService} from "../../core/api/controllers/media-controller.service";
import {delayArray, flashArray, possibleRestTime, possibleSets,} from "../../core/helpers/exercise-data";
import {NotificationService} from "../../core/services/notification.service";
import {FormBuilder, FormGroup, ValidatorFn, Validators,} from "@angular/forms";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {Observable, Subject} from "rxjs";
import {CanComponentDeactivate, UnsavedChangesGuard,} from "../../core/guards/can-deactivate.guard";
import {ExercisesControllerService} from "../../core/api/controllers/exercises-controller.service";
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {
  EditExerciseDialogComponent,
  IEditExerciseDialogData,
} from "../../shared/components/edit-exercise-dialog/edit-exercise-dialog.component";
import {
  AddExercisesDialogComponent,
  IAddExercisesDialogData,
} from "../../shared/components/add-exercises-dialog/add-exercises-dialog.component";
import {ExerciseValidationService} from "../../core/helpers/exercise-validation.service";
import {AthletesControllerService} from "../../core/api/controllers/athletes-controller.service";
import {ExerciseControllerService} from "../../core/api/controllers/exercise-controller.service";

interface NewPlaylistProps {
  creationDate?: number;
  type: number;
  restTime: number;
  circuitSets: number;
  exerciseList: any[];
}

@Component({
  selector: "app-playlists",
  templateUrl: "./playlists.component.html",
  styleUrls: ["./playlists.component.scss"],
  providers: [
    PlaylistControllerService,
    MediaControllerService,
    ExercisesControllerService,
  ],
})
export class PlaylistsComponent implements OnInit, UnsavedChangesGuard {
  playlistData = [];
  athleteToDelete;
  selectedPlaylist = null;
  copySelectedPlaylist = null;
  possibleSets = possibleSets;
  possibleRestTime = possibleRestTime;
  possibleTypes = ["Count", "React", "Sequence", "Agility", "Vertical"];
  selectedType;
  playlistType = 0;
  playlistCircuitSets = 1;
  playlistRestTime;
  athletesSelected = [];
  addAthletesSelected = [];
  selectedIndex;
  newPlaylist: NewPlaylistProps = {
    type: 0,
    restTime: this.possibleRestTime[0],
    circuitSets: 1,
    exerciseList: [],
  };
  exerciseList = [];
  copyExerciseList = [];
  exercisesSelected = [];
  addExercisesSelected = [];
  modal;
  react = [];
  dontReact = [];
  reactSensors = [];
  sequenceSensors = [];
  agilitySensors = [];
  categoryInput;
  categoryOptions = [];
  exerciseCategories = [];
  delayArray = delayArray;
  flashArray = flashArray;
  modifiedSettings = [];
  filteredExerciseList = [];
  filteredAthleteList = [];
  modified = false;
  updateSelected = PlaylistsComponent.updateSelected;
  updateAthletesSelected = PlaylistsComponent.updateAthletesSelected;
  editing = true;
  exerciseEditForm: FormGroup;
  selectedExercise;
  toggle = true;
  canLoadPlaylistData = false;
  canLoadExerciseList = false;
  canLoadSelectedPlaylist = false;
  canLoadAthleteList = false;
  searchAddExercise = "";
  searchAddAthlete = "";
  loading = false;
  playlistsLoaded;
  searching = false;
  filterTimeout;
  automated = false;
  circuit = false;
  firstName: string;
  lastName: string;
  notSaved = false;

  exerciseCategoriesNew = [];
  exercisesNew = [];

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  static updateSelected(value, array) {
    const newValue = {
      categoryId: value.categoryId,
      id: value.id,
      exerciseUUID: value.uuid,
      name: value.name,
      paired: value.paired,
      sets: 1,
    };
    let index = -1;
    const foundValue = array.some((el, i) => {
      if (el.id === value.id) {
        index = i;
        return true;
      }
      return false;
    });
    index >= 0 && foundValue ? array.splice(index, 1) : array.push(newValue);
  }

  static updateAthletesSelected(value, array) {
    const index = array.indexOf(value);
    index >= 0 ? array.splice(index, 1) : array.push(value);
  }

  constructor(
    private playlistService: PlaylistControllerService,
    private exercisesController: ExercisesControllerService,
    private exerciseController: ExerciseControllerService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private athletesController: AthletesControllerService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private exerciseService: ExercisesControllerService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("playlist_screen", {});
    this.getAthleteData();
  }

  checkIfSelected(id) {
    return this.exercisesSelected.some((el) => el.id === id);
  }

  canDeactivate(
    component: CanComponentDeactivate,
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.modified) {
      this.openConfirmModal(this.selectedPlaylist, this.selectedIndex);

      return this.navigateAwaySelection$;
    }
    return true;
  }

  choose(choice: boolean): void {
    this.navigateAwaySelection$.next(choice);
  }

  getAthleteData() {
    this.playlistsLoaded = false;
    const playlistDataLength = this.playlistData.length;
    const page =
      playlistDataLength > 0 ? Math.floor(playlistDataLength / 20) : 0;
    const size = 20;
    this.playlistService
      .getPlaylistData(
        page,
        size,
        this.searchAddAthlete,
        this.searchAddAthlete != ""
          ? this.playlistData[this.selectedIndex].id
          : "",
      )
      .subscribe(
        (playlistData) => {
          const dataLength = playlistData.length;
          if (this.searchAddAthlete == "") {
            this.playlistData =
              playlistDataLength > 0
                ? this.playlistData.concat(playlistData)
                : playlistData;
            this.canLoadPlaylistData =
              dataLength % 20 === 0 && dataLength !== 0;
            if (page === 0 && this.playlistData.length > 0) {
              this.updateSelectedPlaylist(this.playlistData[0].playlist, 0);
            }
          } else {
            this.canLoadAthleteList = dataLength % 20 === 0 && dataLength !== 0;
            for (const athlete of playlistData) {
              this.filteredAthleteList.push(athlete);
            }
          }
          this.playlistsLoaded = true;
        },
        (error) => {
          this.notificationService.error(error);
          this.playlistsLoaded = true;
        },
      );
  }

  getExerciseData() {
    const lengthToAdd = this.editing
      ? this.selectedPlaylist.exerciseList.length
      : this.newPlaylist.exerciseList.length;
    const length = this.exerciseList.length + lengthToAdd;
    const page = length > 0 ? Math.floor(length / 20) : 0;
    const size = 20;
    const exercisesToFilter = this.editing
      ? this.selectedPlaylist.exerciseList.map((value) => value.exerciseId)
      : [];
    this.exercisesController
      .getExerciseDetails(page, size, exercisesToFilter, this.searchAddExercise)
      .subscribe(
        (response) => {
          if (this.searchAddExercise == "") {
            for (const exercise of response) {
              this.exerciseList.push(exercise);
            }
            this.filteredExerciseList = Object.assign([], this.exerciseList);
          } else {
            for (const exercise of response) {
              this.filteredExerciseList.push(exercise);
            }
          }
          const exercises = response.length;
          this.canLoadExerciseList = exercises % 20 === 0 && exercises !== 0;
        },
        (error) => this.notificationService.error(error),
      );
  }

  checkModifiedSets(event) {
    if (event) {
      this.modified = true;
    }
  }

  onChangeAutomated() {
    this.modified = true;
    if (this.automated) {
      this.selectedPlaylist.type = 2;
      this.playlistType = 2;
    } else {
      this.selectedPlaylist.type = 0;
      this.playlistType = 0;
    }
  }

  onChangeCircuit() {
    this.modified = true;
    if (this.circuit) {
      this.selectedPlaylist.type = 1;
      this.playlistType = 1;
    } else {
      this.selectedPlaylist.type = 2;
      this.playlistType = 2;
    }
  }

  updateSelectedPlaylist(playlist, index: number) {
    this.selectedIndex = index;
    this.filteredAthleteList = Object.assign(
      [],
      this.filterCurrentlySelected(),
    );
    this.selectedPlaylist = playlist;
    if (!this.selectedPlaylist) return;
    this.copySelectedPlaylist = JSON.parse(
      JSON.stringify(this.selectedPlaylist),
    );

    if (this.selectedPlaylist.type == 1) {
      this.automated = true;
      this.circuit = true;
      this.playlistType = 1;
    } else if (this.selectedPlaylist.type == 2) {
      this.automated = true;
      this.circuit = false;
      this.playlistType = 2;
    } else {
      this.automated = false;
      this.circuit = false;
      this.playlistType = 0;
    }
    const playlistLength = this.selectedPlaylist.length;
    this.canLoadSelectedPlaylist =
      playlistLength % 20 === 0 && playlistLength !== 0;
    this.playlistCircuitSets = playlist.circuitSets;
    this.playlistRestTime = playlist.restTime;
    // this.playlistType = playlist.type;
    this.exerciseList = [];
    this.getExerciseData();
  }

  filterCurrentlySelected() {
    return this.playlistData
      .slice(0, this.selectedIndex)
      .concat(this.playlistData.slice(this.selectedIndex + 1));
  }

  filterAthleteList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        const filteredSelected = this.filterCurrentlySelected();
        this.filteredAthleteList = Object.assign([], filteredSelected);
        const length = filteredSelected.length;
        this.canLoadAthleteList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 1) {
        return;
      }
      this.searching = true;
      this.playlistService
        .getPlaylistData(0, 20, value, this.playlistData[this.selectedIndex].id)
        .subscribe(
          (response: any) => {
            this.filteredAthleteList = response;
            const length = response.length;
            this.canLoadAthleteList = length % 20 === 0 && length !== 0;
            this.searching = false;
          },
          (error) => {
            this.notificationService.error(error);
            this.searching = false;
          },
        );
    }, 350);
  }

  isAlreadyAdded(exercise: any): boolean {
    for (const addedExercise of this.addExercisesSelected) {
      if (addedExercise.id === exercise.id) {
        return true;
      }
    }
    return false;
  }

  selectAllAthletes() {
    this.athletesSelected = [];
    for (const athlete of this.playlistData) {
      this.athletesSelected.push(athlete.id);
    }
  }

  onChangeAutomatedNew() {
    if (this.automated) {
      this.newPlaylist.type = 2;
    } else {
      this.newPlaylist.type = 0;
    }
  }

  onChangeCircuitNew() {
    if (this.circuit) {
      this.newPlaylist.type = 1;
    } else {
      this.newPlaylist.type = 2;
    }
  }

  createMode() {
    this.athletesSelected = [];
    this.exercisesSelected = [];
    this.newPlaylist = {
      type: 0,
      restTime: this.possibleRestTime[0],
      circuitSets: 1,
      exerciseList: [],
    };
    this.automated = false;
    this.circuit = false;
    this.playlistCircuitSets = 1;
    this.playlistRestTime = this.possibleRestTime[0];
    this.editing = false;
    this.exerciseList = [];
    this.getExerciseData();
  }

  editMode() {
    this.athletesSelected = [];
    this.editing = true;
    this.exerciseList = [];
    this.getExerciseData();
  }

  openExerciseModal() {
    this.addExercisesSelected = [];
    this.searchAddExercise = "";
    this.filteredExerciseList = Object.assign([], this.exerciseList);
    const data: IAddExercisesDialogData = {
      exerciseCategories: this.exerciseCategoriesNew,
      playlistIndex: this.selectedIndex,
      searchAddExercise: this.searchAddExercise,
      exerciseList: this.exerciseList,
      exercises: this.exercisesNew,
      addExercisesSelected: this.addExercisesSelected,
      newWorkout: this.newPlaylist,
    };

    this.dialog
      .open(AddExercisesDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.addExercises();
        }
      });

    this.getCategories();
  }

  getCategories() {
    const locationId = localStorage.getItem("selectedLocationId");
    this.exercisesController.getCategories(locationId).subscribe(
      (response) => {
        this.exerciseCategoriesNew = response;
        this.exercisesNew = [];
        // this.categoriesLoaded = true;
        this.exerciseCategoriesNew.forEach((value, index) => {
          this.getExercisesByCategory(value, index);
        });
      },
      (error) => {
        this.notificationService.error(error);
        // this.categoriesLoaded = true;
      },
    );
  }

  filterExercisesByCategory(data, id) {
    return data.filter((item) => {
      return item.exercises.some((exercise) => {
        return (
          (exercise.pairedExercise && exercise.pairedExercise.id === id) ||
          (exercise.regularExercise && exercise.regularExercise.id === id)
        );
      });
    });
  }

  getExercisesByCategory(category, index: number) {
    const locationId = localStorage.getItem("selectedLocationId");
    const exercisesForCategory = this.exercisesNew[category.id];
    // const page = exercisesForCategory && exercisesForCategory.length > 0 ? Math.floor(exercisesForCategory.length / 20) : 0;
    // const size = 20;
    this.exerciseService.findAllByCategory(locationId).subscribe(
      (response: any) => {
        const exercisesByCategory = this.filterExercisesByCategory(
          response,
          category.id,
        );
        this.exercisesNew[category.id] =
          exercisesForCategory && exercisesForCategory.length > 0
            ? this.exercisesNew[category.id].concat(exercisesByCategory)
            : exercisesByCategory;
        const index = this.exerciseCategories.indexOf(category);
        if (this.exercisesNew[category.id].length === 0) {
          this.exerciseCategories.splice(index, 1);
        }
        // const exerciseLength = exercisesByCategory.length;
        // this.canLoadExercises[category.id] = exerciseLength % 20 === 0 && exerciseLength !== 0;
      },
      (error) => this.notificationService.error(error),
    );
    if (index === this.exerciseCategories.length - 1) {
      this.loading = false;
    }
  }

  checkModified(playlist, index, event, firstName, lastName) {
    if (this.modified) {
      event.preventDefault();
      this.openConfirmModal(playlist, index);
      return;
    }
    this.firstName = firstName;
    this.lastName = lastName;
    this.switchSelectedAthlete(playlist, index);
  }

  updateModified() {
    if (!this.modified && this.newPlaylist.exerciseList.length > 0)
      this.modified = true;
  }

  openConfirmDeleteExerciseModal() {
    const data: IConfirmationDialogData = {
      title: "Confirmation",
      message: `Are you sure you want to delete this playlist ?`,
      confirmButtonLabel: "Yes",
      cancelButtonLabel: "Close",
    };

    this.dialog
      .open(ConfirmationDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          for (const exercise of this.exercisesSelected) {
            this.removeExercise(exercise);
          }
          this.exercisesSelected = [];
          this.savePlaylist();
        }
      });
  }

  openConfirmModal(playlist, index) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Confirmation",
          message:
            "You have made some changes to the playlist. Are you sure you want to discard them?",
          confirmButtonLabel: "Discard",
          cancelButtonLabel: "Close",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.notSaved = true;
          this.switchSelectedAthlete(playlist, index);
        }
      });
  }

  switchSelectedAthlete(playlist, index) {
    this.modified = false;
    this.modifiedSettings = [];
    // this.toggleTab();
    if (this.notSaved) {
      this.playlistData[this.selectedIndex].playlist =
        this.copySelectedPlaylist;
    }
    this.updateSelectedPlaylist(playlist, index);
  }

  toggleTab() {
    this.toggle = !this.toggle;
  }

  openEditModal(exerciseId: string) {
    const locationId = localStorage.getItem("selectedLocationId");
    this.exercisesController.getCategories(locationId).subscribe(
      (categories) => {
        this.exerciseCategories = categories;
        this.categoryOptions = categories.map((category) => category.name);
        const playlistId = this.selectedPlaylist.id;

        this.exerciseController.getExercise(exerciseId, playlistId).subscribe(
          (response: any) => {
            const exercise = response.exercise;
            const exerciseType = exercise.exerciseType;
            this.selectedExercise = {
              type: exerciseType,
              name: exercise.name,
              active: exercise.active,
              videoUrl: exercise.videoUrl,
            };
            this.initExerciseForm(
              exerciseType,
              this.settingsAlreadyModified(response.id) ||
                response.exerciseSettings,
            );
            this.selectedType = this.possibleTypes[this.selectedExercise.type];
            const categoryId = exercise.categoryId;
            for (const category of categories) {
              if (category.id === categoryId) {
                this.categoryInput = category.name;
                break;
              }
            }

            const data: IEditExerciseDialogData = {
              exerciseEditForm: this.exerciseEditForm,
              selectedExercise: this.selectedExercise,
              categoryInput: this.categoryInput,
              selectedType: this.selectedType,
              reactSensors: this.reactSensors,
              react: this.react,
              dontReact: this.dontReact,
              sequenceSensors: this.sequenceSensors,
              agilitySensors: this.agilitySensors,
            };
            this.dialog
              .open(EditExerciseDialogComponent, { data })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  this.saveExercise();
                }
              });
          },
          (error) => this.notificationService.error(error),
        );
      },
      (error) => this.notificationService.error(error),
    );
  }

  initExerciseForm(type: number, settings: any) {
    const validators: ValidatorFn[] = [
      ExerciseValidationService.startValueValidation() as ValidatorFn,
    ];
    switch (type) {
      case 0:
        this.exerciseEditForm = this.formBuilder.group({
          countType: [settings.countType as number, Validators.required],
        });
        break;
      case 1:
        let isolatedReact = 0;
        if (settings.inactiveSensors !== null) {
          Object.assign(this.reactSensors, settings.inactiveSensors);
          isolatedReact = 1;
        }
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          timerPrompt: [settings.timerPrompt as number, Validators.required],
          cognitiveReact: [
            settings.cognitiveReact as number,
            Validators.required,
          ],
          isolatedReact: [isolatedReact as number, Validators.required],
        });
        Object.assign(this.dontReact, settings.dontReactTo);
        Object.assign(this.react, settings.reactTo);
        validators.push(
          ExerciseValidationService.neurocognitiveValidation() as ValidatorFn,
        );
        validators.push(
          ExerciseValidationService.activeSensorValidation() as ValidatorFn,
        );
        break;
      case 2:
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          timerPrompt: [settings.timerPrompt as number, Validators.required],
        });
        Object.assign(this.sequenceSensors, settings.sequence);
        break;
      case 3:
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          agilityCustomType: [
            settings.agilityCustomType as number,
            Validators.required,
          ],
        });
        Object.assign(this.agilitySensors, settings.sequence);
        validators.push(
          ExerciseValidationService.arrowValidation() as ValidatorFn,
        );
        break;
      default:
        this.notificationService.error("Invalid exercise type.");
        break;
    }
    this.exerciseEditForm.addControl(
      "startType",
      this.formBuilder.control(settings.startType, Validators.required),
    );
    this.exerciseEditForm.addControl(
      "startValue",
      this.formBuilder.control(settings.startValue),
    );
    this.exerciseEditForm.addControl(
      "endType",
      this.formBuilder.control(settings.endType, Validators.required),
    );
    this.exerciseEditForm.addControl(
      "endValue",
      this.formBuilder.control(settings.endValue, Validators.required),
    );
    this.exerciseEditForm.setValidators(validators);
  }

  settingsAlreadyModified(exerciseId: number) {
    for (const settings of this.modifiedSettings) {
      if (settings.exerciseId === exerciseId) {
        return settings;
      }
    }
    return false;
  }

  saveExercise() {
    this.modified = true;
    const exerciseSettings: any = this.getExerciseSettings(
      this.selectedExercise.type,
    );
    exerciseSettings.startType =
      this.exerciseEditForm.controls["startType"].value;
    exerciseSettings.startValue =
      this.exerciseEditForm.controls["startValue"].value;
    exerciseSettings.endType = this.exerciseEditForm.controls["endType"].value;
    exerciseSettings.endValue =
      this.exerciseEditForm.controls["endValue"].value;
    this.modifiedSettings.push(exerciseSettings);
    this.modal.approve("");
  }

  getExerciseSettings(exerciseType: number): object {
    switch (exerciseType) {
      case 0:
        return { countType: this.exerciseEditForm.controls["countType"].value };
      case 1:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          timerPrompt: this.exerciseEditForm.controls["timerPrompt"].value,
          cognitiveReact:
            this.exerciseEditForm.controls["cognitiveReact"].value,
          dontReactTo: this.dontReact,
          inactiveSensors: this.reactSensors,
          reactTo: this.react,
        };
      case 2:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          timerPrompt: this.exerciseEditForm.controls["timerPrompt"].value,
          sequence: this.sequenceSensors,
        };
      case 3:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          agilityCustomType:
            this.exerciseEditForm.controls["agilityCustomType"].value,
          sequence: this.agilitySensors,
        };
      default:
        this.notificationService.error("Invalid exercise type.");
        this.exerciseEditForm.controls["type"].setValue(0);
        return;
    }
  }

  removeSelectedPlaylist() {
    const data: IConfirmationDialogData = {
      title: "Confirmation",
      message: `Are you sure you want to delete this playlist ?`,
      confirmButtonLabel: "Yes",
      cancelButtonLabel: "Close",
    };

    this.dialog
      .open(ConfirmationDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          for (const exercise of this.copyExerciseList) {
            this.removeExercise(exercise);
          }
          this.savePlaylist();
        }
      });
  }

  removeSelectedExercises() {
    const { exerciseList } = this.newPlaylist;
    const exercisesSelectedArr = this.exercisesSelected.slice();
    if (this.selectedPlaylist.exerciseList.length === 1) {
      this.openConfirmDeleteExerciseModal();
    } else {
      for (let i = 0; i < this.exercisesSelected.length; i++) {
        this.newPlaylist.exerciseList.splice(
          exerciseList.findIndex(
            (item) => item.id === this.exercisesSelected[i].id,
          ),
          1,
        );
        exercisesSelectedArr.splice(i, 1);
      }

      this.exercisesSelected = exercisesSelectedArr;
    }
  }

  removeExercise(selected) {
    for (let i = 0; i < this.selectedPlaylist.exerciseList.length; i++) {
      if (selected.id === this.selectedPlaylist.exerciseList[i].id) {
        this.selectedPlaylist.exerciseList.splice(i, 1);
      }
    }
  }

  savePlaylist() {
    this.loading = true;
    this.selectedPlaylist.type = this.playlistType;
    this.selectedPlaylist.restTime = this.playlistRestTime;
    this.selectedPlaylist.circuitSets = this.playlistCircuitSets;

    if (this.playlistType === 1) {
      for (const exerciseItem of this.selectedPlaylist.exerciseList) {
        exerciseItem.sets = this.playlistCircuitSets;
      }
    } else if (this.playlistType === 0) {
      for (const exerciseItem of this.selectedPlaylist.exerciseList) {
        exerciseItem.sets = 1;
      }
      this.selectedPlaylist.restTime = this.possibleRestTime[0];
      this.selectedPlaylist.circuitSets = 1;
    }

    this.playlistService
      .savePlaylist({
        playlist: this.selectedPlaylist,
        settings: this.modifiedSettings,
      })
      .subscribe(
        () => {
          this.loading = false;
          this.modified = false;
          this.notificationService.success("Playlist successfully saved.");
          this.updateSelectedPlaylist(
            this.selectedPlaylist,
            this.selectedIndex,
          );
          this.toggleTab();
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        },
      );
  }

  hasAthletes() {
    if (
      this.newPlaylist.exerciseList.length == 0 &&
      this.athletesSelected.length === 0
    ) {
      return true;
    } else if (
      this.newPlaylist.exerciseList.length > 0 &&
      this.athletesSelected.length > 0
    ) {
      return true;
    }
    return false;
  }

  createNewPlaylist() {
    this.newPlaylist.creationDate = Date.now();
    this.newPlaylist.circuitSets = this.playlistCircuitSets;
    if (this.newPlaylist.type === 1) {
      const newExerciseList = [];
      for (const exerciseItem of this.newPlaylist.exerciseList) {
        newExerciseList.push({
          exerciseId: exerciseItem.exerciseId,
          exerciseUUID: exerciseItem.exerciseUUID,
          name: exerciseItem.name,
          sets: this.newPlaylist.circuitSets,
          paired: exerciseItem.paired,
        });
      }
      this.newPlaylist.exerciseList = newExerciseList;
    }
    for (let i = 0; i < this.newPlaylist.exerciseList.length; i++) {
      this.newPlaylist.exerciseList[i].index = i;
    }
    this.clonePlaylist(this.newPlaylist);
    this.editing = true;
    this.firstName = undefined;
    this.lastName = undefined;
    this.toggleTab();
  }

  clonePlaylist(playlist, manualClone: boolean = false) {
    this.loading = true;
    const athleteList = manualClone
      ? this.addAthletesSelected
      : this.athletesSelected;

    this.playlistService
      .clonePlaylist({
        playlist: playlist,
        athleteList: athleteList,
      })
      .subscribe(
        () => {
          this.loading = false;
          this.notificationService.success("Playlist successfully added.");
          this.modified = false;
          for (const athlete of this.playlistData) {
            if (athleteList.indexOf(athlete.id) !== -1) {
              athlete.playlist.exerciseList = playlist.exerciseList;
              athlete.playlist.type = playlist.type;
              athlete.playlist.restTime = playlist.restTime;
              athlete.playlist.circuitSets = playlist.circuitSets;
              athlete.playlist.lastModified = Date.now();
              if (athlete.playlist.id === this.selectedPlaylist.id) {
                this.updateSelectedPlaylist(
                  athlete.playlist,
                  this.selectedIndex,
                );
              }
            }
          }
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        },
      );
  }

  addExercises() {
    this.modified = true;
    this.editing
      ? (this.selectedPlaylist.exerciseList =
          this.selectedPlaylist.exerciseList.concat(this.addExercisesSelected))
      : (this.newPlaylist.exerciseList = this.newPlaylist.exerciseList.concat(
          this.addExercisesSelected,
        ));
    this.exerciseList = this.exerciseList.filter(
      (el) => this.addExercisesSelected.indexOf(el) < 0,
    );
  }

  resetReact() {
    this.reactSensors.splice(0, this.reactSensors.length);
    this.exerciseEditForm.controls["isolatedReact"].updateValueAndValidity();
  }

  setReact(color: number, react: boolean) {
    let array;
    let otherArray;

    if (react) {
      array = this.react;
      otherArray = this.dontReact;
    } else {
      array = this.dontReact;
      otherArray = this.react;
    }

    let index = array.indexOf(color);
    index > -1 ? array.splice(index, 1) : array.push(color);

    index = otherArray.indexOf(color);
    if (index > -1) {
      otherArray.splice(index, 1);
    }
    this.exerciseEditForm.controls["cognitiveReact"].updateValueAndValidity();
  }

  setSensor(sensor: number) {
    const index = this.reactSensors.indexOf(sensor);
    index > -1
      ? this.reactSensors.splice(index, 1)
      : this.reactSensors.push(sensor);

    this.exerciseEditForm.controls["isolatedReact"].updateValueAndValidity();
  }

  resetSequence() {
    this.sequenceSensors.splice(0, this.sequenceSensors.length);
    this.exerciseEditForm.controls["delay"].updateValueAndValidity();
  }

  addSequence(sensor: number) {
    const length = this.sequenceSensors.length;
    if (length < 200) {
      this.sequenceSensors.push(sensor);
      this.exerciseEditForm.controls["delay"].updateValueAndValidity();
    }
  }

  addArrows(arrow: number) {
    if (this.exerciseEditForm.controls["agilityCustomType"].value === 0) {
      const length = this.agilitySensors.length;
      if (length < 200) {
        this.agilitySensors.push(arrow);
        this.exerciseEditForm.controls[
          "agilityCustomType"
        ].updateValueAndValidity();
      }
    } else {
      const index = this.agilitySensors.indexOf(arrow);
      index > -1
        ? this.agilitySensors.splice(index, 1)
        : this.agilitySensors.push(arrow);

      this.exerciseEditForm.controls[
        "agilityCustomType"
      ].updateValueAndValidity();
    }
  }

  arrowsColorCondition(arrow: number) {
    return (
      this.exerciseEditForm.controls["agilityCustomType"].value === 0 ||
      this.agilitySensors.indexOf(arrow) === -1
    );
  }

  openDeleteModal(athlete) {
    this.athleteToDelete = {
      id: athlete.id,
      name: athlete.firstName + " " + athlete.lastName,
    };

    const data: IConfirmationDialogData = {
      title: "Confirmation",
      message: `Are you sure you want to delete ${athlete.firstName} ${athlete.lastName}?`,
      confirmButtonLabel: `Delete ${this.global.data.uppercaseAthleteName}`,
      cancelButtonLabel: "Close",
    };

    this.dialog
      .open(ConfirmationDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteAthlete(athlete.id);
        }
      });
  }

  deleteAthlete(athleteId: string) {
    this.athletesController.deleteAthlete(athleteId).subscribe(
      () => {
        this.playlistData.splice(this.findAthleteIndex(athleteId), 1);
      },
      (error) => this.notificationService.error(error),
    );
  }

  findAthleteIndex(athleteId: string): number {
    for (let i = 0; i < this.playlistData.length; i++) {
      if (athleteId === this.playlistData[i].id) {
        return i;
      }
    }
    return -1;
  }
}
