import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {delayArray, flashArray} from "../../../core/helpers/exercise-data";

export interface IEditExerciseDialogData {
  exerciseEditForm: FormGroup;
  selectedExercise;
  categoryInput;
  selectedType;
  reactSensors;
  react;
  dontReact;
  sequenceSensors;
  agilitySensors;
}

@Component({
  selector: "app-edit-exercise-dialog",
  templateUrl: "./edit-exercise-dialog.component.html",
  styleUrls: ["./edit-exercise-dialog.component.scss"],
})
export class EditExerciseDialogComponent {
  protected readonly flashArray = flashArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditExerciseDialogData,
    public dialogRef: MatDialogRef<EditExerciseDialogComponent>,
  ) {}

  resetReact() {
    this.data.reactSensors.splice(0, this.data.reactSensors.length);
    this.data.exerciseEditForm.controls[
      "isolatedReact"
    ].updateValueAndValidity();
  }

  setReact(color: number, react: boolean) {
    let array;
    let otherArray;

    if (react) {
      array = this.data.react;
      otherArray = this.data.dontReact;
    } else {
      array = this.data.dontReact;
      otherArray = this.data.react;
    }

    let index = array.indexOf(color);
    index > -1 ? array.splice(index, 1) : array.push(color);

    index = otherArray.indexOf(color);
    if (index > -1) {
      otherArray.splice(index, 1);
    }
    this.data.exerciseEditForm.controls[
      "neurocognitiveType"
    ].updateValueAndValidity();
  }

  resetSequence() {
    this.data.sequenceSensors.splice(0, this.data.sequenceSensors.length);
    this.data.exerciseEditForm.controls["delay"].updateValueAndValidity();
  }

  addSequence(sensor: number) {
    const length = this.data.sequenceSensors.length;
    if (length < 200) {
      this.data.sequenceSensors.push(sensor);
      this.data.exerciseEditForm.controls["delay"].updateValueAndValidity();
    }
  }

  setSensor(sensor: number) {
    const index = this.data.reactSensors.indexOf(sensor);
    index > -1
      ? this.data.reactSensors.splice(index, 1)
      : this.data.reactSensors.push(sensor);

    this.data.exerciseEditForm.controls[
      "isolatedReact"
    ].updateValueAndValidity();
  }

  arrowsColorCondition(arrow: number) {
    return (
      this.data.exerciseEditForm.controls["agilityCustomType"].value === 0 ||
      this.data.agilitySensors.indexOf(arrow) === -1
    );
  }

  addArrows(arrow: number) {
    if (this.data.exerciseEditForm.controls["agilityCustomType"].value === 0) {
      const length = this.data.agilitySensors.length;
      if (length < 200) {
        this.data.agilitySensors.push(arrow);
        this.data.exerciseEditForm.controls[
          "agilityCustomType"
        ].updateValueAndValidity();
      }
    } else {
      const index = this.data.agilitySensors.indexOf(arrow);
      index > -1
        ? this.data.agilitySensors.splice(index, 1)
        : this.data.agilitySensors.push(arrow);

      this.data.exerciseEditForm.controls[
        "agilityCustomType"
      ].updateValueAndValidity();
    }
  }

  protected readonly delayArray = delayArray;
}
