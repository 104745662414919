import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {EBodyPart} from "../../../../shared/enums/EBodyPart";

@Component({
  selector: 'app-select-body-part',
  templateUrl: './select-body-part.component.html',
  styleUrls: ['./select-body-part.component.scss']
})
export class SelectBodyPartComponent {
  @Input() _control: FormControl<EBodyPart>;
  protected readonly EBodyPart = EBodyPart;
}
