import {ISportPosition} from "./ISportPosition";

export interface ISport {
  id: string;
  name: string;
  isActive?: boolean;
  positions?: ISportPosition[];
}

export class Sport {
  id: string;
  name: string;

  constructor(sport: ISport) {
    this.id = sport.id;
    this.name = sport.name;
  }
}
