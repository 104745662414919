import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { GlobalPreferences } from "../../helpers/global-data";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsageReportsControllerService {
  constructor(
    private httpClient: HttpClient,
    private global: GlobalPreferences,
  ) {}

  exportUsageReports(payload: {
    start: string;
    end: string;
    organizationId: string;
  }): Observable<any> {
    const gmtOffset = this.global.getTimezoneOffset();

    const params = new HttpParams({
      fromObject: {
        ...payload,
        gmtOffset,
      },
    });

    return this.httpClient.get(`${environment.baseURL}/account/export`, {
      params: params,
      headers: this.createHeaders(),
      responseType: "blob",
    });
  }
  createHeaders() {
    return new HttpHeaders()
      .set(
        "Accept",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      )
      .set("Authorization", "Bearer " + AuthService.getOauthTokenFromStorage());
  }
}
