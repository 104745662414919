import {Injectable} from "@angular/core";
import {EExerciseType} from "../../shared/enums/EExerciseType";
import {IExerciseConfig} from "../../features/exercises/exercise-form/exercise-form.component";
import {ECountdownEndType} from "../../shared/enums/ECountdownEndType";
import {EExerciseJumpType} from "../../shared/enums/EExerciseJumpType";

@Injectable({providedIn: 'root'})
export class ExerciseHelper {
    getConfigTitle(exerciseType: EExerciseType, exerciseConfig: IExerciseConfig): string {
        if(exerciseType === EExerciseType.Count) {
            if(exerciseConfig.countdownEndType === ECountdownEndType.TOUCHES) {
                return `${exerciseConfig.countdownEnd} Touches, ${exerciseConfig.countdownStart} Count`;
            } else if(exerciseConfig.countdownEndType === ECountdownEndType.TIME) {
                return `${exerciseConfig.countdownEnd} Seconds, ${exerciseConfig.countdownStart} Count`;
            }
        } else if(exerciseType === EExerciseType.React) {
            if(exerciseConfig.countdownEndType === ECountdownEndType.TOUCHES) {
                return `${exerciseConfig.countdownEnd} Touches, ${exerciseConfig.timerDelay} Delay, ${exerciseConfig.timerPrompt} Flash`;
            } else if(exerciseConfig.countdownEndType === ECountdownEndType.TIME) {
                return `${exerciseConfig.countdownEnd} Seconds, ${exerciseConfig.timerDelay} Delay, ${exerciseConfig.timerPrompt} Flash`;
            }
        } else if(exerciseType === EExerciseType.Sequence) {
            if(exerciseConfig.countdownEndType === ECountdownEndType.TOUCHES) {
                return `${exerciseConfig.countdownEnd} Touches, Sequence ${exerciseConfig.sensorsSequence.join('')}, ${exerciseConfig.timerDelay} Delay, ${exerciseConfig.timerPrompt} Flash`;
            } else if(exerciseConfig.countdownEndType === ECountdownEndType.TIME) {
                return `${exerciseConfig.countdownEnd} Seconds, Sequence ${exerciseConfig.sensorsSequence.join('')}, ${exerciseConfig.timerDelay} Delay, ${exerciseConfig.timerPrompt} Flash`;
            }
        } else if(exerciseType === EExerciseType.Agility) {
            if(exerciseConfig.countdownEndType === ECountdownEndType.TOUCHES) {
                return `${exerciseConfig.countdownEnd} Arrows, ${exerciseConfig.timerDelay} Delay, Agility Sequence: ${this.getArrows(exerciseConfig.arrows)}`;
            } else if(exerciseConfig.countdownEndType === ECountdownEndType.TIME) {
                return `${exerciseConfig.countdownEnd} Seconds, ${exerciseConfig.timerDelay} Delay, Agility Sequence: ${this.getArrows(exerciseConfig.arrows)}`;
            }
        } else if(exerciseType === EExerciseType.Vertical) {
            return `${exerciseConfig.jumpType === EExerciseJumpType.DROP_JUMP ? 'Drop Jump' : 'Vertical Jump'}, ${exerciseConfig.jumpCount} Jump(s), ${exerciseConfig.numberOfLegs} Leg(s)`;
        }
        return ''
    }

    getArrows(sensorArray: number[]): string {
        const arrows: { [key: number]: string } = {
            1: '↑',  // arrow_upward
            2: '↗',  // arrow_upward (rotated 45°)
            3: '→',  // arrow_forward
            4: '↘',  // arrow_downward (rotated -45°)
            5: '↓',  // arrow_downward
            6: '↙',  // arrow_downward (rotated 45°)
            7: '←',  // arrow_backward
            8: '↖',  // arrow_upward (rotated -45°)
            9: ' '   // empty (no arrow)
        };

        return sensorArray.map(sensor => arrows[sensor] || '?').join('');
    }
}
