<div
  class="bg-[#1A1A1C] text-[#C0C0C5] h-screen p-3 flex flex-col justify-between items-center w-full"
>
  <div class="flex justify-center items-center flex-grow max-w-[400px] w-full">
    <div
      class="w-full h-full flex flex-col gap-[6rem] justify-center items-center"
      style="max-width: 35rem"
    >
      <div class="w-[100px] h-auto">
        <img src="assets/images/qb_v3_logo.svg" />
      </div>
      <form [formGroup]="resetPassForm" class="flex flex-col gap-8 w-full">
        <div class="custom-form-field">
          <label for="newPassword">New password</label>
          <input
            type="password"
            [formControl]="resetPassForm.controls.password"
            id="newPassword"
            placeholder="New password"
          />
          <span
            class="animated-custom-error"
            *ngIf="
              ValidationHelperService.isInvalid(resetPassForm.controls.password)
            "
            [@inOutAnimation]
          >
            {{
              ValidationHelperService.getErrorMessage(
                resetPassForm.controls.password
              )
            }}
          </span>
        </div>
        <div class="custom-form-field">
          <label for="confirmPassword">Confirm new password</label>
          <input
            type="password"
            [formControl]="resetPassForm.controls.confirmPassword"
            id="confirmPassword"
            placeholder="New password confirmation"
          />
          <span
            class="animated-custom-error"
            *ngIf="
              ValidationHelperService.isInvalid(
                resetPassForm.controls.confirmPassword
              )
            "
            [@inOutAnimation]
          >
            {{
              ValidationHelperService.getErrorMessage(
                resetPassForm.controls.confirmPassword
              )
            }}
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <button
            type="submit"
            [disabled]="!resetPassForm.valid"
            (click)="reset()"
            class="!py-[16px] !px-[32px] rounded-2xl text-black bg-[#F5DC11] hover:bg-yellow-300 transition-all font-medium"
          >
            Reset password
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="flex gap-3 justify-center items-center">
    <p class="!m-0 text-[#838388]">Did you remember your password?</p>
    <a
      routerLink="/login"
      class="bg-[#3F3F41] text-white rounded-xl hover:bg-yellow-300 transition-all no-underline py-[10px] px-[24px]"
      >Log in</a
    >
  </div>
</div>
