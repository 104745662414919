<div class="row m-2 m-sm-3 m-md-4 m-lg-5" *ngIf="!isLoading">
  <div class="w-100">
    <div class="card w-100 p-3 border-bottom">
      <div>
        <button
          mat-flat-button
          color="primary"
          type="button"
          (click)="openBackModal()"
        >
          Back
        </button>
      </div>
    </div>

    <form
      class="d-flex justify-content-between flex-column flex-lg-row"
      [formGroup]="athleteForm"
    >
      <div
        class="card w-full flex flex-col gap-3 mt-2 mt-sm-3 mt-md-4 mt-lg-5 mr-lg-5 p-5"
      >
        <!-- First section-->
        <div class="field">
          <p (click)="fileInput.click()" class="btn bold-text">
            <i class="camera icon"></i>Upload Photo
          </p>
          <span class="image-upload">
            <input
              type="file"
              #fileInput
              name="file"
              ng2FileSelect
              [uploader]="imageUploader"
              (click)="imageUploader.clearQueue()"
              accept="image/*"
              class="d-none"
            />
          </span>
        </div>

        <img
          class="ui small circular image athlete-image"
          *ngIf="image != null && imagePreviewUrl == null"
          src="{{ image }}"
        />

        <img
          class="ui small circular image athlete-image"
          *ngIf="imagePreviewUrl != null"
          [src]="imagePreviewUrl"
          height="150"
          width="150"
        />

        <img
          class="ui small circular image athlete-image"
          *ngIf="image == null"
          src="assets/images/no_avatar.png"
          height="150"
          width="150"
        />

        <div class="ui hidden divider"></div>

        <div class="full-width centered-text">
          <p class="medium-text bold-text uppercase">Personal Info</p>
        </div>

        <div class="ui hidden divider"></div>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(
                athleteForm.controls['firstName']
              )
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["firstName"]
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(
                athleteForm.controls['lastName']
              )
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["lastName"]
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Date of Birth</mat-label>
          <input
            #dobDatepicker
            data-language="en"
            id="date-of-birth"
            [formControl]="dateOfBirthControl"
            [matDatepicker]="picker"
            readonly="true"
            [min]="minDate"
            [max]="maxDate"
            (dateInput)="changeDateOfBirth('input', $event)"
            (dateChange)="changeDateOfBirth('change', $event)"
            matInput
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>

          <mat-datepicker
            #picker
            startView="year"
            [startAt]="startDate"
          ></mat-datepicker>
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(
                athleteForm.controls['dateOfBirth']
              )
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["dateOfBirth"]
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option [value]="0">Male</mat-option>
            <mat-option [value]="1">Female</mat-option> </mat-select
          ><br />
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Height</mat-label>
          <input
            matInput
            type="text"
            formControlName="height"
            (change)="handleHeightInput(athleteForm.controls['height'].value)"
          />
          <mat-select
            matTextSuffix
            [(ngModel)]="heightType"
            [ngModelOptions]="{ standalone: true }"
            (valueChange)="
              heightType = $event;
              handleHeightInput(athleteForm.controls['height'].value)
            "
          >
            <mat-option [value]="0">cm</mat-option>
            <mat-option [value]="1">ft</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(athleteForm.controls['height'])
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["height"]
              )
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Weight</mat-label>
          <input
            matInput
            type="number"
            min="1"
            formControlName="weight"
            oninput="if(this.value > 400) this.value=400; if(this.value < 0) this.value = 0;"
          />
          <mat-select
            matTextSuffix
            [formControl]="athleteForm.controls['weightUnit']"
          >
            <mat-option [value]="0">kg</mat-option>
            <mat-option [value]="1">lbs</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(athleteForm.controls['weight'])
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["weight"]
              )
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error
            *ngIf="
              ValidationHelperService.isInvalid(athleteForm.controls['email'])
            "
          >
            {{
              ValidationHelperService.getErrorMessage(
                athleteForm.controls["email"]
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="card w-full flex flex-col gap-3 mt-2 mt-sm-3 mt-md-4 mt-lg-5 p-5"
      >
        <div class="full-width centered-text mb-8">
          <h2 class="medium-text bold-text uppercase">Sport</h2>
        </div>
        <app-select-athlete-category
          [_control]="category"
          [categoryList]="categoryList"
          [manualLoad]="true"
        ></app-select-athlete-category>
        <mat-form-field class="w-full" appearance="outline">
              <mat-label>{{
                      isClinicSuperUser() ? "Activity Level" : "Level"
                  }}</mat-label>

              <mat-select formControlName="level">
                  <mat-option *ngFor="let level of levelList" [value]="level">
                      {{ level?.name }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
        <div class="flex flex-col gap-3" *ngIf="hasLevel">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Sport</mat-label>
                <mat-select
                        formControlName="sport"
                        (valueChange)="updateSelectedSport($event)"
                >
                    <mat-option *ngFor="let sport of sportList" [value]="sport">
                        {{ sport?.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Position</mat-label>
                <mat-select
                        formControlName="position"
                        [disabled]="selectedSport?.positions?.length === 0"
                >
                    <mat-option
                            *ngFor="let position of selectedSport?.positions"
                            [value]="position"
                    >
                        {{ position.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-select-team
                    [_control]="team"
                    [teamList]="teamList"
                    [action]="action"
            ></app-select-team>
        </div>


        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Dominant Leg</mat-label>
          <mat-select formControlName="dominantLeg">
            <mat-option [value]="1">Right</mat-option>
            <mat-option [value]="0">Left</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div class="clear"></div>

    <div class="card w-100 p-3 mt-2 mt-sm-3 mt-md-4 mt-lg-5">
      <div class="flex justify-center items-center">
        <button
          mat-flat-button
          color="primary"
          type="button"
          (click)="saveAthlete()"
        >
          <ng-container *ngIf="!isLoading">Save</ng-container>
          <ng-container *ngIf="isLoading"> Saving</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
