import {SubscriptionBillingPeriod, SubscriptionUserType,} from "../constants/subscription.const";
import {ESubscriptionStatus} from "../../shared/enums/ESubscriptionStatus";
import {EAccountType} from "../../shared/enums/EAccountType";
import {ISubscriptionDropdownItem} from "../../shared/interfaces/DTOs/Response/ISubscriptionDropdownItem";

export interface ISubscription {
  id: string;
  name: string;
  price: number;
  athleteLimit: number;
  discountAnnually: number;
  lastBilled: string;
  subscriptionStatus: ESubscriptionStatus;
}

export interface  IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  status: number;
  username: string;
  organizationSubscription: ISubscription;
  selectedSubscription: ISubscriptionDropdownItem;
  userLocations: IUserLocation[];
  accountType: EAccountType;
  isActive: boolean;
}

export interface IUserLocation {
  id: string;
  imageUrl: string;
  isActive: boolean;
  name: string;
}

export class QBSubscription {
  clinic: QBSubscriptionType;
  performance: QBSubscriptionType;
  billingPeriod: SubscriptionBillingPeriod;

  constructor(subData: any = {}) {
    this.clinic = new QBSubscriptionType(
      subData.clinic,
      SubscriptionUserType.Clinic,
    );
    this.performance = new QBSubscriptionType(
      subData.performance,
      SubscriptionUserType.Performance,
    );
    this.performance.selected = true;
  }

  select(userType: SubscriptionUserType): void {
    this.unselectAll();
    if (userType === SubscriptionUserType.Clinic) {
      this.clinic.selected = true;
      this.clinic.options[0].selected = true;

      this.performance.selected = false;
    } else {
      this.clinic.selected = false;
      this.performance.selected = true;
      this.performance.options[0].selected = true;
    }
    this.billingPeriod = SubscriptionBillingPeriod.Monthly;
  }

  unselectAll(): void {
    this.clinic.options.forEach((o) => (o.selected = false));
    this.performance.options.forEach((o) => (o.selected = false));
    this.billingPeriod = SubscriptionBillingPeriod.Monthly;
  }

  get selectedSubscriptionType(): QBSubscriptionType {
    return this.clinic.selected ? this.clinic : this.performance;
  }
  get selectedSubscriptionOption(): QBSubscriptionOption {
    return this.selectedSubscriptionType.options.find((o) => o.selected);
  }
}

export class QBSubscriptionType {
  options: QBSubscriptionOption[];
  selected: boolean;
  type: SubscriptionUserType;

  constructor(data: any = [], type: SubscriptionUserType) {
    this.options = data
      .map((o) => new QBSubscriptionOption(o))
      .sort((a: QBSubscriptionOption, b: QBSubscriptionOption) => {
        return b.price - a.price;
      });
    this.selected = false;
    this.type = type;
  }

  selectOption(id: string): void {
    this.options.forEach((o) => (o.selected = o.id === id));
  }
}

export class QBSubscriptionOption {
  id: string;
  name: string;
  athleteLimit: number;
  discountAnnually: number;
  price: number;
  imageUrl: string;
  selected: boolean;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || "";
    this.athleteLimit = data.athleteLimit || 0;
    this.discountAnnually = data.discountAnnually || 0;
    this.price = data.price || 0;
    //this.imageUrl = data.imageUrl || "";
    this.imageUrl = "assets/images/subscription.png";
    this.selected = false;
  }
}
