import {SubscriptionBillingPeriod} from "../constants/subscription.const";

export interface IRegistrationPayload {
  organization: Organization;
  user: User;
  billingAddress: IAddress;
  shippingAddress: IAddress;
  organizationAddress: IAddress;
  subscription: Subscription;
  purchasedProducts: PurchasedProduct[];
  creditCard: CreditCard;
  couponId: string;
}

export class RegistrationPayload {
  organization: Organization;
  user: User;
  billingAddress: BillingAddress;
  shippingAddress: ShippingAddress;
  organizationAddress: OrganizationAddress;
  subscription: Subscription;
  purchasedProducts: PurchasedProduct[];
  creditCard: CreditCard;
  couponId: string;

  constructor(data: Partial<IRegistrationPayload> ) {
    this.organization = new Organization(data.user);
    this.user = new User({ ...data.user, ...data.organizationAddress });
    this.billingAddress = new BillingAddress({
      ...data.user,
      ...data.billingAddress,
    });
    this.shippingAddress = new ShippingAddress(data.shippingAddress);
    this.organizationAddress = new OrganizationAddress(
      data.organizationAddress,
    );
    this.subscription = new Subscription(data.subscription);
    this.purchasedProducts =
      data.purchasedProducts.map((p) => new PurchasedProduct(p)) || [];
    this.creditCard = new CreditCard(data.creditCard);
    this.couponId = data.couponId || null;
  }
}

class Organization {
  name: string;

  constructor(data: any = {}) {
    this.name = data.organizationName || "";
  }
}

class User {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  constructor(data: any = {}) {
    this.username = data.username || "";
    this.password = data.password || "";
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.email = data.email || "";
    this.phoneNumber = data.phone || "";
  }
}

export class BillingAddress {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  cityId: string;
  newCity: NewCity;
  firstName: string;
  lastName: string;

  constructor(data: IAddress) {
    this.addressLine1 = data.address || "";
    this.addressLine2 = data.address2 || "";
    this.zipCode = data.zipCode || "";
    if (data.city && data.city.id) {
      this.cityId = data.city.id;
    } else {
      this.newCity = new NewCity(data.city.name, data.state);
    }
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
  }
}

export class ShippingAddress {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  cityId: string;
  newCity: NewCity;
  firstName: string;
  lastName: string;

  constructor(data: any = {}) {
    this.addressLine1 = data.address || "";
    this.addressLine2 = data.address2 || "";
    this.zipCode = data.zipCode || "";
    if (data.city && data.city.id) {
      this.cityId = data.city.id;
    } else {
      this.newCity = new NewCity(data.city.name, data.state);
    }
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
  }
}

export interface IAddress {
  address?: string;
  address2?: string;
  zipCode?: string;
  city?: any;
  state?: string;
  firstName?: string;
  lastName?: string;
}

export class OrganizationAddress {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  cityId: string;
  newCity: NewCity;

  constructor(data: IAddress) {
    this.addressLine1 = data.address || "";
    this.addressLine2 = data.address2 || "";
    this.zipCode = data.zipCode || "";
    if (data.city && data.city.id) {
      this.cityId = data.city.id;
    } else {
      this.newCity = new NewCity(data.city.name, data.state);
    }
  }
}

class Subscription {
  id: string;
  billingPeriod: SubscriptionBillingPeriod;

  constructor(data: any = {}) {
    this.id = data.id || "";
    this.billingPeriod = !isNaN(data.billingPeriod) ? data.billingPeriod : null;
  }
}

class PurchasedProduct {
  id: string;
  quantity: number;

  constructor(data: any = {}) {
    this.id = data.id || "";
    this.quantity = data.quantity || 0;
  }
}

class CreditCard {
  firstName: string;
  lastName: string;
  cardNo: string;
  expiration: string;
  cvv: string;

  constructor(data: any = {}) {
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.cardNo = data.cardNumber || "";
    this.expiration = data.expiration || "";
    this.cvv = data.CVV || "";
  }
}

class NewCity {
  name: string;
  stateId: string;

  constructor(name: string, stateId: string) {
    this.name = name;
    this.stateId = stateId || "";
  }
}
