export interface ITeam {
  id: string;
  name: string;
}

export class Team {
  id: string;
  name: string;

  constructor(team: ITeam) {
    this.id = team.id;
    this.name = team.name;
  }
}
