import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../helpers/custom-validators";
import {ExerciseValidationService} from "../../helpers/exercise-validation.service";
import {IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {IVerticalForm} from "../../../shared/interfaces/forms/exercises/IVerticalForm";
import {IReactForm} from "../../../shared/interfaces/forms/exercises/IReactForm";
import {ICountdownForm} from "../../../shared/interfaces/forms/exercises/ICountdownForm";
import {ICountForm} from "../../../shared/interfaces/forms/exercises/ICountForm";
import {ISequenceForm} from "../../../shared/interfaces/forms/exercises/ISequenceForm";
import {EBodyPart} from "../../../shared/enums/EBodyPart";
import {EStroopEffectType} from "../../../shared/enums/EStroopEffectType";
import {IAgilityForm} from "../../../shared/interfaces/forms/exercises/IAgilityForm";

export interface IRegularExerciseConfigForm {
    id?: FormControl<string>;
    isSelected?: FormControl<boolean>;
    sets?: FormControl<number>;
    difficulty: FormControl<number>;
    verticalForm: FormGroup<IVerticalForm>;
    countdownForm: FormGroup<ICountdownForm>;
    reactForm: FormGroup<IReactForm>;
    countForm: FormGroup<ICountForm>;
    sequenceForm: FormGroup<ISequenceForm>;
    agilityForm: FormGroup<IAgilityForm>;
}

export class RegularExerciseFormFactory {
  static create(): FormGroup<IRegularExerciseForm> {
    return new FormGroup({
      active: new FormControl(true, [Validators.required]),
      name: new FormControl("", [Validators.required]),
      category: new FormControl(
        {
          id: null,
          name: null,
        },
        [CustomValidators.requiredCategoryValidator],
      ),
        bodyPart: new FormControl<EBodyPart>(null),
      type: new FormControl(0, [Validators.required]),
      video: new FormGroup({
          file: new FormControl<File>(null),
          fileUrl: new FormControl<string>('')
      }),
      imgThumb: new FormControl(""),
      config: RegularExerciseConfigFormFactory.create()
    });
  }
}

export class RegularExerciseConfigFormFactory {
    public static create(): FormGroup<IRegularExerciseConfigForm> {
        return new FormGroup<IRegularExerciseConfigForm>({
            id: new FormControl<string>(null),
            isSelected: new FormControl<boolean>(false),
            sets: new FormControl<number>(1, [Validators.required, Validators.min(0), Validators.max(25)]),
            difficulty: new FormControl(1, [Validators.required]),
            verticalForm: new FormGroup<IVerticalForm>({
                verticalJumpType: new FormControl(1, [Validators.required]),
                numberOfLegs: new FormControl(1, [Validators.required]),
                numberOfJumps: new FormControl(1, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(30),
                ]),
                isVerticalReact: new FormControl(false, [Validators.required]),
                reactTo: new FormArray([
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                ]),
                dontReactTo: new FormArray([
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                    new FormControl(false),
                ]),

            }, [
                ExerciseValidationService.minOneReact(),
            ],),
            countdownForm: new FormGroup<ICountdownForm>({
                startType: new FormControl(1, [Validators.required]),
                startValue: new FormControl(1, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(999)
                ]),
                endType: new FormControl(0, [Validators.required]),
                endValue: new FormControl(1, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(999),
                ]),
            }),
            reactForm: new FormGroup<IReactForm>(
                {
                    delay: new FormControl(0, [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(10),
                    ]),
                    timerPrompt: new FormControl(0, [
                        Validators.min(0),
                        Validators.max(10),
                    ]),
                    liftTime: new FormControl(false, [Validators.required]),
                    footFireActivity: new FormControl(false, [Validators.required]),
                    stroopEffectType: new FormControl(EStroopEffectType.NONE, Validators.required),
                    neurocognitiveType: new FormControl(0, Validators.required),
                    isolatedReact: new FormControl(false, Validators.required),
                    reactTo: new FormArray([
                        new FormControl(true),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                    ]),
                    dontReactTo: new FormArray([
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                        new FormControl(false),
                    ]),
                    activeSensors: new FormArray([
                        new FormControl(true),
                        new FormControl(true),
                        new FormControl(true),
                        new FormControl(true),
                        new FormControl(true),
                    ]),
                },
                [
                    ExerciseValidationService.neurocognitiveValidation(),
                    ExerciseValidationService.activeSensorValidation(),
                ],
            ),
            countForm: new FormGroup<ICountForm>({
                countType: new FormControl(0, [Validators.required]),
            }),
            sequenceForm: new FormGroup<ISequenceForm>(
                {
                    delay: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(10)]),
                    timerPrompt: new FormControl(0, [
                        Validators.min(0),
                        Validators.max(10),
                    ]),
                    sequenceSensors: new FormArray<FormControl<number>>(
                        [],
                        [
                            Validators.required,
                            Validators.maxLength(200),
                        ],
                    ),
                },
                [ExerciseValidationService.sequenceValidation()],
            ),
            agilityForm: new FormGroup<IAgilityForm>(
                {
                    delay: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(10)]),
                    agilityCustomType: new FormControl(0, [Validators.required]),
                    agilitySensors: new FormArray<FormControl<number>>(
                        [],
                        [
                            Validators.required,
                            Validators.maxLength(200),
                        ],
                    ),
                    timerPrompt: new FormControl(0, [
                        Validators.required,
                        Validators.min(0),
                        Validators.max(10)]
                    ),
                },
                [ExerciseValidationService.arrowValidation()],
            ),
        });
    }
}
