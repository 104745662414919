<div>
  <div class="header">{{ data.title }}</div>
  <div class="content">{{ data.message }}</div>
  <div class="actions">
    <ng-template *ngFor="let btn of data.buttons">
      <button
        type="button"
        [class]="'btn text-uppercase mr-3 '"
        (click)="dialogRef.close(btn.result)"
      >
        {{ btn.label ? btn.label : "-" }}
      </button>
    </ng-template>
  </div>
</div>
