import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormatPipe} from "./pipes/format.pipe";
import {SecondsToTimePipe} from "./pipes/seconds-to-time.pipe";
import {ResultNumberPipe} from "./pipes/result-number.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {HighlightDirective} from "./directives/highlight.directive";
import {SubscriptionPaymentDialogComponent} from "./components/subscription-payment-dialog/subscription-payment-dialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {ReportDialogComponent} from "./components/report-dialog/report-dialog.component";
import {AddReportDialogComponent} from "./components/add-report-dialog/add-report-dialog.component";
import {AddExercisesDialogComponent} from "./components/add-exercises-dialog/add-exercises-dialog.component";
import {AdvancedConfirmationDialogComponent} from "./components/advanced-confirmation-dialog/advanced-confirmation-dialog.component";
import {VideoPlayerComponent} from "./components/video-player/video-player.component";
import {MoveExercisesDialogComponent} from "./components/move-exercises-dialog/move-exercises-dialog.component";
import {EditExerciseDialogComponent} from "./components/edit-exercise-dialog/edit-exercise-dialog.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ValidateControlDirective} from "./directives/validate-control.directive";
import {SelectCategoryComponent} from "./components/select-category/select-category.component";
import {MatButtonModule} from "@angular/material/button";
import {SelectTeamComponent} from "./components/select-team/select-team.component";
import {VideoUploaderComponent} from "./components/video-uploader/video-uploader.component";
import {FileUploadModule} from "ng2-file-upload";
import {ImageUploaderComponent} from "./components/image-uploader/image-uploader.component";
import {EndTypePipe} from "./pipes/end-type.pipe";
import {SelectAthleteCategoryComponent} from "./components/select-athlete-category/select-athlete-category.component";
import {ProcessingPaymentDialogComponent} from "./components/processing-payment-dialog/processing-payment-dialog.component";
import {ImagePreviewComponent} from "./components/image-preview/image-preview.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MaintenanceComponent} from "./components/maintenance/maintenance.component";
import {MatCardModule} from "@angular/material/card";
import {IsEnabledIndicatorComponent} from "./components/is-active-indicator/is-enabled-indicator.component";

@NgModule({
  declarations: [
    FormatPipe,
    SecondsToTimePipe,
    ResultNumberPipe,
    SafePipe,
    SubscriptionPaymentDialogComponent,
    ConfirmationDialogComponent,
    HighlightDirective,
    ReportDialogComponent,
    AddReportDialogComponent,
    AddExercisesDialogComponent,
    AdvancedConfirmationDialogComponent,
    VideoPlayerComponent,
    MoveExercisesDialogComponent,
    EditExerciseDialogComponent,
    ValidateControlDirective,
    SelectCategoryComponent,
    SelectTeamComponent,
    VideoUploaderComponent,
    ImageUploaderComponent,
    EndTypePipe,
    SelectAthleteCategoryComponent,
    ProcessingPaymentDialogComponent,
    ImagePreviewComponent,
    MaintenanceComponent,
    IsEnabledIndicatorComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        FileUploadModule,
        MatDividerModule,
        MatListModule,
        MatCardModule,
    ],
    exports: [
        FormatPipe,
        SecondsToTimePipe,
        ResultNumberPipe,
        SafePipe,
        HighlightDirective,
        SubscriptionPaymentDialogComponent,
        ConfirmationDialogComponent,
        ReportDialogComponent,
        AddReportDialogComponent,
        AddExercisesDialogComponent,
        AdvancedConfirmationDialogComponent,
        VideoPlayerComponent,
        MoveExercisesDialogComponent,
        EditExerciseDialogComponent,
        ValidateControlDirective,
        SelectCategoryComponent,
        SelectTeamComponent,
        VideoUploaderComponent,
        ImageUploaderComponent,
        EndTypePipe,
        SelectAthleteCategoryComponent,
        ProcessingPaymentDialogComponent,
        IsEnabledIndicatorComponent,
    ],
})
export class SharedModule {}
