<form [formGroup]="_formGroup">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Jump Type</mat-label>
    <mat-select
      placeholder="Jump Type"
      [formControl]="verticalJumpType"
      [value]="verticalJumpType.value"
    >
      <mat-option [value]="EExerciseJumpType.DROP_JUMP">Drop Jump</mat-option>
      <mat-option [value]="EExerciseJumpType.VERTICAL">Vertical</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(verticalJumpType)">
      {{ ValidationHelperService.getErrorMessage(verticalJumpType) }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Jump With</mat-label>
    <mat-select
      placeholder="Jump With"
      [formControl]="numberOfLegs"
      [value]="numberOfLegs.value"
    >
      <mat-option [value]="1">1 Leg</mat-option>
      <mat-option [value]="2">2 Legs</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(numberOfLegs)">
      {{ ValidationHelperService.getErrorMessage(numberOfLegs) }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Number of Jumps</mat-label>
    <input
      type="number"
      [formControl]="numberOfJumps"
      matInput
      step="1"
      [value]="numberOfJumps.value"
    />
    <mat-error *ngIf="ValidationHelperService.isInvalid(numberOfJumps)">
      {{ ValidationHelperService.getErrorMessage(numberOfJumps) }}
    </mat-error>
  </mat-form-field>
</form>
