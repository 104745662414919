import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../../core/helpers/custom-validators";
import {ILocation} from "../../../../shared/interfaces/ILocation";
import {EAction} from "../../../../shared/enums/EAction";

export interface IUserForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    username?: FormControl<string>;
    email: FormControl<string>;
    type: FormControl<string>;
    locations: FormControl<ILocation[]>;
    isOrganizationAdmin: FormControl<boolean>;
}

export class IUserFormFactory {
    static create(action: EAction): FormGroup<IUserForm> {
        if(action === EAction.Add)
            return new FormGroup<IUserForm>({
                firstName: new FormControl("", [Validators.required, CustomValidators.nameValidator]),
                lastName: new FormControl("", [Validators.required, CustomValidators.nameValidator]),
                username:  new FormControl(
                    "",
                    [
                        Validators.required,
                        CustomValidators.usernameValidator,
                        CustomValidators.noWhitespaceValidator,
                        Validators.minLength(7),
                    ],
                ),
                email: new FormControl ("", [Validators.required, CustomValidators.emailValidator]),
                type: new FormControl(""),
                locations: new FormControl([], [Validators.minLength(1)]),
                isOrganizationAdmin: new FormControl<boolean>(false)
            });
        else
            return new FormGroup<IUserForm>({
            firstName: new FormControl("", [Validators.required, CustomValidators.nameValidator]),
            lastName: new FormControl("", [Validators.required, CustomValidators.nameValidator]),
            email: new FormControl ("", [Validators.required, CustomValidators.emailValidator]),
            type: new FormControl(""),
            locations: new FormControl([], [Validators.minLength(1)]),
            isOrganizationAdmin: new FormControl<boolean>(false)
        });
    }
}
