import { Component, OnDestroy, OnInit } from "@angular/core";
import { ResultsControllerService } from "../../core/api/controllers/results-controller.service";
import { NotificationService } from "../../core/services/notification.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { colorArray } from "../../core/helpers/chart-data";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { GlobalPreferences } from "../../core/helpers/global-data";
import { MixpanelService } from "../../core/services/mixpanel.service";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: [
    "./results.component.scss",
    "../athletes/athletes.component.scss",
  ],
  providers: [ResultsControllerService, DatePipe, DecimalPipe],
})
export class ResultsComponent implements OnInit, OnDestroy {
  resultsList = [];
  possibleFilters = [];
  selectedFilters = [];
  filtering = false;
  filters = [];
  currentPage = 0;
  tabular = true;
  colorArray = colorArray;
  loading = false;
  sub: Subscription;
  exporting = false;
  fromDate = 0;
  collapse: boolean[][] = [];

  static outputExerciseType(exerciseType: number): string {
    switch (exerciseType) {
      case 0:
        return "Count";
      case 1:
        return "React";
      case 2:
        return "Sequence";
      case 3:
        return "Agility";
      case 4:
        return "Vertical";
      default:
        return "Unknown exercise.";
    }
  }

  constructor(
    private resultsService: ResultsControllerService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("results_screen", {});
    this.sub = this.route.queryParams.subscribe(
      (params) => {
        const exerciseId = params["exerciseId"];
        const exerciseName = params["exerciseName"];
        if (exerciseId !== undefined && exerciseName !== undefined) {
          this.updateFilters("exerciseId", exerciseId, exerciseName, 0);
        }
        this.getResultData();
        this.getPossibleFilters();
      },
      (error) => this.notificationService.error(error),
    );
  }

  getResultData(shouldFilter: boolean = false, page = null) {
    this.loading = true;
    const currentPage =
      page !== null && page !== undefined ? page : this.currentPage;
    if (shouldFilter) {
      this.resultsList = [];
      sessionStorage.setItem(
        "resultFilters",
        JSON.stringify({
          selectedFilters: this.selectedFilters,
          filters: this.filters,
        }),
      );
    }

    this.resultsService
      .getResultData(
        currentPage,
        5,
        this.constructFilterString(true),
        this.fromDate,
      )
      .subscribe(
        (response) => {
          for (let i = 0; i < response.length; i++) {
            this.collapse[i] = [];
            const canLoadLength = response[i].results.length;
            if (canLoadLength > 0) {
              if (response[i].results[0]["@type"] === "paired") {
                let firstScore;
                if (
                  response[i].results[0].result1 &&
                  response[i].results[0].result2
                ) {
                  if (
                    response[i].results[0].result1.date <
                    response[i].results[0].result2.date
                  ) {
                    firstScore = response[i].results[0].result1.score;

                    response[i].results[0].result2.color =
                      response[i].results[0].result2.percentChange < 0 ? -1 : 1;
                  } else {
                    firstScore = response[i].results[0].result2.score;

                    response[i].results[0].result1.color =
                      response[i].results[0].result1.percentChange < 0 ? -1 : 1;
                  }
                  response[i].results[0].result1.side = response[
                    i
                  ].results[0].result1.shortenedName.replace(
                    new RegExp(
                      response[i].results[0].result2.shortenedName.replace(
                        new RegExp(" ", "g"),
                        "|",
                      ),
                      "g",
                    ),
                    "",
                  );
                  response[i].results[0].result2.side = response[
                    i
                  ].results[0].result2.shortenedName.replace(
                    new RegExp(
                      response[i].results[0].result1.shortenedName.replace(
                        new RegExp(" ", "g"),
                        "|",
                      ),
                      "g",
                    ),
                    "",
                  );
                } else if (response[i].results[0].result1) {
                  firstScore = response[i].results[0].result1.score;
                } else {
                  firstScore = response[i].results[0].result2.score;
                }
                for (let j = 0; j < canLoadLength; j++) {
                  this.collapse[i][j] = true;
                  if (
                    response[i].results[j].result1 &&
                    response[i].results[j].result2
                  ) {
                    response[i].results[j].result2.color =
                      response[i].results[j].percentChange < 0 ? -1 : 1;

                    response[i].results[j].color =
                      response[i].results[j].result2.color;
                    response[i].results[j].result1.side = response[i].results[
                      j
                    ].result1.shortenedName.replace(
                      new RegExp(
                        response[i].results[j].result2.shortenedName.replace(
                          new RegExp(" ", "g"),
                          "|",
                        ),
                        "g",
                      ),
                      "",
                    );
                    response[i].results[j].result2.side = response[i].results[
                      j
                    ].result2.shortenedName.replace(
                      new RegExp(
                        response[i].results[j].result1.shortenedName.replace(
                          new RegExp(" ", "g"),
                          "|",
                        ),
                        "g",
                      ),
                      "",
                    );
                  }
                }

                response[i].overview = this.fromDate;
                response[i].canLoad =
                  canLoadLength % 20 === 0 && canLoadLength !== 0;
                response[i].scoreUnit =
                  response[i].scoreType === "Correct touches"
                    ? "touches"
                    : "seconds";
              } else {
                this.collapse[i][0] = true;
                for (let j = 1; j < canLoadLength; j++) {
                  this.collapse[i][j] = true;

                  response[i].results[j].color =
                    response[i].results[j].percentChange < 0 ? -1 : 1;
                }
                response[i].overview = this.fromDate;
                response[i].canLoad =
                  canLoadLength % 20 === 0 && canLoadLength !== 0;
                response[i].scoreUnit =
                  response[i].scoreType === "Correct touches"
                    ? "touches"
                    : "seconds";
              }
            }
          }
          this.resultsList = response;
          this.loading = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        },
      );
  }

  updateSelectedResults(data, initialize: boolean = false) {
    data.loading = true;
    const noOfResults = data.results.length;
    let page;
    page = noOfResults > 0 ? Math.floor(noOfResults / 20) : 0;
    this.resultsService
      .getResults(
        page,
        20,
        data.overview,
        data.exerciseId,
        data.endType,
        data.endValue,
        data.inputType,
        data.delay,
        data.flash,
        data.promptType,
        data.name,
        this.constructFilterString(true),
      )
      .subscribe(
        (response) => {
          if (response.length > 0) {
            if (data.paired) {
              let i = 0;
              let firstResult;
              if (noOfResults > 0) {
                firstResult = data.results[0].result1
                  ? data.results[0].result1
                  : data.results[0].result2;
                i = data.results.length;
                data.results = data.results.concat(response);
              } else {
                data.results = response;
                if (response[0].result1) {
                  firstResult = response[0].result1;
                } else if (response[0].result2) {
                  firstResult = response[0].result2;
                }
                i = 1;
              }
              for (i; i < data.results.length; i++) {
                if (data.results[i].result1) {
                  firstResult.color =
                    data.results[i].result1.percentChange < 0 ? -1 : 1;
                }
                if (data.results[i].result2) {
                  firstResult.color =
                    data.results[i].result2.percentChange < 0 ? -1 : 1;
                }
              }
              const canLoadLength = response.length;
              data.canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              data.scoreUnit =
                data.scoreType === "Correct touches" ? "touches" : "seconds";
            } else {
              let i = 0;
              let firstScore;
              if (noOfResults > 0) {
                firstScore = data.results[0].score;
                i = data.results.length;
                data.results = data.results.concat(response);
              } else {
                data.results = response;
                if (response[0]) {
                  firstScore = response[0].score;
                }
                i = 1;
              }
              for (i; i < data.results.length; i++) {
                data.results[i].color =
                  data.results[i].percentChange < 0 ? -1 : 1;
              }
              const canLoadLength = response.length;
              data.canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              data.scoreUnit =
                data.scoreType === "Correct touches" ? "touches" : "seconds";
            }
          }
          data.loading = false;
        },
        (error) => {
          this.notificationService.error(error);
          data.loading = false;
        },
      );
  }

  updateResults() {
    this.currentPage = 0;
    this.getResultData(false, 0);
  }

  checkSelectedFilters() {
    this.selectedFilters.forEach((filterId) => {
      for (const el in this.possibleFilters) {
        if (el) {
          let foundExercise = false;

          this.possibleFilters[el].forEach((possibleFilter) => {
            if (possibleFilter.id == filterId) {
              for (const filteredExercise of possibleFilter.elements ||
                possibleFilter.positions) {
                if (this.selectedFilters.indexOf(filteredExercise.id) != -1) {
                  foundExercise = true;
                  break;
                }
              }

              if (!foundExercise) {
                this.selectedFilters.splice(
                  this.selectedFilters.indexOf(filterId),
                  1,
                );
                return;
              }
            }
          });
        }
      }
    });
  }

  updateFilters(key: string, value: any, name: string, type: number) {
    const filter = { key: key, value: value, name: name, type: type };

    for (let i = 0; i < this.filters.length; ++i) {
      if (this.filters[i] && this.filters[i].value === filter.value) {
        this.filters.splice(i, 1);
        this.selectedFilters.splice(this.selectedFilters.indexOf(value), 1);
        this.checkSelectedFilters();
        return;
      }
    }
    this.selectedFilters.push(name === "No Team" ? name : value);
    this.filters.push(filter);
  }

  updateSelectedFilters(value: string) {
    const index = this.selectedFilters.indexOf(value);
    index == -1
      ? this.selectedFilters.push(value)
      : this.selectedFilters.splice(index, 1);
  }

  selectAllFilters(key: string, array, onlyPush: boolean) {
    if (onlyPush) {
      for (const element of array) {
        this.selectedFilters.push(element.id);
        this.filters.push({
          key: key,
          value: element.id,
          name: element.name,
          type: 0,
        });
      }
    } else {
      for (const element of array) {
        for (let i = 0; i < this.filters.length; ++i) {
          if (this.filters[i].value === element.id) {
            this.filters.splice(i, 1);
            this.selectedFilters.splice(
              this.selectedFilters.indexOf(element.id),
              1,
            );
            break;
          }
        }
      }
    }
    this.getResultData(true);
  }

  constructFilterString(existingFilters: boolean): string {
    let filterString = `&`;
    if (!existingFilters) filterString = `?`;
    for (const filter of this.filters) {
      filterString += `${filter.key}${filter.type}=${filter.value}&`;
    }
    return filterString.substring(0, filterString.length - 1);
  }

  getPossibleFilters() {
    this.resultsService.getPossibleFilters().subscribe(
      (response) => {
        this.possibleFilters = response;
      },
      (error) => this.notificationService.error(error),
    );
  }

  massExport() {
    this.exporting = true;
    this.resultsService
      .massExport(this.constructFilterString(false), this.fromDate)
      .subscribe(
        (response) => {
          for (let i = 0; i < response.length; i++) {
            if (response[i] != "") {
              this.downloadReport(
                ResultsComponent.outputExerciseType(i) +
                  " Full " +
                  this.datePipe.transform(new Date(), "MMM d yy"),
                response[i],
              );
            }
          }
          this.exporting = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.exporting = false;
        },
      );
  }

  exportResultData(data: any) {
    data.exporting = true;
    const dataCopy = Object.assign({}, data);
    if (data.paired) {
      dataCopy.chart1 = null;
      dataCopy.chart2 = null;
      dataCopy["@type"] = "paired";
    } else {
      dataCopy.chart = null;
      dataCopy["@type"] = "normal";
    }
    this.resultsService.exportResultData(dataCopy).subscribe(
      (response) => {
        this.downloadReport(
          data.name.substring(0, 20) +
            " " +
            this.datePipe.transform(new Date(), "MMM d yy"),
          response.body,
        );
        data.exporting = false;
      },
      (error) => {
        this.notificationService.error(error);
        data.exporting = false;
      },
    );
  }

  downloadReport(filename: string, data: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display:none");
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "octet/stream" }),
    );
    a.href = url;
    a.download = filename + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  deleteResult(id: string) {
    //todo: complete result deletion
    this.resultsService.deleteResult(id).subscribe(
      () => {
        this.notificationService.success("Result deleted successfully.");
      },
      (error) => this.notificationService.error(error),
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clearFilters() {
    this.getResultData(true, 0);
    this.selectedFilters = [];
    this.filters = [];
  }
}
