import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ValidationHelperService } from "../../../../core/helpers/validation-helper.service";

@Component({
  selector: "app-count-exercise-form",
  templateUrl: "./count-exercise-form.component.html",
  styleUrls: ["./count-exercise-form.component.scss"],
})
export class CountExerciseFormComponent {
  @Input() _formGroup: FormGroup;

  get countType(): FormControl {
    return this._formGroup.get("countType") as FormControl;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
