import * as moment from "moment";
import {DiscountType, ShopItemType, SubscriptionBillingPeriod,} from "../constants/subscription.const";
import {Country} from "./geography.model";
import {QBSubscription} from "./subscription.model";

export interface Product {
  id: string;
  name: string;
  unitName: string;
  description: string;
  defaultShippingPrice: number;
  price: number;
  imageUrl: string;
  active: boolean;
  creationDate: number;
  lastModified: number;
  shippingPrices: ShippingPrice[];
  quantity: number;
}

export interface ShippingPrice {
  regionId: string;
  price: number;
}

export interface Coupon {
  id: string;
  code: string;
  discount: number;
  discountType: DiscountType;
  description: string;
  appliesFor: ShopItemType;
  expiresOn: Date;
}

export class OrderDetails {
  discounts: number;
  subscriptionPrice: number;
  productsPrice: number;
  private _totalPrice: number;
  shippingCost: number;
  activeCoupon: Coupon;

  constructor() {
    this.subscriptionPrice = 0;
    this.productsPrice = 0;
    this.discounts = 0;
    this.shippingCost = 0;
    this.activeCoupon = null;
    this.totalPrice = 0;
  }

  public get totalPrice(): number {
    return this._totalPrice;
  }

  public set totalPrice(value: number) {
    this._totalPrice = Math.max(value, 0);
  }

  subscriptionChanged(qbSubscriptions: QBSubscription): void {
    const selectedSub = qbSubscriptions.selectedSubscriptionOption;
    this.discounts =
      qbSubscriptions.billingPeriod === SubscriptionBillingPeriod.Annually
        ? selectedSub.discountAnnually
        : 0;
    this.subscriptionPrice =
      qbSubscriptions.billingPeriod === SubscriptionBillingPeriod.Annually
        ? selectedSub.price * 12
        : selectedSub.price;
    this.computeTotalPrice();
  }

  productsChanged(products: Product[], shippingCountry: Country): void {
    this.productsPrice = products.reduce(
      (acc, p) => acc + (p.quantity > 0 ? p.price * p.quantity : 0),
      0,
    );
    if (shippingCountry) {
      this.shippingCost = this._getShippingPrice(shippingCountry, products);
    }
    this.computeTotalPrice();
  }

  couponApplied(coupon: any): void {
    this.activeCoupon = coupon;
    if (this.activeCoupon.discountType === DiscountType.Days) {
      this.activeCoupon.expiresOn = moment()
        .add(coupon.discount, "days")
        .toDate();
    }
    this.computeTotalPrice();
  }

  couponRemoved(): void {
    this.activeCoupon = null;
    this.computeTotalPrice();
  }

  countryChanged(shippingCountry: Country, products: Product[]): void {
    this.shippingCost = this._getShippingPrice(shippingCountry, products);
    this.computeTotalPrice();
  }

  formPrefilled(options: any): void {
    this.discounts =
      options.period === SubscriptionBillingPeriod.Annually
        ? options.sub.discountAnnually
        : 0;
    this.subscriptionPrice =
      options.period === SubscriptionBillingPeriod.Annually
        ? options.sub.price * 12
        : options.sub.price;
    this.productsPrice = options.products.reduce(
      (acc, p) => acc + (p.quantity > 0 ? p.price * p.quantity : 0),
      0,
    );
    this.shippingCost = this._getShippingPrice(
      options.country,
      options.products,
    );
    this.activeCoupon = options.coupon;
    this.computeTotalPrice();
  }

  computeTotalPrice(): void {
    if (!this.activeCoupon) {
      this.totalPrice =
        this.subscriptionPrice +
        this.productsPrice +
        this.shippingCost -
        this.discounts;
      this.totalPrice = Math.max(0, this.totalPrice);
      return;
    }
    const couponDiscount = this.activeCoupon.discount;
    switch (this.activeCoupon.discountType) {
      case DiscountType.FixedAmount:
        switch (this.activeCoupon.appliesFor) {
          case ShopItemType.Product:
            const newProductsPrice =
              this.productsPrice - couponDiscount > 0
                ? this.productsPrice - couponDiscount
                : 0;
            this.totalPrice =
              this.subscriptionPrice +
              newProductsPrice +
              this.shippingCost -
              this.discounts;
            break;
          case ShopItemType.Subscription:
            const newSubscriptionPrice =
              this.subscriptionPrice - couponDiscount > 0
                ? this.subscriptionPrice - couponDiscount
                : 0;
            this.totalPrice =
              newSubscriptionPrice +
              this.productsPrice +
              this.shippingCost -
              this.discounts;
            break;
          case ShopItemType.All:
            this.totalPrice =
              this.subscriptionPrice +
              this.productsPrice +
              this.shippingCost -
              this.discounts -
              couponDiscount;
            break;
          default:
            break;
        }
        break;
      case DiscountType.Percentage:
        switch (this.activeCoupon.appliesFor) {
          case ShopItemType.Product:
            const newProductsPrice =
              this.productsPrice - (this.productsPrice * couponDiscount) / 100;
            this.totalPrice =
              this.subscriptionPrice +
              newProductsPrice +
              this.shippingCost -
              this.discounts;
            break;
          case ShopItemType.Subscription:
            const newSubscriptionPrice =
              this.subscriptionPrice -
              (this.subscriptionPrice * couponDiscount) / 100;
            this.totalPrice =
              newSubscriptionPrice +
              this.productsPrice +
              this.shippingCost -
              this.discounts;
            break;
          case ShopItemType.All:
            const costs =
              this.subscriptionPrice + this.productsPrice + this.shippingCost;
            this.totalPrice =
              costs - this.discounts - (costs * couponDiscount) / 100;
            break;
          default:
            break;
        }
        break;
      case DiscountType.Days:
        this.totalPrice = this.productsPrice + this.shippingCost;
        break;
    }
  }

  private _getShippingPrice(
    shippingCountry: Country,
    products: Product[],
  ): number {
    const getShippingPricePerProduct = (p: Product) => {
      const customPriceDetails = p.shippingPrices.find(
        (sp) => sp.regionId === shippingCountry.regionId,
      );
      return (customPriceDetails
        ? customPriceDetails.price
        : p.defaultShippingPrice) * p.quantity;
    };
    return products
      .filter((p) => p.quantity)
      .reduce((acc, p) => acc + getShippingPricePerProduct(p), 0);
  }
}
