<div class="card w-100 p-3 mt-2 mt-sm-0 mr-sm-5 mb-2">
  <div *ngIf="!isLoading">
    <div class="mb-2 mr-1">
      <button mat-flat-button color="primary" (click)="addLocation()">
        Add Location
      </button>
    </div>
    <div class="d-flex flex-column gap-5 mt-5">
      <div
        *ngFor="let location of locations"
        class="flex-grow-1 flex items-center justify-between gap-3 border-bottom p-3"
      >
        {{ location.name }}
        <div class="d-flex align-items-center justify-content-end w-[50%]">
            <app-is-enabled-indicator [value]="location.isActive"></app-is-enabled-indicator>
          <button mat-button [matMenuTriggerFor]="menu">
            <i class="default text ellipsis horizontal icon zoomed"></i>
          </button>
          <mat-menu class="menu pushed-menu" #menu="matMenu">
            <button mat-menu-item (click)="editLocation(location)">Edit</button>
            <button
              mat-menu-item
              (click)="handleDeleteLocationModal(location.id)"
            >
              Delete
            </button>
              <button mat-menu-item (click)="openToggleLocationActive(location)">
                    {{location.isActive ? "Disable" : "Enable"}}
              </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
