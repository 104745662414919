import { Component, Input, OnInit } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { MediaControllerService } from "../../../core/api/controllers/media-controller.service";
import { NotificationService } from "../../../core/services/notification.service";
import { FormControl } from "@angular/forms";
import { ValidationHelperService } from "../../../core/helpers/validation-helper.service";
import { MatDialog } from "@angular/material/dialog";
import {
  IVideoPlayerData,
  VideoPlayerComponent,
} from "../video-player/video-player.component";

@Component({
  selector: "app-video-uploader",
  templateUrl: "./video-uploader.component.html",
  styleUrls: ["./video-uploader.component.scss"],
})
export class VideoUploaderComponent implements OnInit {
  videoUploader: FileUploader;
  isVideoUploading = false;

  @Input() public _control: FormControl;

  constructor(
    private mediaController: MediaControllerService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initVideoUploader();
  }

  initVideoUploader(): void {
    this.videoUploader = new FileUploader({
      queueLimit: 1,
    });

    this.videoUploader.onAfterAddingFile = ({ file }) => {
      if (file.type.indexOf("video") >= 0 && file.size <= 200000000) {
        const data = new FormData();
        data.append("file", file.rawFile);
        this.isVideoUploading = true;
        this.mediaController.mediaUpload(data).subscribe({
          next: (res) => {
            this._control.setValue(res.url);
            this.isVideoUploading = false;
          },
          error: () => {
            this.isVideoUploading = false;
          },
        });
      } else {
        this.notificationService.warn("Invalid video type or size submitted!");
        this._control.setValue(null);
      }
    };
  }

  openPreviewDialog(): void {
    this.dialog.open(VideoPlayerComponent, {
      data: { toView: this._control.value } as IVideoPlayerData,
    });
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
