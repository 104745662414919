import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { ValidationHelperService } from "../../core/helpers/validation-helper.service";

@Directive({
  selector: "[appValidateControl]",
})
export class ValidateControlDirective implements OnDestroy {
  @Input() control: AbstractControl;
  private statusChangesSubscription: Subscription;
  private errorMessageElement: HTMLElement;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  @Input() set appValidateControl(control: AbstractControl) {
    this.cleanup(); // Clean up any existing subscription or error message
    this.control = control;
    this.statusChangesSubscription = this.control.statusChanges.subscribe(
      () => {
        this.updateView();
      },
    );
    this.updateView(); // Initial check to handle the current state
  }

  private updateView() {
    if (this.isInvalid) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.displayErrorMessage();
    } else {
      this.viewContainer.clear();
      this.removeErrorMessage();
    }
  }

  private displayErrorMessage() {
    const errorMessage = ValidationHelperService.getErrorMessage(this.control);
    this.errorMessageElement = this.renderer.createElement("span");
    const text = this.renderer.createText(errorMessage);
    this.renderer.appendChild(this.errorMessageElement, text);
    this.renderer.addClass(this.errorMessageElement, "error-message"); // Add your error message class here
    this.renderer.appendChild(this.el.nativeElement, this.errorMessageElement);
  }

  private removeErrorMessage() {
    if (this.errorMessageElement) {
      this.renderer.removeChild(
        this.el.nativeElement,
        this.errorMessageElement,
      );
      this.errorMessageElement = null;
    }
  }

  get isInvalid() {
    return this.control && this.control.invalid && this.control.touched;
  }

  ngOnDestroy() {
    this.cleanup();
  }

  private cleanup() {
    if (this.statusChangesSubscription) {
      this.statusChangesSubscription.unsubscribe();
    }
    this.removeErrorMessage();
  }
}
