import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class GeographyControllerService {
  private readonly _resourceUrl: string = "/geography";

  constructor(private apiService: ApiService) {}

  getAllCountries(): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}/countries`);
  }

  getStatesByCountry(countryId: string): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}/states`, { countryId });
  }

  getCitiesByState(stateId: string): Observable<any> {
    return this.apiService.get(`${this._resourceUrl}/cities`, { stateId });
  }
}
