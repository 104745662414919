<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="mb-2 mb-sm-3 mb-md-4 mb-lg-5 p-3 card w-full">
    <div>
      <button
        mat-flat-button
        color="primary"
        class="flex justify-center items-center"
        [matMenuTriggerFor]="menu"
      >
        <span> Create </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/save-exercise">Exercise</a>
        <a mat-menu-item routerLink="/save-paired-exercise">Paired Exercise</a>
      </mat-menu>
    </div>
  </div>

  <div class="card w-100">
    <div
      class="flex justify-center items-center w-full h-[600px]"
      *ngIf="isLoading"
    >
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" class="display-block w-100 p-3">
      <div class="w-100 border-bottom">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex p-3">
            <button
              mat-flat-button
              color="primary"
              [matMenuTriggerFor]="menu2"
              [disabled]="exercisesSelected.length === 0"
            >
              Modify selected <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
              <button mat-menu-item (click)="openMoveModal()">
                Move to Category
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

      <div class="full-height" *ngIf="!categoriesLoaded">
          <mat-spinner diameter="30"></mat-spinner>
      </div>

      <div
        class="p-3 text-center"
        *ngIf="
          (exerciseCategories === undefined ||
            exerciseCategories.length === 0) &&
          categoriesLoaded
        "
      >
        <i
          class="material-icons timer-icon text-center mx-auto d-block alt-color-text"
          >timer</i
        >
        <span class="h4 alt-color-text">No Exercises</span>
        <p class="alt-color-text">
          There are no exercises to show. Press the button on the right to
          create a new one.
        </p>
      </div>

      <mat-accordion class="w-100 p-3" *ngIf="categoriesLoaded">
        <mat-expansion-panel *ngFor="let category of exerciseCategories">
          <mat-expansion-panel-header class="py-3">
            <div class="w-full flex justify-between items-center">
              <h4>{{ category.name }}</h4>
              <div class="flex justify-center items-center gap-3">
                <button
                  *ngIf="isSuperAdmin()"
                  mat-flat-button
                  color="primary"
                  size="small"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    addCategory(category.id)
                  "
                >
                  {{
                    selectedCategories.includes(category.id)
                      ? "Deselect All"
                      : "Select All"
                  }}
                </button>
                <div
                  *ngIf="isSuperAdmin()"
                  class="d-flex align-items-center justify-content-center"
                >
                  <div class="ui dropdown center-text button-dropdown">
                    <button
                      mat-button
                      [matMenuTriggerFor]="menu3"
                      (click)="
                        $event.stopPropagation(); $event.preventDefault()
                      "
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu3="matMenu">
                      <button
                        mat-menu-item
                        (click)="
                          addToSingleLocation(
                            exercises[category.id],
                            category.id
                          )
                        "
                        *ngIf="hasExercisesSelected(category.id)"
                      >
                        Push selected to location
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <app-category-exercises
              [exercises]="exercises[category.id]"
              [exercisesSelected]="exercisesSelected"
              [selectedCategories]="selectedCategories"
              [category]="category"
              *ngIf="exercises[category.id].length > 0"
            />
            <div
              class="p-3 text-center"
              *ngIf="exercises[category.id].length === 0"
            >
              <i
                class="material-icons timer-icon text-center mx-auto d-block alt-color-text"
                >timer</i
              >
              <span class="h4 alt-color-text">No Exercises</span>
              <p class="alt-color-text">
                There are no exercises in this category to show. Press the
                "create" button to create a new one.
              </p>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
