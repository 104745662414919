import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalPreferences {
  data: any = {
    isClinic: false,
    athleteName: "athlete",
    uppercaseAthleteName: "Athlete",
    workoutName: "workout",
    uppercaseWorkoutName: "Workout",
  };

  dataChange: Subject<any> = new Subject<any>();

  constructor() {
    this.dataChange.subscribe((data) => (this.data = data));
  }

  getTimezoneOffset() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesLeft = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes < 0 ? "" : "-";
    return `${sign}${String(offsetHours).padStart(2, "0")}:${String(offsetMinutesLeft).padStart(2, "0")}`;
  }
}
