import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "../../../../core/services/message-service";
import { NotificationService } from "../../../../core/services/notification.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LocationsControllerService } from "../../../../core/api/controllers/locations-controller.service";

export interface ILocationActionDialogData {
  action: string;
  location: any;
}

@Component({
  selector: "app-location-action",
  templateUrl: "./location-action.component.html",
  styleUrls: ["./location-action.component.scss"],
})
export class LocationActionComponent implements OnInit {
  locationForm: FormGroup;
  locations = [];
  user: any;
  action: string;
  location = null;
  allreadyPressed = false;
  isLoading = false;

  constructor(
    private messageService: MessageService,
    private locationsController: LocationsControllerService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<LocationActionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ILocationActionDialogData,
  ) {}

  ngOnInit() {
    this.locationForm = this.formBuilder.group({
      name: ["", [Validators.required]],
    });
    this.action = this.data.action;
    this.location = this.data.location;
    if (this?.location?.name) {
      this.locationForm.controls["name"].setValue(this.location.name);
    }
  }

  update() {
    const locationName = this.locationForm.controls["name"].value;
    this.isLoading = true;
    this.locationsController.getAllLocations().subscribe(({ locations }) => {
      const alreadyIsWithName = locations.find(
        ({ name }) => name.toLowerCase() === locationName.toLowerCase(),
      );

      if (!Boolean(alreadyIsWithName)) {
        if (this.action == "Add") {
          this.locationsController
            .addLocation({
              name: locationName,
              imageUrl: "",
            })
            .subscribe(
              () => {
                this.notificationService.success(
                  "The new Location will be added in a few moments",
                );
                this.dialogRef.close(true);
                this.messageService.accessMessage().subscribe((data) => {
                  if (!data) {
                    this.messageService.sendMessage(true);
                  }
                });
                this.isLoading = false;
              },
              () => {
                this.notificationService.error("Not authorized");
                this.dialogRef.close(true);
                this.isLoading = false;
              },
            );
          this.allreadyPressed = true;
        } else {
          this.locationsController
            .editLocation({
              id: this.location.id,
              name: locationName,
              imageUrl: "",
            })
            .subscribe(
              () => {
                this.notificationService.success("Location added");
                this.dialogRef.close(true);
                this.isLoading = false;
              },
              () => {
                this.notificationService.error("Not authorized");
                this.dialogRef.close(true);
                this.isLoading = false;
              },
            );
          this.allreadyPressed = true;
        }
      } else {
        this.notificationService.error(
          "A location with this name already exists !",
        );
        this.isLoading = false;
      }
    });
  }
}
