import {Injectable} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {PaymentHistoryFormType} from "./PaymentHistoryFormType";
import {ETransactionType} from "../../../shared/enums/ETransactionType";

@Injectable({
    providedIn: 'root',
})
export class PaymentHistoryFormFactory {
    create(): FormGroup<PaymentHistoryFormType> {
        return new FormGroup<PaymentHistoryFormType>({
            searchBy: new FormControl(""),
            isSuccess: new FormControl(false),
            transactionType: new FormControl(ETransactionType.FullPurchase),
            invoiceGenerated: new FormControl(false),
            dateTimeInterval: new FormGroup({
                startDate: new FormControl(new Date()),
                endDate: new FormControl(new Date()),
            }),
        });
    }
}
