import {Component, Input, OnInit} from "@angular/core";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EExerciseStartType} from "../../../../shared/enums/EExerciseStartType";
import {ECountdownEndType} from "../../../../shared/enums/ECountdownEndType";
import {EExerciseType} from "../../../../shared/enums/EExerciseType";

@Component({
  selector: "app-countdowns-form",
  templateUrl: "./countdowns-form.component.html",
  styleUrls: ["./countdowns-form.component.scss"],
})
export class CountdownsFormComponent implements OnInit {
  @Input() _formGroup: FormGroup;
  @Input() _exerciseType: FormControl;
  protected readonly ValidationHelperService = ValidationHelperService;

  ngOnInit(): void {
    this.subscribeToStartTypeChanges();
  }

  subscribeToStartTypeChanges(): void {
    this.startType.valueChanges.subscribe((value) => {
      if (value === EExerciseStartType.RANDOM) {
        this.startValue.clearValidators();
        this.startValue.updateValueAndValidity();
      } else if (value === EExerciseStartType.FULL_COUNTDOWN) {
        this.startValue.setValidators([Validators.required, Validators.min(0)]);
      }
    });
  }

  get startType(): FormControl {
    return this._formGroup.get("startType") as FormControl;
  }

  get startValue(): FormControl {
    return this._formGroup.get("startValue") as FormControl;
  }

  get endType(): FormControl {
    return this._formGroup.get("endType") as FormControl;
  }

  get endValue(): FormControl {
    return this._formGroup.get("endValue") as FormControl;
  }

  protected readonly EExerciseStartType = EExerciseStartType;
  protected readonly ECountdownEndType = ECountdownEndType;
  protected readonly EExerciseType = EExerciseType;
}
