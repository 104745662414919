<div>
  <div class="header">
    <h4>Edit Exercise</h4>
  </div>
  <div class="content top-bottom-padding">
    <form class="ui form" [formGroup]="data.exerciseEditForm">
      <div class="five fields">
        <div class="field">
          <label>Name</label>
          <input
            type="text"
            [(ngModel)]="data.selectedExercise.name"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
        </div>

        <div class="field">
          <label>Category</label>
          <input
            type="text"
            [(ngModel)]="data.categoryInput"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
        </div>

        <div class="inline fields">
          <div class="two wide field">
            <label class="medium-text">State</label>
          </div>
          <div class="field">
            <p *ngIf="data.selectedExercise.active" class="grey-label">
              Active
            </p>
            <p *ngIf="!data.selectedExercise.active" class="grey-label">
              Inactive
            </p>
          </div>
        </div>

        <div class="field">
          <label>Type</label>
          <input
            type="text"
            [(ngModel)]="data.selectedType"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
        </div>

        <div class="field">
          <label>Video</label>
          <input
            type="text"
            [(ngModel)]="data.selectedExercise.videoUrl"
            [ngModelOptions]="{ standalone: true }"
            disabled
          />
        </div>
      </div>
      <div class="clearing divider ui"></div>
      <div class="field">
        <label class="big-label uppercase">Countdowns</label>
        <div class="inline fields">
          <div class="field select-field lower">
            <select
              class="exercise-form"
              formControlName="startType"
              (change)="
                data.exerciseEditForm.controls[
                  'startValue'
                ].updateValueAndValidity()
              "
            >
              <option [ngValue]="1">Full Countdown</option>
              <option [ngValue]="0">Random</option>
            </select>
            <label class="small-label grey-label block-display">Start</label>
          </div>

          <div
            *ngIf="data.exerciseEditForm.controls['startType'].value == 1"
            class="field"
          >
            <input
              class="exercise-form block-display"
              type="number"
              formControlName="startValue"
              placeholder="After how many seconds"
              (focus)="
                data.exerciseEditForm.controls['startValue'].markAsUntouched()
              "
            />
            <label class="small-label grey-label block-display"
              >After how many seconds:</label
            >
            <div
              *ngIf="
                !data.exerciseEditForm.controls['startValue'].valid &&
                data.exerciseEditForm.controls['startValue'].touched
              "
              class="ui pointing red basic label input-label"
            >
              Valid number of seconds must be provided.
            </div>
          </div>

          <div class="field select-field lower">
            <select
              class="exercise-form block-display"
              formControlName="endType"
            >
              <option [ngValue]="2">seconds</option>
              <option
                *ngIf="[0, 1, 2].indexOf(this.data.selectedExercise.type) > -1"
                [ngValue]="3"
              >
                touches
              </option>
              <option
                *ngIf="this.data.selectedExercise.type == 3"
                [ngValue]="4"
              >
                arrows
              </option>
              <option
                *ngIf="this.data.selectedExercise.type == 4"
                [ngValue]="10"
              >
                jumps
              </option>
            </select>
            <label class="small-label grey-label block-display">End</label>
          </div>

          <div class="field">
            <input
              class="exercise-form block-display"
              type="number"
              formControlName="endValue"
              placeholder="After how many"
              (focus)="
                data.exerciseEditForm.controls['endValue'].markAsUntouched()
              "
            />
            <label class="small-label grey-label block-display"
              >After how many</label
            >
            <div
              *ngIf="
                !data.exerciseEditForm.controls['endValue'].valid &&
                data.exerciseEditForm.controls['endValue'].touched
              "
              class="ui pointing red basic label input-label"
            >
              A valid end value must pe provided.
            </div>
          </div>
        </div>
      </div>

      <div
        class="field"
        *ngIf="
          this.data.selectedExercise.type == 1 ||
          this.data.selectedExercise.type == 2
        "
      >
        <label class="big-label">Timer</label>
        <div class="two fields">
          <div class="field select-field">
            <label>Delay</label>
            <select class="exercise-form" formControlName="delay">
              <option *ngFor="let delay of data.delayArray" [ngValue]="delay">
                {{ delay | number: "1.2-2" }}
                Delay
              </option>
            </select>
          </div>

          <div class="field select-field">
            <label>Prompt</label>
            <select class="exercise-form" formControlName="timerPrompt">
              <option
                *ngIf="
                  data.exerciseEditForm.controls['neurocognitiveType']?.value !=
                  2
                "
                [ngValue]="0"
              >
                Solid
              </option>
              <option *ngFor="let flash of flashArray" [ngValue]="flash">
                {{ flash | number: "1.2-2" }}
                Flash
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="two fields">
          <div
            *ngIf="this.data.selectedExercise.type == 0"
            class="field select-field"
          >
            <label class="big-label uppercase">Count Type</label>
            <select class="exercise-form" formControlName="countType">
              <option [ngValue]="0">Sum All</option>
              <option [ngValue]="1">Left Right</option>
            </select>
          </div>
        </div>
      </div>

      <ng-container *ngIf="this.data.selectedExercise.type == 1">
        <div class="field">
          <div class="two fields">
            <div class="field select-field">
              <label>Neurocognitive</label>
              <select
                class="exercise-form"
                formControlName="neurocognitiveType"
                (change)="
                  data.exerciseEditForm.controls[
                    'isolatedReact'
                  ].updateValueAndValidity()
                "
              >
                <option [ngValue]="0">Off</option>
                <option [ngValue]="1">Array</option>
                <option [ngValue]="2">Go/No Go</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="big-label">React/Don't React</label>
          <div
            *ngIf="!data.exerciseEditForm.controls['neurocognitiveType'].valid"
            class="ui pointing blue basic label input-label"
          >
            <ng-container
              *ngIf="
                data.exerciseEditForm.controls['neurocognitiveType'].value == 0
              "
            >
              Neurocognitive off needs to have 1 color for react.
            </ng-container>
            <ng-container
              *ngIf="
                data.exerciseEditForm.controls['neurocognitiveType'].value == 1
              "
            >
              Neurocognitive array needs to have 1 color for react and
              {{ 5 - this.data.reactSensors.length || 1 }} for don't react.
            </ng-container>
            <ng-container
              *ngIf="
                data.exerciseEditForm.controls['neurocognitiveType'].value == 2
              "
            >
              Neurocognitive Go/No Go needs to have at least 1 color for react
              and at least 1 color for don't react.
            </ng-container>
          </div>
          <div class="ui grid">
            <div class="eight wide column">
              <p>React</p>
              <div
                class="react-option yellow-background"
                [class.full-opacity]="data.react.indexOf(0) > -1"
                (click)="setReact(0, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(0) > -1"
                ></i>
              </div>
              <div
                class="react-option green-background"
                [class.full-opacity]="data.react.indexOf(1) > -1"
                (click)="setReact(1, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(1) > -1"
                ></i>
              </div>
              <div
                class="react-option red-background"
                [class.full-opacity]="data.react.indexOf(2) > -1"
                (click)="setReact(2, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(2) > -1"
                ></i>
              </div>
              <div
                class="react-option blue-background"
                [class.full-opacity]="data.react.indexOf(3) > -1"
                (click)="setReact(3, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(3) > -1"
                ></i>
              </div>
              <div
                class="react-option orange-background"
                [class.full-opacity]="data.react.indexOf(4) > -1"
                (click)="setReact(4, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(4) > -1"
                ></i>
              </div>
              <div
                class="react-option violet-background"
                [class.full-opacity]="data.react.indexOf(5) > -1"
                (click)="setReact(5, true)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.react.indexOf(5) > -1"
                ></i>
              </div>
            </div>
            <div class="eight wide column">
              <p>Don't react</p>
              <div
                class="react-option yellow-background"
                [class.full-opacity]="data.dontReact.indexOf(0) > -1"
                (click)="setReact(0)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(0) > -1"
                ></i>
              </div>
              <div
                class="react-option green-background"
                [class.full-opacity]="data.dontReact.indexOf(2) > -1"
                (click)="setReact(2)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(1) > -1"
                ></i>
              </div>
              <div
                class="react-option red-background"
                [class.full-opacity]="data.dontReact.indexOf(2) > -1"
                (click)="setReact(2)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(2) > -1"
                ></i>
              </div>
              <div
                class="react-option blue-background"
                [class.full-opacity]="data.dontReact.indexOf(3) > -1"
                (click)="setReact(3)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(3) > -1"
                ></i>
              </div>
              <div
                class="react-option orange-background"
                [class.full-opacity]="data.dontReact.indexOf(4) > -1"
                (click)="setReact(4)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(4) > -1"
                ></i>
              </div>
              <div
                class="react-option violet-background"
                [class.full-opacity]="data.dontReact.indexOf(5) > -1"
                (click)="setReact(5)"
              >
                <i
                  class="icon check react-overlay-icon"
                  *ngIf="data.dontReact.indexOf(5) > -1"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="two fields">
            <div class="field select-field">
              <label class="big-label">Isolated React</label>
              <select
                class="exercise-form"
                formControlName="isolatedReact"
                (change)="resetReact()"
              >
                <option [ngValue]="0">Off</option>
                <option [ngValue]="1">On</option>
              </select>
            </div>
            <div
              class="field"
              *ngIf="data.exerciseEditForm.controls['isolatedReact'].value == 1"
            >
              <label class="big-label">Active Sensors</label>
              <div
                *ngIf="!data.exerciseEditForm.controls['isolatedReact'].valid"
                class="ui pointing blue basic label"
              >
                At least
                <ng-container
                  *ngIf="
                    data.exerciseEditForm.controls['neurocognitiveType']
                      .value == 1
                  "
                >
                  two sensors
                </ng-container>
                <ng-container
                  *ngIf="
                    [0, 2].indexOf(
                      data.exerciseEditForm.controls['neurocognitiveType'].value
                    ) > -1
                  "
                >
                  one sensor
                </ng-container>
                must be active.
              </div>
              <div class="ui grid">
                <div class="four wide column">
                  <div
                    class="toggle-circle flex flex-center medium-text"
                    [class]="{
                      blueBackground: data.reactSensors.indexOf(1) == -1,
                      blackBackground: data.reactSensors.indexOf(1) > -1,
                    }"
                    (click)="setSensor(1)"
                  >
                    1
                  </div>
                </div>
                <div class="four wide column"></div>
                <div class="four wide column">
                  <div
                    class="toggle-circle flex flex-center medium-text"
                    [class]="{
                      blueBackground: data.reactSensors.indexOf(2) == -1,
                      blackBackground: data.reactSensors.indexOf(2) > -1,
                    }"
                    (click)="setSensor(2)"
                  >
                    2
                  </div>
                </div>

                <div class="four wide column"></div>

                <div class="four wide column"></div>
                <div class="four wide column">
                  <div
                    class="toggle-circle flex flex-center medium-text"
                    [class]="{
                      blueBackground: data.reactSensors.indexOf(3) == -1,
                      blackBackground: data.reactSensors.indexOf(3) > -1,
                    }"
                    (click)="setSensor(3)"
                  >
                    3
                  </div>
                </div>
                <div class="four wide column"></div>
                <div class="four wide column"></div>

                <div class="four wide column">
                  <div
                    class="toggle-circle flex flex-center medium-text"
                    [class]="{
                      blueBackground: data.reactSensors.indexOf(4) == -1,
                      blackBackground: data.reactSensors.indexOf(4) > -1,
                    }"
                    (click)="setSensor(4)"
                  >
                    4
                  </div>
                </div>
                <div class="four wide column"></div>
                <div class="four wide column">
                  <div
                    class="toggle-circle flex flex-center medium-text"
                    [class]="{
                      blueBackground: data.reactSensors.indexOf(5) == -1,
                      blackBackground: data.reactSensors.indexOf(5) > -1,
                    }"
                    (click)="setSensor(5)"
                  >
                    5
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="this.data.selectedExercise.type == 2">
        <div class="field">
          <label class="big-label">Sequence</label>
          <div
            *ngIf="
              data.exerciseEditForm.controls['delay'].hasError(
                'invalidSequence'
              )
            "
            class="ui left pointing blue label"
          >
            The sequence has to contain between 2 and 20 steps.
          </div>
          <button
            type="button"
            class="ui right floated button blueBackground"
            (click)="resetSequence()"
          >
            Reset
          </button>
          <p>{{ data.sequenceSensors.toString() }}</p>
          <div class="ui grid">
            <div class="four wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blueBackground: data.sequenceSensors.indexOf(1) == -1,
                  blackBackground: data.sequenceSensors.indexOf(1) > -1,
                }"
                (click)="addSequence(1)"
              >
                1
              </div>
            </div>
            <div class="four wide column"></div>
            <div class="four wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blueBackground: data.sequenceSensors.indexOf(2) == -1,
                  blackBackground: data.sequenceSensors.indexOf(2) > -1,
                }"
                (click)="addSequence(2)"
              >
                2
              </div>
            </div>
            <div class="four wide column"></div>

            <div class="four wide column"></div>
            <div class="four wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blueBackground: data.sequenceSensors.indexOf(3) == -1,
                  blackBackground: data.sequenceSensors.indexOf(3) > -1,
                }"
                (click)="addSequence(3)"
              >
                3
              </div>
            </div>
            <div class="four wide column"></div>
            <div class="four wide column"></div>

            <div class="four wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blueBackground: data.sequenceSensors.indexOf(4) == -1,
                  blackBackground: data.sequenceSensors.indexOf(4) > -1,
                }"
                (click)="addSequence(4)"
              >
                4
              </div>
            </div>
            <div class="four wide column"></div>
            <div class="four wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blueBackground: data.sequenceSensors.indexOf(5) == -1,
                  blackBackground: data.sequenceSensors.indexOf(5) > -1,
                }"
                (click)="addSequence(5)"
              >
                5
              </div>
            </div>
            <div class="four wide column"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="this.data.selectedExercise.type == 3">
        <div class="field select-field">
          <label class="big-label">Delay between arrows</label>
          <select class="exercise-form" formControlName="delay">
            <option *ngFor="let delay of delayArray" [ngValue]="delay">
              {{ delay | number: "1.2-2" }}
              Delay
            </option>
          </select>
        </div>

        <div class="field">
          <label class="big-label">Arrows</label>
          <div
            *ngIf="
              data.exerciseEditForm.controls['agilityCustomType'].hasError(
                'invalidArrows'
              )
            "
            class="ui left pointing blue label"
          >
            The sequence has to contain between 2 and 20 steps.
          </div>
          <div
            *ngIf="
              data.exerciseEditForm.controls['agilityCustomType'].hasError(
                'invalidActiveArrows'
              )
            "
            class="ui left pointing blue label"
          >
            At least one sensor must be active.
          </div>
          <div class="ui right floated buttons">
            <button
              type="button"
              class="ui left attached button"
              (click)="
                data.agilitySensors = [];
                data.exerciseEditForm.controls['agilityCustomType'].setValue(0)
              "
              [class.primary]="
                data.exerciseEditForm.controls['agilityCustomType'].value == 0
              "
            >
              Sequence
            </button>
            <button
              type="button"
              class="ui right attached button"
              (click)="
                data.agilitySensors = [];
                data.exerciseEditForm.controls['agilityCustomType'].setValue(1)
              "
              [class.primary]="
                data.exerciseEditForm.controls['agilityCustomType'].value == 1
              "
            >
              React
            </button>
          </div>
          <p
            *ngIf="
              data.exerciseEditForm.controls['agilityCustomType'].value == 0
            "
          >
            {{ data.agilitySensors.toString() }}
          </p>
          <div class="ui grid">
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(8),
                  blueBackground: arrowsColorCondition(8),
                }"
                (click)="addArrows(8)"
              >
                8
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(1),
                  blueBackground: arrowsColorCondition(1),
                }"
                (click)="addArrows(1)"
              >
                1
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(2),
                  blueBackground: arrowsColorCondition(2),
                }"
                (click)="addArrows(2)"
              >
                2
              </div>
            </div>

            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(7),
                  blueBackground: arrowsColorCondition(7),
                }"
                (click)="addArrows(7)"
              >
                7
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(9),
                  blueBackground: arrowsColorCondition(9),
                }"
                (click)="addArrows(9)"
              >
                9
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(3),
                  blueBackground: arrowsColorCondition(3),
                }"
                (click)="addArrows(3)"
              >
                3
              </div>
            </div>

            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(6),
                  blueBackground: arrowsColorCondition(6),
                }"
                (click)="addArrows(6)"
              >
                6
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(5),
                  blueBackground: arrowsColorCondition(5),
                }"
                (click)="addArrows(5)"
              >
                5
              </div>
            </div>
            <div class="five wide column">
              <div
                class="toggle-circle flex flex-center medium-text"
                [class]="{
                  blackBackground: !arrowsColorCondition(4),
                  blueBackground: arrowsColorCondition(4),
                }"
                (click)="addArrows(4)"
              >
                4
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="actions">
    <button type="button" class="ui button" (click)="dialogRef.close(false)">
      Close
    </button>
    <button
      type="button"
      class="ui button save-button"
      [disabled]="!data.exerciseEditForm.valid"
      (click)="dialogRef.close(true)"
    >
      Save Exercise
    </button>
  </div>
</div>
