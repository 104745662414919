import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {AthletesControllerService} from "../../../core/api/controllers/athletes-controller.service";

@Component({
  selector: "app-select-athlete-category",
  templateUrl: "./select-athlete-category.component.html",
  styleUrls: ["./select-athlete-category.component.scss"],
})
export class SelectAthleteCategoryComponent {
  @Input() _control: FormControl;
  @Input() manualLoad = false;
  @Input() categoryList: any[];

  constructor(private athletesController: AthletesControllerService) {}

  getCategories(): any {
    return this.athletesController.getCategories.bind(this.athletesController);
  }

  saveCategory(): any {
    return this.athletesController.saveCategory.bind(this.athletesController);
  }

  updateCategory(): any {
    return this.athletesController.updateCategory.bind(this.athletesController);
  }

  deleteCategory(): any {
    return this.athletesController.deleteCategory.bind(this.athletesController);
  }
}
