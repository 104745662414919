import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {CustomValidators} from "../../../../core/helpers/custom-validators";
import {AuthService} from "../../../../core/services/auth.service";
import {NotificationService} from "../../../../core/services/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {AccountControllerService} from "../../../../core/api/controllers/account-controller.service";
import {UsersControllerService} from "../../../../core/api/controllers/users-controller.service";
import {LocationsControllerService} from "../../../../core/api/controllers/locations-controller.service";

export interface IUserActionDialogData {
  action: string;
  currentUser?: any;
}

@Component({
  selector: "app-user-action",
  templateUrl: "./user-action-dialog.component.html",
  styleUrls: ["./user-action-dialog.component.scss"],
})
export class UserActionDialogComponent implements OnInit {
  userForm: FormGroup;
  selectedLocations = [];
  locations = [];
  type = 0;
  user: any;
  action: string;
  alreadyExists = 0;
  errorLocations = false;
  isLoading = false;

  constructor(
    private locationsController: LocationsControllerService,
    private usersController: UsersControllerService,
    private formBuilder: FormBuilder,
    private accountControllerService: AccountControllerService,
    private notificationService: NotificationService,
    public authService: AuthService,
    private dialogRef: MatDialogRef<UserActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IUserActionDialogData,
  ) {}

  ngOnInit() {
    this.action = this?.data?.action;
    this.user = this?.data?.currentUser;
    if (this.action == "Add") {
      this.userForm = this.formBuilder.group({
        firstName: ["", [Validators.required, CustomValidators.nameValidator]],
        lastName: ["", [Validators.required, CustomValidators.nameValidator]],
        username: [
          "",
          [
            Validators.required,
            CustomValidators.usernameValidator,
              CustomValidators.noWhitespaceValidator,
            Validators.minLength(7),
          ],
        ],
        email: ["", [Validators.required, CustomValidators.emailValidator]],
        type: [""],
        location: ["", Validators.required],
        isOrganizationAdmin: [false]
      });
    } else {
      this.userForm = this.formBuilder.group({
        firstName: ["", [Validators.required, CustomValidators.nameValidator]],
        lastName: ["", [Validators.required, CustomValidators.nameValidator]],
        email: ["", [Validators.required, Validators.email]],
        location: ["", Validators.required],
        isOrganizationAdmin: [false]
      });
    }

    if (this?.user?.firstName && this?.user?.firstName?.length > 0) {
      this.userForm.controls["firstName"].setValue(this.user?.firstName);
      this.userForm.controls["lastName"].setValue(this.user?.lastName);
      this.userForm.controls["email"].setValue(this.user?.email);
      this.userForm.controls["isOrganizationAdmin"].setValue(this.user?.isOrganizationAdmin)
    }

    this.locationsController.getAllLocations().subscribe((response) => {
      this.locations = response.locations;

      this.selectedLocations = [];

      this.user?.userLocations.forEach((value: { name: string }) => {
        const locationIndex = this.locations.findIndex(
          (location) => location.name == value.name,
        );
        if (locationIndex !== -1) {
          this.selectedLocations.push(this.locations[locationIndex]);
        }
      });

      this.userForm.controls["location"].setValue(this.selectedLocations);
    });
  }

  update() {
    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      return;
    }
    this.errorLocations = false;
    this.isLoading = true;
    const locationIds = [];
    this.userForm.controls["location"].value.forEach((value) => {
      locationIds.push(value.id);
    });
    if (locationIds.length == 0) {
      this.errorLocations = true;
      return;
    }
    if (this.data.action == "Edit") {
      this.usersController
        .editUser({
          id: this.user.id,
          firstName: this.userForm.controls["firstName"].value,
          lastName: this.userForm.controls["lastName"].value,
          email: this.userForm.controls["email"].value,
          isOrganizationAdmin: this.userForm.controls["isOrganizationAdmin"].value,
          locationIds,
        })
        .subscribe(
          () => {
            this.notificationService.success("User edited!");
            this.dialogRef.close(true);
          },
          ({ error }) => {
            this.isLoading = false;

            this.dialogRef.close(false);

            if (error.validationErrors && error.validationErrors.length) {
              error.validationErrors.forEach((err) => {
                this.notificationService.error(err);
              });
            } else if (error.error) {
              this.notificationService.error(error.error);
            }
          },
        );
    } else {
      this.usersController
        .addUser({
          firstName: this.userForm.controls["firstName"].value,
          lastName: this.userForm.controls["lastName"].value,
          email: this.userForm.controls["email"].value,
          username: this.userForm.controls["username"].value,
          isOrganizationAdmin: this.userForm.controls["isOrganizationAdmin"].value,
          locationIds,
        })
        .subscribe(
          () => {
            this.isLoading = false;
            this.notificationService.success("User added!");
            this.dialogRef.close(true);
          },
          ({ error }) => {
            this.isLoading = false;

            this.dialogRef.close(false);

            if (error.validationErrors && error.validationErrors.length) {
              error.validationErrors.forEach((err) => {
                this.notificationService.error(err);
              });
            } else if (error.error) {
              this.notificationService.error(error.error);
            }
          },
        );
    }
  }

  checkUsername() {
    this.accountControllerService
      .validUser(this.userForm.controls["username"].value)
      .then(() => {
        this.alreadyExists = 1;
      })
      .catch((error) => {
        error.status === 409
          ? (this.alreadyExists = -1)
          : this.notificationService.error(error);
      });
  }

  selectedLocationsNotNull() {
    return this.selectedLocations.length == 0;
  }

  checkingValue(): boolean {
    if (!this.userForm.valid && this.selectedLocations.length == 0) {
      return true;
    }
    return false;
  }

  get location(): FormControl {
    return this.userForm.get("location") as FormControl;
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
