import {Component, OnInit} from "@angular/core";
import {AuthService} from "../../core/services/auth.service";
import {LocationsService} from "../../core/services/locations.service";
import {AccountControllerService} from "../../core/api/controllers/account-controller.service";

@Component({
  selector: "app-no-location-whitelisted",
  templateUrl: "./no-location-whitelisted.component.html",
  styleUrls: ["./no-location-whitelisted.component.scss"],
})
export class NoLocationWhitelistedComponent implements OnInit {
  locations: any[] = [];
  isLoading = true;

  constructor(
    private authService: AuthService,
    private locationsService: LocationsService,
    private accountController: AccountControllerService,
  ) {}

  ngOnInit() {
    this.fetchLocations();
  }

  fetchLocations(): void {
    this.isLoading = true;
    this.accountController.getUserSettings().subscribe({
      next: (resp) => {
        this.locations = resp.locations;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  logout(): void {
    this.authService.logout();
  }
}
