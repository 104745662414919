import {Injectable} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ApiService} from "../api.service";

@Injectable({
  providedIn: "root",
})
export class PaymentControllerService {
  private readonly _resourceUrl: string = "/payment";

  constructor(private apiService: ApiService) {}

  attemptPayment(data: any): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/attempt-payment`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  connectQuickbooks(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}/connect-to-quickbooks`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteCreditCard(organizationId): Observable<any> {
    return this.apiService.delete(
      `${this._resourceUrl}/credit-card`,
      {
        organizationId,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }
}
