<div class="card p-3 mt-2 mt-sm-0 mr-sm-5 mb-2 w-full">
  <div *ngIf="!isLoading">
    <div class="mr-1">
      <button mat-flat-button color="primary" (click)="addUser()">
        Add User
      </button>
    </div>
    <div class="d-flex flex-column gap-5 py-5">
      <div
        *ngFor="let currentUser of users"
        class="flex-grow-1 d-flex align-items-center gap-3 border-bottom py-3"
      >
        <div
          class="d-flex justify-content-start align-items-center gap-3 w-[30%]"
        >
          <div id="profile-wrapper" class="flex flex-center">
            <div
              *ngIf="
                currentUser.profilePictureUrl !== null &&
                currentUser.profilePictureUrl !== ''
              "
              [style.background-image]="
                'url(' + currentUser?.profilePictureUrl + ')'
              "
              class="profile-picture"
            ></div>
          </div>

          <div>
            <div class="d-flex align-items-center">
              <div>
                <p class="grayish bold-text no-text-align no-bottom-margin">
                  {{ currentUser.firstName }} {{ currentUser.lastName }}
                </p>
                <p class="grayish no-text-align">
                  {{ currentUser.username }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-[50%]">
          <p>
            <span
              *ngFor="let location of currentUser.userLocations; let i = index"
            >
              {{ location.name
              }}<span *ngIf="i < currentUser.userLocations.length - 1">, </span>
            </span>
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-end w-[20%]">
          <button mat-button [matMenuTriggerFor]="menu">
            <i class="default text ellipsis horizontal icon zoomed"></i>
          </button>
          <mat-menu class="menu pushed-menu" #menu="matMenu">
            <button mat-menu-item (click)="editUser(currentUser)">Edit</button>
            <button mat-menu-item (click)="disableUser(currentUser)">
              Disable
            </button>
            <button mat-menu-item (click)="deleteUser(currentUser)">
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
