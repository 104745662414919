<mat-icon *ngIf="sensor === 1"> arrow_upward </mat-icon>

<mat-icon *ngIf="sensor === 2" class="rotate-45"> arrow_upward </mat-icon>

<mat-icon *ngIf="sensor === 3"> arrow_forward </mat-icon>

<mat-icon *ngIf="sensor === 4" class="-rotate-45"> arrow_downward </mat-icon>

<mat-icon *ngIf="sensor === 5"> arrow_downward </mat-icon>

<mat-icon *ngIf="sensor === 6" class="rotate-45"> arrow_downward </mat-icon>

<mat-icon *ngIf="sensor === 7"> arrow_backward </mat-icon>

<mat-icon *ngIf="sensor === 8" class="-rotate-45"> arrow_upward </mat-icon>

<div *ngIf="sensor === 9" class="w-[24px] h-[24px] bg-white"></div>
