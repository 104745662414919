<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100 mb-2 mb-sm-3 mb-md-4 mb-lg-5 p-3">
    <div [class]="{ toggled: !toggle }">
      <div class="d-flex justify-content-between">
        <!--        <a type="button" class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4" [routerLink]="'/save-' + global?.data?.workoutName">
          <i class="material-icons button-icon"> add </i>
        </a>-->

        <button
          *ngIf="selectedWorkouts.length"
          type="button"
          class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4"
          (click)="syncWorkout()"
        >
          sync selected
        </button>
      </div>
    </div>
  </div>

  <div class="card bg-white w-100">
    <div class="w-100" [class]="{ toggled: !toggle }">
      <div class="full-height" *ngIf="!workoutsLoaded">
        <div class="ui massive active text loader"></div>
      </div>

      <div
        class="results-container no-results flex flex-center"
        *ngIf="workoutList.length == 0 && workoutsLoaded"
      >
        <i class="material-icons no-data-icon"> assignment </i>
        <span class="h4">No {{ global?.data?.uppercaseWorkoutName }}s</span>
        <p class="error-text">
          There are no {{ global?.data?.workoutName }}s to show. Press the
          button on the right to create a new one.
        </p>
      </div>

      <div *ngIf="selectedIndex != null && workoutList.length > 0">
        <div
          *ngFor="let workout of workoutData[selectedIndex]?.workouts"
          class="inline-display full-width"
        >
          <div class="single-workout box-shadow-bottom ui grid">
            <div class="fourteen wide column inline-display workout-data">
              <p class="grayish element-name font-weight-bold">
                {{ workout.name }}
              </p>
              <p class="greyish" *ngIf="workout.playlists.length == 1">
                {{ workout.playlists.length }} playlist
              </p>
              <p class="greyish" *ngIf="workout.playlists.length != 1">
                {{ workout.playlists.length }} playlists
              </p>
            </div>

            <div class="one wide column flex flex-center">
              <div class="ui dropdown center-text button-dropdown" >
                <i class="default text ellipsis horizontal icon zoomed"></i>
                <div class="menu pushed-menu" >
                  <div
                    class="item"
                    routerLink="/save-{{
                      global?.data?.uppercaseWorkoutName
                    }}/{{ workout.id }}"
                  >
                    <i class="pencil alternate icon blue"></i>Edit
                    {{ global?.data?.uppercaseWorkoutName }}
                  </div>
                  <div class="item" (click)="deleteWorkout(workout.id)">
                    <i class="trash icon red"></i>Delete
                    {{ global?.data?.uppercaseWorkoutName }}
                  </div>
                </div>
              </div>
            </div>

            <div class="one wide column flex flex-center">
              <div class="workout-header">
                <button
                  type="button"
                  class="ui icon button button-dropdown zoomed"
                  (click)="workout.collapsed = !workout.collapsed"
                >
                  <i class="angle down icon" *ngIf="!workout.collapsed"></i>
                  <i class="angle up icon" *ngIf="workout.collapsed"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            *ngFor="let playlist of workout.playlists"
          >
            <div class="single-playlist ui grid box-shadow-bottom">
              <div class="fifteen wide column flex playlist-details">
                <div class="playlist-name">
                  <p class="font-weight-bold">{{ playlist.name }}</p>
                </div>
                <div class="selects flex">
                  <select
                    class="ui fluid selection disabled dropdown inline-display"
                    [(ngModel)]="playlist.type"
                    disabled
                  >
                    <option [ngValue]="0">Normal</option>
                    <option [ngValue]="1">Circuit</option>
                    <option [ngValue]="2">Automated</option>
                  </select>
                  <select
                    class="ui fluid selection disabled dropdown inline-display"
                    [(ngModel)]="playlist.circuitSets"
                    disabled
                  >
                    <option *ngFor="let set of possibleSets" [ngValue]="set">
                      {{ set }}
                    </option>
                  </select>
                  <select
                    class="ui fluid selection disabled dropdown inline-display"
                    [(ngModel)]="playlist.restTime"
                    disabled
                  >
                    <option
                      *ngFor="let time of possibleRestTime"
                      [ngValue]="time"
                    >
                      {{ time | secondsToTime }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="one wide right floated column flex flex-center">
                <div class="workout-header">
                  <button
                    type="button"
                    class="ui right floated icon button button-dropdown zoomed"
                    (click)="playlist.collapsed = !playlist.collapsed"
                  >
                    <i class="angle down icon" *ngIf="!playlist.collapsed"></i>
                    <i class="angle up icon" *ngIf="playlist.collapsed"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="full-width">
              <div
                class="single-exercise ui grid box-shadow-bottom"
                *ngFor="let exercise of playlist.exerciseList"
              >
                <div
                  class="select-field two wide computer two wide tablet two wide mobile column flex flex-center"
                >
                  <select
                    class="ui fluid selection disabled dropdown set-dropdown"
                    [(ngModel)]="exercise.sets"
                    disabled
                  >
                    <option *ngFor="let set of possibleSets" [ngValue]="set">
                      {{ set }} sets
                    </option>
                  </select>
                </div>
                <div
                  class="twelve wide computer ten wide tablet ten wide mobile column exercise-name"
                >
                  <p class="grayish element-name">
                    {{ exercise.name }}
                    <span class="small-text" *ngIf="exercise.paired === 2">
                      (Composed of: {{ exercise.firstExerciseName }} and
                      {{ exercise.secondExerciseName }})
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedIndex == null && workoutList.length > 0">
        <div *ngFor="let workout of workoutList" class="">
          <div class="">
            <div
              class="d-flex align-items-center justify-content-between border-bottom rounded"
            >
              <div class="d-flex align-items-center ml-2 p-2">
                <div *ngIf="isSuperAdmin()" class="mr-5">
                  <label class="check-container">
                    <input
                      type="checkbox"
                      [checked]="selectedWorkouts.indexOf(workout.id) > -1"
                      (click)="handleWorkoutSelection(workout.id)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div>
                  <p class="font-weight-bold text-uppercase">
                    {{ workout.name }}
                  </p>
                  <p class="mb-1" *ngIf="workout.playlists.length == 1">
                    {{ workout.playlists.length }} playlist
                  </p>
                  <p class="" *ngIf="workout.playlists.length != 1">
                    {{ workout.playlists.length }} playlists
                  </p>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <div class="p-4 border-left border-right">
                  <div
                    class="ui dropdown center-text button-dropdown"
                    suiDropdown
                  >
                    <i class="default text ellipsis horizontal icon zoomed"></i>
                    <div class="menu pushed-menu" suiDropdownMenu>
                      <div
                        *ngIf="isSuperAdmin()"
                        class="item"
                        (click)="syncWorkout(workout.id)"
                      >
                        Sync {{ global?.data?.uppercaseWorkoutName }}
                      </div>

                      <div
                        class="item"
                        routerLink="/save-{{ global?.data?.workoutName }}/{{
                          workout.id
                        }}"
                      >
                        Edit {{ global?.data?.uppercaseWorkoutName }}
                      </div>
                      <div class="item" (click)="deleteWorkout(workout.id)">
                        Delete {{ global?.data?.uppercaseWorkoutName }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ml-3 mr-3">
                  <div class="workout-header">
                    <div
                      class="pointer"
                      (click)="workout.collapsed = !workout.collapsed"
                    >
                      <i
                        class="angle down icon"
                        style="font-size: 20px"
                        *ngIf="!workout.collapsed"
                      ></i>
                      <i
                        class="angle up icon"
                        style="font-size: 20px"
                        *ngIf="workout.collapsed"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              [suiCollapse]="!workout.collapsed"
              *ngFor="let playlist of workout.playlists"
            >
              <div class="pt-3 pb-3 ml-2 p-2 border-bottom">
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="d-flex justify-content-between w-100 align-items-center flex-sm-row flex-column ml-sm-1"
                  >
                    <div class="ml-2 mb-4 mb-sm-0">
                      <p class="font-weight-bold">{{ playlist.name }}</p>
                      <p>{{ playlist.exerciseList.length }} exercises</p>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="disabled pl-2">
                        <select
                          class="disabled browser-default custom-select"
                          [(ngModel)]="playlist.type"
                          disabled
                        >
                          <option [ngValue]="0">Normal</option>
                          <option [ngValue]="1">Circuit</option>
                          <option [ngValue]="2">Automated</option>
                        </select>
                      </div>

                      <div class="disabled pl-2">
                        <select
                          class="disabled browser-default custom-select"
                          [(ngModel)]="playlist.circuitSets"
                          disabled
                        >
                          <option
                            *ngFor="let set of possibleSets"
                            [ngValue]="set"
                          >
                            {{ set }}
                          </option>
                        </select>
                      </div>

                      <div class="disabled pl-2">
                        <select
                          class="disabled browser-default custom-select"
                          [(ngModel)]="playlist.restTime"
                          disabled
                        >
                          <option
                            *ngFor="let time of possibleRestTime"
                            [ngValue]="time"
                          >
                            {{ time | secondsToTime }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="workout-header">
                      <div
                        class="border p-1 m-2 rounded pointer"
                        (click)="playlist.collapsed = !playlist.collapsed"
                      >
                        <i
                          class="angle down icon"
                          style="font-size: 20px"
                          *ngIf="!playlist.collapsed"
                        ></i>
                        <i
                          class="angle up icon"
                          style="font-size: 20px"
                          *ngIf="playlist.collapsed"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div [suiCollapse]="!playlist.collapsed" class="ml-3 pt-2">
                <div class="border-bottom">
                  <div
                    class="d-flex justify-content-start p-3 align-items-center"
                    *ngFor="let exercise of playlist.exerciseList"
                  >
                    <div class="">
                      <select
                        class="browser-default custom-select"
                        [(ngModel)]="exercise.sets"
                        disabled
                        style="width: 100px"
                      >
                        <option
                          *ngFor="let set of possibleSets"
                          [ngValue]="set"
                        >
                          {{ set }} sets
                        </option>
                      </select>
                    </div>

                    <div class="">
                      <p class="ml-3">
                        {{ exercise.name }}
                        <span class="small-text" *ngIf="exercise.paired === 2">
                          (Composed of: {{ exercise.firstExerciseName }} and
                          {{ exercise.secondExerciseName }})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="canLoadPagedWorkouts">
          <p (click)="getWorkouts()"><b class="load-more">Load more</b></p>
        </div>
      </div>
    </div>
  </div>
</div>
