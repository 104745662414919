<div class="absolute top-0 left-0 w-full flex items-center justify-center bg-black" style="z-index: 99999">
    <span class="text-white font-bold">
        You are currently using the new version of QuickBoard. If you have not yet migrated to our new platform, <a
            href="https://app.thequickboard.com" class="color-primary">click here</a> to continue using the old version of the website.
    </span>
</div>
<div
  class="bg-[#1A1A1C] text-[#C0C0C5] h-screen p-3 flex flex-col justify-between items-center w-full"
>
  <div class="flex justify-center items-center flex-grow max-w-[400px] w-full">
    <div
      class="w-full h-full flex flex-col gap-[6rem] justify-center items-center"
      style="max-width: 35rem"
    >
      <div class="w-[100px] h-auto">
        <img src="assets/images/qb_v3_logo.svg" />
      </div>
      <form
        *ngIf="!loggedIn"
        [formGroup]="loginForm"
        (ngSubmit)="login()"
        class="flex flex-col gap-5 w-full"
      >
        <div class="custom-form-field">
          <label for="loginUsername">Username</label>
          <input
            type="text"
            formControlName="loginUsername"
            id="loginUsername"
            placeholder="username"
          />
        </div>
        <div class="custom-form-field">
          <label for="loginPassword">Password</label>
          <input
            type="password"
            formControlName="loginPassword"
            id="loginPassword"
          />
        </div>
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center">
            <mat-checkbox
              formControlName="rememberMe"
              color="primary"
              class="[&_label]:!text-[#838388] border-[#C0C0C5]"
              >Remember Me</mat-checkbox
            >
          </div>
          <div>
            <a
              class="underline text-[#838388] hover:text-[#F5DC11] transition-all"
              routerLink="/forgot-password"
              >Forgot Password</a
            >
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <button
            type="submit"
            [disabled]="isLoading"
            class="!py-[16px] !px-[32px] rounded-2xl text-black bg-[#F5DC11] hover:bg-yellow-300 font-medium transition-all relative"
          >
            <span>Log in</span>
            <div class="absolute right-[25%] top-[50%] translate-y-[-50%]">
              <mat-spinner
                *ngIf="isLoading"
                diameter="30"
                color="accent"
              ></mat-spinner>
            </div>
          </button>
        </div>
      </form>
      <div
        class="col-md-5 col-md-offset-3 jumbotron centered_box w-full"
        *ngIf="loggedIn && tfa"
      >
        <form [formGroup]="codeForm">
          <div class="form-group">
            <label for="code" id="verification_code_label"
              >Google Authenticator Verification Code</label
            >
            <input
              type="text"
              [(ngModel)]="codeTry"
              class="form-control"
              formControlName="code"
              id="code"
              (focus)="codeForm.controls['code'].markAsUntouched()"
            />
            <div
              *ngIf="codeForm.controls['code'].hasError('pattern')"
              class="ui pointing red basic label"
            >
              Invalid code
            </div>
          </div>

          <div class="form-group">
            <button
              type="button"
              (click)="login2FA()"
              [disabled]="!codeForm.valid"
              class="btn btn-primary main_action_button"
            >
              Verify Code
            </button>
            <button
              type="button"
              (click)="logout()"
              class="btn btn-default back_button"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="flex gap-3 justify-center items-center">
    <p class="!m-0 text-[#838388]">Don`t have an account?</p>
    <a
      routerLink="/register"
      class="bg-[#3F3F41] text-white rounded-xl hover:bg-yellow-300 transition-all no-underline py-[10px] px-[24px]"
      >Sign up</a
    >
  </div>
</div>
