<div class="content p-5">
  <div class="ui form" [formGroup]="accountInformationForm">
    <div class="two fields column form-group">
      <div class="field">
        <label class="big-label">First name</label>
        <input
          class="form-control-register"
          type="text"
          formControlName="firstName"
          (focus)="
            accountInformationForm.controls['firstName'].markAsUntouched()
          "
          placeholder="First name"
        />
        <div
          *ngIf="
            !accountInformationForm.controls['firstName'].valid &&
            accountInformationForm.controls['firstName'].touched
          "
          class="ui pointing red basic label"
        >
          First name must not be empty and cannot contain special characters.
        </div>
      </div>

      <div class="field">
        <label class="big-label">Last name</label>
        <input
          class="form-control-register"
          type="text"
          formControlName="lastName"
          (focus)="
            accountInformationForm.controls['lastName'].markAsUntouched()
          "
          placeholder="Last name"
        />
        <div
          *ngIf="
            !accountInformationForm.controls['lastName'].valid &&
            accountInformationForm.controls['lastName'].touched
          "
          class="ui pointing red basic label"
        >
          Last name must not be empty and cannot contain special characters.
        </div>
      </div>
    </div>

    <div class="two fields column form-group">
      <div class="field">
        <label class="inline-display big-label">Organization Name</label>
        <input
          class="form-control-register"
          type="text"
          placeholder="Organization name"
          (focus)="
            accountInformationForm.controls[
              'organizationName'
            ].markAsUntouched()
          "
          formControlName="organizationName"
        />
        <div
          *ngIf="
            accountInformationForm.controls['organizationName'].hasError(
              'required'
            ) && accountInformationForm.controls['organizationName'].touched
          "
          class="ui pointing red basic label"
        >
          Organization name must not be empty.
        </div>
      </div>

      <div class="field">
        <label class="big-label right-pushed">Phone number</label>
        <input
          class="form-control-register"
          type="tel"
          placeholder="Phone number"
          formControlName="phone"
          (focus)="accountInformationForm.controls['phone'].markAsUntouched()"
        />
        <div
          *ngIf="
            !accountInformationForm.controls['phone'].valid &&
            accountInformationForm.controls['phone'].touched
          "
          class="ui pointing red basic label"
        >
          Phone number should only contain numbers, spaces and ( , ) , + or - .
        </div>
      </div>
    </div>

    <div class="two fields column form-group">
      <div class="field">
        <label class="inline-display big-label">Email</label>
        <input
          class="form-control-register"
          type="text"
          placeholder="Email"
          formControlName="email"
        />
      </div>
    </div>

    <div class="nav nav-tabs mt-5" id="buttons-container">
      <li class="nav-item">
        <a
          class="nav-link pointer p-3 text-uppercase font-weight-bold"
          (click)="addressSelected = 0"
          [class]="{ active: addressSelected == 0 }"
        >
          Shipping
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link pointer p-3 text-uppercase font-weight-bold"
          (click)="addressSelected = 1"
          [class]="{ active: addressSelected == 1 }"
        >
          Billing
        </a>
      </li>
    </div>

    <ng-container *ngIf="addressSelected == 0">
      <h4 class="ui huge centered header medium-font-weight">
        Shipping Address
      </h4>
      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">Country</label>
          <select
            class="form-control-register"
            formControlName="shippingCountry"
            (change)="countryHasChanged(shippingObject)"
          >
            <option
              *ngFor="let country of shippingObject.countries"
              value="{{ country?.id }}"
            >
              {{ country?.name }}
            </option>
          </select>
        </div>

        <div class="field">
          <label class="big-label right-pushed">State/Province</label>
          <select
            class="form-control-register"
            formControlName="shippingState"
            (change)="stateHasChanged($event)"
          >
            <option
              *ngFor="let state of shippingObject.states"
              value="{{ state?.id }}"
            >
              {{ state?.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">City</label>

          <!--          <sui-select
            class="selection form-control-register"
            formControlName="shippingCity"
            (keyup)="accountInformationForm.controls['shippingCity'].setValue($event.srcElement.value); newShippingCity = true"
            [options]="shippingObject.typeahead"
            labelField="name"
            valueField="id"
            [isSearchable]="true"
            #shippingCityRef
            (click)="accountInformationForm.controls['shippingCity'].markAsUntouched()">
            <sui-select-option *ngFor="let o of shippingCityRef.filteredOptions" [value]="o"></sui-select-option>
          </sui-select>-->
          <div
            *ngIf="
              !accountInformationForm.controls['shippingCity'].valid &&
              accountInformationForm.controls['shippingCity'].touched
            "
            class="ui pointing red basic label"
          >
            A city must be entered.
          </div>
        </div>

        <div class="field">
          <label class="big-label">Address</label>
          <input
            class="form-control-register"
            type="text"
            formControlName="shippingAddress"
            placeholder="Address"
            (focus)="
              accountInformationForm.controls[
                'shippingAddress'
              ].markAsUntouched()
            "
          />
          <div
            *ngIf="
              !accountInformationForm.controls['shippingAddress'].valid &&
              accountInformationForm.controls['shippingAddress'].touched
            "
            class="ui pointing red basic label"
          >
            Address cannot be empty.
          </div>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label inline-display">Address 2</label
          ><label
            class="right-floated inline-display optional-text-right right-pushed"
            >Optional</label
          >
          <input
            class="form-control-register"
            type="text"
            formControlName="shippingAddress2"
            placeholder="Address"
          />
        </div>

        <div class="field">
          <label class="big-label inline-display right-pushed"
            >Postal code / Zipcode</label
          ><label
            class="right-floated inline-display optional-text-right right-pushed"
            >Optional</label
          >
          <input
            class="form-control-register"
            type="text"
            placeholder="Zip code"
            formControlName="shippingZipCode"
          />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="addressSelected == 1">
      <h4 class="ui huge centered header medium-font-weight">
        Billing Address
      </h4>
      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">Country</label>
          <select
            class="form-control-register"
            formControlName="billingCountry"
            (change)="countryHasChanged(billingObject)"
          >
            <option
              *ngFor="let country of billingObject.countries"
              value="{{ country?.id }}"
            >
              {{ country?.name }}
            </option>
          </select>
        </div>

        <div class="field">
          <label class="big-label right-pushed">State/Province</label>
          <select
            class="form-control-register"
            formControlName="billingState"
            (change)="stateHasChanged($event, true)"
          >
            <option
              *ngFor="let state of billingObject.states"
              value="{{ state?.id }}"
            >
              {{ state?.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label">City</label>
          <!--
          <sui-select
            class="selection form-control-register"
            formControlName="billingCity"
            (keyup)="accountInformationForm.controls['billingCity'].setValue($event.srcElement.value); newBillingCity = true"
            [options]="billingObject.typeahead"
            labelField="name"
            valueField="id"
            [isSearchable]="true"
            #billingCityRef
            (click)="accountInformationForm.controls['billingCity'].markAsUntouched()">
            <sui-select-option *ngFor="let o of billingCityRef.filteredOptions" [value]="o"></sui-select-option>
          </sui-select>-->
          <div
            *ngIf="
              !accountInformationForm.controls['billingCity'].valid &&
              accountInformationForm.controls['billingCity'].touched
            "
            class="ui pointing red basic label"
          >
            A city must be entered.
          </div>
        </div>

        <div class="field">
          <label class="big-label">Address</label>
          <input
            class="form-control-register"
            type="text"
            formControlName="billingAddress"
            placeholder="Address"
            (focus)="
              accountInformationForm.controls[
                'billingAddress'
              ].markAsUntouched()
            "
          />
          <div
            *ngIf="
              !accountInformationForm.controls['billingAddress'].valid &&
              accountInformationForm.controls['billingAddress'].touched
            "
            class="ui pointing red basic label"
          >
            Address cannot be empty.
          </div>
        </div>
      </div>

      <div class="two fields column form-group">
        <div class="field">
          <label class="big-label inline-display">Address 2</label
          ><label
            class="right-floated inline-display optional-text-right right-pushed"
            >Optional</label
          >
          <input
            class="form-control-register"
            type="text"
            formControlName="billingAddress2"
            placeholder="Address"
          />
        </div>

        <div class="field">
          <label class="big-label inline-display right-pushed"
            >Postal code / Zipcode</label
          ><label
            class="right-floated inline-display optional-text-right right-pushed"
            >Optional</label
          >
          <input
            class="form-control-register"
            type="text"
            placeholder="Zip code"
            formControlName="billingZipCode"
          />
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="actions">
  <button type="button" class="ui button" (click)="dialogRef.close(false)">
    Close
  </button>
  <!-- <button type="button" class="primary ui button" (click)="onSelect(accountInformationForm)">select</button> -->
  <button
    type="button"
    class="primary ui button"
    [disabled]="!accountInformationForm.valid"
    (click)="changeAccountInformation()"
  >
    Update
  </button>
</div>
