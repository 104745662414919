<form [formGroup]="_formGroup">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Jump Type</mat-label>
    <mat-select
      placeholder="Jump Type"
      [formControl]="verticalJumpType"
      [value]="verticalJumpType.value"
    >
      <mat-option [value]="EExerciseJumpType.DROP_JUMP">Drop Jump</mat-option>
      <mat-option [value]="EExerciseJumpType.VERTICAL">Vertical</mat-option>
    </mat-select>
    <mat-error *ngIf="ValidationHelperService.isInvalid(verticalJumpType)">
      {{ ValidationHelperService.getErrorMessage(verticalJumpType) }}
    </mat-error>
  </mat-form-field>
    <div class="grid gap-5"
         [class.grid-cols-3]="_formGroup.controls.verticalJumpType.value !== EExerciseJumpType.DROP_JUMP"
         [class.grid-cols-2]="_formGroup.controls.verticalJumpType.value === EExerciseJumpType.DROP_JUMP"
    >
        <mat-form-field appearance="outline">
            <mat-label>Jump With</mat-label>
            <mat-select
                    placeholder="Jump With"
                    [formControl]="numberOfLegs"
                    [value]="numberOfLegs.value"
            >
                <mat-option [value]="1">1 Leg</mat-option>
                <mat-option [value]="2">2 Legs</mat-option>
            </mat-select>
            <mat-error *ngIf="ValidationHelperService.isInvalid(numberOfLegs)">
                {{ ValidationHelperService.getErrorMessage(numberOfLegs) }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="_formGroup.controls.verticalJumpType.value !== EExerciseJumpType.DROP_JUMP">
            <mat-label>Vertical React</mat-label>
            <mat-select
                    placeholder="Vertical React"
                    [formControl]="_formGroup.controls.isVerticalReact"
                    [value]="_formGroup.controls.isVerticalReact"
            >
                <mat-option [value]="false">Off</mat-option>
                <mat-option [value]="true">On</mat-option>
            </mat-select>
            <mat-error *ngIf="ValidationHelperService.isInvalid(_formGroup.controls.isVerticalReact)">
                {{ ValidationHelperService.getErrorMessage(_formGroup.controls.isVerticalReact) }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Number of Jumps</mat-label>
            <input
                    type="number"
                    [formControl]="numberOfJumps"
                    matInput
                    step="1"
                    [value]="numberOfJumps.value"
            />
            <mat-error *ngIf="ValidationHelperService.isInvalid(numberOfJumps)">
                {{ ValidationHelperService.getErrorMessage(numberOfJumps) }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class=" pb-8">
        <div class="w-100 d-flex !cursor-default" *ngIf="_formGroup.controls.isVerticalReact.value && _formGroup.controls.verticalJumpType.value !== EExerciseJumpType.DROP_JUMP">
            <div class="w-50">
                <label class="w-100">React</label>

                <div>
                    <div
                            class="react-option green-background"
                            [class.full-opacity]="react[1]"
                            (click)="setReact(1, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[1]"
                        >done</i
                        >
                    </div>

                    <div
                            class="react-option red-background"
                            [class.full-opacity]="react[2]"
                            (click)="setReact(2, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[2]"
                        >done</i
                        >
                    </div>

                    <div
                            class="react-option blue-background"
                            [class.full-opacity]="react[3]"
                            (click)="setReact(3, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[3]"
                        >done</i
                        >
                    </div>

                    <div
                            class="react-option orange-background"
                            [class.full-opacity]="react[4]"
                            (click)="setReact(4, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[4]"
                        >done</i
                        >
                    </div>

                    <div
                            class="react-option violet-background"
                            [class.full-opacity]="react[5]"
                            (click)="setReact(5, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[5]"
                        >done</i
                        >
                    </div>

                    <div
                            class="react-option bg-white"
                            [class.full-opacity]="react[6]"
                            (click)="setReact(6, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon !text-black" *ngIf="react[6]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option bg-[#C0C0C0]"
                            [class.full-opacity]="react[7]"
                            (click)="setReact(7, 1)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="react[7]"
                        >done</i
                        >
                    </div>
                </div>
            </div>
            <div
                    class="w-50"
            >
                <label class="w-100">Don't react</label>
                <div>
                    <div
                            class="react-option green-background"
                            [class.full-opacity]="dontReact[1]"
                            (click)="setReact(1, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[1]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option red-background"
                            [class.full-opacity]="dontReact[2]"
                            (click)="setReact(2, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[2]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option blue-background"
                            [class.full-opacity]="dontReact[3]"
                            (click)="setReact(3, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[3]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option orange-background"
                            [class.full-opacity]="dontReact[4]"
                            (click)="setReact(4, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[4]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option violet-background"
                            [class.full-opacity]="dontReact[5]"
                            (click)="setReact(5, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[5]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option bg-white"
                            [class.full-opacity]="dontReact[6]"
                            (click)="setReact(6, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon !text-black" *ngIf="dontReact[6]"
                        >done</i
                        >
                    </div>
                    <div
                            class="react-option bg-[#C0C0C0]"
                            [class.full-opacity]="dontReact[7]"
                            (click)="setReact(7, 2)"
                            matRipple
                    >
                        <i class="material-icons react-overlay-icon" *ngIf="dontReact[7]"
                        >done</i
                        >
                    </div>
                </div>
            </div>
        </div>
        <mat-error *ngIf="ValidationHelperService.isInvalid(_formGroup.controls.reactTo)">
            {{ ValidationHelperService.getErrorMessage(_formGroup.controls.reactTo) }}
        </mat-error>
    </div>

</form>
