import {Component, Inject, OnInit} from "@angular/core";
import {FormGroup,} from "@angular/forms";
import {AuthService} from "../../../../core/services/auth.service";
import {NotificationService} from "../../../../core/services/notification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {AccountControllerService} from "../../../../core/api/controllers/account-controller.service";
import {UsersControllerService} from "../../../../core/api/controllers/users-controller.service";
import {LocationsControllerService} from "../../../../core/api/controllers/locations-controller.service";
import {IUserForm, IUserFormFactory} from "./IUserFormFactory";
import {EAction} from "../../../../shared/enums/EAction";
import {ILocation} from "../../../../shared/interfaces/ILocation";
import {IEditUserReq} from "../../../../shared/interfaces/DTOs/Request/IEditUserReq";
import {ICreateUserReq} from "../../../../shared/interfaces/DTOs/Request/ICreateUserReq";

export interface IUserActionDialogData {
  action: EAction;
  currentUser?: any;
}

@Component({
  selector: "app-user-action",
  templateUrl: "./user-action-dialog.component.html",
  styleUrls: ["./user-action-dialog.component.scss"],
})
export class UserActionDialogComponent implements OnInit {
  userForm: FormGroup<IUserForm>;
  selectedLocations = [];
  locations: ILocation[] = [];
  type = 0;
  user: any;
  action: EAction;
  alreadyExists = 0;
  errorLocations = false;
  isLoading = false;

  constructor(
    private locationsController: LocationsControllerService,
    private usersController: UsersControllerService,
    private accountControllerService: AccountControllerService,
    private notificationService: NotificationService,
    public authService: AuthService,
    private dialogRef: MatDialogRef<UserActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IUserActionDialogData,
  ) {}

  ngOnInit() {
    this.action = this?.data?.action;
    this.user = this?.data?.currentUser;

    this.userForm = IUserFormFactory.create(this.action);

    if (this?.user?.firstName && this?.user?.firstName?.length > 0) {
      this.userForm.controls.firstName.setValue(this.user?.firstName);
      this.userForm.controls.lastName.setValue(this.user?.lastName);
      this.userForm.controls.email.setValue(this.user?.email);
      this.userForm.controls.isOrganizationAdmin.setValue(this.user?.isOrganizationAdmin)
    }

    this.locationsController.getAllLocations().subscribe((response) => {
      this.locations = response.locations;

      this.selectedLocations = [];

      this.user?.userLocations.forEach((value: { name: string }) => {
        const locationIndex = this.locations.findIndex(
          (location) => location.name == value.name,
        );
        if (locationIndex !== -1) {
          this.selectedLocations.push(this.locations[locationIndex]);
        }
      });

      this.userForm.controls["locations"].setValue(this.selectedLocations);
    });
  }

  onSubmit() {
    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      return;
    }
    
    if (this.userForm.controls.locations.value.length === 0) {
      this.userForm.controls.locations.setErrors({
        minlength: true
      });
      return;
    }

    if (this.data.action === EAction.Edit) this.editUser();
    else this.createUser();
  }

  editUserPayload(): IEditUserReq {
    const locationIds = [];
    this.userForm.controls.locations.value.forEach((value) => {
      locationIds.push(value.id);
    });
    return {
      id: this.user.id,
      firstName: this.userForm.controls.firstName.value,
      lastName: this.userForm.controls.lastName.value,
      email: this.userForm.controls.email.value,
      isOrganizationAdmin: this.userForm.controls.isOrganizationAdmin.value,
      locationIds,
    };
  }

  createUserPayload(): ICreateUserReq {
    const locationIds = [];
    this.userForm.controls.locations.value.forEach((value) => {
      locationIds.push(value.id);
    });
    return {
      firstName: this.userForm.controls.firstName.value,
      lastName: this.userForm.controls.lastName.value,
      email: this.userForm.controls.email.value,
      username: this.userForm.controls.username.value,
      isOrganizationAdmin: this.userForm.controls.isOrganizationAdmin.value,
      locationIds,
    }
  }

  createUser(): void {
    this.isLoading = true;
    this.usersController
        .addUser(this.createUserPayload())
        .subscribe(
            () => {
              this.isLoading = false;
              this.notificationService.success("User added!");
              this.dialogRef.close(true);
            },
            ({ error }) => {
              this.isLoading = false;

              this.dialogRef.close(false);

              if (error.validationErrors && error.validationErrors.length) {
                error.validationErrors.forEach((err) => {
                  this.notificationService.error(err);
                });
              } else if (error.error) {
                this.notificationService.error(error.error);
              }
            },
        );
  }


  editUser(): void {
    this.isLoading = true;
    this.usersController
        .editUser(this.editUserPayload())
        .subscribe(
            () => {
              this.notificationService.success("User edited!");
              this.dialogRef.close(true);
            },
            ({ error }) => {
              this.isLoading = false;

              this.dialogRef.close(false);

              if (error.validationErrors && error.validationErrors.length) {
                error.validationErrors.forEach((err) => {
                  this.notificationService.error(err);
                });
              } else if (error.error) {
                this.notificationService.error(error.error);
              }
            },
        );
  }

  checkUsername() {
    this.accountControllerService
      .validUser(this.userForm.controls["username"].value)
      .then(() => {
        this.alreadyExists = 1;
      })
      .catch((error) => {
        error.status === 409
          ? (this.alreadyExists = -1)
          : this.notificationService.error(error);
      });
  }

  protected readonly ValidationHelperService = ValidationHelperService;
  protected readonly EAction = EAction;
}
