import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {BehaviorSubject, EMPTY, Observable, throwError} from "rxjs";
import {catchError, filter, map, switchMap, take,} from "rxjs/internal/operators";
import {AuthService} from "../../core/services/auth.service";
import {AuthControllerService} from "../../core/api/controllers/auth-controller.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  jwtHelper: JwtHelperService;
  static failedRefreshAttempts = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authController: AuthControllerService,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set("Api-Version", "3.0"),
    });
    return next.handle(request).pipe(
      catchError((response) => {
          if(response.status === 503) {
              this.router.navigate(["maintanance"], {
                  skipLocationChange: true,
              });
              return EMPTY;
          }
        if (response.status === 401) {
          this.isRefreshingToken = true;
          return this.refreshToken().pipe(
            map((token_data) => {
              localStorage.setItem("token", token_data["accessToken"]);
              localStorage.setItem("refresh_token", token_data["refreshToken"]);
              this.tokenSubject.next("done");
              return this.reformHeaders(request);
            }),
            switchMap((req) => {
              return next.handle(req);
            }),
          );
        } else if (
          response.status === 406 &&
          response.error.error.includes("Ip is not part of whitelist.")
        ) {
          // Means the user is not allowed to access the resource because the location is not whitelisted
          this.router.navigate(["no-location-allowed"], {
            skipLocationChange: true,
          });
        } else if (!(response instanceof HttpErrorResponse)) {
          return throwError(response);
        } else if (request.url.indexOf("logout") > -1) {
          AuthService.clearStorageData();
          this.router.navigate(["/login"]);
          return EMPTY;
        } else if (
          (response.error.error === "invalid_grant" &&
            response.error.error_description.indexOf("Invalid refresh token") >
              -1) ||
          (response.status === 400 && response.error.error.includes("token(s)"))
        ) {
          this.authService.logout();
          return EMPTY;
        } else if (
          request.headers.has("Authorization") &&
          this.jwtHelper.isTokenExpired(
            AuthService.getOauthTokenFromStorage(),
          ) &&
          response.status !== 404
        ) {
          return EMPTY;
        } else if (this.isRefreshingToken) {
          return this.tokenSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token) => {
              return next.handle(this.reformHeaders(request));
            }),
          );
        } else if (response.status !== 401) {
          return throwError(response);
        }
      }),
    );
  }

  refreshToken(): Observable<any> {
    const body = {
      accessToken: AuthService.getOauthTokenFromStorage(),
      refreshToken: AuthService.getOauthRefreshTokenFromStorage(),
    };
    return this.authController.refresh(body);
  }

  reformHeaders(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: new HttpHeaders()
        .set(
          "Authorization",
          "Bearer " + AuthService.getOauthTokenFromStorage(),
        )
        .set("Api-Version", "3.0"),
    });
  }
}
