import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {EAction} from "../../../shared/enums/EAction";
import {ValidationHelperService} from "../../../core/helpers/validation-helper.service";
import {IPairedExerciseForm} from "../../../shared/interfaces/forms/exercises/IPairedExerciseForm";
import {IRegularExerciseForm} from "../../../shared/interfaces/forms/exercises/IRegularExerciseForm";
import {PairedExerciseHelper} from "../../../core/forms/helpers/PairedExerciseHelper";
import {NotificationService} from "../../../core/services/notification.service";
import {IPairedExercise, IPairedExerciseConfig} from "../save-paired-exercise/save-paired-exercise.component";
import {ExerciseHelper} from "../../../core/helpers/exercise.helper";

@Component({
  selector: "app-paired-exercise-form",
  templateUrl: "./paired-exercise-form.component.html",
  styleUrls: ["./paired-exercise-form.component.scss"],
})
export class PairedExerciseFormComponent implements OnInit {
  @Input() _formGroup: FormGroup<IPairedExerciseForm>;
  @Input() action: EAction;
  @Input() exercise: IPairedExercise;
  @Output() _submit = new EventEmitter();

  configFormGroup = new FormGroup({
    selectedConfig: new FormControl(),
  });

  protected readonly ValidationHelperService = ValidationHelperService;

  constructor(private notificationService: NotificationService, private exerciseHelper: ExerciseHelper) {}

  ngOnInit(): void {
    if(this.action === EAction.Edit) {
      this.configFormGroup.controls.selectedConfig.setValue(this.exercise.pairedExerciseConfigs.find(config => config.id === this.exercise.defaultConfigId));
      this.configFormGroup.controls.selectedConfig.valueChanges.subscribe((data) => {
        this.exercise.defaultConfigId = data.id;
        PairedExerciseHelper.patchFormConfig(this._formGroup, this.exercise);
      })
    }
  }

  submitForm() {
    if (!PairedExerciseHelper.isFormValid(this._formGroup)) {
      this._formGroup.markAllAsTouched();
      this.notificationService.error("The form is invalid. Please check.");
      return;
    } else {
      this._submit.emit(PairedExerciseHelper.createPayload(this._formGroup));
    }
  }

  getConfigTitle(config: IPairedExerciseConfig): string {
      const firstExerciseConfig = this.exercise.firstExercise.configs.find(c => c.id === config.firstConfigId);
      const secondExerciseConfig = this.exercise.secondExercise.configs.find(c => c.id === config.secondConfigId);
      return `${this.exercise.firstExercise.name} | ${this.exerciseHelper.getConfigTitle(this.exercise.type, firstExerciseConfig)} \n | ${this.exercise.secondExercise.name} | ${this.exerciseHelper.getConfigTitle(this.exercise.type, secondExerciseConfig)}`
  }

  get firstExercise(): FormGroup<IRegularExerciseForm> {
    return this._formGroup.controls["firstExercise"] as FormGroup;
  }

  get secondExercise(): FormGroup<IRegularExerciseForm> {
    return this._formGroup.controls["secondExercise"] as FormGroup;
  }

  get type(): FormControl {
    return this._formGroup.controls["type"] as FormControl;
  }

  get name(): FormControl {
    return this._formGroup.controls["name"] as FormControl;
  }

  get category(): FormControl {
    return this._formGroup.controls["category"] as FormControl;
  }

  protected readonly EAction = EAction;
}
