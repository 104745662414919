import {Pipe, PipeTransform} from "@angular/core";
import {EExerciseType} from "../enums/EExerciseType";

@Pipe({
  name: "endType",
})
export class EndTypePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    const exerciseType: EExerciseType = args[0] as EExerciseType;
    if(value === 1) return "seconds";
    else if(exerciseType === EExerciseType.Agility) return "arrows";
    else return "touches";
  }
}
