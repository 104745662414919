<div class="card p-3">
    <div *ngIf="!isLoading">
        <div class="relative overflow-x-auto" *ngIf="invoices.length > 0">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Invoice name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Date
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr class="bg-white border-b cursor-pointer" *ngFor="let invoice of invoices" (click)="openInvoice(invoice.url)" >
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {{invoice.name}}
                    </th>
                    <td class="px-6 py-4">
                        {{invoice.date | date}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="invoices.length === 0" class="flex w-full h-[400px] justify-center items-center">
            <div class="p-3 text-center">
                <i class="material-icons timer-icon text-center mx-auto d-block alt-color-text">description</i>
                <span class="h4 alt-color-text">No invoices</span>
                <p class="alt-color-text">
                    There are no invoices to show.
                </p>
            </div>
        </div>
    </div>
  <div class="h-[600px] flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
