import {Component, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ETransactionType} from "../../../shared/enums/ETransactionType";
import {GlobalPreferences} from "../../../core/helpers/global-data";
import {AdminControllerService} from "../../../core/api/controllers/admin-controller.service";
import {PaymentHistoryFormFactory} from "./PaymentHistoryFormFactory";
import {PaymentHistoryFormType, PaymentHistoryFormValue} from "./PaymentHistoryFormType";

type TransactionHystoryFiltersType = {pagination: {page: number, itemsPerPage: number}} & PaymentHistoryFormValue

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  isLoading = false;
  paymentHistoryForm: FormGroup<PaymentHistoryFormType>;

  filters: TransactionHystoryFiltersType = { pagination: { page: 0, itemsPerPage: 20 } } as TransactionHystoryFiltersType;
  defaultFilters: TransactionHystoryFiltersType = { pagination: { page: 0, itemsPerPage: 20 } } as TransactionHystoryFiltersType;
  transactions = [];
  totalPages = 0;
  currentPage = 0;
  transactionType = ETransactionType;
  transactionTypeValues = ["Full Purchase", "Subscription"];

  constructor(
    private adminController: AdminControllerService,
    public global: GlobalPreferences,
    private formFactory: PaymentHistoryFormFactory
  ) {
      this.paymentHistoryForm = this.formFactory.create();
  }

  ngOnInit(): void {
    this.handlePaymentHistoryResetForm();
  }

  handlePaymentHistoryFilters() {
    this.filters = {
      ...this.filters,
      ...this.paymentHistoryForm.value
    } as TransactionHystoryFiltersType;

    this.fetchPaymentHistory();
  }

  handlePaymentHistoryResetForm() {
    this.paymentHistoryForm.reset();
    this.filters = this.defaultFilters;
    this.currentPage = 0
    this.fetchPaymentHistory();
  }

  fetchPaymentHistory() {
    this.isLoading = true;
    const gmtOffset = this.global.getTimezoneOffset();

    this.isLoading = true;
    this.adminController
      .getPaymentHistory({ ...this.filters, gmtOffset })
      .subscribe(({ data, totalCount }: any) => {
        this.transactions = data;
        this.totalPages = totalCount;
        this.isLoading = false;
      });
  }

  handlePaymentHistoryPageSelect(page) {
    this.filters.pagination = {
      ...this.filters.pagination,
      page,
    };

    this.fetchPaymentHistory();
  }

  handlePaymentHistoryPageChange(page) {
    this.currentPage = page;
    this.handlePaymentHistoryPageSelect(page);
  }
}
