<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="full-height" *ngIf="!organizationsLoaded">
      <mat-spinner diameter="30"></mat-spinner>
  </div>
  <div class="w-100">
    <div class="card w-100 p-3 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <div class="d-flex justify-content-between">
        <div class="align-self-center">
          <button
            *ngIf="!updateVideos"
            mat-flat-button
            color="primary"
            disabled
            (click)="updateVideos ? handleVideoUpdate() : addExerciseToAll()"
          >
            {{ updateVideos ? "Update Videos" : "Add Exercise to All" }}
          </button>
        </div>
        <div class="ui search align-self-center">
          <mat-form-field appearance="outline">
            <mat-label>Search for organization name</mat-label>
            <input
              (keyup)="onSearch(searchText)"
              [(ngModel)]="searchText"
              matInput
              #message
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="card w-100">
      <div class="" *ngIf="showData">
        <div
          *ngFor="let org of organizations; let i = index"
          class="inline-display full-width org-container"
        >
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title
                  appHighlight
                  [searchText]="searchText"
                  [orgName]="org.name"
                >
                  {{ org.name }}
                </mat-panel-title>
                <mat-panel-description
                  align="right"
                  *ngIf="org.locations.length === 1"
                >
                  {{ org.locations.length }} location
                </mat-panel-description>
                <mat-panel-description *ngIf="org.locations.length !== 1">
                  {{ org.locations.length }} locations
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div *ngFor="let loc of org.locations; let j = index">
                <div class="location-row mb-3">
                  <p class="location-name bold-text">
                    {{ loc.name }}
                    <span *ngIf="loc.foundUsernames"
                      >(
                      <span *ngFor="let user of loc.foundUsernames">
                        <span
                          appHighlight
                          [searchText]="searchText"
                          [orgName]="user"
                        >
                          {{ user }}
                        </span>

                        <span>, </span>
                      </span>
                      )</span
                    >
                  </p>

                  <div class="playlist-name" *ngIf="loc.exerciseSynced">
                    <button
                      disabled
                      class="qb-blue-background primary white-text ui button inline-display"
                    >
                      Syncronized
                    </button>
                  </div>
                  <div class="playlist-name" *ngIf="!loc.exerciseSynced">
                    <button
                      (click)="
                        updateVideos
                          ? handleVideoUpdate(loc.id, i, j)
                          : syncExercise(loc, i, j)
                      "
                      [disabled]="organizations[i].locations[j].synced"
                      mat-flat-button
                      color="primary"
                    >
                      {{ updateVideos ? "Update Video" : "Add Exercise" }}
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="d-flex flex-row-reverse" *ngIf="canLoadOrganizations">
          <button
            mat-flat-button
            color="primary"
            class="qb-blue-background primary white-text ui button inline-display mt-2"
            (click)="getOrganizations()"
          >
            Load More
          </button>
        </div>
      </div>
      <div
        class="card w-100"
        *ngIf="organizationsLoaded && organizations.length == 0"
      >
        <span class="h4 p-3 alt-color-text"
          >No Organizations found. Please search for another organization
          name</span
        >
      </div>
    </div>
  </div>
</div>
