<div class="flex flex-col gap-3 max-w-[1200px] h-full p-[3rem] mx-auto">
    <div class="flex justify-between items-center border-b py-3">
        <div class="flex items-center justify-center gap-3">
            <h2 class="text-4xl !m-0">Sports</h2>
            <button mat-flat-button color="primary" class="flex justify-center items-center gap-3" (click)="openCreateSportDialog()">
                <mat-icon>add</mat-icon>
                <span>Add sport</span>
            </button>
        </div>
        <div class="flex gap-3 items-center justify-center">
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Search</mat-label>
                <input type="text" matInput [(ngModel)]="searchField" (ngModelChange)="search()">
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!isLoading" class="flex flex-col gap-5 py-8">
        <div *ngFor="let sport of sports" class="grid grid-cols-2 gap-3 items-center border-b p-3 ">
            <div>
                <p class="!m-0 font-medium text-lg">{{sport.name}}</p>
            </div>
            <div class="flex justify-end items-center gap-3">
                <app-is-enabled-indicator [value]="sport.isActive"></app-is-enabled-indicator>
                <div class="flex items-center justify-center">
                    <button
                            matRipple
                            class="flex justify-center items-center !rounded-full p-2 !bg-[#F5DC11]"
                            [matMenuTriggerFor]="menu"
                    >
                        <div class="flex justify-center items-center">
                            <mat-icon class="scale-75">more_horizontal</mat-icon>
                        </div>
                    </button>
                    <mat-menu class="menu pushed-menu" #menu="matMenu">
                        <a
                                mat-menu-item
                                class="item"
                                routerLink="/sports/{{sport.id}}"
                        >Edit</a>
                        <button
                                mat-menu-item
                                class="item"
                                (click)="toggleIsActive(sport)"
                        >{{sport.isActive ? "Disable" : "Enable"}}</button
                        >
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="sports?.length === 0 && searchField?.length !== 0" class="h-[400px] w-full flex items-center justify-center">
        <h2 class="text-xl text-gray-500">There are no sports with this name</h2>
    </div>
    <div *ngIf="isLoading" class="flex justify-center items-center w-full h-[400px]">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>
