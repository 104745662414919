import {Component, OnInit} from "@angular/core";
import {IWorkout} from "../../../shared/interfaces/IWorkout";
import {WorkoutsControllerService} from "../../../core/api/controllers/workouts/workouts-controller.service";
import {WorkoutControllerService} from "../../../core/api/controllers/workout-controller.service";
import {NotificationService} from "../../../core/services/notification.service";
import {GlobalPreferences} from "../../../core/helpers/global-data";
import {ActivatedRoute, Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {IGetWorkoutsPayload} from "../../../core/api/controllers/workouts/data/IGetWorkoutsPayload";

@Component({
  selector: 'app-deleted-workouts',
  templateUrl: './deleted-workouts.component.html',
  styleUrls: ['./deleted-workouts.component.scss']
})
export class DeletedWorkoutsComponent implements OnInit{
  workouts: IWorkout[] = [];
  page: number = 0;
  itemsPerPage: number = 25;
  totalCount: number = 0;
  isLoading = false;

  constructor(
      private workoutsService: WorkoutsControllerService,
      private workoutController: WorkoutControllerService,
      private notificationService: NotificationService,
      public global: GlobalPreferences,
      private router: Router,
      private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe({
      next: (params) => {
        if(params['page'] && params['page'] !== this.page) this.page = params['page'];
        if(params['itemsPerPage'] && params['itemsPerPage'] !== this.itemsPerPage) this.itemsPerPage = params['itemsPerPage'];
        this.getWorkouts();
      }
    })
    this.getWorkouts();
  }

  getWorkouts() {
    this.isLoading = true;
    this.workoutsService.getWorkouts(this.createPayload()).subscribe(
        (response) => {
          this.totalCount = response.totalCount;
          this.workouts = response.data;
          this.isLoading = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.isLoading = false;
        }
    );
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.router.navigate([], {
      queryParams: {
        page: this.page,
        itemsPerPage: this.itemsPerPage
      },
      queryParamsHandling: 'merge'
    })
  }

  createPayload(): IGetWorkoutsPayload {
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      locationId: localStorage.getItem("selectedLocationId"),
      isActive: false
    }
  }

  restoreWorkout(workoutId: string) {
    this.workouts = this.workouts.filter(workout => workout.id !== workoutId);
  }
}
