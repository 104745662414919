<div class="fullscreen">
  <div class="full-height" *ngIf="isLoading">
      <mat-spinner diameter="30"></mat-spinner>
  </div>

  <div *ngIf="locations.length === 0 && !isLoading">
    <h2>
      You do not have access to any locations. <br />
      Contact your administrator.
    </h2>
    <button class="link-button" (click)="logout()">Go to login page</button>
  </div>

  <app-select-locations
    [isRequired]="true"
    *ngIf="locations.length > 0 && !isLoading"
  ></app-select-locations>
</div>
