export enum SubscriptionBillingPeriod {
  Monthly,
  Annually,
}

export enum SubscriptionUserType {
  Clinic,
  Performance,
}

export enum ShopItemType {
  Product = 1,
  Subscription = 2,
  All = 3,
}

export enum DiscountType {
  FixedAmount = 0,
  Percentage = 1,
  Days = 2,
}

export enum AddressType {
  Personal = 1,
  Billing = 2,
  Shipping = 3,
}
