import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class LocationsControllerService {
  private readonly _resourceUrl: string = "/locations";

  constructor(private apiService: ApiService) {}

  getAllLocations(): Observable<any> {
    return this.apiService.get(
      `${this._resourceUrl}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  addLocation(value: any) {
    return this.apiService.post(
      `${this._resourceUrl}`,
      value,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  editLocation(value: any) {
    return this.apiService.put(
      `${this._resourceUrl}`,
      value,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteLocation(locationId: string, action: any, newLocationId?: string) {
    const params = { action };
    if (newLocationId) {
      Object.assign(params, { newLocationId });
    }
    return this.apiService
      .delete(
        `${this._resourceUrl}/${locationId}`,
        {},
        AuthService.defaultTokenHeader().headers,
      )
      .toPromise();
  }
}
