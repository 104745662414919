import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {possibleRestTime, possibleSets,} from "../../core/helpers/exercise-data";
import {NotificationService} from "../../core/services/notification.service";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {MediaControllerService} from "../../core/api/controllers/media-controller.service";
import {ExercisesControllerService} from "../../core/api/controllers/exercises-controller.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {
  AddExercisesDialogComponent,
  IAddExercisesDialogData,
} from "../../shared/components/add-exercises-dialog/add-exercises-dialog.component";
import {WorkoutControllerService} from "../../core/api/controllers/workout-controller.service";

@Component({
  selector: "app-save-workout",
  templateUrl: "./save-workout.component.html",
  styleUrls: ["./workouts.component.scss"],
  providers: [MediaControllerService, ExercisesControllerService],
})
export class SaveWorkoutComponent implements OnInit, OnDestroy {
  id;
  private sub;
  newWorkout;
  playlistIndex = null;
  athletesSelected = [];
  playlistsSelected = [];
  playlistExercisesSelected = [];
  addExercisesSelected = [];
  possibleSets = possibleSets;
  possibleRestTime = possibleRestTime;
  updateSelected = SaveWorkoutComponent.updateSelected;
  exerciseList = [];
  athleteList = [];
  filteredExerciseList = [];
  editing = false;
  canLoadAthletes = false;
  canLoadExerciseList = false;
  searchAddExercise = "";
  loading = false;
  searching = false;
  filterTimeout;
  globalSub;
  automatedArray: any = [];
  circuitArray: any = [];
  exerciseCategories = [];
  exercises = [];
  loadingExercises = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workoutController: WorkoutControllerService,
    private notificationService: NotificationService,
    private exercisesController: ExercisesControllerService,
    private exerciseService: ExercisesControllerService,
    public global: GlobalPreferences,
    private dialog: MatDialog,
    private exercisesControllerService: ExercisesControllerService,
  ) {}

  static updateSelected(value, array) {
    const newValue = {
      exerciseId: value.id,
      exerciseUUID: value.uuid,
      name: value.name,
      paired: value.paired,
      sets: 1,
    };
    const index = array.indexOf(newValue);
    index >= 0 ? array.splice(index, 1) : array.push(newValue);
  }

  ngOnInit() {
    this.automatedArray = Array.from({ length: 100 }, () => false);
    this.circuitArray = Array.from({ length: 100 }, () => false);
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf("save-" + data.workoutName) == -1) {
          this.router.navigate(["/save-" + data.workoutName]);
        }
      },
      (error) => this.notificationService.error(error),
    );
    this.getAthleteData();
    this.newWorkout = {
      name: "",
      playlists: [],
      collapsed: false,
    };
    this.sub = this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        if (this.id !== undefined) {
          this.retrieveWorkout();
        }
      },
      (error) => this.notificationService.error(error),
    );
  }

  getExerciseData() {
    const noOfExercises =
      this.exerciseList.length +
      this.newWorkout.playlists[this.playlistIndex].exerciseList.length;
    const page = noOfExercises > 0 ? Math.floor(noOfExercises / 20) : 0;
    const size = 20;
    const exercisesToFilter = this.newWorkout.playlists[
      this.playlistIndex
    ].exerciseList.map((value) => value.exerciseId);
    this.exercisesControllerService
      .getExerciseDetails(page, size, exercisesToFilter, this.searchAddExercise)
      .subscribe(
        (response) => {
          if (this.searchAddExercise == "") {
            for (const exercise of response) {
              this.exerciseList.push(exercise);
            }
            this.filteredExerciseList = Object.assign([], this.exerciseList);
          } else {
            for (const exercise of response) {
              this.filteredExerciseList.push(exercise);
            }
          }
          const exercises = response.length;
          this.canLoadExerciseList = exercises % 20 === 0 && exercises !== 0;
        },
        (error) => this.notificationService.error(error),
      );
  }

  getAthleteData() {
    const noOfAthletes = this.athleteList.length;
    const page = noOfAthletes > 0 ? Math.floor(noOfAthletes / 20) : 0;
    const size = 20;
    this.workoutController.getAthletes(page, size).subscribe(
      (response) => {
        this.athleteList =
          noOfAthletes > 0 ? this.athleteList.concat(response) : response;
        const canLoadLength = response.length;
        this.canLoadAthletes = canLoadLength % 20 === 0 && canLoadLength !== 0;
      },
      (error) => this.notificationService.error(error),
    );
  }

  retrieveWorkout() {
    this.workoutController.getWorkout(this.id).subscribe(
      (response) => {
        this.newWorkout = response["workout"];
        const playlists = response["workout"].playlists;
        for (let i = 0; i < playlists.length; i++) {
          let playlist = playlists[i];
          if (playlist.type == 0) {
            this.automatedArray[i] = false;
          } else if (playlist.type == 1) {
            this.automatedArray[i] = true;
            this.circuitArray[i] = true;
          } else if (playlist.type == 2) {
            this.automatedArray[i] = true;
            this.circuitArray[i] = false;
          }
        }
        this.newWorkout.collapsed = true;
        this.athletesSelected = response["athletes"];
        this.editing = true;
      },
      (error) => {
        if (error.status === 400) {
          this.router.navigate(["/save-workout"]);
        }
        this.notificationService.error(error);
      },
    );
  }

  addPlaylist() {
    this.newWorkout.playlists.push({
      name: "",
      type: 0,
      restTime: this.possibleRestTime[0],
      circuitSets: 1,
      exerciseList: [],
      collapsed: false,
    });
  }

  beginAddingExercises(index: number) {
    this.playlistIndex = index;
    this.addExercisesSelected = [];
    this.searchAddExercise = "";
    this.exerciseList = [];
    this.getExerciseData();
    this.getCategories();

    const data: IAddExercisesDialogData = {
      exerciseCategories: this.exerciseCategories,
      playlistIndex: index,
      searchAddExercise: this.searchAddExercise,
      exerciseList: this.exerciseList,
      addExercisesSelected: this.addExercisesSelected,
      exercises: this.exercises,
      newWorkout: this.newWorkout,
    };

    this.dialog.open(AddExercisesDialogComponent, {
      data: data,
    });
  }

  getCategories() {
    const locationId = localStorage.getItem("selectedLocationId");
    this.exercisesController.getCategories(locationId).subscribe(
      (data) => {
        this.exerciseCategories = data;
        this.exercises = [];
        this.exerciseCategories.forEach((value, index) => {
          this.getExerciseByCategory(value, index);
        });
      },
      (error) => {
        this.notificationService.error(error);
      },
    );
  }

  filterExercisesByCategory(data, id) {
    return data.filter((item) => {
      return item.exercises.some((exercise) => {
        return (
          (exercise.pairedExercise && exercise.pairedExercise.id === id) ||
          (exercise.regularExercise && exercise.regularExercise.id === id)
        );
      });
    });
  }

  getExerciseByCategory(category, index: number) {
    const locationId = localStorage.getItem("selectedLocationId");
    const exerciseForCategory = this.exercises[category.id];
    // const page = exerciseForCategory && exerciseForCategory.length > 0 ? Math.floor(exerciseForCategory.length / 20) : 0;
    // const size = 20;

    this.exerciseService.findAllByCategory(locationId).subscribe(
      (response: any) => {
        const exercisesByCategory = this.filterExercisesByCategory(
          response,
          category.id,
        );
        this.exercises[category.id] =
          exerciseForCategory && exerciseForCategory.length > 0
            ? this.exercises[category.id].concat(exercisesByCategory)
            : exercisesByCategory;
        const ind = this.exerciseCategories.indexOf(category);

        if (this.exercises[category.id].length === 0) {
          this.exerciseCategories.splice(ind, 1);
        }
      },
      (error) => this.notificationService.error(error),
    );

    if (index == this.exerciseCategories.length - 1) {
      this.loadingExercises = false;
    }
  }

  saveWorkout() {
    this.loading = true;
    if (this.newWorkout.creationDate == null) {
      this.newWorkout.creationDate = Date.now();
    }
    for (let i = 0; i < this.newWorkout.playlists.length; i++) {
      if (this.newWorkout.playlists[i].exerciseList.length === 0) {
        this.newWorkout.playlists.splice(i, 1);
        i--;
        continue;
      }
      this.addRequiredData(this.newWorkout.playlists[i]);
      this.newWorkout.playlists[i].index = i;
    }
    this.workoutController
      .saveWorkout({
        athletes: this.athletesSelected,
        workout: this.newWorkout,
      })
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(["/workouts"]);
          this.notificationService.success("Workout saved successfully.");
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        },
      );
  }

  addRequiredData(playlist) {
    if (playlist.type === 1) {
      const exerciseList = [];
      for (const exerciseItem of playlist.exerciseList) {
        exerciseList.push({
          exerciseId: exerciseItem.exerciseId,
          exerciseUUID: exerciseItem.exerciseUUID,
          name: exerciseItem.name,
          paired: exerciseItem.paired,
          sets: playlist.circuitSets,
        });
      }
      playlist.exerciseList = exerciseList;
    }
  }

  selectAllAthletes() {
    for (const athlete of this.athleteList) {
      if (this.athletesSelected.indexOf(athlete.id) == -1) {
        this.athletesSelected.push(athlete.id);
      }
    }
  }

  removeAllSelected() {
    let index;
    for (const playlist of this.playlistsSelected) {
      index = this.newWorkout.playlists.indexOf(playlist);
      if (index > -1) {
        this.newWorkout.playlists.splice(index, 1);
      }
    }

    for (const exerciseToRemove of this.playlistExercisesSelected) {
      for (const playlist of this.newWorkout.playlists) {
        for (const exercise of playlist.exerciseList) {
          index = playlist.exerciseList.indexOf(exerciseToRemove);
          if (index > -1) {
            playlist.exerciseList.splice(index, 1);
          }
        }
      }
    }
  }

  removePlaylist(index: number) {
    this.newWorkout.playlists.splice(index, 1);
  }

  removeExercise(playlistIndex: number, exerciseIndex: number) {
    this.newWorkout.playlists[playlistIndex].exerciseList.splice(
      exerciseIndex,
      1,
    );
  }

  filterExerciseList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        this.filteredExerciseList = Object.assign([], this.exerciseList);
        const length = this.exerciseList.length;
        this.canLoadExerciseList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 1) {
        return;
      }
      this.searching = true;
      this.exercisesControllerService
        .getExerciseDetails(
          0,
          20,
          this.newWorkout.playlists[this.playlistIndex].exerciseList.map(
            (value) => value.exerciseId,
          ),
          value,
        )
        .subscribe(
          (response: any) => {
            this.filteredExerciseList = response;
            const length = response.length;
            this.canLoadExerciseList = length % 20 === 0 && length !== 0;
            this.searching = false;
          },
          (error) => {
            this.notificationService.error(error);
            this.searching = false;
          },
        );
    }, 350);
  }

  cannotSaveWorkout(): boolean {
    if (this.newWorkout.name == "" || this.newWorkout.playlists.length == 0) {
      return true;
    }
    let number = 0;
    for (const playlist of this.newWorkout.playlists) {
      if (playlist.exerciseList.length == 0 || playlist.name === "") {
        number++;
      }
    }
    return number === this.newWorkout.playlists.length;
  }

  openBackModal() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Discard Changes",
          message: "Are you sure you want to discard changes?",
          confirmButtonLabel: "Discard",
          cancelButtonLabel: "Cancel",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.router.navigate([this.global?.data?.workoutName]);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.globalSub.unsubscribe();
  }

  toggleAutomated(newPlaylist, i) {
    if (this.automatedArray[i]) {
      newPlaylist.type = 2;
      // this.automated = !this.automated;
      // this.automatedArray[i] = false;
    } else {
      newPlaylist.type = 0;
      // this.automated = !this.automated;
      // this.automatedArray[i] = true;
    }
  }

  toggleCircuit(newPlaylist, i) {
    if (this.circuitArray[i]) {
      newPlaylist.type = 1;
      // this.circuitArray[i] = false;
    } else if (!this.circuitArray[i] && this.automatedArray[i]) {
      newPlaylist.type = 2;
    } else {
      newPlaylist.type = 0;
      // this.circuitArray[i] = true;
    }
  }
}
