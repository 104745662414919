<div>
  <div class="header no-border gray-background center-text">
    Generate Report
  </div>
  <div class="content gray-background">
    <div>
      <label>
        <input
          type="radio"
          name="period"
          (change)="data.type = 0"
          value="0"
          checked
        />
        Weekly
      </label>
      <label>
        <input type="radio" name="period" (change)="data.type = 1" value="1" />
        Monthly
      </label>
    </div>

    <div class="ui search">
      <div class="ui icon input">
        <i class="search icon"></i>
        <input
          class="prompt"
          type="text"
          placeholder="Type athlete name."
          [(ngModel)]="data.searchAddAthlete"
          #addAthleteSearch
          (input)="filterAthleteList(addAthleteSearch.value)"
        />
      </div>
    </div>
    <div class="ui big active loader" *ngIf="searching"></div>

    <div class="overflowing">
      <div
        *ngFor="let athlete of filteredAthleteList"
        class="single-exercise box-shadow-bottom ui grid"
      >
        <div
          class="one wide computer two wide tablet two wide mobile column center-text left-column flex flex-center"
        >
          <div class="ui fitted checkbox zoomed">
            <input
              (change)="updateSelected(athlete.id, data.addAthletesSelected)"
              [checked]="data.addAthletesSelected.indexOf(athlete.id) > -1"
              type="checkbox"
            />
            <label></label>
          </div>
        </div>
        <div
          class="select-field middle-playlist three wide computer two wide tablet four wide mobile column flex flex-center"
        >
          <div class="profile-wrapper flex flex-center">
            <div
              *ngIf="
                athlete.profilePicturePath != null &&
                athlete.profilePicturePath != ''
              "
              [style.background-image]="
                'url(' + athlete?.profilePicturePath + ')'
              "
              class="profile-picture"
            ></div>

            <div
              *ngIf="
                athlete.profilePicturePath == null ||
                athlete.profilePicturePath == ''
              "
              [style.background-image]="'url(assets/images/no_avatar.png)'"
              class="profile-picture"
            ></div>
          </div>
        </div>

        <div
          class="twelve wide computer eleven wide tablet eight wide mobile column exercise-name"
        >
          <p class="grayish">{{ athlete.firstName }} {{ athlete.lastName }}</p>
        </div>
      </div>
      <div *ngIf="data.canLoadAthleteList">
        <p (click)="getAthletes()"><b class="load-more">Load more</b></p>
      </div>
    </div>
  </div>
  <div class="actions no-border gray-background center-text">
    <button type="button" class="ui button" (click)="dialogRef.close(false)">
      Close
    </button>
    <button
      type="button"
      class="qb-blue-background white-text ui button"
      [disabled]="data.addAthletesSelected.length === 0"
      (click)="dialogRef.close(true)"
    >
      Generate Report
    </button>
  </div>
</div>
