<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="full-height" *ngIf="!organizationsLoaded || !checkFinished">
    <div class="ui massive active text loader"></div>
  </div>

  <div class="w-100">
    <div class="card w-100 p-3 mb-2 mb-sm-3 mb-md-4 mb-lg-5">
      <div class="d-flex justify-content-between">
        <div class="align-self-center" *ngIf="checkFinished">
          <div *ngIf="multipleWorkoutIds.length === 1">
            <button
              *ngIf="!isWorkoutOnAllLocations && !addAllWorkout"
              class="btn btn-primary"
              (click)="addToAll()"
            >
              Add Workout to All
            </button>
          </div>
          <button
            *ngIf="isWorkoutOnAllLocations && !addAllWorkout"
            class="btn btn-primary"
            (click)="syncForPlaylists()"
          >
            Sync Playlists for All
          </button>
        </div>
        <div class="ui search align-self-center">
          <mat-form-field appearance="outline">
            <mat-label>Search organization</mat-label>
            <input
              matInput
              class="border-0"
              type="text"
              placeholder="organization name"
              (keyup)="onSearch(searchText)"
              [(ngModel)]="searchText"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="card w-100">
      <p class="p-3" *ngIf="addAllWorkout">
        The request has been sent. Please wait about 3-5 minutes for the workout
        to be added / synchronized to all locations. You will receive an email
        when the operation is finished
      </p>

      <div class="" *ngIf="showData">
        <div
          *ngFor="let org of organizations; let i = index"
          class="w-100 org-container"
        >
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title
                  appHighlight
                  [searchText]="searchText"
                  [orgName]="org.name"
                >
                  {{ org.name }}
                </mat-panel-title>
                <mat-panel-description
                  align="right"
                  *ngIf="org.locationWorkoutList.length === 1"
                  >{{ org.locationWorkoutList.length }} location
                </mat-panel-description>
                <mat-panel-description
                  *ngIf="org.locationWorkoutList.length !== 1"
                  >{{ org.locationWorkoutList.length }} locations
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div *ngFor="let loc of org.locationWorkoutList; let j = index">
                <div class="workout-row mb-3">
                  <p class="bold-text workout-name">
                    {{ loc.name }}
                    <span *ngIf="loc.foundUsernames"
                      >(
                      <span *ngFor="let user of loc.foundUsernames">
                        <span
                          appHighlight
                          [searchText]="searchText"
                          [orgName]="user"
                        >
                          {{ user }}
                        </span>

                        <span>, </span>
                      </span>
                      )</span
                    >
                  </p>

                  <div class="playlist-name" *ngIf="loc.workoutId == null">
                    <button
                      [disabled]="addWorkoutArray[i][j]"
                      class="btn btn-outline-primary"
                      (click)="onAddWorkoutIndividually(loc, i, j)"
                    >
                      Add
                    </button>
                  </div>

                  <div
                    class="playlist-name"
                    *ngIf="loc.playlistsSynced && loc.exerciseSynced"
                  >
                    <button disabled class="btn btn-outline-primary">
                      Syncronized
                    </button>
                  </div>

                  <div
                    class="playlist-name"
                    *ngIf="loc.playlistsSynced && !loc.exerciseSynced"
                  >
                    <button
                      (click)="syncExercise(loc, i, j)"
                      [disabled]="syncExerciseArray[i][j]"
                      class="btn btn-outline-primary"
                    >
                      Sync Workout
                    </button>
                  </div>

                  <div
                    class="playlist-name"
                    *ngIf="
                      loc.workoutId != null &&
                      !loc.playlistsSynced &&
                      !loc.exerciseSynced
                    "
                  >
                    <button
                      (click)="syncFull(loc, i, j)"
                      [disabled]="syncFullArray[i][j]"
                      class="btn btn-outline-primary"
                    >
                      Sync Workout
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse" *ngIf="canLoadOrganizations">
      <button
        mat-flat-button
        color="primary"
        class="qb-blue-background primary white-text ui button inline-display mt-2"
        (click)="getOrganizations()"
      >
        Load More
      </button>
    </div>
  </div>
</div>
