import {Component, Input} from "@angular/core";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IAthleteForm} from "../athletes.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRippleModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
  selector: "app-athlete-block",
  templateUrl: "./athlete-block.component.html",
  styleUrls: ["./athlete-block.component.scss"],
  imports: [
    MatCheckboxModule,
    MatRippleModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    RouterLink,
    NgIf
  ],
  standalone: true
})
export class AthleteBlockComponent {
  @Input() athlete: FormGroup<IAthleteForm>;
}
