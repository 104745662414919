import {Component, Inject, OnInit} from "@angular/core";
import * as moment from "moment/moment";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GlobalPreferences} from "../../../../core/helpers/global-data";
import {UsageReportsControllerService} from "../../../../core/api/controllers/usage-reports-controller.service";
import {SettingsService} from "../../../../core/services/settings.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-usage-report-dialog",
  templateUrl: "./usage-report-dialog.component.html",
  styleUrls: ["./usage-report-dialog.component.scss"],
})
export class UsageReportDialogComponent implements OnInit {
  newStartDate = undefined;
  newEndDate = undefined;
  isLoading = false;

  constructor(
    private httpClient: HttpClient,
    private usageReportsController: UsageReportsControllerService,
    public global: GlobalPreferences,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      organizationId: string;
    },
    private dialogRef: MatDialogRef<UsageReportDialogComponent>,
    private settingsService: SettingsService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  changeDate(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.newStartDate = moment(dateRangeStart.value).toDate();
    this.newEndDate = moment(dateRangeEnd.value).toDate();
  }

  isValidDate(newStartDate, newEndDate) {
    return (
      moment(newStartDate).isValid() &&
      moment(newEndDate).isValid() &&
      newStartDate !== undefined &&
      newEndDate !== undefined
    );
  }

  getResultData() {
    if (this.isValidDate(this.newStartDate, this.newEndDate)) this.fetchLogs();
  }

  fetchLogs() {
    this.isLoading = true;
    this.usageReportsController
      .exportUsageReports({
        start: this.newStartDate.toLocaleDateString(),
        end: this.newEndDate.toLocaleDateString(),
        organizationId: this.data.organizationId,
      })
      .subscribe({
        next: (res) => {
          this.downloadFile(res);
          this.dialogRef.close();
          this.isLoading = false;
        },
        error: (err) => {
          this.matSnackBar.open(err.error.error ?? "Something went wrong", "Close", {
            duration: 3000,
          });
          if(err.error.validationErrors) {
            this.matSnackBar.open(err.error.validationErrors[0].message, "Close", {
              duration: 3000,
            });
          }
          this.isLoading = false;
        },
      });
  }

  downloadFile(res) {
    const blob = new Blob([res], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    // Download the file with the given filename
    const a = document.createElement("a");
    a.href = url;
    const name =
      this.settingsService?.settings?.organization?.name +
      "_" +
      this.formatDateWithOffset() +
      ".xlsx";
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  formatDateWithOffset() {
    // Get the current UTC time
    const now = new Date();

    // Compute the local GMT offset (in hours)
    const timezoneOffset = now.getTimezoneOffset() / 60; // returns in minutes, so divide by 60 to get hours
    const gmtOffset = -timezoneOffset; // Invert because getTimezoneOffset() gives negative for positive GMT zones

    // Add the GMT offset (in hours) to the UTC time
    const offsetInMilliseconds = gmtOffset * 60 * 60 * 1000;
    const adjustedTime = new Date(now.getTime() + offsetInMilliseconds);

    // Format the adjusted time to "MM-dd-yyyy_HH-mm-ss"
    const formattedDate = adjustedTime
      .toISOString()
      .replace("T", "_")
      .replace(/\..+/, "")
      .split(":")
      .join("-");
    return (
      formattedDate.substring(5, 7) +
      "-" +
      formattedDate.substring(8, 10) +
      "-" +
      formattedDate.substring(0, 4) +
      "_" +
      formattedDate.substring(11)
    );
  }
}
