import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ReportsControllerService {
  constructor(private apiService: ApiService) {}

  getReports(): Observable<any> {
    return this.apiService.get(
      `/reports`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  generateReport(data): Observable<any> {
    return this.apiService.post(
      `/generate-report`,
      data,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  deleteReport(id: number) {
    return this.apiService.delete(
      `/report`,
      {
        id,
      },
      AuthService.defaultTokenHeader().headers,
    );
  }

  getAthletes(
    page: number,
    size: number,
    search: string = "",
  ): Observable<any> {
    const details =
      search == ""
        ? `page=${page}&size=${size}`
        : `page=${page}&size=${size}&search=${search}`;
    return this.apiService.get(
      `/report-athletes?${details}`,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }
}
