import {Component, Input} from "@angular/core";
import {NotificationService} from "../../../core/services/notification.service";
import {ExercisesControllerService} from "../../../core/api/controllers/exercises-controller.service";
import {Router} from "@angular/router";
import {VideoPlayerComponent} from "../../../shared/components/video-player/video-player.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../../core/services/auth.service";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-category-exercises",
  templateUrl: "./category-exercises.component.html",
  styleUrls: ["./category-exercises.component.scss"],
})
export class CategoryExercisesComponent {
  @Input() exercises: any;
  @Input() exercisesSelected: any;
  @Input() selectedCategories: any;
  @Input() category: any;

  constructor(
    private exerciseService: ExercisesControllerService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {}

  onUpdateExerciseVideo(ex) {
    const exercise = this.getExerciseProps(ex);
    this.exerciseService.updateExerciseVideo(exercise.id).subscribe(
      () => {
        this.notificationService.success(
          "Video will be updated in a few moments",
        );
      },
      (error) => this.notificationService.error(error),
    );
  }

  getExerciseProps(exercise) {
    return {
      ...(exercise.regularExercise || exercise.pairedExercise),
      categoryId: exercise.categoryId,
    };
  }

  updateSelectedExercise(ex) {
    const exercise = this.getExerciseProps(ex);
    const intersection = this.exercisesSelected.find(
      ({ id }) => id === exercise.id,
    );
    if (intersection) {
      this.exercisesSelected.splice(
        this.exercisesSelected.indexOf(intersection),
        1,
      );
    } else {
      this.exercisesSelected.push(exercise);
    }
  }

  isPairedExercise(exercise) {
    return (
      Boolean(exercise.pairedExercise) &&
      Boolean(exercise.pairedExercise.firstExercise) &&
      Boolean(exercise.pairedExercise.secondExercise)
    );
  }

  addToSingleLocation(
    ex: any,
    categoryId: string = null,
    updateVideos = null,
  ): void {
    const queryParams = {};
    const exercise = this.getExerciseProps(ex);
    if (exercise) {
      Object.assign(queryParams, {
        exerciseId: exercise.id,
        exercisePaired: this.isPairedExercise(exercise),
      });
    }

    if (categoryId) {
      const currentSelectedExercises = this.exercisesSelected.filter(
        (item) => item.categoryId === categoryId,
      );
      if (!currentSelectedExercises.length) {
        return;
      }

      Object.assign(queryParams, {
        exerciseId: currentSelectedExercises.map((item) => item.id),
      });
    }

    if (updateVideos) {
      Object.assign(queryParams, { updateVideos });
    }

    this.router.navigate(["/sync-exercise"], { queryParams });
  }

  playVideo(videoUrl: string) {
    this.dialog.open(VideoPlayerComponent, {
      data: {
        toView: videoUrl,
      },
    });
  }

  setFavorited(exercise) {
    this.exerciseService
      .setField({
        id: exercise.id,
        field: "favorited",
        value: !exercise.favorited,
      })
      .subscribe(
        () => {
          exercise.favorited = !exercise.favorited;
        },
        (error) => this.notificationService.error(error),
      );
  }

  openDeleteConfirmationDialog(exercise: any) {
    const data = {
      title: "Delete Exercise",
      message: `Are you sure you want to delete the exercise "${exercise?.pairedExercise?.name || exercise?.regularExercise?.name}" ?`,
      cancelButtonLabel: "Cancel",
      confirmButtonLabel: "Delete",
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteExercise(exercise);
        }
      });
  }

  deleteExercise(exercise: any) {
    const id = exercise?.pairedExercise?.id || exercise?.regularExercise?.id;
    const exercisesBackup = [...this.exercises];
    this.exercises.splice(this.exercises.indexOf(exercise), 1);
    this.exerciseService.removeExercise(id).subscribe(
      () => {
        this.notificationService.success("Exercise deleted successfully.");
      },
      (error) => {
        this.exercises = exercisesBackup;
        this.notificationService.error(error);
      },
    );
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }
}
