<div class="row m-2 m-sm-3 m-md-4 m-lg-5">
  <div class="card w-100">
    <div class="w-100 border-bottom">
      <div class="p-3">
        <div class="w-100">
          <button
            type="button"
            class="text-uppercase btn btn-primary pb-1 pt-2 pl-4 pr-4 mr-3"
            disabled
          >
            <i class="material-icons button-icon"> add </i>
          </button>
          <button
            type="button"
            class="btn btn-secondary text-uppercase font-weight-bold"
            (click)="filtering = true"
            *ngIf="!filtering"
          >
            Filters
          </button>
          <button
            type="button"
            class="btn btn-secondary text-uppercase font-weight-bold"
            (click)="filtering = false"
            *ngIf="filtering"
          >
            Done
          </button>
        </div>
      </div>
    </div>

    <div [class]="{ toggled: !filtering }" class="filter-athlete-wrapper w-100">
      <div class="w-100">
        <div class="border-bottom p-3" *ngIf="filters.length != 0">
          <div title class="medium-font medium-text">
            <span class="font-weight-bold pointer" (click)="clearFilters()">
              Delete all terms
            </span>
          </div>
        </div>

        <div class="w-100">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold">Timeframe</span>
                <i class="dropdown icon"></i>
              </div>

              <div
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
                content
              >
                <div class="field">
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="fromDate == 0"
                    (checkChange)="fromDate = 0; updateResults()"
                  >
                    <p class="filter-text">Today</p>
                  </sui-checkbox>-->
                </div>

                <div class="field">
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="fromDate == 1"
                    (checkChange)="fromDate = 1; updateResults()"
                  >
                    <p class="filter-text">Last Week</p>
                  </sui-checkbox>-->
                </div>

                <div class="field">
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="fromDate == 2"
                    (checkChange)="fromDate = 2; updateResults()"
                  >
                    <p class="filter-text">Last Month</p>
                  </sui-checkbox>-->
                </div>

                <div class="field">
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="fromDate == 3"
                    (checkChange)="fromDate = 3; updateResults()"
                  >
                    <p class="filter-text">All Time</p>
                  </sui-checkbox>-->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100">
          <mat-accordion>
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >{{ global?.data?.uppercaseAthleteName }}
                  <ng-container *ngIf="possibleFilters['athletes']">
                    <span>({{ possibleFilters["athletes"].length }})</span>
                  </ng-container>
                </span>

                <i class="dropdown icon"></i>
              </div>

              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div
                  class="field"
                  *ngFor="let athlete of possibleFilters['athletes']"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(athlete.id) > -1"
                    (checkChange)="
                      updateFilters(
                        'athleteId',
                        athlete.id,
                        athlete.firstName + ' ' + athlete.lastName,
                        0
                      );
                      getResultData(true, 0)
                    "
                  >
                    <p class="filter-text">
                      {{ athlete.firstName }} {{ athlete.lastName }}
                    </p>
                  </sui-checkbox>-->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >{{ global?.data?.uppercaseAthleteName }} Category
                  <ng-container *ngIf="possibleFilters['athleteCategories']">
                    <span
                      >({{ possibleFilters["athleteCategories"].length }})</span
                    >
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
                content
              >
                <div
                  *ngFor="let category of possibleFilters['athleteCategories']"
                  class="field"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(category.id) > -1"
                    (checkChange)="
                      updateSelectedFilters(category.id);
                      selectAllFilters(
                        'athleteId',
                        category.elements,
                        selectedFilters.indexOf(category.id) > -1
                      )
                    "
                  >
                    <p class="filter-text">{{ category.name }}</p>
                  </sui-checkbox>-->
                  <div
                    class="filter box-shadow-bottom"
                    *ngIf="
                      category?.elements?.length != 0 &&
                      selectedFilters.indexOf(category.id) > -1
                    "
                  >
                    <mat-accordion class="ui fluid accordion">
                      <mat-expansion-panel>
                        <div title class="medium-text medium-font">
                          <span
                            >{{ global?.data?.uppercaseAthleteName }}
                            <ng-container>
                              <span>({{ category.elements?.length }})</span>
                            </ng-container>
                          </span>
                          <i class="dropdown icon"></i>
                        </div>
                        <div
                          content
                          class="field-wrapper d-flex flex-wrap border-bottom p-3"
                        >
                          <div
                            class="field"
                            *ngFor="let element of category.elements"
                          >
<!--                            <sui-checkbox
                              class="medium-label"
                              [ngModel]="
                                selectedFilters.indexOf(element.id) > -1
                              "
                              (checkChange)="
                                updateFilters(
                                  'athleteId',
                                  element.id,
                                  element.name,
                                  0
                                );
                                getResultData(true, 0)
                              "
                            >
                              <p class="filter-text">{{ element.name }}</p>
                            </sui-checkbox>-->
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100">
          <mat-accordion>
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >Exercise Category
                  <ng-container *ngIf="possibleFilters['exerciseCategories']">
                    ({{ possibleFilters["exerciseCategories"].length }})
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div
                  *ngFor="let category of possibleFilters['exerciseCategories']"
                  class="field"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(category.id) > -1"
                    (checkChange)="
                      updateSelectedFilters(category.id);
                      selectAllFilters(
                        'exerciseId',
                        category.elements,
                        selectedFilters.indexOf(category.id) > -1
                      )
                    "
                  >
                    <p class="filter-text">{{ category.name }}</p>
                  </sui-checkbox>-->
                  <div
                    class="filter box-shadow-bottom"
                    *ngIf="
                      category?.elements?.length != 0 &&
                      selectedFilters.indexOf(category.id) > -1
                    "
                  >
                    <mat-accordion class="ui fluid accordion">
                      <mat-expansion-panel>
                        <div title class="medium-text medium-font">
                          <span
                            >Exercise
                            <ng-container>
                              ({{ category.elements?.length }})
                            </ng-container>
                          </span>
                          <i class="dropdown icon"></i>
                        </div>
                        <div
                          content
                          class="field-wrapper d-flex flex-wrap border-bottom p-3"
                        >
                          <div
                            class="field"
                            *ngFor="let element of category.elements"
                          >
<!--                            <sui-checkbox
                              class="medium-label"
                              [ngModel]="
                                selectedFilters.indexOf(element.id) > -1
                              "
                              (checkChange)="
                                updateFilters(
                                  'exerciseId',
                                  element.id,
                                  element.name,
                                  0
                                );
                                getResultData(true, 0)
                              "
                            >
                              <p class="filter-text">{{ element.name }}</p>
                            </sui-checkbox>-->
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </mat-expansion-panel> </mat-accordion
          >`
        </div>

        <div class="w-100">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold">
                  Sport
                  <ng-container *ngIf="possibleFilters['sports']">
                    ({{ possibleFilters["sports"]?.length || 0 }})
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div
                  class="field"
                  *ngFor="let sport of possibleFilters['sports']"
                >
 <!--                 <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(sport.id) > -1"
                    (checkChange)="
                      updateFilters('sportId', sport.id, sport.name, 0);
                      selectAllFilters(
                        'positionId',
                        sport.positions,
                        selectedFilters.indexOf(sport.id) > -1
                      )
                    "
                  >
                    <p class="filter-text">{{ sport.name }}</p>
                  </sui-checkbox>-->
                  <div
                    class="filter box-shadow-bottom"
                    *ngIf="
                      sport?.positions?.length != 0 &&
                      selectedFilters.indexOf(sport.id) > -1
                    "
                  >
                    <mat-accordion class="ui fluid accordion">
                      <mat-expansion-panel [isOpen]="false">
                        <div title class="medium-text medium-font">
                          <span>
                            Position
                            <ng-container>
                              ({{ sport.positions?.length }})
                            </ng-container>
                          </span>
                          <i class="dropdown icon"></i>
                        </div>
                        <div
                          content
                          class="field-wrapper d-flex flex-wrap border-bottom p-3"
                        >
                          <div
                            class="field"
                            *ngFor="let position of sport.positions"
                          >
<!--                            <sui-checkbox
                              class="medium-label"
                              [ngModel]="
                                selectedFilters.indexOf(position.id) > -1
                              "
                              (checkChange)="
                                updateFilters(
                                  'positionId',
                                  position.id,
                                  position.name,
                                  0
                                );
                                getResultData(true, 0)
                              "
                            >
                              <p class="filter-text">{{ position.name }}</p>
                            </sui-checkbox>-->
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100">
          <mat-accordion >
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >Level
                  <ng-container *ngIf="possibleFilters['levels']">
                    ({{ possibleFilters["levels"]?.length || 0 }})
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div
                  class="field"
                  *ngFor="let level of possibleFilters['levels']"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(level.id) > -1"
                    (checkChange)="
                      updateFilters('levelId', level.id, level.name, 0);
                      getResultData(true, 0)
                    "
                  >
                    <p class="filter-text">{{ level.name }}</p>
                  </sui-checkbox>-->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100">
          <mat-accordion>
            <mat-expansion-panel>
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >Team
                  <ng-container *ngIf="possibleFilters['teams']">
                    ({{ possibleFilters["teams"]?.length + 1 }})
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div class="field">
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf('No Team') > -1"
                    (checkChange)="
                      updateFilters('teamId', '', 'No Team', 0);
                      getResultData(true, 0)
                    "
                  >
                    <p class="filter-text">No Team</p>
                  </sui-checkbox>-->
                </div>
                <div
                  class="field"
                  *ngFor="let team of possibleFilters['teams']"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(team.id) > -1"
                    (checkChange)="
                      updateFilters('teamId', team.id, team.name, 0);
                      getResultData(true, 0)
                    "
                  >
                    <p class="filter-text">{{ team.name }}</p>
                  </sui-checkbox>-->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="w-100" *ngIf="possibleFilters['ipads']?.length > 0">
          <mat-accordion class="ui fluid accordion">
            <mat-expansion-panel
              [isOpen]="possibleFilters['ipads']?.length > 0"
            >
              <div
                title
                class="d-flex justify-content-between border-bottom p-3 pointer"
              >
                <span class="font-weight-bold"
                  >iPads
                  <ng-container *ngIf="possibleFilters['ipads']">
                    ({{ possibleFilters["ipads"]?.length || 0 }})
                  </ng-container>
                </span>
                <i class="dropdown icon"></i>
              </div>
              <div
                content
                class="field-wrapper d-flex flex-wrap border-bottom p-3"
              >
                <div
                  class="field"
                  *ngFor="let ipad of possibleFilters['ipads']"
                >
<!--                  <sui-checkbox
                    class="medium-label"
                    [ngModel]="selectedFilters.indexOf(ipad.id) > -1"
                    (checkChange)="
                      updateFilters('activeIpads', ipad.id, ipad.name, 0);
                      getResultData(true, 0)
                    "
                  >
                    <p class="filter-text">{{ ipad.name }}</p>
                  </sui-checkbox>-->
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="ui inline cookie nag">
        <span class="title">
          We use cookies to ensure you get the best experience on our website
        </span>
        <i class="close icon"></i>
      </div>
    </div>
  </div>

  <div class="card w-100 mt-2 mt-sm-3 mt-md-4 mt-lg-5">
    <div class="full-height" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div
      class="results-container w-100"
      [class]="{ toggled: filtering }"
      *ngIf="resultsList.length != 0"
    >
      <div class="w-100">
        <div class="w-100 border-bottom border-top">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    class="nav-link pointer p-3 text-uppercase font-weight-bold"
                    (click)="tabular = false"
                    [class.active]="!tabular"
                    [class.inactive]="tabular"
                    >Graphical</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link pointer p-3 text-uppercase font-weight-bold"
                    (click)="tabular = true"
                    [class.active]="tabular"
                    [class.inactive]="!tabular"
                    >Tabular</a
                  >
                </li>
              </ul>
            </div>

            <button
              type="button"
              class="btn btn-primary text-uppercase text-white mr-3"
              [disabled]="exporting || resultsList.length == 0"
              (click)="massExport()"
            >
              Mass
              <ng-container *ngIf="!exporting">Export</ng-container>
              <ng-container *ngIf="exporting">Exporting...</ng-container>
            </button>
          </div>
        </div>

        <div
          class="results-wrapper box-shadow-bottom"
          [class]="{ toggled: filtering, loading: data?.loading }"
          *ngFor="let data of resultsList; let i = index"
        >
          <div class="wrapper">
            <div class="w-100 p-3 border-bottom">
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <div class="exercise-text">
                  <span class="font-weight-bold h6">{{ data.name }}</span>
                  <p class="">
                    {{ data.endValue | number: "1.0-3" }}&nbsp;
                    <ng-container *ngIf="data.endType == 2"
                      >Seconds&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.endType == 3"
                      >Touches&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.endType == 4"
                      >Arrows&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.endType == 10"
                      >Jumps&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.delay"
                      >{{
                        data.delay | number: "1.0-3"
                      }}
                      Delay&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.promptType == 0"
                      >Solid&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.promptType == 1"
                      >{{ data.flash | number: "1.0-1" }} Flash Time&nbsp;
                    </ng-container>
                    <ng-container *ngIf="data.inputType == 0"
                      >Unknown&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.inputType == 1"
                      >Bluetooth&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.inputType == 2"
                      >Cabled&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.inputType == 3"
                      >Touchscreen&nbsp;</ng-container
                    >
                    <ng-container *ngIf="data.inputType == 4"
                      >AR&nbsp;</ng-container
                    >
                  </p>
                </div>

                <button
                  class="btn btn-outline-primary text-uppercase"
                  type="button"
                  (click)="exportResultData(data)"
                  [disabled]="data.exporting"
                >
                  <ng-container *ngIf="!data.exporting">Export</ng-container>
                  <ng-container *ngIf="data.exporting"
                    >Exporting...</ng-container
                  >
                </button>
              </div>

              <div class="overflowing-table overflowing">
                <table
                  class="w-100 table-bordered table-striped"
                  *ngIf="tabular && data.results.length != 0"
                >
                  <thead>
                    <tr>
                      <th colspan="3">Name</th>
                      <th colspan="3">Date</th>
                      <th *ngIf="[1].includes(data.exerciseType)">
                        Avg. Reaction
                      </th>
                      <th
                        *ngIf="[0].includes(data.exerciseType) && !data.paired"
                      >
                        Left Touches
                      </th>
                      <th
                        *ngIf="[0].includes(data.exerciseType) && !data.paired"
                      >
                        Right Touches
                      </th>
                      <th *ngIf="[0, 1, 2].includes(data.exerciseType)">
                        Touches
                      </th>
                      <th *ngIf="[1, 2].includes(data.exerciseType)">Errors</th>
                      <th *ngIf="[4].includes(data.exerciseType)">
                        Jump Height
                      </th>
                      <th *ngIf="[4].includes(data.exerciseType)">
                        Avg Air Time
                      </th>
                      <th *ngIf="[4].includes(data.exerciseType)">RSI</th>
                      <th
                        *ngIf="
                          [0, 1, 2, 4].includes(data.exerciseType) &&
                          !data.paired
                        "
                      >
                        Change%
                      </th>
                      <th
                        *ngIf="
                          [0, 1, 2, 4].includes(data.exerciseType) &&
                          data.paired
                        "
                      >
                        Difference%
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container
                      *ngFor="let result of data.results; let j = index"
                    >
                      <tr
                        (click)="collapse[i][j] = !collapse[i][j]"
                        *ngIf="
                          (result.result1 || result.result2) && data.paired
                        "
                      >
                        <td colspan="3" class="date-cell" data-label="Name">
                          {{ result.firstName }} {{ result.lastName }}
                        </td>

                        <td colspan="3" class="date-cell" data-label="Date">
                          {{ result.date | date: "MMM d, y, h:mm a" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg. Reaction"
                          *ngIf="[1].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.result1">
                            {{
                              result.result1.averageReactionTime | resultNumber
                            }}
                          </p>
                          <p *ngIf="result.result2">
                            {{
                              result.result2.averageReactionTime | resultNumber
                            }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="Touches"
                          *ngIf="[0, 1, 2].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.result1">
                            {{ result.result1.touches }}
                          </p>
                          <p *ngIf="result.result2">
                            {{ result.result2.touches }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="Errors"
                          *ngIf="[1, 2].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.result1">
                            {{ result.result1.errors }}
                          </p>
                          <p *ngIf="result.result2">
                            {{ result.result2.errors }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="Jump Height"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.result1">
                            {{ result.result1.score | number: "1.0-3" }}
                          </p>
                          <p *ngIf="result.result2">
                            {{ result.result2.score | number: "1.0-3" }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg Air Time"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.averageAirTime">
                            {{ result.averageAirTime | resultNumber }}
                          </p>
                          <p *ngIf="result.result1">
                            {{ result.result1.averageAirTime | resultNumber }}
                          </p>
                          <p *ngIf="result.result2">
                            {{ result.result2.averageAirTime | resultNumber }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="RSI"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          <p *ngIf="result.result1">
                            {{ result.result1.rsi || "-" }}
                          </p>
                          <p *ngIf="result.result2">
                            {{ result.result2.rsi || "-" }}
                          </p>
                        </td>

                        <td
                          colspan="1"
                          data-label="%Change"
                          class="green"
                          *ngIf="[0, 1, 2, 4].includes(data.exerciseType)"
                        >
                          <!-- {{ result.percentChange || "-" }}<span *ngIf="result.percentChange" style="color: inherit">%</span> -->
                          {{ result.difference || "-"
                          }}<span
                            *ngIf="result.difference"
                            style="color: inherit"
                            >%</span
                          >
                        </td>
                      </tr>

                      <tr
                        (click)="collapse[i][j] = !collapse[i][j]"
                        *ngIf="
                          !result.result1 &&
                          !result.result2 &&
                          data.paired == false
                        "
                      >
                        <td colspan="3" class="date-cell" data-label="Name">
                          {{ result.firstName }} {{ result.lastName }}
                        </td>

                        <td colspan="3" class="date-cell" data-label="Date">
                          {{ result.date | date: "MMM d, y, h:mm a" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg. Reaction"
                          *ngIf="[1].includes(data.exerciseType)"
                        >
                          {{ result.averageReactionTime | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Left Touches"
                          *ngIf="[0].includes(data.exerciseType)"
                        >
                          {{ result.leftTouches }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Right Touches"
                          *ngIf="[0].includes(data.exerciseType)"
                        >
                          {{ result.rightTouches }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Touches"
                          *ngIf="[0, 1, 2].includes(data.exerciseType)"
                        >
                          {{ result.touches }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Errors"
                          *ngIf="[1, 2].includes(data.exerciseType)"
                        >
                          {{ result.errors }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Jump Height"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.score | number: "1.0-3" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg Air Time"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.averageAirTime | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="RSI"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.rsi || "-" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="%Change"
                          [class]="{
                            green: result.color === 1,
                            red: result.color === -1,
                          }"
                          *ngIf="[0, 1, 2, 4].includes(data.exerciseType)"
                        >
                          {{ result.percentChange || "-"
                          }}<span
                            *ngIf="result.percentChange"
                            style="color: inherit"
                            >%</span
                          >
                        </td>
                      </tr>

                      <tr
                        (click)="collapse[i][j] = !collapse[i][j]"
                        *ngIf="result.result1 && data.paired == false"
                      >
                        <td colspan="3" class="date-cell" data-label="Name">
                          {{ result.firstName }} {{ result.lastName }}
                        </td>

                        <td colspan="3" class="date-cell" data-label="Date">
                          {{ result.date | date: "MMM d, y, h:mm a" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg. Reaction"
                          *ngIf="[1].includes(data.exerciseType)"
                        >
                          {{
                            result.result1.averageReactionTime | resultNumber
                          }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Touches"
                          *ngIf="[0, 1, 2].includes(data.exerciseType)"
                        >
                          {{ result.result1.touches }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Errors"
                          *ngIf="[1, 2].includes(data.exerciseType)"
                        >
                          {{ result.result1.errors }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Jump Height"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result1.score | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg Air Time"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result1.averageAirTime | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="RSI"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result1.rsi || "-" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="%Change"
                          [class]="{
                            green: result.result1.color === 1,
                            red: result.result1.color === -1,
                          }"
                          *ngIf="[0, 1, 2, 4].includes(data.exerciseType)"
                        >
                          {{ result.result1.percentChange || "-"
                          }}<span
                            *ngIf="result.result1.percentChange"
                            style="color: inherit"
                            >%</span
                          >
                        </td>
                      </tr>

                      <tr
                        (click)="collapse[i][j] = !collapse[i][j]"
                        *ngIf="result.result2 && data.paired == false"
                      >
                        <td colspan="3" class="date-cell" data-label="Name">
                          {{ result.firstName }} {{ result.lastName }}
                        </td>

                        <td colspan="3" class="date-cell" data-label="Date">
                          {{ result.date | date: "MMM d, y, h:mm a" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg. Reaction"
                          *ngIf="[1].includes(data.exerciseType)"
                        >
                          {{
                            result.result2.averageReactionTime | resultNumber
                          }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Touches"
                          *ngIf="[0, 1, 2].includes(data.exerciseType)"
                        >
                          {{ result.result2.touches }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Errors"
                          *ngIf="[1, 2].includes(data.exerciseType)"
                        >
                          {{ result.result2.errors }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Jump Height"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result2.score | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="Avg Air Time"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result2.averageAirTime | resultNumber }}
                        </td>

                        <td
                          colspan="1"
                          data-label="RSI"
                          *ngIf="[4].includes(data.exerciseType)"
                        >
                          {{ result.result2.rsi || "-" }}
                        </td>

                        <td
                          colspan="1"
                          data-label="%Change"
                          [class]="{
                            green: result.result2.color === 1,
                            red: result.result2.color === -1,
                          }"
                          *ngIf="[0, 1, 2, 4].includes(data.exerciseType)"
                        >
                          {{ result.result2.percentChange || "-"
                          }}<span
                            *ngIf="result.result2.percentChange"
                            style="color: inherit"
                            >%</span
                          >
                        </td>
                      </tr>

                      <tr
                        *ngFor="
                          let sensor of result?.sensorResults;
                          let k = index
                        "
                        [hidden]="collapse[i][j]"
                      >
                        <td colspan="2" style="color: #292a2b">
                          <p class="sensor-title">
                            <img
                              *ngIf="k < 5"
                              class="sensors mr-2"
                              src="../../assets/images/{{ k + 1 }}of5-1.png"
                              style="width: 40px; height: auto"
                            />Sensor {{ k + 1 }}
                          </p>
                        </td>
                        <td colspan="10" *ngIf="result.exerciseType == 1">
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                            class="sensor-data"
                          >
                            <span style="color: #32353c"
                              >Avg React Time:
                              {{
                                sensor.averageReactionTime | number: "1.3-3"
                              }}</span
                            >
                            <span style="color: #32353c"
                              >Avg Contact Time:
                              {{
                                sensor.averageContactTime | number: "1.3-3"
                              }}</span
                            >
                            <span style="color: #32353c"
                              >Touches: {{ sensor.totalTouches }}</span
                            >
                            <span style="color: #32353c"
                              >Errors: {{ sensor.totalErrors }}</span
                            >
                          </div>
                        </td>
                        <td
                          colspan="8"
                          *ngIf="
                            result.exerciseType != 1 && data.countType != 1
                          "
                        >
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                            class="sensor-data"
                          >
                            <span style="color: #32353c"
                              >Avg Contact Time:
                              {{
                                sensor.averageContactTime | number: "1.3-3"
                              }}</span
                            >
                            <span style="color: #32353c"
                              >Touches: {{ sensor.totalTouches }}</span
                            >
                            <span style="color: #32353c"
                              >Errors: {{ sensor.totalErrors }}</span
                            >
                          </div>
                        </td>

                        <td
                          colspan="9"
                          *ngIf="
                            result.exerciseType == 0 && data.countType == 1
                          "
                        >
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                            class="sensor-data"
                          >
                            <span style="color: #32353c"
                              >Avg Contact Time:
                              {{
                                sensor.averageContactTime | number: "1.3-3"
                              }}</span
                            >
                            <span style="color: #32353c"
                              >Touches: {{ sensor.totalTouches }}</span
                            >
                            <span style="color: #32353c"
                              >Errors: {{ sensor.totalErrors }}</span
                            >
                          </div>
                        </td>
                      </tr>

                      <ng-container *ngIf="result?.result1 && result?.result2">
                        <ng-container
                          *ngFor="
                            let sensor of result?.result1?.sensorResults;
                            let k = index
                          "
                        >
                          <tr [hidden]="collapse[i][j]">
                            <td rowspan="2" colspan="2" style="color: #292a2b">
                              <p class="sensor-title">
                                <img
                                  *ngIf="k < 5"
                                  class="sensors mr-2"
                                  src="../../assets/images/{{ k + 1 }}of5-1.png"
                                  style="width: 40px; height: auto"
                                />Sensor {{ k + 1 }}
                              </p>
                            </td>
                            <td
                              colspan="1"
                              style="color: #292a2b"
                              *ngIf="result?.result1?.side"
                            >
                              {{ result?.result1?.side.toUpperCase() }}
                            </td>
                            <td
                              colspan="1"
                              style="color: #292a2b"
                              *ngIf="!result?.result1?.side"
                            >
                              {{ result?.result1?.shortenedName.toUpperCase() }}
                            </td>
                            <td colspan="11" *ngIf="result.exerciseType == 1">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                "
                                class="sensor-data"
                              >
                                <span style="color: #32353c"
                                  >Avg React Time:
                                  {{
                                    sensor.averageReactionTime | number: "1.2-2"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Avg Contact Time:
                                  {{
                                    sensor.averageContactTime | number: "1.3-3"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Touches: {{ sensor.totalTouches }}</span
                                >
                                <span style="color: #32353c"
                                  >Errors: {{ sensor.totalErrors }}</span
                                >
                              </div>
                            </td>
                            <td colspan="10" *ngIf="result?.exerciseType != 1">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                "
                                class="sensor-data"
                              >
                                <span style="color: #32353c"
                                  >Avg Contact Time:
                                  {{
                                    sensor.averageContactTime | number: "1.3-3"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Touches: {{ sensor.totalTouches }}</span
                                >
                                <span style="color: #32353c"
                                  >Errors: {{ sensor.totalErrors }}</span
                                >
                              </div>
                            </td>
                          </tr>
                          <tr [hidden]="collapse[i][j]">
                            <td
                              colspan="1"
                              style="color: #292a2b"
                              *ngIf="result?.result2?.side"
                            >
                              {{ result?.result2?.side.toUpperCase() }}
                            </td>
                            <td
                              colspan="1"
                              style="color: #292a2b"
                              *ngIf="!result?.result2?.side"
                            >
                              {{ result?.result2?.shortenedName.toUpperCase() }}
                            </td>
                            <td colspan="11" *ngIf="result.exerciseType == 1">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                "
                                class="sensor-data"
                              >
                                <span style="color: #32353c"
                                  >Avg React Time:
                                  {{
                                    result.result2.sensorResults[k]
                                      .averageReactionTime | number: "1.2-2"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Avg Contact Time:
                                  {{
                                    result.result2.sensorResults[k]
                                      .averageContactTime | number: "1.3-3"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Touches:
                                  {{
                                    result.result2.sensorResults[k].totalTouches
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Errors:
                                  {{
                                    result.result2.sensorResults[k].totalErrors
                                  }}</span
                                >
                              </div>
                            </td>
                            <td colspan="10" *ngIf="result.exerciseType != 1">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                "
                                class="sensor-data"
                              >
                                <span style="color: #32353c"
                                  >Avg Contact Time:
                                  {{
                                    result.result2.sensorResults[k]
                                      .averageContactTime | number: "1.3-3"
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Touches:
                                  {{
                                    result.result2.sensorResults[k].totalTouches
                                  }}</span
                                >
                                <span style="color: #32353c"
                                  >Errors:
                                  {{
                                    result.result2.sensorResults[k].totalErrors
                                  }}</span
                                >
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div
                *ngIf="data?.loading"
              >
                  <mat-spinner diameter="30"></mat-spinner>
              </div>

              <div class="w-100" *ngIf="data.canLoad && tabular">
                <button
                  type="button"
                  class="text-uppercase btn btn-primary d-block mt-3 mx-auto"
                  (click)="updateSelectedResults(data)"
                >
                  Show More
                </button>
              </div>

              <div
                class="chart-wrapper"
                *ngIf="!tabular && data?.results?.length != 0"
              >
                <div
                  class="chart flex flex-center"
                  *ngIf="data.chart"
                  [chart]="data.chart"
                  id="paired-charts"
                ></div>
                <ng-container *ngIf="data.chart1 || data.chart2">
                  <div
                    class="chart flex flex-center chart1"
                    [chart]="data.chart1"
                    id="chart1"
                  ></div>
                  <div
                    class="chart flex flex-center chart2"
                    [chart]="data.chart2"
                    id="chart2"
                  ></div>
                </ng-container>
                <div *ngIf="data.canLoad">
                  <button
                    type="button"
                    class="ui primary button"
                    (click)="updateSelectedResults(data)"
                  >
                    Show More
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="data?.loading"
          >
              <mat-spinner diameter="30"></mat-spinner>
          </div>

          <div
            [class]="{ toggled: filtering }"
            class="small-no-results no-results flex flex-center"
            *ngIf="data?.results?.length == 0 && !data?.loading"
          >
            <i class="material-icons chart-icon alt-color-text">insert_chart</i>
            <span class="h4 alt-color-text">No Results</span>
            <p class="error-text">
              There are no results to show. Select the filters on the left to
              show results based on your preferences, or type in the search bar
              above.
            </p>
          </div>
        </div>

        <div class="w-100 p-3">
          <div class="d-flex justify-content-center align-items-center">
            <ul class="pagination mt-2">
              <li class="page-item">
                <a
                  class="page-link cursor pointer"
                  *ngIf="currentPage != 0"
                  (click)="currentPage = currentPage - 1; getResultData(true)"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link cursor pointer">{{ currentPage + 1 }}</a>
              </li>
              <li class="page-item">
                <a
                  class="page-link cursor pointer"
                  *ngIf="
                    resultsList?.length != 0 && resultsList?.length % 5 == 0
                  "
                  (click)="currentPage = currentPage + 1; getResultData(true)"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex align-items-center flex-column justify-content-center p-1 w-100"
      *ngIf="resultsList.length == 0 && !loading"
    >
      <div class="w-100 text-center p-3">
        <i
          class="material-icons d-block chart-icon text-center chart-icon alt-color-text"
          >insert_chart</i
        >
        <span class="text-center h4 alt-color-text">No Results</span>
        <p class="text-center">
          There are no results to show. Select the filters on the left to show
          results based on your preferences, or type in the search bar above.
        </p>
      </div>
    </div>
  </div>
</div>
