import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../core/services/login.service";
import { NotificationService } from "../../core/services/notification.service";
import { NotifyParentService } from "../../core/services/notify-parent.service";
import { MixpanelService } from "../../core/services/mixpanel.service";
import { throwError } from "rxjs";
import { CustomValidators } from "../../core/helpers/custom-validators";
import { AuthControllerService } from "../../core/api/controllers/auth-controller.service";
import { ValidationHelperService } from "../../core/helpers/validation-helper.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  codeForm: FormGroup;
  tfa = null;
  codeTry = "";
  maxLoginAttempts = 5;
  timeoutValue = 900; // in seconds
  timeoutInfo = {
    loginAttempts: 0,
    timeoutStart: 0,
  };

  isLoading = false;

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private authController: AuthControllerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private notifyParent: NotifyParentService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit() {
    this.getTimeoutInfo();
    this.storeTimeoutInfo();
    this.tfa =
      this.authService.checkTokenAccess("PRE_AUTH") ||
      this.authService.checkTokenAccess("ADMIN_PRE_AUTH");
    this.loginForm = this.formBuilder.group({
      loginUsername: ["", [Validators.required]],
      loginPassword: ["", [Validators.required]],
      rememberMe: [false],
    });
    this.codeForm = this.formBuilder.group({
      code: ["", [Validators.required, CustomValidators.codeValidator]],
    });
  }

  login() {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    if (this.timeoutInfo.loginAttempts >= this.maxLoginAttempts) {
      if (this.currentTimeout > 0)
        this.notificationService.error(
          `You have exceeded maximum login attempts of 5. You can try again in ${this.currentTimeoutMinute} minutes and ${this.currentTimeoutSecond} seconds.`,
        );
      else if (this.currentTimeout === 0) {
        if (this.timeoutInfo.timeoutStart === 0) {
          this.timeoutInfo.loginAttempts++;
          this.timeoutInfo.timeoutStart = new Date().getTime();
          this.notificationService.error(
            `You have exceeded maximum login attempts of 5. You can try again in ${this.currentTimeoutMinute} minutes and ${this.currentTimeoutSecond} seconds.`,
          );
        } else {
          this.timeoutInfo.timeoutStart = 0;
          this.timeoutInfo.loginAttempts = 0;
          this.makeLoginCall();
        }
      }
    } else this.makeLoginCall();
  }

  storeTimeoutInfo(): void {
    setInterval(() => {
      if (this.timeoutInfo)
        localStorage.setItem("timeoutInfo", JSON.stringify(this.timeoutInfo));
    }, 1000);
  }

  getTimeoutInfo(): void {
    if (localStorage.getItem("timeoutInfo"))
      this.timeoutInfo = JSON.parse(localStorage.getItem("timeoutInfo"));
  }

  makeLoginCall(): void {
    this.mixpanelService.track("login", {
      username: this.loginForm.controls["loginUsername"].value,
    });
    this.isLoading = true;
    this.authController
      .login(
        this.loginForm.controls["loginUsername"].value,
        this.loginForm.controls["loginPassword"].value,
      )
      .then((response) => {
        this.loginService.setTokens(
          this.loginForm.controls["rememberMe"].value,
          response["accessToken"],
          response["refreshToken"],
        );
        this.tfa =
          this.authService.checkTokenAccess("PRE_AUTH") ||
          this.authService.checkTokenAccess("ADMIN_PRE_AUTH");
        this.mixpanelService.track("login_success", {
          username: this.loginForm.controls["loginUsername"].value,
        });

        if (!this.tfa) {
          this.router.navigate(["/"]);
          this.notifyParent.sendData("fullUpdate");
        }

        if (response.selectedLocationId) {
          localStorage.setItem(
            "selectedLocationId",
            response.selectedLocationId,
          );
        }
        this.isLoading = false;
      })
      .catch((error) => {
        throwError(error);
        AuthService.removeOauthTokenFromStorage();
        this.timeoutInfo.loginAttempts++;
        this.notificationService.error("Username or password is incorrect");
        this.isLoading = false;
      });
  }

  login2FA() {
    this.loginService
      .login2FA(
        this.loginForm.controls["loginUsername"].value,
        this.loginForm.controls["loginPassword"].value,
        this.codeTry,
      )
      .then((response) => {
        this.loginService.setTokens(
          this.loginForm.controls["rememberMe"].value,
          response["access_token"],
          response["refresh_token"],
        );
        this.router.navigate(["/"]);
        this.notifyParent.sendData("fullUpdate");
      })
      .catch((error) => this.notificationService.error(error));
  }

  logout() {
    this.mixpanelService.track("logout", {});
    this.authService.logout();
  }

  get currentTimeoutMinute(): number {
    return Math.floor(this.currentTimeout / 60) + 1;
  }

  get currentTimeoutSecond(): number {
    return Math.floor(this.currentTimeout % 60);
  }

  get currentTimeout(): number {
    if (this.timeoutInfo.timeoutStart === 0) return 0;
    const cTimeout =
      this.timeoutValue -
      (new Date().getTime() - this.timeoutInfo.timeoutStart) / 1000;
    return cTimeout > 0 ? cTimeout : 0;
  }

  get loggedIn() {
    return this.authService.loggedIn();
  }

  protected readonly ValidationHelperService = ValidationHelperService;
}
