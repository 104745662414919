import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthControllerService {
  private readonly _resourceUrl: string = "/auth";

  constructor(private apiService: ApiService) {}

  logout(): Observable<any> {
    return this.apiService.post(
      `${this._resourceUrl}/logout`,
      null,
      {},
      AuthService.defaultTokenHeader().headers,
    );
  }

  login(username: string, password: string): Promise<any> {
    return this.apiService
      .post(`${this._resourceUrl}/login`, { username, password })
      .toPromise();
  }

  refresh(body): Observable<any> {
    const headers = new HttpHeaders()
      .set("Authorization", "Basic YnJvd3Nlcjo=")
      .set("Api-Version", "3.0");
    return this.apiService.post(`/auth/refresh`, body, {}, headers);
  }
}
