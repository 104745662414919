import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class LoggedOutGuard implements CanActivate {
  jwtHelper;

  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate() {
    const localToken = AuthService.getOauthTokenFromStorage();
    if (localToken === null || this.jwtHelper.isTokenExpired(localToken)) {
      return true;
    } else {
      return this.refuse();
    }
  }

  refuse(): boolean {
    this.router.navigate(["/"]);
    return false;
  }
}
