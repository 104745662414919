<div class="w-full h-screen bg-white">
  <div class="mx-auto max-w-[1000px] p-5 flex flex-col gap-5">
    <h2 class="text-center">Privacy Policy</h2>
      <div>
          <h3>Introduction</h3>
          <p>
              At The Quick Board, we are committed to protecting your privacy. This
              Privacy Policy outlines how we collect, use, share, and protect your
              personal and financial information when you access and use our athletic
              performance enhancement and fitness services and products.
          </p>
      </div>
    <div>
        <h3>Data Collection</h3>
        <p>
            To access and use our services, you must provide certain personal
            information at the time of registration, including your name, address,
            email address, and payment information. We may also collect additional
            data such as IP addresses, browser type, and browsing history through
            cookies and similar tracking technologies. This information is necessary
            to manage individual subscriber accounts, process payments, and improve
            our services.
        </p>
    </div>
    <div>
        <h3>Data Use</h3>
        <p>The information we collect is used to:</p>
        <ul class="list-disc list-inside pl-5">
            <li>Manage and recognize your account.</li>
            <li>
                Process transactions, including charging your credit card for services
                and products purchased.
            </li>
            <li>
                Contact you for technical support, provide product updates, and
                communicate account information.
            </li>
            <li>
                Send you opt-in newsletters, promotional offers, and other
                correspondence, which you can unsubscribe from at any time .
            </li>
            <li>
                Conduct internal analytics to better understand user preferences and
                improve our services.
            </li>
        </ul>
        <p>
            We retain your personal data only for as long as necessary to fulfill the
            purposes for which it was collected, including any legal or reporting
            requirements.
        </p>
    </div>
    <div>
        <h3>Data Sharing</h3>
        <p>
            Your financial information, such as your credit card number and expiration
            date, is shared only with our secure payment gateway and payment processor
            to complete transactions. We do not share your financial information with
            any other third parties.
        </p>
        <p>
            We may share your personal information with third-party service providers
            who assist us in operating our website, conducting our business, or
            servicing you. These providers have access to personal information needed
            to perform their functions but are not permitted to use it for other
            purposes.
        </p>
        <p>
            We may also disclose your personal information if required by law, to
            protect our rights, or in the event of a merger or acquisition.
        </p>
    </div>
    <div>
        <h3>Security</h3>
        <p>
            We have implemented industry-standard security measures to protect against
            the loss, misuse, or alteration of the information under our control. All
            orders are processed through a secure server (SSL), and we adhere to PCI
            DSS compliance standards to ensure the protection of your payment
            information.
        </p>
        <p>
            However, please note that no method of transmission over the internet or
            electronic storage is 100% secure, and we cannot guarantee absolute
            security.
        </p>
    </div>
    <div>
        <h3>User Rights</h3>
        <p>
            You have the right to access, correct, or delete your personal information
            at any time by logging into your account manager. If you wish to restrict
            the processing of your data or withdraw your consent to certain uses, you
            may do so by contacting us at the email address provided below.
        </p>
    </div>
    <div>
        <h3>Cookies and Tracking Technologies</h3>
        <p>
            We use cookies and similar tracking technologies to enhance your
            experience on our website and analyze usage patterns. You can manage your
            cookie preferences through your browser settings.
        </p>
    </div>
    <div>
        <h3>Children’s Privacy</h3>
        <p>
            Our services are not intended for children under the age of 13. We do not
            knowingly collect personal information from children under 13. If we
            become aware that we have inadvertently collected such information, we
            will take steps to delete it
        </p>
    </div>
    <div>
        <h3>Policy Updates</h3>
        <p>
            We may update this Privacy Policy from time to time to reflect changes in
            our practices or for other operational, legal, or regulatory reasons. We
            will notify you of any significant changes by posting the new Privacy
            Policy on our website and updating the effective date. We encourage you to
            review this policy periodically.
        </p>
    </div>
    <div>
        <h3>Compliance with Laws</h3>
        <p>
            We are committed to complying with applicable data protection laws,
            including the General Data Protection Regulation (GDPR) and the California
            Consumer Privacy Act (CCPA). If you are a resident of the European Union
            or California, you may have additional rights under these laws.
        </p>
    </div>
    <div>
        <h3>Contacting Us</h3>
        <p>If you have any questions or concerns about this policy, the practices of this site, or any of the
            products or services of The Quick Board, please contact us at info@thequickboard.com.</p>
    </div>
  </div>
</div>
