import {IInjuryGrade} from "./IInjuryGrade";

export interface IInjury {
  id: string;
  name: string;
  injuryLocation?: EInjuryLocation;
  grades?: IInjuryGrade[];
  isActive?: boolean;
}

export enum EInjuryLocation {
  Head,
  Hip,
  Thigh,
  Knee,
  Leg,
  Ankle,
  Foot,
  Shoulder,
  Elbow,
  Wrist,
  Hand,
  Neck,
  Back,
  Neuro
}

export const getInjuryLocationName = (injuryLocation: EInjuryLocation): string => {
  switch (injuryLocation) {
    case EInjuryLocation.Head:
      return "Head";
    case EInjuryLocation.Hip:
      return "Hip";
    case EInjuryLocation.Thigh:
      return "Thigh";
    case EInjuryLocation.Knee:
      return "Knee";
    case EInjuryLocation.Leg:
      return "Leg";
    case EInjuryLocation.Ankle:
      return "Ankle";
    case EInjuryLocation.Foot:
      return "Foot";
    case EInjuryLocation.Shoulder:
      return "Shoulder";
    case EInjuryLocation.Elbow:
      return "Elbow";
    case EInjuryLocation.Wrist:
      return "Wrist";
    case EInjuryLocation.Hand:
      return "Hand";
    case EInjuryLocation.Neck:
      return "Neck";
    case EInjuryLocation.Back:
      return "Back";
    case EInjuryLocation.Neuro:
      return "Neuro";
    default:
      throw new Error("Unknown injury location");
  }
};
