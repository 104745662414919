import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface IAddExercisesDialogData {
  exerciseCategories: any;
  playlistIndex: number;
  searchAddExercise: string;
  exerciseList: any;
  exercises: any;
  addExercisesSelected: any;
  newWorkout: any;
}

@Component({
  selector: "app-add-exercises-dialog",
  templateUrl: "./add-exercises-dialog.component.html",
  styleUrls: ["./add-exercises-dialog.component.scss"],
})
export class AddExercisesDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IAddExercisesDialogData) {}

  updateSelected(value, array) {
    const newValue = {
      exerciseId: value.id,
      exerciseUUID: value.uuid,
      name: value.name,
      paired: value.paired,
      sets: 1,
    };
    const index = array.indexOf(newValue);
    index >= 0 ? array.splice(index, 1) : array.push(newValue);
  }

  isAlreadyAdded(exercise: any): boolean {
    for (const addedExercise of this.data.addExercisesSelected) {
      if (addedExercise.id === exercise.id) {
        return true;
      }
    }
    return false;
  }

  addExercises() {
    for (const exercise of this.data.addExercisesSelected) {
      this.data.newWorkout.playlists[this.data.playlistIndex].exerciseList.push(
        exercise,
      );
    }
    this.data.playlistIndex = null;
  }
}
