import {Component, Input} from "@angular/core";
import {IDraggableRegularExerciseConfig} from "../playlist-row-form.component";
import {FormGroup} from "@angular/forms";
import {ValidationHelperService} from "../../../../core/helpers/validation-helper.service";
import {IPlaylistForm} from "../../workout-form/WorkoutFormFactory";

@Component({
  selector: 'app-draggable-exercise-config',
  templateUrl: './draggable-exercise-config.component.html',
  styleUrls: ['./draggable-exercise-config.component.scss']
})
export class DraggableExerciseConfigComponent {
  @Input() config: IDraggableRegularExerciseConfig;
  @Input() playlistForm: FormGroup<IPlaylistForm>;
  protected readonly ValidationHelperService = ValidationHelperService;
}
