<div *ngIf="!isLoading">
  <mat-form-field
    appearance="outline"
    *ngIf="teamMode == 'select'"
    class="w-full"
  >
    <mat-label>Team</mat-label>
    <mat-select
      (click)="_control.markAsUntouched()"
      placeholder="Team"
      (selectionChange)="selectTeamObject($event)"
      [formControl]="_control"
    >
      <mat-option *ngFor="let option of teamList" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>

    <button
      mat-icon-button
      matSuffix
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon fontSet="material-icons-outlined">more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        type="button"
        (click)="teamMode = 'add'; _modifyControl.setValue('')"
      >
        Add team
      </button>
      <button
        mat-menu-item
        type="button"
        *ngIf="_control.value != ''"
        (click)="
          _modifyControl.setValue(_control.value.name); teamMode = 'edit'
        "
      >
        Edit team
      </button>
    <!--  <button
        mat-menu-item
        type="button"
        *ngIf="_control.value != ''"
        (click)="openConfirmModal()"
      >
        Remove team
      </button>-->
    </mat-menu>
    <mat-error *ngIf="ValidationHelperService.isInvalid(_control)">{{
      ValidationHelperService.getErrorMessage(_control)
    }}</mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    *ngIf="teamMode != 'select'"
    class="w-full"
  >
    <mat-label>Team</mat-label>
    <input
      type="text"
      [formControl]="_modifyControl"
      matInput
      (focus)="_control.markAsUntouched()"
    />
    <div matSuffix class="d-flex justify-content-center align-items-center">
      <button
        mat-icon-button
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          teamMode = 'select';
          updateAthleteTeam()
        "
        *ngIf="teamMode == 'edit' && _modifyControl?.valid"
      >
        <mat-icon fontSet="material-icons-outlined">check</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="teamMode == 'add' && _modifyControl?.valid"
        (click)="addAthleteTeam()"
      >
        <mat-icon fontSet="material-icons-outlined">check</mat-icon>
      </button>
      <button mat-icon-button (click)="closeTeam()">
        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
      </button>
    </div>
    <mat-error *ngIf="ValidationHelperService.isInvalid(_modifyControl)">{{
      ValidationHelperService.getErrorMessage(_modifyControl)
    }}</mat-error>
  </mat-form-field>
</div>
