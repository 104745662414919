import {Component, OnDestroy, OnInit} from "@angular/core";
import {NotificationService} from "../../core/services/notification.service";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {AuthService} from "../../core/services/auth.service";
import {MatTableDataSource} from "@angular/material/table";
import {ISubscription, IUser} from "../../core/models/subscription.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {AdminControllerService} from "../../core/api/controllers/admin-controller.service";
import {PaymentControllerService} from "../../core/api/controllers/payment-controller.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  subscription = { type: "", endDate: "" };
  favoriteExercises = [];
  mostActiveAthletesChart = { value: null, fromDate: 3, loading: false };
  totalItems;
  usersPage = 0;
  usersSize = 10;
  usersDataSource;
  users: IUser[];
  subscriptions: ISubscription[] = [];
  subscriptionForm: FormGroup;
  updatedUsers = [];
  searchText = "";
  displayedColumns: string[] = [
    "userName",
    "organizationName",
    "firstName",
    "lastName",
    "email",
    "status",
    "lastBilled",
    "subscription",
    "price",
    "athleteLimit",
    "creditCardLast4",
  ];
  valueChanged = false;
  refreshTimer;
  athleteCount: number;
  organizationCount: number;
  userCount: number;

  private searchText$ = new Subject<string>();


  constructor(
    private paymentController: PaymentControllerService,
    private adminController: AdminControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mixpanelService.track("dashboard", {});
    this.searchText$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
    ).subscribe(() => {
      this.getUsers();
    });
    this.getSubscription();
    if (this.isSuperAdmin()) {
      this.getOrganizationsActiveCount();
      this.getUsers();
    }
    this.subscriptionForm = this.fb.group({
      subscriptionControl: this.fb.array([
        this.fb.group({
          name: "",
        }),
      ]),
    });
  }

  getSubscription() {
    this.subscription = JSON.parse(localStorage.getItem("userSubscription"));
  }

  debouncedGetUsers(evt: any): void {
    this.searchText$.next(evt.target.value);
  }

  getUsers() {
    this.adminController
      .getUsers(this.usersPage, this.usersSize, this.searchText)
      .subscribe(
        (res) => {
          this.users = res.data;
          this.usersDataSource = new MatTableDataSource(
            res.data
          );
          this.totalItems = res.totalCount;
        },
        (error) => this.notificationService.error(error),
      );
  }

  changed(element) {
    this.valueChanged = true;
    this.userUpdated(element);
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SuperAdmin");
  }

  pageChanged(event) {
    this.usersPage = event.pageIndex;
    this.usersSize = event.pageSize;
    this.cancelUpdate();
  }

  userUpdated(element: IUser) {
    console.log(element)
    const user = {
      id: element.organizationId,
      subscriptionId: element.subscriptionDetails.id,
      subscriptionStatus: element.subscriptionDetails.subscriptionStatus,
      lastBilled: element.subscriptionDetails.lastBilled,
      athleteLimit: element.subscriptionDetails.athleteLimit,
      price: element.subscriptionDetails.price,
    };
    this.updatedUsers.push(user);
  }

  cancelUpdate() {
    this.updatedUsers = [];
    this.getUsers();
  }

  updateUsers() {
    this.adminController
      .updateUsers({ editedSubscriptions: this.updatedUsers })
      .subscribe(
        (data) => {
          this.updatedUsers = [];
          this.getUsers();
        },
        ({ error }) => {
          this.updatedUsers = [];
          this.getUsers();
          if (error.validationErrors && error.validationErrors.length) {
            error.validationErrors.forEach((er) => {
              this.notificationService.error(er);
            });
          } else {
            this.notificationService.error(error.error);
          }
        },
      );
  }

  deleteCreditCard(organizationId) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Delete Credit Card",
          message: "Are you sure you want to delete this credit card?",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.paymentController.deleteCreditCard(organizationId).subscribe(
            ({ message }) => {
              this.getUsers();
              this.notificationService.success(message);
            },
            (error) => this.notificationService.error(error),
          );
        }
      });
  }

  getOrganizationsActiveCount() {
    this.adminController
      .getOrganizationsActiveCount()
      .subscribe(({ athletesCount, usersCount, organizationsCount }) => {
        this.athleteCount = athletesCount;
        this.userCount = usersCount;
        this.organizationCount = organizationsCount;
      });
  }

  ngOnDestroy() {
    this.favoriteExercises.forEach((exercise) => {
      clearTimeout(exercise.refreshTimer);
    });
  }
}
