import {Component, Input, OnInit} from "@angular/core";
import {LocationsService} from "../../core/services/locations.service";
import {Router} from "@angular/router";
import {AuthService} from "../../core/services/auth.service";
import {NotifyParentService} from "../../core/services/notify-parent.service";
import {ILocation} from "../../shared/interfaces/ILocation";
import {FormControl, FormGroup} from "@angular/forms";
import {AccountControllerService} from "../../core/api/controllers/account-controller.service";
import {UsersControllerService} from "../../core/api/controllers/users-controller.service";

@Component({
  selector: "app-select-locations",
  templateUrl: "./select-locations.component.html",
  styleUrls: ["./select-locations.component.scss"],
})
export class SelectLocationsComponent implements OnInit {
  form = new FormGroup({
    location: new FormControl(""),
  });

  @Input() isRequired = false;

  constructor(
    private locationService: LocationsService,
    private router: Router,
    private authService: AuthService,
    private childNotifier: NotifyParentService,
    private accountController: AccountControllerService,
    private usersController: UsersControllerService,
  ) {}

  locations: ILocation[] = [];
  searchedLocations: ILocation[] = [];
  isLoading = false;

  ngOnInit() {
    this.getLocation();
  }

  getLocation(): void {
    this.isLoading = true;
    this.accountController.getUserSettings().subscribe((resp) => {
      this.locations = resp.locations;
      this.isLoading = false;
    });
  }

  selectLocation(id) {
    this.usersController.selectLocation(id).subscribe((resp) => {
      localStorage.setItem("selectedLocationId", id);
      this.router.navigate(["/"]);
      this.childNotifier.sendData("update");
    });
  }

  goHome(): void {
    this.router.navigate(["/"]);
  }

  get selectedLocation() {
    const locationId = localStorage.getItem("selectedLocationId");
    return this.locations.find((location) => location.id === locationId);
  }

  isSelected(id: string) {
    return this.selectedLocation && this.selectedLocation.id === id;
  }

  updateSearchedLocations() {
    this.searchedLocations = this.locations.filter((location) =>
      location.name
        .toLowerCase()
        .includes(this.form.get("location").value.toLowerCase()),
    );
  }

  logout() {
    this.authService.logout();
  }
}
