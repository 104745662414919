<div
        class="bg-[#1A1A1C] text-[#C0C0C5] h-screen p-3 flex flex-col justify-between items-center w-full"
>
    <div class="flex justify-center items-center flex-grow max-w-[400px] w-full">
        <div
                class="w-full h-full flex flex-col gap-[6rem] justify-center items-center"
                style="max-width: 35rem"
        >
                <p class="text-4xl font-bold text-[#C0C0C5] m-0">
                    Forgot Password
                </p>
            <form
                    *ngIf="!loggedIn"
                    [formGroup]="resetForm"
                    (ngSubmit)="reset()"
                    class="flex flex-col gap-5 w-full"
            >
                <div class="custom-form-field">
                    <label for="loginUsername">Username</label>
                    <input
                            type="text"
                            [formControl]="resetForm.controls.loginUsernameEmail"
                            id="loginUsername"
                            placeholder="username"
                    />
                    <mat-error *ngIf="ValidationHelperService.isInvalid(resetForm.controls.loginUsernameEmail)">
                        {{ ValidationHelperService.getErrorMessage(resetForm.controls.loginUsernameEmail) }}
                    </mat-error>
                </div>
                <div class="flex flex-col gap-2">
                    <button
                            type="submit"
                            [disabled]="isLoading"
                            class="!py-[16px] !px-[32px] rounded-2xl text-black bg-[#F5DC11] hover:bg-yellow-300 font-medium transition-all relative"
                    >
                        <span>Reset password</span>
                        <div class="absolute right-[25%] top-[50%] translate-y-[-50%]">
                            <mat-spinner
                                    *ngIf="isLoading"
                                    diameter="30"
                                    color="accent"
                            ></mat-spinner>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="flex gap-3 justify-center items-center">
        <p class="!m-0 text-[#838388]">You have remembered your password?</p>
        <a
                routerLink="/register"
                class="bg-[#3F3F41] text-white rounded-xl hover:bg-yellow-300 transition-all no-underline py-[10px] px-[24px]"
        >Log in</a
        >
    </div>
</div>
