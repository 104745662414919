<div>
  <div class="header no-border gray-background center-text" id="modal-header">
    Add Activity
  </div>

  <div class="content gray-background">
    <div class="activities">
      <div id="activity-selection">
        <h3>Select Activity</h3>

        <div id="activities-container">
          <div class="activity-wrapper">
            <div class="activity">
              <i class="ui icon child"></i>
              <p>Agility</p>
            </div>
          </div>

          <div class="activity-wrapper">
            <div class="activity">
              <i class="ui icon child"></i>
              <p>Agility</p>
            </div>
          </div>

          <div class="activity-wrapper">
            <div class="activity">
              <i class="ui icon child"></i>
              <p>Agility</p>
            </div>
          </div>

          <div class="activity-wrapper">
            <div class="activity">
              <i class="ui icon child"></i>
              <p>Agility</p>
            </div>
          </div>
        </div>
      </div>

      <div id="test-selection">
        <h3>Select Test</h3>

        <div class="ui right labeled input">
          <input class="activity-form" placeholder="Some data" />
        </div>
      </div>

      <div id="test-info">
        <h3>Test Info</h3>

        <div id="inputs">
          <form class="ui form">
            <div class="three fields">
              <div class="ui field">
                <div class="ui right labeled input">
                  <input class="activity-form" placeholder="Value" />
                  <select class="info-select">
                    <option [value]="0">measurement</option>
                    <option [value]="1">other measurement</option>
                  </select>
                </div>
              </div>

              <div class="ui field">
                <!--                                <div class="ui right icon input">
                                    <input class="activity-form medium-text"
                                           data-language='en'
                                           readonly="true"
                                           (click)="picker.open()"
                                           (dateInput)="changeDate($event)"
                                           (dateChange)="changeDate($event)"
                                           matInput [matRangeDatepicker]="picker">
                                    <mat-range-datepicker
                                        #picker
                                        [rangeMode]="true">
                                    </mat-range-datepicker>
                                </div>-->
                <label class="small-label grey-label">Date</label>
              </div>

              <div class="ui field">
                <div class="ui right icon input">
                  <i class="cloud upload icon"></i>
                  <input class="activity-form medium-text" type="text" />
                </div>
                <label class="small-label grey-label">Upload Video</label>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="flex flex-center">
        <button class="ui positive button">CREATE ACTIVITY</button>
      </div>
    </div>
  </div>
</div>
