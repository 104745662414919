import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AthletesControllerService} from "../../core/api/controllers/athletes-controller.service";
import {NotificationService} from "../../core/services/notification.service";
import {GlobalPreferences} from "../../core/helpers/global-data";
import {Router} from "@angular/router";
import {MixpanelService} from "../../core/services/mixpanel.service";
import {ISingleAthleteDto} from "../../shared/DTOs/ISingleAthleteDto";
import {MatAccordion} from "@angular/material/expansion";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface IAthleteFilter {
  name: string;
  id: string;
}

export interface IAthleteSportFilter {
  id: string;
  name: string;
  noPositionId: string;
  positions: IAthleteFilter[];
}

export interface IGetFilters {
  categoryFilters: IAthleteFilter[];
  levelFilters: IAthleteFilter[];
  sportFilters: IAthleteSportFilter[];
  teamFilters: IAthleteFilter[];
}

@Component({
  selector: "app-athletes",
  templateUrl: "./athletes.component.html",
  styleUrls: ["./athletes.component.scss"],
  providers: [AthletesControllerService],
})
export class AthletesComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  athletesForm = new FormGroup({
    athletes: new FormArray<FormGroup<{athleteObject: FormControl<ISingleAthleteDto>, isSelected: FormControl<boolean>}>>([])
  })
  athleteList: ISingleAthleteDto[] = [];
  possibleFilters = [];
  filters = [];
  selectedFilters = [];
  filtering = false;
  active = true;
  canLoadAthletes = false;
  isLoaded;
  globalSub;
  filtersForm: FormGroup<{
    categories: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
    levels: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
    sports: FormArray<
        FormGroup<{
          id: FormControl<string>,
          name: FormControl<string>,
          isSelected: FormControl<boolean>,
            noPositionId: FormControl<string>,
          positions: FormArray<
              FormGroup<{
                id: FormControl<string>,
                name: FormControl<string>,
                isSelected: FormControl<boolean>
              }>
          >
        }>>,
    teams: FormArray<FormGroup<{id: FormControl<string>, name: FormControl<string>, isSelected: FormControl<boolean>}>>,
  }>;

  constructor(
    private athletesController: AthletesControllerService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private router: Router,
    private mixpanelService: MixpanelService,
    private snackBarService: MatSnackBar
  ) {}

  ngOnInit() {
    this.mixpanelService.track("athlete_screen", {});
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf(data.athleteName + "s") == -1) {
          this.router.navigate(["/" + data.athleteName + "s"]);
        }
      },
      (error) => this.notificationService.error(error),
    );
    const previousFilters = JSON.parse(
      sessionStorage.getItem("athleteFilters"),
    );
    if (previousFilters != null) {
      this.filters = previousFilters.filters;
      this.selectedFilters = previousFilters.selectedFilters;
      this.active = previousFilters.active;
    }
    this.updateAthleteList();
    this.getPossibleFilters();
  }

  initForm(response: IGetFilters): void {
    this.filtersForm = new FormGroup({
      categories: new FormArray([]),
      levels: new FormArray([]),
      sports: new FormArray([]),
      teams: new FormArray([]),
    });
    response.categoryFilters.forEach((filter) => {
      this.filtersForm.controls.categories.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
    response.levelFilters.forEach((filter) => {
      this.filtersForm.controls.levels.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
    response.sportFilters.forEach((filter) => {
      const newSportFilter =  new FormGroup({
            id: new FormControl(filter.id),
            name: new FormControl(filter.name),
            isSelected: new FormControl(false),
            positions: new FormArray([]),
            noPositionId: new FormControl(filter.noPositionId),
          });

      filter.positions.forEach((position) => {
        newSportFilter.controls.positions.push(
          new FormGroup({
            id: new FormControl(position.id),
            name: new FormControl(position.name),
            isSelected: new FormControl(false),
          }),
        );
      });

      this.filtersForm.controls.sports.push(newSportFilter);
    });
    response.teamFilters.forEach((filter) => {
      this.filtersForm.controls.teams.push(
        new FormGroup({
          id: new FormControl(filter.id),
          name: new FormControl(filter.name),
          isSelected: new FormControl(false),
        }),
      );
    });
  }

  removeSelectedAthletes(): void {
    this.isLoaded = false;
    this.athletesController
      .deleteMultipleAthletes(this.selectedAthletes)
      .subscribe({
        next: () => {
          this.athleteList = this.athleteList.filter(
              (athlete) => !this.selectedAthletes.includes(athlete.id),
          );
          this.setAthletesFormValue(this.athleteList);
          this.isLoaded = true;
        },
        error: () => {
          this.snackBarService.open("Something went wrong trying to delete athletes. Please try again later.", "Close", {
            duration: 2000,
          });
          this.isLoaded = true;
        }
      });
  }

  updateAthleteList(shouldFilter: boolean = false) {
    this.isLoaded = false;
    const noOfAthletes = this.athleteList.length;
    if (shouldFilter) {
      this.athleteList = [];
      sessionStorage.setItem(
        "athleteFilters",
        JSON.stringify({
          selectedFilters: this.selectedFilters,
          filters: this.filters,
          active: this.active,
        }),
      );
    }

    const selectedLocationId = localStorage.getItem("selectedLocationId");
    this.athletesController
      .getAthletes(selectedLocationId, this.constructFilterString())
      .subscribe(
        (response) => {
          this.athleteList =
            noOfAthletes > 0 ? this.athleteList.concat(response) : response;
          const canLoadLength = response.length;
          this.canLoadAthletes =
            canLoadLength % 20 === 0 && canLoadLength !== 0;
          this.setAthletesFormValue(this.athleteList);
          this.isLoaded = true;
        },
        (error) => this.notificationService.error(error),
      );
  }

  setAthletesFormValue(athletesList: ISingleAthleteDto[]) {
    this.athletesForm.controls.athletes.clear();
    athletesList.forEach((athlete) => {
      this.athletesForm.controls.athletes.push(
          new FormGroup({
            athleteObject: new FormControl(athlete),
            isSelected: new FormControl(false),
          })
      )
    });
  }

  constructFilterString() {
      let filters = {
          categoryIds: [],
          sportPositionIds: [],
          levelIds: [],
          teamIds: []
      };
      if(this.filtersForm){
          filters = {
              categoryIds: this.filtersForm.value.categories.filter(category => category.isSelected).map(category => category.id),
              sportPositionIds: [],
              levelIds: this.filtersForm.value.levels.filter(level => level.isSelected).map(level => level.id),
              teamIds: this.filtersForm.value.teams.filter(team => team.isSelected).map(team => team.id),
          };
          const sportPositionIds = this.filtersForm.value.sports.reduce((acc, sport) => {
              if(sport.isSelected) {
                  const selectedPositions = sport.positions.filter(position => position.isSelected);
                  if(selectedPositions.length > 0)
                    return acc.concat(selectedPositions.map(position => position.id));
                  else return acc.concat([sport.noPositionId]);
              } else return acc;
          }, []);
          filters.sportPositionIds = sportPositionIds || [];
      }
     return filters;
  }

  getPossibleFilters() {
      this.isLoaded = false;
    const selectedLocationId = localStorage.getItem("selectedLocationId");
    this.athletesController.getPossibleFilters(selectedLocationId).subscribe(
      (response) => {
        this.possibleFilters = response;
        this.initForm(response);
        this.isLoaded = true;
      },
      (error) => this.notificationService.error(error),
    );
  }

    resetFilters(): void {
      this.filtersForm.controls.categories.controls.forEach((category) => {
        category.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.levels.controls.forEach((level) => {
        level.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.teams.controls.forEach((team) => {
        team.controls.isSelected.setValue(false);
      });

      this.filtersForm.controls.sports.controls.forEach((sport) => {
        sport.controls.isSelected.setValue(false);
        sport.controls.positions.controls.forEach((position) => {
          position.controls.isSelected.setValue(false);
        });
      });
      this.updateAthleteList();
    }

  get hasFilters() {
    let isFiltered = false;
    if(!this.filtersForm) return false;
    isFiltered = isFiltered || this.filtersForm.value.categories.some(category => category.isSelected);
    isFiltered = isFiltered || this.filtersForm.value.levels.some(level => level.isSelected);
    isFiltered = isFiltered || this.filtersForm.value.teams.some(team => team.isSelected);
    this.filtersForm.controls.sports.value.forEach(sport => {
      isFiltered = isFiltered || sport.isSelected;
      isFiltered = isFiltered || sport.positions.some(position => position.isSelected);
    })
    return isFiltered;
  }

  ngOnDestroy() {
    this.globalSub.unsubscribe();
    sessionStorage.removeItem("athleteFilters");
  }

  get selectedAthletes(): string[] {
    return this.athletesForm.controls.athletes.controls.filter(
        (control) => control.value.isSelected,
    ).map(
        athlete => athlete.value.athleteObject.id
    );
  }
}
