import { Component, OnInit } from "@angular/core";
import {
  IUserActionDialogData,
  UserActionDialogComponent,
} from "./user-action/user-action-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../../core/services/notification.service";
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { UsersControllerService } from "../../../core/api/controllers/users-controller.service";
import { LocationsControllerService } from "../../../core/api/controllers/locations-controller.service";

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.scss"],
})
export class TeamComponent implements OnInit {
  users = [];
  locations = [];
  currentUser: any;
  isLoading = false;

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private usersController: UsersControllerService,
    private locationsController: LocationsControllerService,
  ) {}

  ngOnInit(): void {
    this.getLocations();
  }

  addUser(): void {
    const data: IUserActionDialogData = {
      action: "Add",
    };
    this.dialog
      .open(UserActionDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getUsers();
        }
      });
  }

  disableUser(currentUser: any): void {
    this.currentUser = currentUser;
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Disable " + currentUser.username,
          message: `Are you sure you want to disable user: ${currentUser.username}?`,
        } as IConfirmationDialogData,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.confirmDisableUser();
        }
      });
  }

  confirmDisableUser(): void {
    this.isLoading = true;
    this.usersController.disableUser(this.currentUser.id).subscribe({
      next: () => {
        this.getUsers();
        this.notificationService.success("User disabled successfully.");
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.error(error.error);
        this.isLoading = false;
      },
    });
  }

  getLocations(): void {
    this.isLoading = true;
    this.locationsController.getAllLocations().subscribe(
      (response) => {
        this.locations = response.locations;
        this.locations = this.locations.map((location) => {
          return {
            ...location,
            ips: ["192.168.1.1", "172.192.15.13", "152.145.161.2"],
            collapsed: false,
          };
        });
        this.getUsers();
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  getUsers(): void {
    this.isLoading = true;
    this.usersController.getUsers().subscribe(
      (response) => {
        this.users = response;
        this.users.forEach((value) => {
          const locationsName = [];
          value.userLocations.forEach((location) => {
            locationsName.push(this.getLocationNameById(location));
          });
          value.locationsName = locationsName;
        });
        this.isLoading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.isLoading = false;
      },
    );
  }

  getLocationNameById(locationId: string): void {
    let name = null;
    this.locations.forEach((value) => {
      if (value.id == locationId) {
        name = value.name;
      }
    });
    return name;
  }

  deleteUser(currentUser: any): void {
    this.currentUser = currentUser;
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "Delete " + currentUser.username,
          message: `Are you sure you want to delete user: ${currentUser.username}?`,
        } as IConfirmationDialogData,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.confirmDeleteUser();
          this.getUsers();
        }
      });
  }

  confirmDeleteUser() {
    this.usersController.deleteUser(this.currentUser.id).subscribe(
      (response) => {
        if (response.id) {
          this.notificationService.success(
            `User deleted: ${this.currentUser.username}`,
          );
        } else {
          this.notificationService.error("We could not delete this user");
        }
        this.currentUser = {};
      },
      (error) => {
        this.notificationService.error(error);
      },
    );
  }

  editUser(currentUser: any) {
    const data: IUserActionDialogData = {
      action: "Edit",
      currentUser,
    };
    this.dialog
      .open(UserActionDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getUsers();
        }
      });
  }
}
